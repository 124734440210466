import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';

const CreditRange = ({ score }) => {
  const series = (score / 900) * 100;
  const [state, setState] = useState({
    series: [series],
    options: {
      chart: {
        type: 'radialBar',
        offsetY: -20,
        sparkline: {
          enabled: true,
        },
      },
      plotOptions: {
        radialBar: {
          startAngle: -135,
          endAngle: 135,
          track: {
            background: '#e7e7e7',
            strokeWidth: '97%',
            margin: 5,
          },
          dataLabels: {
            name: {
              show: false,
            },
            value: {
              offsetY: -2,
              fontSize: '22px',
              formatter: function (val) {
                return score;
              },
            },
          },
        },
      },
      grid: {
        padding: {
          top: -10,
        },
      },
      fill: {
        type: 'gradient',
      },
      colors: ['#DC9400'],
      labels: ['Average Results'],
    },
  });

  useEffect(() => {
    setState({
      ...state,
      series: [series],
      options: {
        chart: {
          type: 'radialBar',
          offsetY: -20,
          sparkline: {
            enabled: true,
          },
        },
        plotOptions: {
          radialBar: {
            startAngle: -135,
            endAngle: 135,
            track: {
              background: '#e7e7e7',
              strokeWidth: '97%',
              margin: 5,
            },
            dataLabels: {
              name: {
                show: false,
              },
              value: {
                offsetY: -2,
                fontSize: '22px',
                formatter: function (val) {
                  return `${val}`;
                },
              },
            },
          },
        },
        grid: {
          padding: {
            top: -10,
          },
        },
        fill: {
          type: 'gradient',
        },
        colors: ['#DC9400'],
        labels: ['Average Results'],
      },
    });
  }, [score]);

  // @ts-ignore
  return <ReactApexChart options={state.options} series={state.series} type="radialBar" height={180} />;
};
export default CreditRange;
