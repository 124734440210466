import React from 'react';
import styles from './Request.module.scss';
import { useNavigate } from 'react-router-dom';
import pageRoutes from '../../../constants/pageRoutes';

const Request = () => {
  const navigate = useNavigate();
  const explore = `/${pageRoutes.DASHBOARD}/${pageRoutes.KONNECT}/${pageRoutes.REQUESTS}/${pageRoutes.EXPLORE}`;
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h3>Connect Requests</h3>
        <p className={styles.pending}>You hav no new connect requests</p>
      </div>
      <button onClick={() => navigate(`${explore}`)} className={styles.sea_all}>
        Open Connect
      </button>
    </div>
  );
};

export default Request;
