import React from 'react';
import images from '../../../constants';
import { DocumentsContainer, Header, Pending, Text, UploadBtn } from '../../Documents/styles';
import { BsCheck2All } from 'react-icons/bs';
import { openModal } from '../../../features/modal/modalSlice';
import { MODAL_TYPES } from '../../../modules/ModalWindow/modalTypes';
import { useAppDispatch } from '../../../app/hooks';

const MyDocuments = ({ documents, docs }) => {
  const dispatch = useAppDispatch();
  const onOpenEdit = (doc) => {
    localStorage.setItem('doc', doc);
    dispatch(openModal({ modalType: MODAL_TYPES.EDIT_DOCS_MODAL }));
  };
  console.log(documents);
  return (
    <>
      {docs.length > 0 ? (
        <DocumentsContainer>
          <>
            <Header>Pending Documents</Header>
            {docs.map((doc, index) => (
              <Pending key={index}>
                <img className={'error'} src={images.errorIcon} alt="Error icon" />
                <div className="middle">
                  <img src={images.photoIcon} alt="Photo icon" className="icon" />
                  <Text>{doc.replace(/_/g, ' ')}</Text>
                </div>
                <UploadBtn onClick={() => onOpenEdit(doc)}>Upload</UploadBtn>
              </Pending>
            ))}
          </>
        </DocumentsContainer>
      ) : null}
      {documents.length > 0 ? (
        <DocumentsContainer>
          <>
            <Header>Uploaded Documents</Header>
            {documents.map((doc, index) => (
              <Pending key={index}>
                <BsCheck2All />
                <div className="middle">
                  <img src={images.photoIcon} alt="Photo icon" className="icon" />
                  <Text>{doc.collection_name.replace(/_/g, ' ')}</Text>
                </div>
                <div className={'actions'}>
                  <a href={doc.original_url} target={'_blank'} referrerPolicy={'no-referrer'}>
                    <UploadBtn>View</UploadBtn>
                  </a>
                  <UploadBtn onClick={() => onOpenEdit(doc.collection_name)}>Edit</UploadBtn>
                </div>
              </Pending>
            ))}
          </>
        </DocumentsContainer>
      ) : null}
    </>
  );
};

export default MyDocuments;
