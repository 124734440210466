import React from 'react';
import Modal from 'react-modal';
import { Button, Close, Container } from '../CheckMail/styles';
import { HiArrowNarrowRight } from 'react-icons/hi';
import images from '../../../../constants';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { closeModal, openModal, selectCurrentModal } from '../../../../features/modal/modalSlice';
import { MODAL_TYPES } from '../../modalTypes';
import { useLocation } from 'react-router-dom';
import pageRoutes from '../../../../constants/pageRoutes';
import { schema } from './schema';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useForgotPasswordMutation } from '../../../../features/auth/resetApiSlice';
import { setTokenAndRef } from '../../../../features/auth/refSlice';
import { toast, ToastContainer } from 'react-toastify';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    zIndex: 100,
    border: 'none',
    width: '75%',
    borderRadius: '20px',
    maxWidth: '600px',
  },
};

const ForgotPassword = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const modalType = useAppSelector(selectCurrentModal);
  const isOpen = modalType === MODAL_TYPES.FORGOT_PASSWORD_MODAL && location.pathname === `/${pageRoutes.FORGOT}`;

  const [forgotPassword, { isLoading }] = useForgotPasswordMutation();

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid },
  } = useForm<{ email: string }>({
    resolver: yupResolver(schema),
    mode: 'all',
  });

  const onSubmitHandler = async (data) => {
    try {
      const response = await forgotPassword(data.email).unwrap();
      const responseData = response.response;
      if (responseData.token && responseData.reference) {
        dispatch(setTokenAndRef({ token: responseData.token, reference: responseData.reference }));
        dispatch(closeModal());
        dispatch(openModal({ modalType: MODAL_TYPES.CHECK_MAIL_MODAL }));
      } else {
        toast.error('Something went wrong!');
      }
    } catch (error: any) {
      console.log(error);
      toast.error(error.data.message);
    }
  };

  const buttonContent = isLoading ? (
    <div className={'container dots-flow'} />
  ) : (
    <>
      <span>Send</span>
      <HiArrowNarrowRight />
    </>
  );

  return (
    <>
      <Modal
        onRequestClose={() => dispatch(closeModal())}
        isOpen={isOpen}
        style={customStyles}
        ariaHideApp={false}
        overlayClassName="Overlay-2">
        <Container>
          <Close onClick={() => dispatch(closeModal())} />
          <h3>Forgot Your Password?</h3>
          <img src={images.forgotPassword} alt="forgot password" />
          <p className="title">Enter your registered email address</p>
          <p>We will send you a link to your email to reset your password</p>
          <form onSubmit={handleSubmit(onSubmitHandler)}>
            <input type="email" autoFocus placeholder={'Enter email address'} {...register('email')} />
            {errors.email && <small className="errors">{errors.email.message}</small>}
            <Button
              disabled={isLoading || !isDirty || !isValid}
              type="submit"
              whileHover={{ backgroundColor: 'var(--color-primary-dark)' }}
              whileTap={{ scale: 0.97 }}>
              {buttonContent}
            </Button>
          </form>
        </Container>
      </Modal>
      <ToastContainer />
    </>
  );
};

export default ForgotPassword;
