import { apiSlice } from '../../app/api/apiSlice';
import { createEntityAdapter } from '@reduxjs/toolkit';

const adapter = createEntityAdapter<any>();
const initialState = adapter.getInitialState();

const creditRecosApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCreditRecos: builder.query<any, string>({
      query: (id) => `/attribute/recommendation/${id}`,
      transformResponse: (rawResult: any) => adapter.setAll(initialState, rawResult?.response),
    }),
  }),
});

export const { useGetCreditRecosQuery } = creditRecosApiSlice;
