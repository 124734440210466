import React from 'react';
import { Input, Top } from './styles';
import { RiArrowUpSLine } from 'react-icons/ri';

function Multiple({ options }) {
  return options.map((item, index) => (
    <div key={index}>
      <Top>
        <span>{item}</span>
        <RiArrowUpSLine />
      </Top>
      <Input type={'number'} placeholder={'0'} />
    </div>
  ));
}

export default Multiple;
