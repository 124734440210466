import styled from 'styled-components';

export const Wrapper = styled.div`
  border-radius: 10px;
  box-shadow: var(--shadow-light);

  img {
    width: 100%;
    object-fit: cover;
    object-position: center center;
    height: 15rem;
    border-radius: 10px 10px 0 0;
  }

  .content {
    background-color: #fff;
    padding: 1.6rem;
    border-radius: 0 0 10px 10px;
    display: flex;
    flex-direction: column;
  }
`;

export const HRule = styled.hr`
  height: 0;
  border: 1px solid var(--color-accent-light);
  margin: 1rem -1.6rem;
`;
