import React from 'react';
import { Avatar, Date, Header, MTicketWrapper, Text, Title, Username } from './styles';

function MyTickets() {
  return (
    <MTicketWrapper>
      <Header>
        <Avatar
          src={
            'https://firebasestorage.googleapis.com/v0/b/nectar-f5ee3.appspot.com/o/avatar.jpg?alt=media&token=0ae57f44-06fd-401d-b1e3-9f182515bcc6'
          }
        />
        <div>
          <Username>Jane Doe</Username>
          <Date>Jan 28 2022</Date>
        </div>
      </Header>
      <Title>Failed to link user to an expert adisor</Title>
      <Text>
        Lorem ipsum dolor sit amet. Qui similique tenetur eum porro sapiente et unde aperiam est repellat sint
      </Text>
    </MTicketWrapper>
  );
}

export default MyTickets;
