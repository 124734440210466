import React, { useState } from 'react';
import { VscMenu } from 'react-icons/vsc';
import { FiChevronDown } from 'react-icons/fi';
import { BiBell } from 'react-icons/bi';
import { IoMailOutline } from 'react-icons/io5';
import styles from './Topbar.module.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import { SignOut, TooltipWrapper } from './styles';
import { useAppDispatch } from '../../app/hooks';
import { AiOutlineLogout } from 'react-icons/ai';
import { BsPersonCircle } from 'react-icons/bs';
import { logOut } from '../../features/auth/authSlice';
import pageRoutes from '../../constants/pageRoutes';
import { getDocuments } from '../../utils/object-mapper';

// @ts-ignore
const Topbar = ({ toggle, showSidebar, user }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [showProfile, setShowProfile] = useState(0);

  const handleSignOutClick = () => {
    dispatch(logOut());
  };

  const openInbox = () => {
    navigate(`/${pageRoutes.DASHBOARD}/${pageRoutes.INBOX}`);
  };

  let title: string;

  switch (location.pathname) {
    case `/${pageRoutes.DASHBOARD}/${pageRoutes.HOME}`:
      title = 'Entrepreneur Dashboard';
      break;
    case `/${pageRoutes.DASHBOARD}/${pageRoutes.PROFILE}`:
      title = 'My Profile';
      break;
    case `/${pageRoutes.DASHBOARD}/${pageRoutes.BUSINESS}`:
      title = 'Business Profile';
      break;
    case `/${pageRoutes.DASHBOARD}/${pageRoutes.CREDIT}`:
      title = 'Credit Profile';
      break;
    case `/${pageRoutes.DASHBOARD}/${pageRoutes.IMPACT}`:
      title = 'Impact Profile';
      break;
    case `/${pageRoutes.DASHBOARD}/${pageRoutes.DATA_ROOM}`:
      title = 'Data Room';
      break;
    case `/${pageRoutes.DASHBOARD}/${pageRoutes.FUNDING}/${pageRoutes.APPLY}`:
      title = 'Funding';
      break;
    case `/${pageRoutes.DASHBOARD}/${pageRoutes.FUNDING}/${pageRoutes.MY_LOANS}`:
      title = 'My Loans';
      break;
    case `/${pageRoutes.DASHBOARD}/${pageRoutes.KONNECT}/${pageRoutes.POSTS}`:
      title = 'Posts';
      break;
    case `/${pageRoutes.DASHBOARD}/${pageRoutes.KONNECT}/${pageRoutes.REQUESTS}`:
      title = 'Connect';
      break;
    case `/${pageRoutes.DASHBOARD}/${pageRoutes.ACADEMY}/${pageRoutes.ENROLLED}`:
    case `/${pageRoutes.DASHBOARD}/${pageRoutes.ACADEMY}/${pageRoutes.CERTIFICATIONS}`:
    case `/${pageRoutes.DASHBOARD}/${pageRoutes.ACADEMY}/${pageRoutes.COMPLETE}`:
    case `/${pageRoutes.DASHBOARD}/${pageRoutes.ACADEMY}/${pageRoutes.INPROGRESS}`:
    case `/${pageRoutes.DASHBOARD}/${pageRoutes.ACADEMY}`:
      title = 'Masterclasses';
      break;
    case `/${pageRoutes.DASHBOARD}/${pageRoutes.EXPERTS}`:
      title = 'Experts';
      break;
    case `/${pageRoutes.DASHBOARD}/${pageRoutes.SUPPORT}`:
      title = 'Support';
      break;
    case `/${pageRoutes.DASHBOARD}/${pageRoutes.MARKET}`:
      title = 'Business Tools';
      break;
    default:
      title = 'Entrepreneur Dashboard';
  }

  const avatar = getDocuments(['avatar'], user?.media)[0]?.original_url;

  const imageUrl =
    'https://thumbs.dreamstime.com/b/default-avatar-profile-icon-vector-social-media-user-portrait-176256935.jpg';

  return (
    <section className={styles.topbar}>
      <div className={`${styles.topbar__nav} ${showSidebar ? `${styles.active}` : ''}`}>
        <VscMenu className={styles.toggle} onClick={toggle} />
        <p className={styles.funding_header}>{title}</p>
      </div>
      {/* <div className={styles.topbar__search}>
        <input type="text" placeholder={'What are you looking for?'} />
        <BiSearch />
      </div>*/}
      <div className={styles.topbar__user}>
        <div
          tabIndex={0}
          onFocus={() => {}}
          onClick={openInbox}
          onBlur={() => {}}
          data-for={'inbox'}
          className={styles.notification}>
          <IoMailOutline />
          {/*<span>2</span>*/}
        </div>
        <div tabIndex={0} onFocus={() => {}} onBlur={() => {}} data-for={'inbox-2'} className={styles.notification}>
          <BiBell />
          {/*<span>2</span>*/}
        </div>
        <div
          className={styles.topbar__user}
          tabIndex={0}
          onFocus={() => setShowProfile(1)}
          onBlur={() => setShowProfile(0)}>
          <TooltipWrapper
            className={`${showProfile === 1 ? 'show' : ''}`}
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{
              duration: 0.3,
              delay: 0.1,
              ease: [0, 0.71, 0.2, 1.01],
            }}
            key={showProfile}>
            <SignOut onClick={() => navigate(pageRoutes.PROFILE)} whileTap={{ scale: 0.9 }}>
              <BsPersonCircle />
              <p>My Account</p>
            </SignOut>
            <SignOut whileTap={{ scale: 0.9 }} onClick={handleSignOutClick}>
              <AiOutlineLogout />
              <p>Sign Out</p>
            </SignOut>
          </TooltipWrapper>
          <img
            className={styles.topbar__user__photo}
            src={`${avatar === '' || avatar === undefined ? imageUrl : avatar}`}
            alt={user.name}
          />
          <div className={styles.topbar__user__profile}>
            <p>{user.name}</p>
            <p>
              Entrepreneur
              <span>
                <FiChevronDown style={{ transform: `${showProfile === 1 ? 'scaleY(-1)' : ''}` }} />
              </span>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Topbar;
