import React, { useState } from 'react';
import styles from './Packages.module.scss';
import radio from './RadioButton.module.scss';
import images from '../../constants';
import { BsArrowRight } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';

function Packages() {
  const packages = [
    {
      name: 'Basic',
      price: 'FREE',
      text: 'You will be able to connect with investor clubs, limited connects with people, reduces your risk levels',
    },
    {
      name: 'Gold Plan',
      price: 'KES 4,500',
      text: 'You will be able to connect with investor clubs, limited connects with people, reduces your risk levels',
    },
    {
      name: 'Platinum Plan',
      price: 'KES 6,500',
      text: 'You will be able to connect with investor clubs, limited connects with people, reduces your risk levels',
    },
  ];
  const [checked, setChecked] = useState(packages[0]);
  const isChecked = (pack) => pack.name === checked.name;

  const navigate = useNavigate();

  return (
    <main className={styles.container}>
      <section style={{ backgroundImage: `url(${images.africaLight})` }} className={styles.welcome}>
        <img src={images.logoWhite} alt="Melanin Kapital Logo" />
        <p>Thank you for signing up!</p>
        <h2>Enjoy much more from us By subscribing to our Platform</h2>
        <p className={styles.text}>
          By subscribing you are able to access so much More from our services and grow your account At a faster rate
        </p>
      </section>
      <section className={styles.content}>
        <h1>
          Karibu sana Tafari! Let us know <br />
          Which package suits your needs
        </h1>
        <h6>Kindly select one and proceed to the next step</h6>
        {packages.map((pack) => (
          <div
            onClick={() => setChecked(pack)}
            className={`${styles.package} ${isChecked(pack) ? styles.package_active : ''}`}
            key={pack.name}>
            <div>
              <label className={radio.container}>
                <input type="radio" checked={isChecked(pack)} onChange={() => setChecked(pack)} />
                <span className={radio.checkmark} />
              </label>
              <h3>{pack.name}</h3>
              <h4>{pack.price}</h4>
            </div>
            <p>{pack.text}</p>
          </div>
        ))}
        <button onClick={() => navigate('/fill_business_profile')}>
          <span>Continue</span>
          <BsArrowRight />
        </button>
      </section>
    </main>
  );
}

export default Packages;
