import styled from 'styled-components';

export const Wrapper = styled.section`
  padding: 1.6rem;
  background-color: #fff;
  border-radius: 10px;
`;

export const Loading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const Card = styled.div`
  border-radius: 10px;
  padding: 1.6rem;
  margin-bottom: 2rem;
  border: 1px solid #707070;

  img {
    display: block;
    width: 100%;
    height: 18rem;
    object-fit: cover;
    object-position: center;
    margin-bottom: 1rem;
    border-radius: 10px 10px 0 0;
  }
`;

export const CompleteText = styled.p`
  color: var(--color-accent);
  font-size: 1.5rem;
  margin-bottom: 0.4rem;
`;

export const Bar = styled.div<{ total: number }>`
  display: grid;
  grid-template-columns: repeat(${(props) => props.total}, 1fr);
  column-gap: 0.3rem;

  hr {
    background-color: #707070;
    height: 5px;
    border: none;
  }

  & > hr:first-child {
    border-radius: 3px 0 0 3px;
  }

  & > hr:last-child {
    border-radius: 0 3px 3px 0;
  }

  .complete {
    background-color: var(--color-accent);
  }
`;
