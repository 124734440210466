import React from 'react';
import { BsCheckCircle, BsInfoCircle } from 'react-icons/bs';
import { AnalysisCard, AnalysisWrapper, CashFlowCard, ContentIcon, ExTitle, InfoIcon, Text } from './styles';
import { useGetCashFlowQuery } from '../../features/analysis/analysisSlice';
import MeetupLoader from '../Loaders/MeetupLoader';
import { Btn, Header, HeaderContainer, StatementWrapper } from '../RiskProfile/Statements/styles';
import { MPESA_DOC } from '../../constants/constants';
import { openModal } from '../../features/modal/modalSlice';
import { MODAL_TYPES } from '../../modules/ModalWindow/modalTypes';
import { useAppDispatch } from '../../app/hooks';

const CashFlow = ({ company, documents, isAnalysis }) => {
  const dispatch = useAppDispatch();
  const { data, isLoading, isSuccess, isError, error } = useGetCashFlowQuery(company.id);
  let cashFlows = [];

  if (isLoading) {
    return <MeetupLoader />;
  } else if (isError) {
    console.log(error);
  } else if (isSuccess) {
    const {
      id,
      business_id,
      created_by,
      created_at,
      updated_at,
      file,
      media,
      statement_duration,
      first_day,
      last_day,
      ...refinedData
    } = data;
    cashFlows = Object.keys(refinedData).map((key) => {
      return { name: key.replace(/_/g, ' '), amount: refinedData[key]?.toLocaleString() };
    });
  }

  if (!isAnalysis) {
    return (
      <StatementWrapper>
        <HeaderContainer>
          <Header>Cash-flow Analysis Summary</Header>
        </HeaderContainer>
        <Text style={{ color: 'black', marginTop: '1.6rem' }}>You data is being analysed. Please check back later</Text>
      </StatementWrapper>
    );
  }

  return (
    <AnalysisWrapper>
      <CashFlowCard>
        <div className="header">
          <h4>Statement Period</h4>
          <p>{data?.statement_duration}</p>
        </div>
        <div className="content">
          <h4>First Day In Statement:</h4>
          <p>{data?.first_day}</p>
        </div>
        <div className="content">
          <h4>Last Day In Statement:</h4>
          <p>{data?.last_day}</p>
        </div>
      </CashFlowCard>
      {cashFlows.map((cashFlow, index) => (
        <AnalysisCard key={index}>
          <ContentIcon bgc={'#cdd5f7'} customColor={'#2445D4'}>
            <BsCheckCircle />
          </ContentIcon>
          <ExTitle>{cashFlow.amount}</ExTitle>
          <Text>{cashFlow.name}</Text>
          <InfoIcon>
            <BsInfoCircle />
          </InfoIcon>
        </AnalysisCard>
      ))}
    </AnalysisWrapper>
  );
};

export default CashFlow;
