export const steps = [
  {
    selector: '.first-step',
    content: 'Complete your entrepreneur profile',
  },
  {
    selector: '.second-step',
    content: 'Complete your business profile',
  },
  {
    selector: '.third-step',
    content: 'Manage your documents in the dataroom',
  },
  {
    selector: '.fourth-step',
    content: 'Miss one document? Get help from our academy',
  },
  {
    selector: '.fifth-step',
    content: 'Need more support ? Book a meeting with our experts',
  },
  {
    selector: '.sixth-step',
    content: 'Have a question? Contact our customer success team',
  },
  {
    selector: '.seventh-step',
    content: 'You are ready? Connect with our club members',
  },
];
