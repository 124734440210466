import React from 'react';
import { Cert, Download, View, Wrapper } from './styles';
import { Title } from '../Overview/RecommendedClasses/styles';
import { AiFillEye, AiOutlineDownload } from 'react-icons/ai';
import EmptyState from '../../EmptyState/EmptyState';
import images from '../../../constants';

const MyCertifications = () => {
  const certifications = 0;

  if (certifications === 0) {
    const emptyContent = {
      title: 'You do not have any certifications',
      subtitle: 'Finish a masterclass and pass the quizzes at the end to acquire a certificate',
      imageUrl: images.emptyCert,
      header: 'Acquired Certifications',
    };
    return <EmptyState content={emptyContent} />;
  }

  return (
    <Wrapper>
      <Title>My Certifications</Title>
      <Cert>
        <img
          src="https://firebasestorage.googleapis.com/v0/b/nectar-f5ee3.appspot.com/o/academy%2Fgreen%2FCertificate.svg?alt=media&token=7ef32009-bf68-4e23-b5db-2fe22103683f"
          alt="Certificate"
        />
        <div>
          <h5>Revenue Growth</h5>
          <p>revenue_growth_certificate.pdf</p>
        </div>
        <View>
          <AiFillEye />
        </View>
        <Download>
          <AiOutlineDownload />
        </Download>
      </Cert>
      <Cert>
        <img
          src="https://firebasestorage.googleapis.com/v0/b/nectar-f5ee3.appspot.com/o/academy%2Fgreen%2FCertificate.svg?alt=media&token=7ef32009-bf68-4e23-b5db-2fe22103683f"
          alt="Certificate"
        />
        <div>
          <h5>Revenue Growth</h5>
          <p>revenue_growth_certificate.pdf</p>
        </div>
        <View>
          <AiFillEye />
        </View>
        <Download>
          <AiOutlineDownload />
        </Download>
      </Cert>
      <Cert>
        <img
          src="https://firebasestorage.googleapis.com/v0/b/nectar-f5ee3.appspot.com/o/academy%2Fgreen%2FCertificate.svg?alt=media&token=7ef32009-bf68-4e23-b5db-2fe22103683f"
          alt="Certificate"
        />
        <div>
          <h5>Revenue Growth</h5>
          <p>revenue_growth_certificate.pdf</p>
        </div>
        <View>
          <AiFillEye />
        </View>
        <Download>
          <AiOutlineDownload />
        </Download>
      </Cert>
      <Cert>
        <img
          src="https://firebasestorage.googleapis.com/v0/b/nectar-f5ee3.appspot.com/o/academy%2Fgreen%2FCertificate.svg?alt=media&token=7ef32009-bf68-4e23-b5db-2fe22103683f"
          alt="Certificate"
        />
        <div>
          <h5>Revenue Growth</h5>
          <p>revenue_growth_certificate.pdf</p>
        </div>
        <View>
          <AiFillEye />
        </View>
        <Download>
          <AiOutlineDownload />
        </Download>
      </Cert>
    </Wrapper>
  );
};

export default MyCertifications;
