import React from 'react';
import { Doc, DocImage, DocumentsContainer, Header, Pending, Text, UploadBtn } from './styles';
import images from '../../constants';
import { Actions } from '../../pages/Dashboard/DataRoom/styles';
import { useAppDispatch } from '../../app/hooks';
import { openModal } from '../../features/modal/modalSlice';
import { MODAL_TYPES } from '../../modules/ModalWindow/modalTypes';
import EmptyState from '../EmptyState/EmptyState';

const Documents = ({ documents, isCompany, docs }) => {
  const dispatch = useAppDispatch();

  const onOpenEdit = (doc) => {
    localStorage.setItem('doc', doc);
    dispatch(openModal({ modalType: MODAL_TYPES.EDIT_DOCS_MODAL }));
  };

  if (!isCompany) {
    const emptyContent = {
      header: 'Uploaded Documents',
      title: 'You have no documents yet',
      subtitle: 'Add your company details to start uploading the necessary documents',
      imageUrl: images.emptyDocs,
    };
    return <EmptyState content={emptyContent} />;
  }

  return (
    <>
      {docs.length > 0 ? (
        <DocumentsContainer>
          <Header>Pending Documents</Header>
          {docs.map((doc, index) => (
            <Pending key={index}>
              <img className={'error'} src={images.errorIcon} alt="Error icon" />
              <div className="middle">
                <img src={images.fileTemplate} alt="File template" className="icon" />
                <Text>{doc.replace(/_/g, ' ')}</Text>
              </div>
              <UploadBtn onClick={() => onOpenEdit(doc)}>Upload</UploadBtn>
            </Pending>
          ))}
        </DocumentsContainer>
      ) : null}
      {documents.length > 0 ? (
        <DocumentsContainer>
          <Header>Uploaded Documents</Header>
          {documents.map((doc, index) => (
            <Doc key={index}>
              <DocImage src={images.document} alt={doc} />
              <Text>{doc.collection_name.replace(/_/g, ' ')}</Text>
              <Actions>
                <a href={doc.original_url} target={'_blank'} referrerPolicy={'no-referrer'}>
                  <img src={images.view} alt="View Icon" />
                </a>
                <img
                  onClick={() => onOpenEdit(doc.collection_name)}
                  className={'doc'}
                  src={images.check_pen}
                  alt="Edit Icon"
                />
              </Actions>
            </Doc>
          ))}
        </DocumentsContainer>
      ) : null}
    </>
  );
};

export default Documents;
