import { createEntityAdapter, createSelector } from '@reduxjs/toolkit';
import { apiSlice } from '../../app/api/apiSlice';
import { POSTS } from '../../types/api-responses';

const postsResultsAdapter = createEntityAdapter();

const initialState = postsResultsAdapter.getInitialState();

export const postsApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getPostsList: build.query<any, void>({
      query: () => '/post/list',
      transformResponse: (rawResult: any) => {
        return postsResultsAdapter.setAll(initialState, rawResult?.response?.data);
      },
      providesTags: (result) =>
        result
          ? [...result.ids.map((id) => ({ type: POSTS, id })), { type: POSTS, id: 'LIST' }]
          : [{ type: POSTS, id: 'LIST' }],
    }),
    addComment: build.mutation<any, any>({
      query: ({ id, content }) => {
        const fData = new FormData();
        fData.append('content', content);
        return {
          url: `/post/comment/${id}`,
          method: 'POST',
          body: fData,
        };
      },
      invalidatesTags: (result, error, arg) => [{ type: POSTS, id: arg.id }],
    }),
    getPost: build.query<any, any>({
      query: (id) => `/post/${id}`,
      transformResponse: (rawResult: any) => {
        return rawResult?.response;
      },
    }),
  }),
});

export const { useGetPostsListQuery, useAddCommentMutation, useGetPostQuery } = postsApiSlice;
const selectPostsResult = postsApiSlice?.endpoints?.getPostsList.select();
const selectPostsData = createSelector(selectPostsResult, (postsResult) => postsResult?.data);

export const { selectAll: selectAllPosts } = postsResultsAdapter.getSelectors(
  (state: any) => selectPostsData(state) ?? initialState,
);
