import { createEntityAdapter } from '@reduxjs/toolkit';
import { apiSlice } from '../../app/api/apiSlice';
import { BUSINESSES_TAG } from '../../types/api-responses';

const businessAdapter = createEntityAdapter();
const initialState = businessAdapter.getInitialState();

const businessSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getBusinessById: build.query<any, string>({
      query: (id) => `/user/${id}`,
      transformResponse: (rawResult: any) => {
        return rawResult?.response;
      },
      providesTags: [{ type: BUSINESSES_TAG, id: 'LIST' }],
    }),
    createBusiness: build.mutation({
      query: (payload: any) => {
        const data = new FormData();
        for (const payloadKey in payload) {
          data.append(payloadKey, payload[payloadKey]);
        }
        return {
          url: '/business/create',
          method: 'POST',
          body: data,
        };
      },
      invalidatesTags: [{ type: BUSINESSES_TAG, id: 'LIST' }],
    }),
    updateBusiness: build.mutation({
      query: (payload) => {
        const data = new FormData();
        for (const payloadKey in payload.item) {
          data.append(payloadKey, payload.item[payloadKey]);
        }
        return {
          url: `/business/update/${payload.id}`,
          method: 'POST',
          body: data,
        };
      },
      invalidatesTags: [{ type: BUSINESSES_TAG, id: 'LIST' }],
    }),
    getBusinessDetails: build.query<any, string>({
      query: (id) => `/business/${id}`,
      transformResponse: (rawResult: any) => {
        return rawResult;
      },
    }),
  }),
});

export const {
  useCreateBusinessMutation,
  useGetBusinessByIdQuery,
  useUpdateBusinessMutation,
  useGetBusinessDetailsQuery,
} = businessSlice;
