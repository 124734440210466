import React, { useEffect, useState } from 'react';
import { Back, Button, DONT, InputDiv, ProgressBar, Title } from './styles';
import Options from './Options';
import DragNDrop from '../../components/LoanModal/DragNDrop/DragNDrop';
import Multiple from './Multiple';
import { HiArrowRight } from 'react-icons/hi';
import { HiOutlineArrowLongLeft } from 'react-icons/hi2';
import pageRoutes from '../../constants/pageRoutes';
import { useNavigate } from 'react-router-dom';

const PagesWrapper = ({ onNext, questions, page }) => {
  const climateRoute = `/${pageRoutes.DASHBOARD}/${pageRoutes.IMPACT}`;
  const navigate = useNavigate();
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const handleNext = () => {
    if (currentQuestion < questions.length - 1) {
      setCurrentQuestion(currentQuestion + 1);
    } else {
      setCurrentQuestion(0);
      onNext();
    }
  };

  useEffect(() => {
    setCurrentQuestion(0);
    if (page === 2) {
      setCurrentQuestion(0);
    }
  }, []);

  const progress = ((currentQuestion + 1) / questions.length) * 100;

  const handleCancel = () => {
    navigate(climateRoute);
  };

  const onBack = () => {
    if (currentQuestion > 0) {
      setCurrentQuestion(currentQuestion - 1);
    } else {
      setCurrentQuestion(0);
    }
  };

  const item = questions[currentQuestion];
  return (
    <>
      <div className="flex">
        <Back onClick={onBack}>
          <HiOutlineArrowLongLeft />
        </Back>
        <div className={'large'}>
          <p className="progress-text">
            {currentQuestion + 1} / {questions.length}
          </p>
          <ProgressBar>
            <div style={{ width: `${progress}%` }} />
          </ProgressBar>
        </div>
      </div>

      <Title>{item.question}</Title>
      {item.questionType === 'options' && <Options options={item.options} />}
      {item.questionType === 'input' && (
        <>
          <InputDiv>
            <input type="number" placeholder={'0'} autoFocus={true} />
            <select name="" id="">
              <option value="">KES</option>
              <option value="">USD</option>
            </select>
          </InputDiv>
          <DONT active={false}>I Don't Know</DONT>
        </>
      )}
      {item.questionType === 'upload' && <DragNDrop />}
      {item.questionType === 'multiple' && <Multiple options={item.options} />}

      <div className="btns">
        <Button onClick={handleCancel} className={'cancel'}>
          Cancel
        </Button>
        <Button onClick={handleNext}>
          <span>Next</span>
          <HiArrowRight />
        </Button>
      </div>
    </>
  );
};

export default PagesWrapper;
