import styled from 'styled-components';
import { motion } from 'framer-motion';

export const Wrapper = styled.section`
  background-color: #fff;
  margin-top: 2rem;
  padding: 1.6rem;
  border-radius: 10px;
  font-size: 1.6rem;
  color: #707070;
  letter-spacing: 0.3px;

  h2 {
    font-size: 1.72rem;
    font-weight: bold;
    color: #000;
    margin-bottom: 3rem;
  }

  .cards {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 1.6rem;
  }
`;

export const ActionCard = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.6rem;
  border-radius: 10px;
  border: 1px solid var(--color-accent);
  position: relative;
  background-color: transparent;

  h4 {
    margin-top: 2.5rem;
    color: #000;
    font-size: 1.8rem;
    font-weight: 500;
  }

  p {
    margin: 1rem 0 2.5rem 0;
  }

  &.second {
    border-color: var(--color-primary);
  }
`;

export const Action = styled(motion.div)`
  margin-top: auto;
  text-transform: uppercase;
  font-size: 1.8rem;
  color: var(--color-primary);
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  column-gap: 1rem;
  justify-content: flex-end;
  font-weight: bold;
  width: 100%;

  &:hover {
    text-decoration: underline;
  }

  svg {
    width: 2rem;
    height: auto;
    fill: var(--color-primary);
  }
  &.second {
    color: var(--color-accent);

    svg {
      fill: var(--color-accent);
    }
  }
`;

export const HandIcon = styled.div`
  position: absolute;
  top: -2.5rem;
  left: 2.5rem;
  padding: 0.7rem;
  background-color: var(--color-primary);
  border-radius: 5px;
  height: 5rem;
  width: 5rem;

  img {
    height: 100%;
    width: 100%;
  }

  &.second {
    background-color: var(--color-accent);
  }
`;
