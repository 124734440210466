import styled from 'styled-components';
import { motion } from 'framer-motion';

export const TooltipWrapper = styled(motion.div)`
  background-color: #fff;
  border-radius: 10px;
  box-shadow: var(--shadow-light);
  width: 27rem !important;
  position: absolute;
  z-index: 3;
  top: 100%;
  right: 0.5rem;
  display: none;
  padding-bottom: 3rem;

  &.show {
    display: block;
  }
`;

export const SignOut = styled(motion.div)`
  cursor: pointer;
  font-size: 1.5rem;
  padding: 1.3rem;
  margin: 0.3rem 0.5rem;
  border-radius: 5px;
  text-align: right;
  color: #000;
  display: flex;
  align-items: center;
  column-gap: 0.6rem;
  font-weight: 500;

  svg {
    width: 2rem;
    height: 2rem;
  }

  &:hover {
    background-color: var(--color-primary-light-1);
  }
`;
