// @ts-nocheck
import React, { useEffect, useState } from 'react';
import {
  CalculatorContainer,
  CalculatorWrapper,
  Header,
  HRule,
  Label,
  MinDiv,
  PrimaryText,
  SecText,
  Title,
} from './styles';

import RangeSlider from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';
import { getMonthlyRates } from '../../../utils/loan-calculator';
import { openModal } from '../../../features/modal/modalSlice';
import { MODAL_TYPES } from '../../../modules/ModalWindow/modalTypes';
import { useAppDispatch } from '../../../app/hooks';
import { setApplicationLoan } from '../../../features/loans/loanApplicationSlice';

const LoanCalculator = () => {
  const dispatch = useAppDispatch();
  const openApplyLoanModal = () => {
    dispatch(setApplicationLoan({ amount: myLoan[1], months: months[1] }));
    dispatch(openModal({ modalType: MODAL_TYPES.LOAN_MODAL }));
  };

  const min = 100;
  const max = 1000000;
  const minMonth = 12;
  const maxMonth = 36;

  const [myLoan, setLoan] = useState([min, min]);
  const [months, setMonths] = useState([minMonth, minMonth]);
  const [monthlyPayment, setMonthlyPayment] = useState(0);

  useEffect(() => {
    setMonthlyPayment(getMonthlyRates(myLoan[1], 35, months[1]));
  }, [myLoan, months]);

  // @ts-ignore
  return (
    <CalculatorWrapper>
      <Header>Repayment Calculator</Header>
      <CalculatorContainer>
        <Label>Asset price</Label>
        <Title>KSH {myLoan[1].toLocaleString()}</Title>
        <div className="slider">
          <RangeSlider
            id={'range-slider'}
            rangeSlideDisabled={true}
            min={min}
            max={max}
            step={100}
            value={myLoan}
            onInput={setLoan}
            thumbsDisabled={[true, false]}
          />
          <MinDiv>
            <p>Ksh {min.toLocaleString()}</p>
            <p>Ksh {max.toLocaleString()}</p>
          </MinDiv>
        </div>
        <HRule />
        <Label>Repayment Period</Label>
        <Title>{months[1]} Months</Title>
        <div className="slider">
          <RangeSlider
            id={'range-slider'}
            min={minMonth}
            max={maxMonth}
            value={months}
            onInput={setMonths}
            thumbsDisabled={[true, false]}
          />
          <MinDiv>
            <p>{minMonth} months</p>
            <p>{maxMonth} months</p>
          </MinDiv>
        </div>
        <HRule />
        <PrimaryText>
          <Label>Monthly Installment</Label>
          <Title>KSH {(monthlyPayment / 12).toLocaleString()}</Title>
        </PrimaryText>
        <SecText>
          <Label>Total Repayment Amount</Label>
          <Title>KSH {monthlyPayment.toLocaleString()}</Title>
        </SecText>
      </CalculatorContainer>
    </CalculatorWrapper>
  );
};

export default LoanCalculator;
