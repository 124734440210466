import React from 'react';
import { Container, Content, Count, Play } from './styles';
import { BsClock } from 'react-icons/bs';
import { TfiLock } from 'react-icons/tfi';
import { FaPlay } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import pageRoutes from '../../../constants/pageRoutes';
import { formatDuration } from '../../../utils/object-mapper';

const LessonCard = ({ lesson, count }) => {
  const navigate = useNavigate();
  const openEnrollModal = () => {
    navigate(`${pageRoutes.LESSON}/${lesson.id}`);
  };

  return (
    <Container>
      <Count>
        <div>
          <p>{count}</p>
        </div>
      </Count>
      <Content>
        <div className="flex">
          <div className="flex-1">
            <BsClock />
            <small>{formatDuration(+lesson.duration)}</small>
          </div>
          <TfiLock />
        </div>
        <div className="flex summary">
          <div>
            <h3>{lesson.title}</h3>
            <p>{lesson.description}</p>
            {/*{lesson.completed ? (
              <Bar>
                <div className="container">
                  <div className="bar" style={{ width: '20%' }} />
                </div>
                <p>20/100</p>
              </Bar>
            ) : null}*/}
          </div>
          <Play
            onClick={
              // () => navigate(`${pageRoutes.LESSON}/1`)
              openEnrollModal
            }
            whileHover={{ backgroundColor: '#018158' }}
            whileTap={{ scale: 0.94 }}>
            <FaPlay />
          </Play>
        </div>
      </Content>
    </Container>
  );
};

export default LessonCard;
