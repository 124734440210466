import React from 'react';
import { Avatar, Button, Header, HeaderWrapper } from './styles';
import { useAppDispatch } from '../../../app/hooks';
import { openModal } from '../../../features/modal/modalSlice';
import { MODAL_TYPES } from '../../../modules/ModalWindow/modalTypes';
import { getDocuments } from '../../../utils/object-mapper';
import images from '../../../constants';
import { useNavigate } from 'react-router-dom';
import pageRoutes from '../../../constants/pageRoutes';
import { BsCameraFill } from 'react-icons/bs';
import { Container, Info, Logo } from '../../BusinessProfile/BusinessHeader/styles';

function ProfileHeader({ user }) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const onEditModal = () => {
    // dispatch(openModal({ modalType: MODAL_TYPES.EDIT_USER_MODAL }));
    navigate(`${pageRoutes.USER_EDIT}`);
  };

  const onEditPhotoModal = () => {
    dispatch(openModal({ modalType: MODAL_TYPES.EDIT_USER_PHOTO_MODAL }));
  };
  const userData = {
    avatar: getDocuments(['avatar'], user?.media)[0]?.original_url,
    name: user.name,
    email: user.email,
  };

  return (
    <Container>
      <div className="content">
        <div className="bg_image" />
        <Logo>
          <img
            src={`${userData.avatar === '' || userData.avatar === undefined ? images.placeholder : userData.avatar}`}
            alt={user.name}
          />
          <div tabIndex={0} onClick={onEditPhotoModal}>
            <BsCameraFill />
          </div>
        </Logo>

        <Info>
          <h3>{userData.name}</h3>
          <p>{userData.email}</p>
          <Button onClick={onEditModal} whileHover={{ backgroundColor: '#014e35' }} whileTap={{ scale: 0.98 }}>
            Edit profile
          </Button>
        </Info>
      </div>
    </Container>
  );
}

export default ProfileHeader;
