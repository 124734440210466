import { createEntityAdapter, createSelector } from '@reduxjs/toolkit';
import { apiSlice } from '../../app/api/apiSlice';
import { CONNECTS } from '../../types/api-responses';

const requestsAdapter = createEntityAdapter();
const initialRequestsState = requestsAdapter.getInitialState();
export const requestApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    requestConnect: build.mutation({
      query: (input: { follower_id: string; followed_id: string }) => {
        const data = new FormData();
        for (const inputKey in input) {
          data.append(inputKey, input[inputKey]);
        }
        return {
          url: 'connect/user',
          method: 'POST',
          body: data,
        };
      },
      invalidatesTags: () => [{ type: CONNECTS, id: 'REQUESTS_LIST' }],
    }),

    getRequests: build.query<any, void>({
      query: () => '/connect/pending',
      transformResponse: (rawResult: any) => requestsAdapter.setAll(initialRequestsState, rawResult?.response),
      providesTags: () => [{ type: CONNECTS, id: 'REQUESTS_LIST' }],
    }),
  }),
});

export const { useRequestConnectMutation, useGetRequestsQuery } = requestApiSlice;

const selectRequestsResult = requestApiSlice?.endpoints?.getRequests.select();
const selectRequestsData = createSelector(selectRequestsResult, (requestsResult) => requestsResult?.data);
export const { selectAll: selectAllRequests } = requestsAdapter.getSelectors(
  (state: any) => selectRequestsData(state) ?? initialRequestsState,
);
