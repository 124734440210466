import styled from 'styled-components';

export const Wrapper = styled.form`
  margin-right: 6rem;
  margin-left: 3rem;
`;
export const Container = styled.div`
  padding: 1.6rem;
  border: 1px solid #707070;
  border-radius: 5px;
  margin-bottom: 1.6rem;
  width: 100%;
`;
export const Label = styled.label`
  display: block;
  font-size: 1.72rem;
  font-weight: 500;
  margin-bottom: 1rem;

  span {
    font-size: 1.5rem;
    color: #707070;
    margin-left: 2.5rem;
  }
`;

export const Small = styled.small`
  color: red;
  font-size: 1.4rem;
  margin-top: 1rem;
  display: block;
`;
