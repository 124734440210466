import styled from 'styled-components';
import { motion } from 'framer-motion';

export const CourseContainer = styled.main`
  display: grid;
  grid-template-columns: 2fr 1fr;
  column-gap: 2rem;
  position: relative;
  top: 7rem;
  letter-spacing: 0.43px;

  .desc {
    color: #707070;
    font-size: 1.72rem;
    margin: 1.6rem 0;
  }

  .flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .header {
    font-size: 1.8rem;
    font-weight: bold;
    text-transform: capitalize;
    margin-bottom: 1.6rem;

    small {
      font-size: 1.5rem;
      color: #707070;
      font-weight: normal;
    }
  }

  .completion {
    margin-right: 1rem;
    font-weight: normal;
    font-size: 1.72rem;
    color: #707070;
  }
`;

export const Main = styled.section`
  padding: 1.6rem;
`;

export const SideBar = styled.section`
  height: 100%;
  background-color: #fff;

  .main {
    position: sticky;
    margin-top: -6rem;
    top: 0;
    align-self: flex-start;
    padding: 1.6rem;
  }

  h6 {
    margin-top: 6rem;
    font-weight: bold;
    font-size: 1.72rem;
    border-bottom: 1px solid #707070;
    margin-bottom: 2rem;
  }
`;

export const Header = styled.h1`
  letter-spacing: 1.03px;
  font-weight: bold;
  font-size: 2.2rem;
  display: block;
  width: max-content;
  margin-bottom: 1.6rem;

  &::after {
    display: block;
    clear: both;
    content: '';
    position: relative;
    left: 0;
    bottom: 0;
    height: 2px;
    width: 90%;
    border-radius: 10px;
    border-bottom: 3px solid var(--color-accent);
    margin: 0 auto;
  }
`;

export const Chip = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  color: var(--color-accent);
  border: 2px solid var(--color-accent);
  padding: 1rem 2rem;
  font-size: 1.72rem;
  column-gap: 2rem;

  img {
    height: 2rem;
    width: 2rem;
  }
`;

export const Video = styled.div`
  border-radius: 20px;
  margin-bottom: 1rem;
`;

export const Button = styled(motion.button)`
  background-color: var(--color-primary);
  color: white;
  padding: 2rem 6rem;
  border-radius: 5px;
  text-transform: uppercase;
  cursor: pointer;
  border: none;
`;

export const HRule = styled.hr`
  height: 0;
  background-color: transparent;
  border: 1px solid #707070;
  margin: 2rem -1.6rem;
`;
