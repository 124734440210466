import styled from 'styled-components';

const ChatBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const ChatMessage = styled.div<{ isSelf: boolean }>`
  margin-left: 1.6rem;
  margin-right: 1.6rem;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  justify-self: ${(props) => (props.isSelf ? 'end' : 'start')};
  width: fit-content;
`;

export const ChatMessageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
  column-gap: 3rem;
`;

export const ChatMessageSender = styled.div`
  color: #2e7d32;
  font-size: 1.4rem;
`;

export const ChatMessageTimestamp = styled.div`
  font-size: 1.3rem;
  color: #999;
`;

export const ChatMessageContent = styled.div<{ isSelf: boolean }>`
  background-color: ${(props) => (props.isSelf ? '#dcf8c6' : '#fff')};
  border-radius: ${(props) => (props.isSelf ? '20px 20px 0 20px' : '20px 20px 20px 0')};
  padding: 10px;
  position: relative;
  font-size: 1.6rem;
  width: fit-content;


  &::before {
    display: none;
    content: '';
    position: absolute;
    top: 10px;
    ${(props) => (props.isSelf ? 'right: -15px' : 'left: -15px')};
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-${(props) => (props.isSelf ? 'right' : 'left')}: 15px solid ${(props) =>
  props.isSelf ? '#dcf8c6' : '#fff'};
  }
`;
