import { apiSlice } from '../../app/api/apiSlice';

const messagesApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getMessages: build.query<any, any>({
      query: ({ id, time }) => `conversation/pull/${id}`,
      transformResponse: (rawResult: any) => {
        return rawResult?.response;
      },
    }),
  }),
});

export const { useGetMessagesQuery } = messagesApiSlice;
