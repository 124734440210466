import { createEntityAdapter, createSelector } from '@reduxjs/toolkit';
import { apiSlice } from '../../app/api/apiSlice';
import { ACCEPTED, PENDING } from '../../types/api-responses';

const adapter = createEntityAdapter();
const initialState = adapter.getInitialState();

const pendingApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getPending: build.query<any, void>({
      query: () => 'connect/requests/pending',
      transformResponse: (rawResult: any) => adapter.setAll(initialState, rawResult?.response),
      providesTags: () => [{ type: PENDING, id: 'LIST' }],
    }),
    acceptRequest: build.mutation({
      query: (connection_id) => {
        return {
          url: `connect/${connection_id}/approved`,
          method: 'POST',
        };
      },
      invalidatesTags: () => [
        { type: PENDING, id: 'LIST' },
        { type: ACCEPTED, id: 'LIST' },
      ],
    }),
  }),
});

export const { useGetPendingQuery, useAcceptRequestMutation } = pendingApiSlice;
const selectPendingResult = pendingApiSlice?.endpoints?.getPending.select();
const selectPendingData = createSelector(selectPendingResult, (pendingResult) => pendingResult?.data);

export const { selectAll: selectAllPending } = adapter.getSelectors(
  (state: any) => selectPendingData(state) ?? initialState,
);
