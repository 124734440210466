import React from 'react';
import styles from './PostActions.module.scss';
import images from '../../../../constants';
import { TbMessageCircle } from 'react-icons/tb';
import { useAddCommentMutation } from '../../../../features/posts/postsApiSlice';
import { useForm } from 'react-hook-form';
import { schema } from './comment-schema';
import { yupResolver } from '@hookform/resolvers/yup';

const PostActions = ({ post }) => {
  const [addComment, { isLoading }] = useAddCommentMutation();

  const {
    register,
    handleSubmit,
    reset,
    formState: { isValid, isDirty },
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
  });

  const onSubmit = async (data) => {
    if (data.content.trim() === '') return;
    try {
      await addComment({ id: post.id, content: data.content }).unwrap();
      reset();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className={styles.actions}>
      <div className={styles.actions__newcomment}>
        <div className={styles.input}>
          <form onSubmit={handleSubmit(onSubmit)} id={'comment'}>
            <input type="text" placeholder={'Write something'} {...register('content')} />
            <button type={'submit'} form={'comment'} disabled={isLoading || !isDirty || !isValid}>
              {isLoading ? (
                <div className={'container--comment'}>
                  <div className={'bloom'} />
                </div>
              ) : (
                <img src={images.sendIcon} alt="Send message icon" />
              )}
            </button>
          </form>
        </div>
      </div>
      <div className={styles.actions__react}>
        <div>
          <TbMessageCircle />
          <p>{post.comments_count} Comments </p>
        </div>
      </div>
    </div>
  );
};

export default PostActions;
