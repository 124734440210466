import React from 'react';
import { Header, Main, Recommend } from './styles';
import images from '../../../constants';
import { selectCreditAttrs, useGetCreditAttrsQuery } from '../../../features/recommendations/creditAttrsApiSlice';
import { useAppSelector } from '../../../app/hooks';
import { useGetCreditRecosQuery } from '../../../features/recommendations/creditRecosApiSlice';
import { addAttrName } from '../../../utils/scores-utils';
import EmptyState from '../../../components/EmptyState/EmptyState';

const CreditRecos = ({ userId }) => {
  const {
    isLoading: attrsLoading,
    isSuccess: attrsSuccess,
    isError: attrsIsError,
    error: attrsError,
  } = useGetCreditAttrsQuery();
  const { isLoading, isSuccess, isError, error, data } = useGetCreditRecosQuery(userId);
  const attrs = useAppSelector(selectCreditAttrs);
  let recoList = [];

  if (isLoading || attrsLoading) return <div>Loading...</div>;
  if (isError || attrsIsError) return <div>Something went wrong</div>;
  if (isSuccess && attrsSuccess) {
    const recos = data.ids.map((id) => data.entities[id]);
    recoList = addAttrName(attrs, recos);
  }

  const emptyContent = {
    header: 'Credit Score Recommendations',
    subtitle: 'No Credit Score recommendations at this time',
    title: 'No recommendations',
    imageUrl: images.emptyDocs,
  };

  return (
    <>
      {recoList.length === 0 ? (
        <div style={{ marginTop: '-2rem' }}>
          <EmptyState content={emptyContent} />
        </div>
      ) : (
        <>
          <Header>Credit Score Recommendations</Header>
          <Main>
            {recoList.map((reco) => (
              <Recommend key={reco.id}>
                <div className="head">
                  <img src={images.errorIcon} alt="Error" />
                  <h4>ISSUE: {reco.name}</h4>
                </div>
                {reco.message}
              </Recommend>
            ))}
          </Main>
        </>
      )}
    </>
  );
};

export default CreditRecos;
