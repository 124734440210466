import { apiSlice } from '../../app/api/apiSlice';
import { ANALYSIS } from '../../types/api-responses';

const analysisSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getStatus: build.query<string, string>({
      query: (id) => `/decide/analysis/${id}`,
      transformResponse: (rawResult: any) => rawResult?.response,
      providesTags: () => [{ type: ANALYSIS, id: ANALYSIS }],
    }),
    uploadStatement: build.mutation({
      query: (statement) => {
        const formData = new FormData();
        formData.append('pdf', statement);
        return {
          url: '/decide/upload',
          method: 'POST',
          body: formData,
        };
      },
      invalidatesTags: [{ type: ANALYSIS, id: ANALYSIS }],
    }),
    getIncome: build.query<any, string>({
      query: (id) => `/income/analysis/${id}`,
      transformResponse: (rawResult: any) => rawResult?.response,
    }),
    getExpense: build.query<any, string>({
      query: (id) => `/expense/analysis/${id}`,
      transformResponse: (rawResult: any) => rawResult?.response,
    }),
    getCashFlow: build.query<any, string>({
      query: (id) => `/cashflow/analysis/${id}`,
      transformResponse: (rawResult: any) => rawResult?.response,
    }),
  }),
});

export const {
  useGetStatusQuery,
  useUploadStatementMutation,
  useGetIncomeQuery,
  useGetExpenseQuery,
  useGetCashFlowQuery,
} = analysisSlice;
