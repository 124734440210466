import React from 'react';
import Modal from 'react-modal';
import { Button, Close, Container } from '../CheckMail/styles';
import { HiArrowNarrowRight } from 'react-icons/hi';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { closeModal, selectCurrentModal } from '../../../../features/modal/modalSlice';
import { MODAL_TYPES } from '../../modalTypes';
import { useForm } from 'react-hook-form';
import { schema } from './schema';
import { yupResolver } from '@hookform/resolvers/yup';
import { useJoinBuiltInAppMutation } from '../../../../features/apps/appsSlice';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    zIndex: 100,
    border: 'none',
    width: '75%',
    borderRadius: '20px',
    maxWidth: '600px',
  },
};

const JoinModal = () => {
  const dispatch = useAppDispatch();
  const modalType = useAppSelector(selectCurrentModal);
  const isOpen = modalType === MODAL_TYPES.JOIN_MODAL;
  const [joinBuiltInApp, { isLoading }] = useJoinBuiltInAppMutation();

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid },
  } = useForm<{ password: string; confirm_password: string }>({
    resolver: yupResolver(schema),
    mode: 'all',
  });

  const buttonContent = isLoading ? (
    <div className={'container dots-flow'} />
  ) : (
    <>
      <span>Sign Up</span>
      <HiArrowNarrowRight />
    </>
  );
  const onSubmitHandler = async (data) => {
    try {
      await joinBuiltInApp(data).unwrap();
      dispatch(closeModal());
    } catch (error) {
      console.log(error);
    }
  };

  console.log(errors);
  return (
    <Modal
      onRequestClose={() => {}}
      isOpen={isOpen}
      style={customStyles}
      ariaHideApp={false}
      overlayClassName="Overlay">
      <Container>
        <Close onClick={() => dispatch(closeModal())} />
        <h3>Sign up to Built Accounting</h3>
        <img
          src={
            'https://firebasestorage.googleapis.com/v0/b/nectar-f5ee3.appspot.com/o/bs_tools%2Ffaidi.png?alt=media&token=b1f5e799-b04b-484d-bbf7-94b86b089a26'
          }
          alt="built accounting"
        />
        <p>Set up your password for built accounting</p>
        <form onSubmit={handleSubmit(onSubmitHandler)}>
          <input type="password" autoFocus required placeholder={'Password'} {...register('password')} />
          {errors.password && <small className="errors">{errors.password.message}</small>}
          <input type="password" required placeholder={'Confirm Password'} {...register('confirm_password')} />
          {errors.confirm_password && <small className="errors">{errors.confirm_password.message}</small>}
          <Button
            type={'submit'}
            disabled={!isDirty || !isValid || isLoading}
            whileHover={{ backgroundColor: 'var(--color-primary-dark)' }}
            whileTap={{ scale: 0.97 }}>
            {buttonContent}
          </Button>
        </form>
      </Container>
    </Modal>
  );
};

export default JoinModal;
