import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: max-content 1fr;
  column-gap: 2rem;
  margin-bottom: 2rem;
  color: #707070;
  font-size: 1.6rem;
  letter-spacing: 0.43px;
`;

export const Check = styled.div<{ completed: boolean }>`
  width: 3rem !important;
  height: 3rem !important;
  border-radius: 50%;
  display: grid;
  place-items: center;
  border: 2px solid var(--color-primary);
  cursor: pointer;

  svg {
    display: ${(props) => (props.completed ? 'block' : 'none')};
    width: 2rem;
    height: 2rem;
    fill: var(--color-primary);
  }
`;
