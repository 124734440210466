import React from 'react';
import styles from '../Auth.module.scss';
import { motion } from 'framer-motion';
import { toast, ToastContainer } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema } from './schema';
import { TERMS_AND_CONDITIONS } from '../../../constants/constants';
import { Container } from './styles';
import { BsCheck } from 'react-icons/bs';
import { useResetPasswordMutation } from '../../../features/auth/resetApiSlice';
import { selectTokenAndRef } from '../../../features/auth/refSlice';
import pageRoutes from '../../../constants/pageRoutes';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../../app/hooks';

const NewPassword = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid },
  } = useForm<{ password: string; confirmPassword: string }>({
    resolver: yupResolver(schema),
    mode: 'all',
  });

  const [resetPassword, { isLoading }] = useResetPasswordMutation();
  const token = useAppSelector(selectTokenAndRef);

  const requirements = [
    'Minimum of 6 characters',
    'Contains an uppercase letter',
    'Contains a number (0-9)',
    'Contains special characters (!@#$%&*.)',
    'Cannot be a previously used password',
  ];
  const onSubmitHandler = async (data) => {
    const { password } = data;
    const input = {
      password,
      ...token,
    };
    try {
      await resetPassword(input).unwrap();
      toast.success('Password reset successful');
      navigate(`/${pageRoutes.SIGN_IN}`);
    } catch (err: any) {
      console.log(err);
      toast.error(err.data.message);
    }
  };

  const buttonContent = 'Reset password';

  return (
    <motion.div className={styles.main} initial={{ opacity: 0, scale: 0.5 }} animate={{ opacity: 1, scale: 1 }}>
      <h2>
        Reset your
        <br />
        password
      </h2>
      <p className={styles.welcome}>Kindly provide the following to reset your password</p>
      <form style={{ marginBottom: '4rem' }} onSubmit={handleSubmit(onSubmitHandler)}>
        <div className={styles.input_container}>
          <input className={styles.text_input} type={'password'} {...register('password')} placeholder={'Password'} />
          {errors.password?.message && <small>{errors.password?.message}</small>}
        </div>
        <div className={styles.input_container}>
          <input
            className={styles.text_input}
            type={'password'}
            {...register('confirmPassword')}
            placeholder={'Confirm password'}
          />
          {errors.confirmPassword?.message && <small>{errors.confirmPassword?.message}</small>}
        </div>
        <p className={styles.policies}>
          By continuing you agree to the{' '}
          <a target={'_blank'} href={TERMS_AND_CONDITIONS}>
            Terms & Conditions{' '}
          </a>
          of Melanin Kapital
        </p>
        <motion.button
          type={'submit'}
          disabled={!isDirty || !isValid || isLoading}
          whileHover={{ backgroundColor: 'var(--color-primary-dark)' }}
          whileTap={{ scale: 0.97 }}
          className={styles.sign_in_btn}>
          {buttonContent}
        </motion.button>
      </form>
      <Container>
        <h3>Password requirements</h3>
        {requirements.map((req) => (
          <div key={req}>
            <BsCheck />
            <p>{req}</p>
          </div>
        ))}
      </Container>
      <ToastContainer autoClose={1000} />
    </motion.div>
  );
};

export default NewPassword;
