import styled from 'styled-components';

export const CommentSection = styled.div`
  margin-top: 20px;

  .content {
    background-color: #e5fff8;
    border-radius: 10px;
    padding: 1.6rem;
    margin-bottom: 1.6rem;
  }
`;

export const Comment = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;

  & img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
  }
`;

export const CommentDetails = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;

  & span {
    font-size: 12px;
    font-weight: bold;
  }

  & p {
    font-size: 11px;
    margin-left: 5px;
  }
`;

export const CommentText = styled.p`
  font-size: 1.6rem;
  margin-left: 50px;
`;
