import React from 'react';
import { Container, EditButton, Info, Logo } from './styles';
import { BsCameraFill } from 'react-icons/bs';
import images from '../../../constants';
import { openModal } from '../../../features/modal/modalSlice';
import { MODAL_TYPES } from '../../../modules/ModalWindow/modalTypes';
import { useAppDispatch } from '../../../app/hooks';

const BusinessTopHeader = ({ isEdit, business, onEdit, slogan }) => {
  const dispatch = useAppDispatch();
  const onOpenModal = () => {
    if (business.isCompany) {
      dispatch(openModal({ modalType: MODAL_TYPES.EDIT_BUS_LOGO_MODAL }));
    }
  };

  return (
    <Container>
      <div className="content">
        <div className="bg_image" />
        <Logo>
          <img src={business.logo || images.placeholderLogo} alt={business.logoDescription} />
          <div tabIndex={0} onClick={onOpenModal}>
            <BsCameraFill />
          </div>
        </Logo>
        <Info>
          <h3>{isEdit || !business.isCompany ? 'Complete Your Business Profile' : business.name}</h3>
          {isEdit || !business.isCompany ? (
            <p>
              Looking to get qualified for an asset? Please provide the following details to help us know more about
              your company.
            </p>
          ) : null}
          {business.isCompany && !isEdit ? (
            <>
              <p style={{ textTransform: 'capitalize' }}>{slogan}</p>
              <EditButton
                onClick={() => onEdit(true)}
                whileTap={{ scale: 0.97 }}
                whileHover={{ backgroundColor: '#014e35' }}>
                Update business profile
              </EditButton>
            </>
          ) : null}
        </Info>
      </div>
      <div className="space" />
    </Container>
  );
};

export default BusinessTopHeader;
