import * as yup from 'yup';

export const schema = yup.object().shape({
  name: yup.string().required('Company name is required'),
  category_id: yup.string().required('Category is required'),
  type_of_company: yup.string().required('Type of company is required'),
  office_location: yup.string().required('Office location is required'),
  company_website: yup.string(),
  office_phone: yup.string().min(7, 'Enter a valid phone number').required('Phone number is required'),
  office_email: yup.string().email('Enter a valid email address').required('Email is required'),
  description: yup.string().required('Brief business history is required'),
  linkedin: yup.string(),
  twitter: yup.string(),
  facebook: yup.string(),
  instagram: yup.string(),
});
