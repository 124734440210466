import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { closeModal, selectCurrentModal } from '../../../../features/modal/modalSlice';
import { MODAL_TYPES } from '../../modalTypes';
import Modal from 'react-modal';
import { Button, Close, Container } from './styles';
import { AiOutlineClose } from 'react-icons/ai';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    zIndex: 100,
    border: 'none',
    padding: 0,
    width: '80%',
    maxWidth: '650px',
    borderRadius: '10px',
    backgroundColor: 'transparent',
  },
};

const EnrollModal = () => {
  const dispatch = useAppDispatch();
  const modalType = useAppSelector(selectCurrentModal);
  const isOpen = modalType === MODAL_TYPES.ENROLL_MODAL;
  const onSubmitHandler = () => {
    dispatch(closeModal());
  };
  return (
    <Modal
      onRequestClose={onSubmitHandler}
      isOpen={isOpen}
      style={customStyles}
      ariaHideApp={false}
      overlayClassName="Overlay">
      <Container>
        <div className="content">
          <Close>
            <AiOutlineClose />
          </Close>
          <h4>Sorry!</h4>
          <p>
            You need to be enrolled in this masterclass to be able to access the video content and get your
            certification!
          </p>
          <Button>ENROLL FOR THIS MASTERCLASS</Button>
          <div className="not_now" onClick={onSubmitHandler}>
            Not now
          </div>
        </div>
      </Container>
    </Modal>
  );
};

export default EnrollModal;
