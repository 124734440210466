import styled from 'styled-components';
import { motion } from 'framer-motion';

export const BtnDiv = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 2rem;
  margin-top: 2rem;
`;

export const Button = styled(motion.button)`
  padding: 2rem;
  font-size: 1.6rem;
  font-weight: 500;
  color: #ffffff;
  border-radius: 5px;
  background-color: var(--color-primary);
  border: 1px solid var(--color-primary);

  &.accent {
    background-color: transparent;
    color: var(--color-accent);
    border: 1px solid var(--color-accent);
  }
`;

export const HRule = styled.hr`
  height: 0;
  border: 0.8px solid #707070;
  margin: 1rem -1.6rem;
`;

export const TimeChip = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;

  p {
    width: max-content;
    font-size: 1.2rem;
    font-weight: 500;
    letter-spacing: 0.4px;
    color: #707070;
    background-color: rgba(255, 196, 27, 0.66);
    padding: 0.8rem 1.2rem;
    border-radius: 3px;
    text-align: right;
    margin: 0.5rem 0;
  }
`;
