import { createEntityAdapter } from '@reduxjs/toolkit';
import { apiSlice } from '../../app/api/apiSlice';
import { LOANS_TAG } from '../../types/api-responses';

const loansAdapter = createEntityAdapter();
const initialState = loansAdapter.getInitialState();

export const loansApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    applyLoan: build.mutation({
      query: (payload: any) => {
        const data = new FormData();
        for (const payloadKey in payload) {
          data.append(payloadKey, payload[payloadKey]);
        }
        return {
          url: '/loan/application/apply',
          method: 'POST',
          body: data,
        };
      },
      invalidatesTags: [{ type: LOANS_TAG, id: 'LIST' }],
    }),
    getLoanApplicationById: build.query<any, string>({
      query: (id: string) => `/loan/application/${id}`,
      transformResponse: (rawResult: any) => rawResult?.response,
    }),
    getLoans: build.query<any, string>({
      query: (id: string) => `/loan/application/list/${id}`,
      transformResponse: (rawResult: any) => loansAdapter.setAll(initialState, rawResult?.response),
      providesTags: (result) =>
        result
          ? [...result.ids.map((id) => ({ type: LOANS_TAG, id })), { type: LOANS_TAG, id: 'LIST' }]
          : [{ type: LOANS_TAG, id: 'LIST' }],
    }),
  }),
});

export const { useApplyLoanMutation, useGetLoansQuery, useGetLoanApplicationByIdQuery } = loansApiSlice;
