import React from 'react';
import { Close, Container, Header, HeaderActions } from '../LoanModal/styles';
import CompanyDocuments from '../../../../components/LoanModal/CompanyDocuments/CompanyDocuments';
import Modal from 'react-modal';
import { closeModal, selectCurrentModal } from '../../../../features/modal/modalSlice';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { MODAL_TYPES } from '../../modalTypes';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    zIndex: 100,
    border: 'none',
    padding: 0,
    width: '70%',
    borderRadius: '10px',
    height: 'auto',
    paddingBottom: '2rem',
  },
};

const EditDocsModal = () => {
  const dispatch = useAppDispatch();
  const modalType = useAppSelector(selectCurrentModal);
  const isOpen = modalType === MODAL_TYPES.EDIT_DOCS_MODAL;
  const onSubmitHandler = () => {
    dispatch(closeModal());
  };
  const content = <CompanyDocuments onClose={onSubmitHandler} />;
  return (
    <Modal
      onRequestClose={() => {}}
      isOpen={isOpen}
      style={customStyles}
      ariaHideApp={false}
      overlayClassName="Overlay">
      <Header>
        <h2>Edit your documents</h2>
        <HeaderActions>
          <Close onClick={onSubmitHandler} />
        </HeaderActions>
      </Header>
      <Container>
        <div>
          <div className="scrollable">{content}</div>
        </div>
      </Container>
    </Modal>
  );
};

export default EditDocsModal;
