import * as yup from 'yup';

export const schema = yup.object().shape({
  firstName: yup.string().required('Firstname is required'),
  lastName: yup.string().required('Lastname is required'),
  email: yup.string().email('Email is invalid, example: username@gmail.com').required('Email is required'),
  phone: yup.string().min(7, 'Enter a valid phone number').required('Phone number is required'),
  nationality: yup.string().required('Country is required'),
  date_of_birth: yup.date(),
  gender: yup.string(),
  linkedin: yup.string(),
});
