import styled from 'styled-components';

export const Wrapper = styled.section`
  margin-right: 6rem;
  margin-left: 3rem;
`;
export const Container = styled.div`
  padding: 2.5rem;
  border: 1px solid #707070;
  border-radius: 5px;
  margin-bottom: 1.6rem;
  width: 100%;
`;
export const Label = styled.label`
  display: block;
  font-size: 1.72rem;
  font-weight: 500;
  margin-bottom: 1rem;
`;

export const Small = styled.small`
  color: #707070;
  font-size: 1.2rem;
  margin-top: -0.5rem;
  display: block;
`;
