import React from 'react';
import { Icon, Input, InputWrapper, Label, SummaryWrapper, Text, Title } from './styles';
import { HiOutlineMail } from 'react-icons/hi';
import { BsCalendar2Week, BsWhatsapp } from 'react-icons/bs';
import { FaRegAddressBook } from 'react-icons/fa';
import { useGetUserQuery } from '../../../features/user/userApiSlice';
import MeetupLoader from '../../Loaders/MeetupLoader';
import ProfileHeader from '../ProfileHeader/ProfileHeader';
import { RiLinkedinFill } from 'react-icons/ri';
import { extractName } from '../../../utils/object-mapper';

const ProfileSummary = () => {
  const { data: user, isLoading } = useGetUserQuery();
  if (isLoading) return <MeetupLoader />;
  const dob = new Date(user.date_of_birth);
  const { firstName, lastName } = extractName(user.name);
  return (
    <>
      <ProfileHeader user={user} />
      <SummaryWrapper>
        <Title>Profile Summary</Title>
        <InputWrapper>
          <Input>
            <Icon>
              <FaRegAddressBook />
            </Icon>
            <Label>Firstname:</Label>
            <Text>{firstName}</Text>
          </Input>
          <Input>
            <Icon>
              <FaRegAddressBook />
            </Icon>
            <Label>Lastname:</Label>
            <Text>{lastName}</Text>
          </Input>
          <Input>
            <Icon>
              <FaRegAddressBook />
            </Icon>
            <Label>Country:</Label>
            <Text>{user.other_info.nationality}</Text>
          </Input>
          <Input>
            <Icon>
              <FaRegAddressBook />
            </Icon>
            <Label>Gender</Label>
            <Text style={{ textTransform: 'capitalize' }}>{user.gender}</Text>
          </Input>
        </InputWrapper>
        <Input>
          <Icon>
            <HiOutlineMail />
          </Icon>
          <Label>Email address:</Label>
          <Text>{user.email}</Text>
        </Input>
        <Input>
          <Icon>
            <BsCalendar2Week />
          </Icon>
          <Label>Date of birth:</Label>
          <Text>{dob.toLocaleString().split(',')[0]}</Text>
        </Input>
        <Input>
          <Icon>
            <BsWhatsapp />
          </Icon>
          <Label>Phone number:</Label>
          <Text>{user.phone}</Text>
        </Input>
        <Input>
          <Icon>
            <RiLinkedinFill />
          </Icon>
          <Label>LinkedIn</Label>
          <Text className={'link'}>
            <a href={user.other_info.linkedin} target={'_blank'} referrerPolicy={'no-referrer'}>
              Visit
            </a>
          </Text>
        </Input>
      </SummaryWrapper>
    </>
  );
};

export default ProfileSummary;
