import React from 'react';
import { BsInfoCircle } from 'react-icons/bs';
import { AnalysisCard, AnalysisWrapper, ContentIcon, ExTitle, IncomeCard, InfoIcon, Text } from './styles';
import { VscGraphLine } from 'react-icons/vsc';
import { useGetIncomeQuery } from '../../features/analysis/analysisSlice';
import MeetupLoader from '../Loaders/MeetupLoader';
import { Btn, Header, HeaderContainer, StatementWrapper } from '../RiskProfile/Statements/styles';
import { MPESA_DOC } from '../../constants/constants';
import { openModal } from '../../features/modal/modalSlice';
import { MODAL_TYPES } from '../../modules/ModalWindow/modalTypes';
import { useAppDispatch } from '../../app/hooks';

const AnalysisIncome = ({ company, documents, isAnalysis }) => {
  const dispatch = useAppDispatch();
  const { data, isLoading, isSuccess, isError, error } = useGetIncomeQuery(company.id);
  let incomes = [];

  if (isLoading) {
    return <MeetupLoader />;
  } else if (isError) {
    console.log(error);
  } else if (isSuccess) {
    const { id, business_id, created_by, created_at, updated_at, file, media, confidence, ...refinedData } = data;
    incomes = Object.keys(refinedData).map((key) => {
      return { name: key.replace(/_/g, ' '), amount: refinedData[key]?.toLocaleString() };
    });
  }

  const onUpload = () => {
    localStorage.setItem('doc', MPESA_DOC);
    dispatch(openModal({ modalType: MODAL_TYPES.EDIT_DOCS_MODAL }));
  };

  if (!isAnalysis) {
    return (
      <StatementWrapper>
        <HeaderContainer>
          <Header>Income Analysis Summary</Header>
        </HeaderContainer>
        <Text style={{ color: 'black', marginTop: '1.6rem' }}>You data is being analysed. Please check back later</Text>
      </StatementWrapper>
    );
  }

  return (
    <AnalysisWrapper>
      <IncomeCard>
        <h6>Confidence Intervals On Income</h6>
        <p>Percentage of confidence on the amount identified as Income</p>
        <div className="content">
          <p>Confidence</p>
          <p className={'percent'}>{data?.confidence ? `${data?.confidence * 100}%` : '0%'}</p>
        </div>
        <div className="bar">
          <div style={{ width: `${data?.confidence ? `${data?.confidence * 100}%` : '0%'}` }} />
        </div>
      </IncomeCard>
      {incomes.map((income, index) => (
        <AnalysisCard key={index}>
          <ContentIcon bgc={'#e8fff8'} customColor={'#018158'}>
            <VscGraphLine />
          </ContentIcon>
          <ExTitle>{income.amount}</ExTitle>
          <Text>{income.name}</Text>
          <InfoIcon>
            <BsInfoCircle />
          </InfoIcon>
        </AnalysisCard>
      ))}
    </AnalysisWrapper>
  );
};

export default AnalysisIncome;
