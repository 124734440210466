import React from 'react';
import styles from '../Post.module.scss';
import { TbDots } from 'react-icons/tb';
import PostActions from './PostActions';
import images from '../../../../constants';
import TimeAgo from '../../../Common/TimeAgo';

const PostItem = ({ post, onModal }) => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.header__profile}>
          <img src={images.logoIcon} alt="User" />
          <div>
            <p>
              Admin <TimeAgo timestamp={post.created_at} />
            </p>
            <p>Melanin Kapital</p>
          </div>
        </div>
        <TbDots />
      </div>
      <hr />
      <div className={styles.content} onClick={() => onModal(post.id)}>
        <p>{post.content}</p>
        {post.poster && <img src={post.poster} alt="Post" />}
      </div>
      <hr />
      <PostActions post={post} />
    </div>
  );
};

export default PostItem;
