import React, { useState } from 'react';
import styles from './Faqs.module.scss';
import { motion } from 'framer-motion';
import { useGetAllFaqsQuery } from '../../features/faqs/faqsApiSlice';

function Faqs() {
  const [expanded, setExpanded] = useState(0);

  const { data: faqsList, isLoading, isSuccess, isError, error } = useGetAllFaqsQuery();

  let content;
  if (isLoading)
    content = (
      <div className={'container-meetup'}>
        <div className="meetup">
          <div />
          <div />
          <div />
          <div />
        </div>
      </div>
    );
  else if (isSuccess) {
    content = faqsList.map((faq, index) => (
      <article onClick={() => setExpanded(index)} key={`${faq.question + index}`}>
        <h5>
          <span>0{index + 1}</span>
          {faq.question}
        </h5>
        <motion.div whileInView={{ y: [100, 50, 0], opacity: [0, 0, 1] }} transition={{ duration: 0.5 }}>
          <p className={styles.show}>{faq.answer}</p>
        </motion.div>
        {index < faqsList.length - 1 ? <hr /> : null}
      </article>
    ));
  } else if (isError) {
    content = <p>Something went wrong</p>;
    console.log(error);
  }

  return (
    <section className={styles.container}>
      <h3>Frequently asked questions</h3>
      {content}
    </section>
  );
}

export default Faqs;
