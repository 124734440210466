import styled from 'styled-components';

export const MTicketWrapper = styled.article`
  background-color: #fff;
  border-radius: 10px;
  margin-bottom: 2rem;
  padding: 1rem 1rem 1rem 2rem;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  column-gap: 1rem;
  margin-bottom: 1rem;
`;

export const Avatar = styled.img`
  width: 4rem;
  height: 4rem;
  object-fit: contain;
  border-radius: 50%;
`;

export const Username = styled.p`
  font-family: Montserrat, sans-serif;
  font-weight: 600;
  font-size: 1.28rem;
  margin-bottom: 0.5rem;
`;

export const Date = styled(Username)`
  font-weight: normal;
  font-size: 1.2rem;
`;

export const Title = styled.p`
  width: 80%;
  font-weight: 500;
  font-size: 1.44rem;
`;

export const Text = styled.p`
  width: 80%;
  font-size: 1.34rem;
`;
