import styled from 'styled-components';

export const Wrapper = styled.div``;

export const TArea = styled.textarea`
  height: auto;
  outline: none;
  border: 1px solid #707070;
  border-radius: 5px;
  width: 100%;
  padding: 1.6rem;
  margin: 0 0 1.6rem;
  z-index: 1;

  &:focus {
    border-color: var(--color-accent);
  }
`;

export const Socials = styled.div`
  display: flex;
  align-items: center;
  column-gap: 2rem;
  margin-bottom: 1.6rem;

  svg {
    width: 3rem;
    height: 3rem;
  }

  input {
    flex: 1;
    border-radius: 5px;
    padding: 1.6rem;
    background-color: transparent;
    outline: none;
    border: 1px solid #707070;

    &:focus {
      border-color: var(--color-accent);
    }
  }
`;
