import styled from 'styled-components';

export const Wrapper = styled.section`
  border: 1px solid #707070;
  border-radius: 5px;
  padding: 1.6rem;
  margin-right: 6rem;
  margin-left: 3rem;
`;
export const IContainer = styled.div<{ rows: number }>`
  display: grid;
  grid: repeat(${(props) => props.rows}, 1fr) / 1fr 1fr;
  gap: 1.6rem;
  margin-bottom: 1.6rem;
`;

export const Label = styled.label`
  color: #707070;
  font-size: 1.5rem;
  font-weight: 500;
  display: block;
`;

export const Input = styled.input`
  background-color: transparent;
  outline: none;
  border-radius: 5px;
  border: 1px solid #707070;
  padding: 1.6rem;
  width: 100%;

  &:focus {
    border-color: var(--color-accent);
  }
`;
