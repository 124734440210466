import React, { useState } from 'react';
import { Wrapper } from './styles';
import AcademyHeader from '../../../components/Academy/Header/AcademyHeader';
import { Outlet } from 'react-router-dom';
import MasterClassCard from '../../../components/Academy/MasterClassCard/MasterClassCard';
import { AnimatePresence, motion } from 'framer-motion';
import { useAppSelector } from '../../../app/hooks';
import { selectAllCoursesReversed, useGetCoursesQuery } from '../../../features/courses/coursesApiSlice';
import MeetupLoader from '../../../components/Loaders/MeetupLoader';
import { selectAllCategories, useGetCategoriesQuery } from '../../../features/categories/categories';
import { useSelector } from 'react-redux';
import Select from 'react-select';

const Academy = () => {
  const { isSuccess } = useGetCategoriesQuery();
  const categories = useSelector(selectAllCategories);
  let tabs = [{ label: 'All Courses', value: 0 }];
  if (isSuccess) {
    tabs = [
      ...tabs,
      ...categories
        .filter((category: any) => category.type === 'academy')
        .map((category: any) => {
          return { label: category.name, value: category.id };
        }),
    ];
  }
  let filteredCourses = [];
  const { isLoading } = useGetCoursesQuery();
  const courses = useAppSelector(selectAllCoursesReversed);
  const [selectedTab, setSelectedTab] = useState(tabs[0]);
  if (selectedTab.value !== 0) {
    filteredCourses = courses.filter((course: any) => course.category === selectedTab.value);
  } else {
    filteredCourses = courses;
  }

  return (
    <Wrapper>
      <div>
        <AcademyHeader />
        <div className={'tab'}>
          <p className="heading">{selectedTab.label}</p>
          <Select
            value={selectedTab}
            options={tabs}
            onChange={(e) => setSelectedTab(e)}
            styles={{
              container: (base) => ({
                ...base,
                fontSize: '1.6rem',
                width: '30rem',
              }),
              control: (base) => ({
                ...base,
                fontSize: '1.6rem',
                height: '5.4rem',
              }),
            }}
            defaultValue={tabs[0]}
            isSearchable
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: '#cdd5f7',
                primary: '#FFAB00',
                neutral20: '#707070',
              },
            })}
          />
        </div>
        <AnimatePresence mode={'wait'}>
          <motion.div
            key={selectedTab.label}
            initial={{ y: 10, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: -10, opacity: 0 }}
            transition={{ duration: 0.2 }}>
            {isLoading ? (
              <MeetupLoader />
            ) : (
              <div className="masterclasses">
                {filteredCourses.length === 0 && (
                  <div className="no_courses">
                    <p>No masterclasses found</p>
                  </div>
                )}
                {filteredCourses.map((course) => (
                  <MasterClassCard key={course.id} course={course} />
                ))}
              </div>
            )}
          </motion.div>
        </AnimatePresence>
      </div>
      <div className="sidebar">
        <Outlet />
      </div>
    </Wrapper>
  );
};

export default Academy;
