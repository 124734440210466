import React, { useState } from 'react';
import { DragFile, FLabel, Form, UBtn } from './styles';

function DragNDrop(props) {
  const [dragActive, setDragActive] = React.useState(false);
  const inputRef = React.useRef(null);
  const [name, setName] = useState('Drag and drop your file here or click the button below to upload a file');

  // handle drag events
  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  // triggers when file is dropped
  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      // at least one file has been dropped so do something
      // handleFiles(e.dataTransfer.files);
      setName(`Chosen file: ${e.dataTransfer.files[0].name}`);
    }
  };

  // triggers when file is selected with click
  const handleChange = function (e) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      // at least one file has been selected so do something
      // handleFiles(e.target.files);
      setName(`Chosen file: ${e.target.files[0].name}`);
    }
  };

  const onButtonClick = () => {
    inputRef.current.click();
  };

  return (
    <Form id="form-file-upload" onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()}>
      <input ref={inputRef} type="file" id="input-file-upload" multiple={false} onChange={handleChange} />
      <FLabel id="label-file-upload" htmlFor="input-file-upload" className={dragActive ? 'drag-active' : ''}>
        <div>
          <p className={'text'}>{name}</p>
          <UBtn className="upload-button" onClick={onButtonClick}>
            Upload a file
          </UBtn>
        </div>
      </FLabel>
      {dragActive && (
        <DragFile
          id="drag-file-element"
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}></DragFile>
      )}
    </Form>
  );
}

export default DragNDrop;
