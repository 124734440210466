import { apiSlice } from '../../app/api/apiSlice';
import { createEntityAdapter, createSelector } from '@reduxjs/toolkit';

const expertsAdapter = createEntityAdapter();
const initialState = expertsAdapter.getInitialState();
export const expertsSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getExperts: build.query<any, void>({
      query: () => '/all/expert',
      transformResponse: (rawResult: any) => {
        return expertsAdapter.setAll(initialState, rawResult?.response);
      },
    }),
  }),
});

export const { useGetExpertsQuery } = expertsSlice;
const selectExpertsResult = expertsSlice?.endpoints?.getExperts.select();
const selectExpertsData = createSelector(selectExpertsResult, (expertsResult) => expertsResult?.data);
export const { selectAll: selectAllExperts } = expertsAdapter.getSelectors(
  (state: any) => selectExpertsData(state) ?? initialState,
);
