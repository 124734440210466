import React from 'react';
import ReactPlayer from 'react-player';
import { Chip, CourseContainer, Header, HRule, Main, SideBar, Video } from './styles';
import CourseProgress from '../../../../components/Masterclass/Progress/CourseProgress';
import LessonCard from '../../../../components/Masterclass/LessonCard/LessonCard';
import { useGetLessonsQuery } from '../../../../features/lessons/lessonsApiSlice';
import { useParams } from 'react-router-dom';
import MeetupLoader from '../../../../components/Loaders/MeetupLoader';
import { selectAllCoursesReversed, useGetCoursesQuery } from '../../../../features/courses/coursesApiSlice';
import { useAppSelector } from '../../../../app/hooks';
import { formatDuration } from '../../../../utils/object-mapper';
import { selectAllCategories, useGetCategoriesQuery } from '../../../../features/categories/categories';
import { useSelector } from 'react-redux';

const course_sample = {
  title: 'Introduction to fundraising',
  instructor: {
    name: 'Melanie Keita',
    imageUrl: 'https://melaninkapital.com/wp-content/uploads/2021/02/melanie.jpg',
  },
  description:
    'This Masterclass will explain the basic eligibility concepts with regards to what type of funding you should look out for and when to ask for funding',
  category: 'Finance',
  introVideo: 'https://youtu.be/dbyDT3Qp3hA',
  lessons: [
    {
      title: 'When do you need to fundraise & when is the right time?',
      duration: '12',
      description:
        'This masterclass will take you through a checklist that your business should tick before starting the fundraising process.',
      completed: true,
    },
    {
      title: 'Types of financing ? Debt, equity and grant // the actors',
      duration: '12',
      description:
        'This masterclass will deep dive into the different types of funding available in the market describing the pros and cons of each to help you know identify the best match for your business.',
      completed: false,
    },
    {
      title: 'Fundraising process: your must-haves and how it works',
      duration: '12',
      description: 'This masterclass gives a comprehensive analysis of the fundraising process.',
      completed: false,
    },
    {
      title: 'In the shoes of your investors: how do investors work ?',
      duration: '12',
      description:
        'This Masterclass will explain the fundraising process from the investors perspective to help you understand',
      completed: false,
    },
    {
      title: 'How to fundraise on Melanin Kapital platform?',
      duration: '12',
      description:
        'This masterclass will take you through a checklist that your business should tick before starting the fundraising process.',
      completed: false,
    },
  ],
  duration: '60',
  lessonsCount: 5,
};
const Course = () => {
  const { isLoading: loadingCategories } = useGetCategoriesQuery();
  const categories = useSelector(selectAllCategories);
  const { courseId } = useParams();
  const { isLoading: loading } = useGetCoursesQuery();
  const courses = useAppSelector(selectAllCoursesReversed);
  const { isLoading, isSuccess, isError, error, data } = useGetLessonsQuery(courseId);
  const course = courses.find((obj: any) => obj.id === +courseId);
  const category = categories.find((obj: any) => obj.id === course?.category) as any;

  let lessons = [];
  let totalDuration = 0;
  if (isLoading || loading) {
    return <MeetupLoader />;
  } else if (isSuccess) {
    lessons = data.ids.map((id) => {
      totalDuration += +data.entities[id].duration;
      return data.entities[id];
    });
  } else if (isError) {
    console.log(error);
  }

  const isEnrolled = true;

  return (
    <CourseContainer>
      <Main>
        {lessons[0]?.video_url ? (
          <Video>
            <ReactPlayer url={lessons[0]?.video_url} controls={true} width="100%" height={'350px'} light={true} />
          </Video>
        ) : (
          <h2>
            <b>This course has no lessons yet, please check again later.</b>
          </h2>
        )}
        <div className="flex">
          <Header>{course.name}</Header>
        </div>
        <div className="desc">{course.description}</div>
        <div className="flex">
          {/*<Instructor instructor={course_sample.instructor} />
          {isEnrolled ? (
            <div className="flex">
              <h4 className={'completion'}>Masterclass Completion</h4>
              <CircleProgress percentage={30} />
            </div>
          ) : (
            <Button whileTap={{ scale: 0.97 }} whileHover={{ backgroundColor: '#014e35' }}>
              Enroll
            </Button>
          )}*/}
          <div />
          <Chip>
            <img
              src="https://firebasestorage.googleapis.com/v0/b/nectar-f5ee3.appspot.com/o/academy%2FGroup%2011724.svg?alt=media&token=41831bd0-27f0-4643-a900-8ef684445754"
              alt="Finance Chip"
            />
            <p>{category?.name}</p>
          </Chip>
        </div>
        <HRule />
        <div className="flex header">
          <p>Masterclass content</p>
          <small>
            {lessons.length} Lessons &#x2022; {formatDuration(totalDuration)}
          </small>
        </div>
        {lessons.map((lesson, index) => (
          <LessonCard key={index} count={index + 1} lesson={lesson} />
        ))}
      </Main>
      <SideBar>
        <div className="main">
          <h6>Masterclass Completion Progress</h6>
          {lessons.map((lesson) => (
            <CourseProgress key={lesson.title} lesson={lesson} />
          ))}
        </div>
      </SideBar>
    </CourseContainer>
  );
};

export default Course;
