import styled from 'styled-components';

export const UserWrapper = styled.section`
  display: grid;
  grid-template-columns: 2fr 1fr;
  column-gap: 2rem;
  position: relative;
  top: 7rem;
  padding-right: 1rem;

  .main {
    padding: 1.6rem;
  }

  .side {
    background-color: #fff;
    min-height: 100vh;
    padding: 1.6rem;
  }
`;
