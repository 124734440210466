import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { closeModal, selectCurrentModal } from '../../../../features/modal/modalSlice';
import { MODAL_TYPES } from '../../modalTypes';
import Modal from 'react-modal';
import SurveyComponent from '../../../../components/survey/SurveyComponent';
import { ModalContent } from './styles';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    zIndex: 100,
    border: 'none',
    width: '70%',
  },
};
function UploadStatement(props) {
  const dispatch = useAppDispatch();
  const modalType = useAppSelector(selectCurrentModal);
  const isOpen = modalType === MODAL_TYPES.UPLOAD_MODAL;
  const onSubmitHandler = () => {
    dispatch(closeModal());
  };
  const onCompleteSurvey = (survey) => {
    console.log(survey.data);
  };

  return (
    <Modal
      onRequestClose={onSubmitHandler}
      isOpen={isOpen}
      style={customStyles}
      ariaHideApp={false}
      overlayClassName="Overlay">
      <ModalContent>
        <SurveyComponent onComplete={onCompleteSurvey} data={{}} json={''} />
      </ModalContent>
    </Modal>
  );
}

export default UploadStatement;
