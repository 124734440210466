import styled from 'styled-components';
import { motion } from 'framer-motion';

export const Container = styled.div`
  background-color: transparent;
  padding: 5rem;
  letter-spacing: 0.43px;
  text-align: center;
  font-size: 2.2rem;

  .content {
    background-color: #fff;
    border-radius: 10px;
    position: relative;
    padding: 2rem 2rem 3rem;
  }

  h4 {
    font-weight: bold;
    font-size: 3.5rem;
    margin: -2rem 0 1.6rem;
  }

  .not_now {
    text-decoration: underline;
    cursor: pointer;
    color: var(--color-accent);
    text-transform: uppercase;
    font-size: 2rem;
  }
`;

export const Close = styled.div`
  display: grid;
  place-items: center;
  position: relative;
  top: -7rem;
  right: 0;
  left: 0;
  margin: 0 auto;
  background-color: var(--color-accent);
  width: 10rem;
  height: 10rem;
  border-radius: 50%;

  svg {
    width: 6rem;
    height: 6rem;
    fill: white;
  }
`;

export const Button = styled(motion.button)`
  background-color: var(--color-primary);
  color: white;
  font-size: 1.8rem;
  text-transform: uppercase;
  font-weight: 500;
  width: max-content;
  padding: 2.1rem;
  margin: 2.7rem auto;
  letter-spacing: 0.6px;
`;
