import React, { useState } from 'react';
import {
  Arrow,
  Asset,
  Btn,
  BtnDiv,
  Card,
  Color,
  Container,
  Desc,
  Extra,
  Header,
  Specs,
  SpecsContainer,
  Text,
  Title,
  Wrapper,
} from './styles';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { closeModal, openModal, selectCurrentModal } from '../../../../features/modal/modalSlice';
import { MODAL_TYPES } from '../../modalTypes';
import Modal from 'react-modal';
import { AiOutlineClose } from 'react-icons/ai';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    zIndex: 100,
    border: 'none',
    padding: 0,
    width: 'max-content',
    borderRadius: '10px',
  },
};

const arr = [
  {
    name: 'Stima Boda',
    id: 'SUZUKI GSX-R600',
    price: 300000,
    color: '#018158',
    color_name: 'Green',
    model: 'SUZUKI',
    power: '1977 CC',
    weight: '200 pounds',
    imageUrl:
      'https://firebasestorage.googleapis.com/v0/b/nectar-f5ee3.appspot.com/o/bike.png?alt=media&token=0be548e4-a7b1-447c-8194-85448b88fb79',
    vendor:
      'https://firebasestorage.googleapis.com/v0/b/nectar-f5ee3.appspot.com/o/dashboard%2Fstima-boda.png?alt=media&token=877efbc3-781c-41e4-a745-4ade3a8d6860',
  },
  {
    name: 'Kawasaki Ninja',
    id: 'KAWASAKI ZX-10R',
    price: 270000,
    color: '#9c0000',
    color_name: 'Red',
    model: 'KAWASAKI',
    power: '1352 CC',
    weight: '190 pounds',
    imageUrl: 'https://i.pinimg.com/originals/84/00/08/8400082474ea23e4f010a59b0582e095.jpg',
    vendor:
      'https://firebasestorage.googleapis.com/v0/b/nectar-f5ee3.appspot.com/o/dashboard%2Fstima-boda.png?alt=media&token=877efbc3-781c-41e4-a745-4ade3a8d6860',
  },
  {
    name: 'BMW S1000RR',
    id: 'BMW S1000RR',
    price: 280000,
    color: '#000000',
    color_name: 'Black',
    model: 'BMW',
    power: '1912 CC',
    weight: '195 pounds',
    imageUrl: 'https://imgd.aeplcdn.com/600x600/n/cw/ec/125053/s1000rr-left-side-view.gif?isig=0',
    vendor:
      'https://firebasestorage.googleapis.com/v0/b/nectar-f5ee3.appspot.com/o/dashboard%2Fstima-boda.png?alt=media&token=877efbc3-781c-41e4-a745-4ade3a8d6860',
  },
];
const ProductModal = () => {
  const dispatch = useAppDispatch();
  const modalType = useAppSelector(selectCurrentModal);
  const isOpen = modalType === MODAL_TYPES.PRODUCT_MODAL;
  const [currentIndex, setCurrentIndex] = useState(0);

  const product = arr[currentIndex];
  const onSubmitHandler = () => {
    dispatch(closeModal());
  };
  const isNextProduct = currentIndex < arr.length - 1;
  const isPreviousProduct = currentIndex > 0 && currentIndex < arr.length;

  const openApplyLoanModal = () => {
    dispatch(openModal({ modalType: MODAL_TYPES.LOAN_MODAL }));
  };

  return (
    <Modal
      onRequestClose={onSubmitHandler}
      isOpen={isOpen}
      style={customStyles}
      ariaHideApp={false}
      overlayClassName="Overlay">
      <Wrapper>
        <Header>
          <p>Product Details</p> <AiOutlineClose onClick={onSubmitHandler} />
        </Header>
        <Container>
          <Asset>
            <div className="image">
              <img src={product.vendor} alt="Vendor" />
            </div>
            <div>
              <Title>{product.name}</Title>
              <Desc>{product.id}</Desc>
              <Card>
                <img src={product.imageUrl} alt="Product" />
                <Arrow
                  onClick={() => setCurrentIndex(currentIndex - 1)}
                  className={`left ${isPreviousProduct ? '' : 'inactive'}`}>
                  <BsChevronLeft />
                </Arrow>
                <Arrow
                  onClick={() => setCurrentIndex(currentIndex + 1)}
                  className={`right ${isNextProduct ? '' : 'inactive'}`}>
                  <BsChevronRight />
                </Arrow>
              </Card>
              <Extra>ASSET PRICE</Extra>
              <Title>
                {product.price.toLocaleString()} <span> KSH</span>
              </Title>
            </div>
          </Asset>
          <div>
            <Specs>Specifications</Specs>
            <BtnDiv>
              <SpecsContainer>
                <Desc>Color</Desc>
                <Text>{product.color_name}</Text>
                <Color color={product.color} />
              </SpecsContainer>
              <SpecsContainer>
                <Desc>Model</Desc>
                <Text>{product.model}</Text>
              </SpecsContainer>
              <SpecsContainer>
                <Desc>Power</Desc>
                <Text>{product.power}</Text>
              </SpecsContainer>
              <SpecsContainer>
                <Desc>Weight</Desc>
                <Text>{product.weight}</Text>
              </SpecsContainer>
              <Btn className={'apply'} onClick={openApplyLoanModal} whileTap={{ scale: 0.96 }}>
                Apply for Asset
              </Btn>
              <Btn className={'green'}>View More</Btn>
            </BtnDiv>
          </div>
        </Container>
      </Wrapper>
    </Modal>
  );
};

export default ProductModal;
