import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { closeModal, selectCurrentModal } from '../../../../features/modal/modalSlice';
import { MODAL_TYPES } from '../../modalTypes';
import pageRoutes from '../../../../constants/pageRoutes';
import Modal from 'react-modal';
import { Button, Close, Container } from '../CheckMail/styles';
import images from '../../../../constants';
import { useConfirmEmailMutation } from '../../../../features/user/userApiSlice';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    zIndex: 100,
    border: 'none',
    width: '75%',
    borderRadius: '20px',
    maxWidth: '600px',
  },
};

const ConfirmEmail = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const modalType = useAppSelector(selectCurrentModal);
  const navigate = useNavigate();
  const isOpen = modalType === MODAL_TYPES.CONFIRM_EMAIL && location.pathname === `/${pageRoutes.CONFIRM_EMAIL}`;

  const email = localStorage.getItem('email');

  const [confirmEmail, { isLoading }] = useConfirmEmailMutation();

  let content = '';

  const onBackPressed = () => {
    dispatch(closeModal());
    navigate(-1);
  };
  const handleConfirmEmail = async () => {
    try {
      await confirmEmail({ email: email || '' }).unwrap();
      content = 'Email sent successfully';
    } catch (err: any) {
      console.log(err);
    }
  };

  return (
    <Modal
      onRequestClose={() => {}}
      isOpen={isOpen}
      style={customStyles}
      ariaHideApp={false}
      overlayClassName="Overlay-2">
      <Container>
        <Close onClick={onBackPressed} />
        <h3>Confirm Your Email</h3>
        <img src={images.checkMail} alt="check mail" />
        <p>
          Check your mail for the confirmation link. If you don't see it, check your spam folder or{' '}
          <span className="link" onClick={isLoading ? () => {} : handleConfirmEmail}>
            {isLoading ? 'please wait' : 'resend email.'}
          </span>
        </p>
        <p>{content}</p>
        <Button onClick={onBackPressed}>Back to sign in</Button>
      </Container>
    </Modal>
  );
};

export default ConfirmEmail;
