import React, { useState } from 'react';
import styles from '../RequestList.module.scss';
import { selectAllUsers, useGetAllUsersQuery } from '../../../../features/connect/connectApiSlice';
import { toast, ToastContainer } from 'react-toastify';
import { useGetUserQuery } from '../../../../features/user/userApiSlice';
import { useAppSelector } from '../../../../app/hooks';
import MeetupLoader from '../../../Loaders/MeetupLoader';
import {
  selectAllRequests,
  useGetRequestsQuery,
  useRequestConnectMutation,
} from '../../../../features/connect/requestApiSlice';
import { getDocuments } from '../../../../utils/object-mapper';
import { useGetAcceptedQuery } from '../../../../features/connect/acceptedApiSlice';

const imageUrl =
  'https://thumbs.dreamstime.com/b/default-avatar-profile-icon-vector-social-media-user-portrait-176256935.jpg';

const ExploreList = () => {
  const { data: userData, isSuccess: isSuccessUser } = useGetUserQuery();
  const {
    data: allAccepted,
    isLoading: isAcceptedLoading,
    isError: isAcceptedError,
    isSuccess: isAcceptedSuccess,
  } = useGetAcceptedQuery();
  const { isLoading, isSuccess, isError, error } = useGetAllUsersQuery();
  const [selected, setSelected] = useState(0);
  const {
    isLoading: isLoadingRequests,
    isSuccess: isSuccessRequests,
    isError: isErrorRequests,
    error: errorRequests,
  } = useGetRequestsQuery();
  const [requestConnection, { isLoading: newRequestLoading }] = useRequestConnectMutation();

  const allUsers = useAppSelector(selectAllUsers) as any;
  const sentRequests = useAppSelector(selectAllRequests) as any;
  let filteredUsers = [];

  if (isError || isErrorRequests || isAcceptedError) {
    console.log(error);
    console.log(errorRequests);
    return <div>Something went wrong</div>;
  } else if (isLoading || isLoadingRequests || isAcceptedLoading) {
    return <MeetupLoader />;
  } else if (isSuccess || isSuccessRequests || isAcceptedSuccess) {
    const acceptedIds = new Set(allAccepted.map((accepted) => accepted['0'].id));
    const sentRequestIds = new Set(sentRequests.map((request) => request.id));
    filteredUsers = allUsers.filter((user) => {
      return user.id !== userData.id && !acceptedIds.has(user.id) && !sentRequestIds.has(user.id);
    });
  }

  const onConnect = async (id) => {
    setSelected(id);
    try {
      await requestConnection({ followed_id: id, follower_id: userData.id }).unwrap();
      toast.success('Connect request sent');
    } catch (e: any) {
      console.log(e);
      toast.error(e.data.message);
    }
  };
  const buttonContent = (id) =>
    newRequestLoading && id === selected ? <div className={'container dots-flow'} /> : 'Connect';

  return (
    <>
      {filteredUsers.length === 0 && <div className={styles.no_requests}>No users to connect with</div>}
      <div className={styles.requests}>
        {filteredUsers.map((user) => {
          const avatar = getDocuments(['avatar'], user?.media)[0]?.original_url;
          return (
            <article key={user.id} className={styles.requests_card}>
              <img
                className={styles.speaker}
                src={`${avatar === '' || avatar === undefined ? imageUrl : avatar}`}
                alt="Connect user"
              />
              <div className={styles.requests_card_content}>
                <h4>{user.name}</h4>
              </div>
              <button onClick={() => onConnect(user.id)} disabled={newRequestLoading}>
                {buttonContent(user.id)}
              </button>
            </article>
          );
        })}
      </div>
      <ToastContainer />
    </>
  );
};

export default ExploreList;
