export const addAttrName = (attributes, recos) => {
  const idToName = {};
  attributes.forEach((attribute) => {
    idToName[attribute.id] = attribute.name;
  });

  return recos.map((reco) => {
    if (idToName[reco.credit_attribute_id]) {
      return {
        ...reco,
        name: idToName[reco.credit_attribute_id],
      };
    }
  });
};

export const addBusAttrName = (attributes, recos) => {
  const idToName = {};
  attributes.forEach((attribute) => {
    idToName[attribute.id] = attribute.name;
  });

  return recos.map((reco) => {
    if (idToName[reco.business_gap_id]) {
      return {
        ...reco,
        name: idToName[reco.business_gap_id],
      };
    }
  });
};

export const getCurrentYearMonth = () => {
  const currentDate = new Date();
  return currentDate.toISOString().slice(0, 7).replace('-', '/');
};

export const cleanUpData = (attributes, scores) => {
  if (typeof scores !== 'object') {
    return {
      scores: [
        ...attributes.map((attribute) => ({
          attributeId: attribute.id,
          name: attribute.name,
          score: 0,
        })),
      ],
      totalScore: 0,
    };
  }
  const scoresColumns = Object.keys(scores);
  if (scoresColumns.length === 0 || attributes.length === 0) {
    return {
      scores: [
        ...attributes.map((attribute) => ({
          attributeId: attribute.id,
          name: attribute.name,
          score: 0,
        })),
      ],
      totalScore: 0,
    };
  }
  const currentYearMonth = getCurrentYearMonth();
  const scoresData = scores[currentYearMonth];

  if (scoresData?.length === 0) {
    return {
      scores: [
        ...attributes.map((attribute) => ({
          attributeId: attribute.id,
          name: attribute.name,
          score: 0,
        })),
      ],
      totalScore: 0,
    };
  } else {
    let totalScore = 0;
    return {
      scores: [
        ...attributes.map((attribute) => {
          const score = scoresData.find((score) => {
            if (score.credit_attribute_id) {
              return score.credit_attribute_id === attribute.id;
            } else {
              return score.business_gap_id === attribute.id;
            }
          });
          if (score) {
            totalScore += score.score;
            return {
              attributeId: attribute.id,
              name: attribute.name,
              score: score.score,
            };
          } else {
            return {
              attributeId: attribute.id,
              name: attribute.name,
              score: 0,
            };
          }
        }),
      ],
      totalScore,
    };
  }
};
