import React from 'react';
import { Card, HeroTitle, LoansHeader, LoansWrapper, Text, Title } from './styles';
import { Outlet } from 'react-router-dom';

const AssetsFlow = () => (
  <LoansWrapper>
    <Title>Overview</Title>
    <LoansHeader>
      <Card>
        <div>
          <Text>Amount funded</Text>
          <HeroTitle>
            500,000<span>ksh</span>
          </HeroTitle>
        </div>
        <img
          src="https://firebasestorage.googleapis.com/v0/b/nectar-f5ee3.appspot.com/o/funding%2Fmoney_icon.svg?alt=media&token=9822f1e9-199e-4a6f-819d-243cc56794fa"
          alt=""
        />
      </Card>
      <Card>
        <div>
          <Text>Amount Left To pay</Text>
          <HeroTitle>
            200,000<span>ksh</span>
          </HeroTitle>
        </div>
        <img
          src="https://firebasestorage.googleapis.com/v0/b/nectar-f5ee3.appspot.com/o/funding%2Fmoney_icon.svg?alt=media&token=9822f1e9-199e-4a6f-819d-243cc56794fa"
          alt=""
        />
      </Card>
      <Card>
        <div>
          <Text>Completed payments</Text>
          <HeroTitle>2 of 6</HeroTitle>
        </div>
        <img
          src="https://firebasestorage.googleapis.com/v0/b/nectar-f5ee3.appspot.com/o/funding%2Fmoney_icon.svg?alt=media&token=9822f1e9-199e-4a6f-819d-243cc56794fa"
          alt=""
        />
      </Card>
      <Card>
        <div>
          <Text>Missed payments</Text>
          <HeroTitle>5</HeroTitle>
        </div>
        <img
          src="https://firebasestorage.googleapis.com/v0/b/nectar-f5ee3.appspot.com/o/funding%2Fmoney_icon.svg?alt=media&token=9822f1e9-199e-4a6f-819d-243cc56794fa"
          alt=""
        />
      </Card>
      <Card>
        <div>
          <Text>Next due date</Text>
          <HeroTitle>Jan 22</HeroTitle>
        </div>
        <img
          src="https://firebasestorage.googleapis.com/v0/b/nectar-f5ee3.appspot.com/o/funding%2Fmoney_icon.svg?alt=media&token=9822f1e9-199e-4a6f-819d-243cc56794fa"
          alt=""
        />
      </Card>
    </LoansHeader>
    <Outlet />
  </LoansWrapper>
);
export default AssetsFlow;
