import React from 'react';
import styles from './SimilarEvents.module.scss';
import { WiDaySunny } from 'react-icons/wi';
import images from '../../constants';

function SimilarEvents() {
  return (
    <section className={styles.container}>
      <div className={styles.header}>
        <WiDaySunny /> <h3>Similar Events</h3>
      </div>
      <img
        className={styles.photo}
        src="https://firebasestorage.googleapis.com/v0/b/nectar-f5ee3.appspot.com/o/investment.jpg?alt=media&token=d3ca1555-1d83-4cb9-9989-f67666ef778f"
        alt="Investment photo"
      />
      <h3>Social investments</h3>
      <p className={styles.context}>
        Make an impact into your investors to get funding that suits You and your business in the long run
      </p>
      <div className={styles.recommend}>
        <div className={styles.recommend_friends}>
          <img src={images.user1} alt="" />
          <img src={images.user3} alt="" />
          <img src={images.user2} alt="" />
        </div>
        <p className={styles.recommend_count}>Jane and 4 others will be attending</p>
      </div>
      <div className={styles.container__btns}>
        <button>Share Event</button>
        <button className={styles.active}>Attend Event</button>
      </div>
    </section>
  );
}

export default SimilarEvents;
