import styled from 'styled-components';
import { motion } from 'framer-motion';
import images from '../../../constants';

export const HomeWrapper = styled.main`
  display: grid;
  grid-template-columns: 2fr 1fr;
  column-gap: 2rem;
  position: relative;
  top: 9rem;
  padding-right: 1rem;
`;

export const SideCard = styled.div`
  background-image: url(${images.sideBg});
  border: 1px solid var(--color-primary);
  color: var(--color-primary);
  letter-spacing: 0.4px;
  margin-bottom: 3rem;
  background-color: #fff;
  column-gap: 1rem;
  border-radius: 5px;
  -webkit-box-shadow: -5px 5.5px 10px 5.5px #dddddd;
  -moz-box-shadow: -5px 5.5px 10px 5.5px #dddddd;
  box-shadow: -5px 5.5px 10px 5.5px #dddddd;

  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  h3 {
    font-size: 2.3rem;
    font-weight: 500;
    text-transform: uppercase;
    margin: 1.6rem 0 1rem 1.6rem;
  }

  p {
    color: #000;
    font-size: 1.6rem;
    margin: 0 0 1rem 1.6rem;
  }

  img {
    display: block;
    max-width: 100%;
    object-fit: fill;
  }

  .bottom {
    display: grid;
    grid-template-columns: 2fr 1fr;
  }

  &.second {
    border-color: var(--color-accent);

    button {
      background-color: var(--color-accent);
    }
  }
`;

export const Button = styled(motion.button)<{ color: string }>`
  padding: 1.5rem;
  color: white;
  text-transform: capitalize;
  font-size: 1.6rem;
  background-color: var(--color-primary);
  margin: 0 0 1rem 1.6rem;
  letter-spacing: 0.6px;
  border-radius: 5px;
`;

export const Clip = styled.div<{ imageUrl: string }>`
  background-image: url(${(props) => props.imageUrl});
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
`;

export const Header = styled.div`
  background-color: var(--color-primary);
  color: white;
  padding: 1.6rem;
  border-radius: 10px 10px 0 0;
  //margin: -1.6rem -1.6rem 1.6rem;
  font-size: 2rem;
  font-weight: bold;
  letter-spacing: 0.6px;
`;

export const Main = styled.div`
  background-color: var(--color-grey-light-1);
  border-radius: 10px;
  max-height: 50rem;
  overflow-y: auto;
  margin: 0 1.6rem 2rem;

  &::-webkit-scrollbar {
    width: 3px;
    height: 1px;
  }

  &::-webkit-scrollbar-track {
    background-color: var(--color-primary-light-1);
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--color-accent);
    border-radius: 10px;
  }
`;

export const Recommend = styled.div`
  background-color: #fff;
  border-radius: 10px;
  font-size: 1.6rem;
  color: #707070;
  padding: 1.6rem;
  margin: 1.6rem;

  .head {
    display: flex;
    align-items: center;
    font-size: 1.72rem;
    font-weight: bold;
    letter-spacing: 0.3px;
    margin-bottom: 0.5rem;
    color: black;

    img {
      margin-right: 1rem;
      width: 2rem;
      height: 2rem;
    }
  }
`;
