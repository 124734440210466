import { createSelector, createSlice } from '@reduxjs/toolkit';

const initialState = {};

const messagesSlice = createSlice({
  name: 'messages',
  initialState,
  reducers: {
    addUserMessage: (state, action) => {
      const { userId, message } = action.payload;
      if (!state[userId]) {
        state[userId] = [];
      }
      state[userId].push(message);
    },
    addUserMessages: (state, action) => {
      const { userId, messages } = action.payload;
      state[userId] = messages;
    },
    insertUserMessages: (state, action) => {
      const { userId, messages } = action.payload;
      if (!state[userId]) {
        state[userId] = [];
      }
      state[userId] = [...messages, ...state[userId]];
    },
  },
});

export const { addUserMessage, addUserMessages, insertUserMessages } = messagesSlice.actions;

export default messagesSlice.reducer;

// Select all messages for a particular user
export const selectUserMessages = createSelector(
  (state) => state.messages,
  (_, userId) => userId,
  (messages, userId) => messages[userId] || [],
);
