import { apiSlice } from '../../app/api/apiSlice';
import { createEntityAdapter } from '@reduxjs/toolkit';

const adapter = createEntityAdapter();
const initialState = adapter.getInitialState();

const creditScoresApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCreditScores: builder.query<any, string>({
      query: (id) => `/attribute/credit/score/${id}`,
      transformResponse: (rawResult: any) => rawResult?.response,
    }),
  }),
});

export const { useGetCreditScoresQuery } = creditScoresApiSlice;
