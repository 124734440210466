import React, { useEffect, useState } from 'react';
import styles from './RequestList.module.scss';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import pageRoutes from '../../../constants/pageRoutes';
import { Li, Nav, Ul, Underline } from '../../Common/Tabs/styles';
import { AnimatePresence, motion } from 'framer-motion';

const posts = `/${pageRoutes.DASHBOARD}/${pageRoutes.KONNECT}/${pageRoutes.POSTS}`;
const home = `/${pageRoutes.DASHBOARD}/${pageRoutes.KONNECT}/${pageRoutes.REQUESTS}`;
const requests = `/${pageRoutes.DASHBOARD}/${pageRoutes.KONNECT}/${pageRoutes.REQUESTS}/${pageRoutes.REQUESTS_LIST}`;
const explore = `/${pageRoutes.DASHBOARD}/${pageRoutes.KONNECT}/${pageRoutes.REQUESTS}/${pageRoutes.EXPLORE}`;
const tabs = [
  { name: 'Explore', path: explore },
  { name: 'Connects', path: home },
  { name: 'Requests', path: requests },
];

const RequestList = () => {
  const location = useLocation();
  const [checked, setChecked] = useState(tabs[0]);
  const navigate = useNavigate();

  useEffect(() => {
    const tab = tabs.find((tab) => tab.path === location.pathname);
    if (tab) {
      setChecked(tab);
    }
  }, [location]);
  const onSetChecked = (tab) => {
    setChecked(tab);
    navigate(tab.path);
  };

  return (
    <section className={styles.container}>
      <div className={styles.top_nav}>
        <button onClick={() => navigate(posts)}>Back to posts</button>
        <input type="text" placeholder={'Search for entrepreneurs'} />
      </div>
      <Nav style={{ marginBottom: '8rem' }}>
        <Ul>
          {tabs.map((tab) => (
            <Li
              key={tab.name}
              className={tab.name === checked.name ? 'selected' : ''}
              onClick={() => onSetChecked(tab)}>
              {tab.name}
              {tab.name === checked.name ? <Underline layoutId="underline" /> : null}
            </Li>
          ))}
        </Ul>
      </Nav>
      <AnimatePresence mode={'wait'}>
        <motion.div
          key={checked.path}
          initial={{ y: 10, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: -10, opacity: 0 }}
          transition={{ duration: 0.2 }}>
          <Outlet />
        </motion.div>
      </AnimatePresence>
    </section>
  );
};
export default RequestList;
