import styled from 'styled-components';

export const ExpertsWrapper = styled.section`
  position: relative;
  top: 9rem;
  padding: 0 1rem;
  column-gap: 2rem;

  .experts {
    flex: 1;
  }

  .sticky {
    position: sticky;
    top: 9rem;
  }

  a {
    text-decoration: none;
    color: black;
  }
`;

export const PageHeader = styled.div`
  background-color: var(--color-primary);
  font-size: 1.6rem;
  padding: 1.6rem;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  color: white;

  h3 {
    font-weight: 500;
    font-size: 1.92rem;
    margin-bottom: 1.5rem;
  }
`;

export const HeaderWrapper = styled.div`
  //display: grid;
  grid-template-columns: 3.5fr 1fr;
  column-gap: 2rem;
`;

export const Tokens = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1.6rem;
`;

export const Wallet = styled.div`
  min-width: 12rem;
  border-radius: 5px;
  border: 1px solid var(--color-primary);
  padding: 1.6rem;
  background-color: #fff;

  img {
    width: 6rem;
    height: auto;
    margin-top: -1rem;
    object-fit: cover;
  }

  h4 {
    font-size: 1.6rem;
    font-weight: normal;
    margin-top: -1rem;
    margin-bottom: 1rem;
  }

  div {
    display: flex;
    align-items: baseline;
    justify-content: space-between;

    h3 {
      font-size: 2rem;
      font-weight: bold;
    }

    svg {
      width: 1.8rem;
      height: 1.8rem;
      cursor: pointer;
      fill: #707070;
      transition: all 0.3s ease-in-out;

      &:hover {
        fill: black;
      }
    }
  }
`;

export const Sidebar = styled.div`
  flex-basis: 33%;
  padding: 1rem 1.6rem;
  background-color: #fff;
  min-height: 100vh;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
`;

export const ExpertsGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 3rem;
  align-items: flex-start;
  row-gap: 3rem;
  padding-bottom: 3rem;
`;

export const ExpertContainer = styled.div`
  padding: 1.2rem;
  position: relative;
  box-shadow: var(--shadow-light);
  border-radius: 10px;
  background-color: #fff;
`;
export const ExpertCard = styled.div`
  border: 1px solid #707070;
  padding: 1.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  background-color: #fff;
  z-index: 2;
  position: relative;
  width: 22rem;

  &.active {
    border-color: var(--color-primary);
  }
`;
export const BlueBox = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  top: 60%;
  width: 40%;
  background-color: var(--color-accent);
  border-bottom-left-radius: 10px;
  z-index: 1;
`;
export const OrangeBox = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 60%;
  height: 40%;
  background-color: var(--color-primary);
  border-top-right-radius: 10px;
  z-index: 1;
`;

export const Avatar = styled.img`
  width: 15rem;
  height: 15rem;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 1rem;
`;

export const Name = styled.p`
  font-weight: 500;
  font-size: 1.5rem;
  text-align: center;
  margin-bottom: 0.5rem;
`;

export const Title = styled.p`
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  color: #707070;
  font-size: 1.34rem;
  margin-bottom: 0.5rem;
`;

export const BWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 2rem;
  column-gap: 1rem;
`;

export const Button = styled.button`
  display: flex;
  border: none;
  outline: none;
  align-items: center;
  column-gap: 0.5rem;
  padding: 0.8rem 1rem;
  color: #707070;
  background-color: transparent;
  text-transform: uppercase;
  font-size: 1.28rem;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;

  svg {
    width: 2rem;
    height: 2rem;
    fill: #707070;
  }

  &:hover {
    background-color: var(--color-primary);
    color: white;

    svg {
      fill: white;
    }
  }
`;

export const Search = styled.div`
  display: flex;
  align-items: center;
  background-color: transparent;
  border: 1px solid #707070;
  border-radius: 10px;
  padding: 1rem 2rem;
  justify-content: space-between;

  input {
    border: none;
    width: 80%;
    outline: none;
    background-color: transparent;
  }

  svg {
    width: 2rem;
    height: 2rem;
  }
`;

export const HeaderSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 2rem 0;
  column-gap: 2rem;

  .tab {
    flex: 1;
  }

  .heading {
    font: bold 1.92rem 'Poppins', sans-serif;

    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 0.2rem;
      background-color: var(--color-accent);
    }
  }
`;

export const SideHeader = styled.div`
  background-color: var(--color-primary-light-1);
  padding: 1.6rem 1.6rem;
  margin: 0 -1.6rem;
  margin-top: -1rem;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;

  h3 {
    font-weight: 500;
    font-size: 1.92rem;
  }
`;

export const SideAvatar = styled(Avatar)`
  width: 20rem;
  height: 20rem;
  margin: 1.6rem 0;
  transform: scaleX(-1);
`;

export const BookButton = styled.button`
  background-color: transparent;
  border: 1px solid var(--color-accent);
  font-weight: 500;
  font-size: 1.44rem;
  text-transform: uppercase;
  width: 80%;
  margin: 2rem auto;
  color: var(--color-accent) !important;
  padding: 1.6rem;
  transition: all 0.3s ease-in-out;

  &:hover {
    background-color: var(--color-accent);
    color: white !important;
  }
`;

export const Label = styled.h6`
  font-weight: bold;
  font-size: 1.44rem;
  color: #707070;
`;

export const InfoGrid = styled.div`
  display: grid;
  grid-template-columns: min-content 1fr;
  column-gap: 2rem;
  margin-bottom: 3rem;
  align-items: center;
  position: relative;

  hr {
    width: 100%;
    height: 0.5px;
    background-color: #707070;
    border: none;
    margin: 1rem 0;
    position: absolute;
    bottom: -2rem;
    left: 0;
  }
`;

export const HText = styled.h6`
  font-weight: bold;
  font-size: 1.72rem;
  color: black;
  width: 7rem;

  &.bio {
    margin-bottom: 1rem;

    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 0.5px;
      background-color: #707070;
      margin-top: 0.5rem;
    }
  }
`;

export const PText = styled.p`
  font-size: 1.6rem;
  color: var(--color-primary);
`;
