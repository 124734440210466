import styled from 'styled-components';

export const FundingHeader = styled.section`
  background-color: var(--color-primary);
  padding: 2rem 1.6rem;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  color: white;
  letter-spacing: 0.4px;

  h3 {
    font-weight: 500;
    font-size: 2rem;
    margin-bottom: 1rem;
  }

  p {
    font-size: 1.7rem;
  }
`;

export const AssetsWrapper = styled.section`
  background-color: #fff;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 2rem 1.6rem;
`;

export const Title = styled.h3`
  font-size: 1.9rem;
  font-weight: 500;
  margin-bottom: 1.5rem;
  margin-top: 2rem;
`;

export const Name = styled.p`
  margin-top: 1rem;
  font-size: 1.7rem;
  font-weight: 500;
  color: #707070;
  text-align: center;
`;

export const Asset = styled.div`
  border-radius: 10px;
  padding: 1.6rem;
  background-color: var(--color-accent-light);
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  position: relative;
  transition: all 0.3s ease-in-out;
  z-index: 1;
  height: 19rem;

  &:hover {
    -webkit-filter: brightness(70%);
    -moz-filter: brightness(70%);
    -o-filter: brightness(70%);
    filter: brightness(70%);

    button {
      display: block;
      -webkit-filter: brightness(100%);
      -moz-filter: brightness(100%);
      -o-filter: brightness(100%);
      filter: brightness(100%);
      z-index: 2;
      transition: all 0.3s ease-in-out;
    }
  }

  button {
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    height: max-content;
    text-align: center;
    font-size: 1.7rem;
    display: none;
    transition: all 0.3s ease-in-out;
    padding: 1rem;
    width: 80%;
    font-weight: 500;
    color: white;
    background-color: var(--color-primary);
  }
`;

export const AssetImage = styled.img`
  width: 5rem;
  height: 5rem;
  fill: var(--color-primary);
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  padding: 1.5rem;
  border-radius: 50%;
  width: max-content;
  margin: 0 auto;
`;

export const AssetContainer = styled.div`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(3, 1fr);
  margin-bottom: 4rem;
`;

export const Steps = styled.div`
  margin-top: 2rem;
  margin-right: -1.4rem;
  margin-left: -1.4rem;
  img {
    width: 100%;
    height: auto;
  }
`;
