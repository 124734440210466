import React, { useState } from 'react';
import { IContainer, Input, Label, Wrapper } from './styles';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Select from 'react-select';
import { useGetUserQuery, useUpdateProfileMutation } from '../../../features/user/userApiSlice';

const options = [
  { value: 'uganda', label: 'Uganda' },
  { value: 'kenya', label: 'Kenya' },
  { value: 'tanzania', label: 'Tanzania' },
  { value: 'rwanda', label: 'Rwanda' },
  { value: 'burundi', label: 'Burundi' },
];

const gender = [
  {
    value: 'female',
    label: 'Female',
  },
  {
    value: 'male',
    label: 'Male',
  },
  {
    value: 'prefer not to say',
    label: 'Prefer not to say',
  },
];
const PersonaDetailsMd = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedGender, setSelectedGender] = useState(null);
  const [updateProfile, { isLoading }] = useUpdateProfileMutation();
  const { data: user } = useGetUserQuery();
  let [firstname, lastname] = user.name.match(/(\w+)\s(\w+)/).slice(1);
  const preloadedData = {
    firstname,
    lastname,
    email: user.email,
    phone: user.phone,
  };

  return (
    <Wrapper>
      <IContainer rows={4}>
        <div>
          <Label>Firstname</Label>
          <Input type={'text'} value={firstname} />
        </div>
        <div>
          <Label>Lastname</Label>
          <Input type={'text'} value={lastname} />
        </div>
        <div>
          <Label>Email Address</Label>
          <Input type={'email'} value={user.email} />
        </div>
        <div>
          <Label>Nationality</Label>
          <Select
            styles={{
              container: (base, props) => ({
                ...base,
                fontSize: '1.6rem',
              }),
              control: (base, props) => ({
                ...base,
                fontSize: '1.6rem',
                height: '5.4rem',
              }),
            }}
            isSearchable
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: '#cdd5f7',
                primary: '#FFAB00',
                neutral20: '#707070',
              },
            })}
            defaultValue={selectedOption}
            onChange={setSelectedOption}
            options={options}
          />
        </div>
        <div>
          <Label>Date of birth</Label>
          <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
          />
        </div>
        <div>
          <Label>Gender</Label>
          <Select
            styles={{
              container: (base) => ({
                ...base,
                fontSize: '1.6rem',
              }),
              control: (base) => ({
                ...base,
                fontSize: '1.6rem',
                height: '5.4rem',
              }),
            }}
            isSearchable
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: '#cdd5f7',
                primary: '#FFAB00',
                neutral20: '#707070',
              },
            })}
            defaultValue={selectedGender}
            onChange={setSelectedGender}
            options={gender}
          />
        </div>
        <div>
          <Label>WhatsApp No</Label>
          <Input type={'tel'} value={user.phone} />
        </div>
        <div>
          <Label>LinkedIn link</Label>
          <Input type={'text'} />
        </div>
      </IContainer>
    </Wrapper>
  );
};

export default PersonaDetailsMd;
