import styled from 'styled-components';

export const Wrapper = styled.main`
  position: relative;
  top: 9rem;
  margin-right: 1rem;
  letter-spacing: 0.3px;
  font-size: 1.6rem;
`;

export const HeaderContainer = styled.section`
  //display: grid;
  grid-template-columns: 1fr max-content;
  column-gap: 2rem;
  margin-bottom: 2rem;

  .card-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 0.8rem;
  }
`;

export const HeaderMain = styled.div`
  background-color: var(--color-primary);
  border-radius: 10px 10px 0 0;
  padding: 1.6rem;
  color: white;
  letter-spacing: 0.4px;

  h3 {
    font-size: 2.2rem;
    font-weight: 500;
    margin-bottom: 2rem;
  }

  p {
    width: 80%;
    font-size: 1.8rem;
  }
`;

export const HeaderCard = styled.div`
  border-radius: 10px;
  border: 1px solid var(--color-primary);
  padding: 0.8rem 1.6rem;
  color: #000000;
  text-transform: capitalize;
  min-width: 18rem;

  img {
    width: 5rem;
    height: 5rem;
    object-fit: contain;
    margin-bottom: 1rem;
  }

  h3 {
    font-size: 3rem;
    font-weight: bold;
    text-align: right;
    margin-top: 0.5rem;
  }
`;

export const Actions = styled.div`
  display: grid;
  grid-template-columns: repeat(3, max-content);
  justify-content: center;
  align-items: flex-end;
  position: relative;
  column-gap: 2rem;

  .doc {
    display: block !important;
    position: relative;
    bottom: 0.5rem;
  }

  img {
    width: 2rem;
    height: 2rem;
    object-fit: contain;
    cursor: pointer;

    &:first-child {
      position: relative;
      top: 0.35rem;
    }
  }

  svg {
    fill: var(--color-accent);
    width: 2rem;
    height: 2rem;
    cursor: pointer;
    transition: all 0.3s ease;

    &.table {
      width: 2.5rem;
      height: 2.5rem;
    }

    &:first-child {
      position: relative;
      top: 0.35rem;
    }

    &:hover {
      fill: black;
      transform: scale(1.01);
    }

    &:active {
      transform: scale(1);
    }
  }
`;

export const Table = styled.table`
  width: 100%;
  border: none;
  border-collapse: collapse;
  text-align: center;
`;

export const Head = styled.thead`
  border-radius: 5px;

  tr {
    background-color: var(--color-primary-light-1);
  }

  th {
    font-size: 1.34rem;
    font-weight: bold;
    padding: 2.7rem 2rem;
    text-align: center;
  }
`;

export const Row = styled.tr`
  font-size: 1.6rem;
  color: #636363;
  border-radius: 5px;
  background-color: #fff;

  &:nth-child(even) {
    background-color: #f5f5f5;
  }

  td {
    padding: 2rem 2rem;

    &.name {
      text-transform: capitalize;
      //text-align: left;
    }
  }
`;
