import React from 'react';
import { HomeWrapper } from './styles';
import SplasherHeader from '../../../components/SplasherHeader/SplasherHeader';
import SplasherActions from '../../../components/SplasherActions/SplasherActions';
import HomeAssets from '../../../components/HomeAssets/HomeAssets';
import { useGetUserBusinessQuery, useGetUserQuery } from '../../../features/user/userApiSlice';
import CreditRecos from './CreditRecos';
import BusRecos from './BusRecos';

const Splasher = () => {
  const { data: user } = useGetUserQuery();
  const { data: userData } = useGetUserBusinessQuery(user.id);
  const profProgress = user?.progress;
  let busProgress = 0;

  if (userData?.companies.length > 0) {
    busProgress = userData?.companies[0].progress;
  }

  const overallProgress = { profProgress, busProgress };

  return (
    <>
      <HomeWrapper>
        <section>
          <SplasherHeader />
          <HomeAssets progress={overallProgress} userId={user.id} />
          <SplasherActions />
        </section>
        <section style={{ backgroundColor: 'white' }}>
          <CreditRecos userId={user.id} />
          <BusRecos userId={user.id} />
        </section>
      </HomeWrapper>
    </>
  );
};

export default Splasher;
