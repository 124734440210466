import styles from './Messages.module.scss';
import { BiSearch } from 'react-icons/bi';
import { IoMdAdd } from 'react-icons/io';
import React from 'react';
import { getDocuments } from '../../../utils/object-mapper';

const imageUrl =
  'https://thumbs.dreamstime.com/b/default-avatar-profile-icon-vector-social-media-user-portrait-176256935.jpg';
const Messages = ({ allKonnects, onSetKonnect, selected }) => {
  if (allKonnects.length === 0) {
    return <div className={styles.no_requests}>You have no connects</div>;
  }

  return (
    <div className={styles.container}>
      <div className={styles.search}>
        <input type="text" placeholder={'Search messages'} />
        <BiSearch />
      </div>
      <div className={styles.list}>
        {allKonnects.map((user) => {
          const avatar = getDocuments(['avatar'], user['0'].media)[0]?.original_url;
          return (
            <div
              key={user['0'].id}
              onClick={() => onSetKonnect(user)}
              className={`${styles.message} ${selected && selected['0'].id === user['0'].id ? styles.selected : ''}`}>
              <img src={`${avatar === '' || avatar === undefined ? imageUrl : avatar}`} alt={'Connect user'} />
              <div>
                <p className={styles.sender}>{user['0'].name}</p>
              </div>
            </div>
          );
        })}
      </div>
      <IoMdAdd className={styles.add} />
    </div>
  );
};

export default Messages;
