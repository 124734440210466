import { configureStore } from '@reduxjs/toolkit';
import { apiSlice } from './api/apiSlice';
import authReducer from '../features/auth/authSlice';
import modalReducer from '../features/modal/modalSlice';
import loanReducer from '../features/loans/loanApplicationSlice';
import profileReducer from '../features/profile/profileSlice';
import refReducer from '../features/auth/refSlice';
import messageReducer from '../features/connect/chatMessagesSlice';

export const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    auth: authReducer,
    modal: modalReducer,
    application: loanReducer,
    profile: profileReducer,
    ref: refReducer,
    messages: messageReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(apiSlice.middleware),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
