import styled from 'styled-components';
import { motion } from 'framer-motion';

export const ContentWrapper = styled.div`
  background: rgba(0, 0, 0, 0.8);
`;

export const SorryWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-flow: column;
  border-radius: 10px;
  text-align: center;
  padding: 4rem;
  align-items: center;
  background-color: #fff;
`;

export const Cancel = styled.div`
  padding: 2rem;
  border-radius: 50%;
  background-color: var(--color-primary);
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: max-content;
  margin: 0 auto;

  svg {
    width: 4rem;
    height: auto;
    fill: white !important;
  }
`;

export const Title = styled.h2`
  font-weight: 500;
  font-size: 3rem;
`;

export const Text = styled.p`
  font-size: 2rem;
  text-align: center;
  margin: 3rem 0;
`;

export const Register = styled.p`
  font-size: 1.6rem;
  margin-bottom: 1.5rem;
`;

export const Button = styled(motion.button)`
  padding: 2rem;
  color: white;
  font-weight: 500;
  font-size: 1.8rem;
  background-color: var(--color-primary);
  width: 100%;
  margin-bottom: 3rem;
  text-transform: uppercase;
`;
