import React, { createRef } from 'react';
import { useGetUserQuery, useUpdateProfileMutation } from '../../../../features/user/userApiSlice';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { closeModal, selectCurrentModal } from '../../../../features/modal/modalSlice';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { schema } from './phot-schema';
import { Close, Header, HeaderActions } from '../LoanModal/styles';
import Modal from 'react-modal';
import { Container, Label, Wrapper } from '../../../../components/LoanModal/PersonalDocuments/styles';
import { Btn, BtnDiv } from '../../../../components/BusinessProfile/EditProfile/styles';
import { MODAL_TYPES } from '../../modalTypes';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    zIndex: 100,
    border: 'none',
    padding: 0,
    width: '70%',
    borderRadius: '10px',
    height: 'auto',
  },
};
const EditUserPhotoModal = () => {
  const [updateProfile, { isLoading }] = useUpdateProfileMutation();
  const { data: user } = useGetUserQuery();
  const modalType = useAppSelector(selectCurrentModal);
  const isOpen = modalType === MODAL_TYPES.EDIT_USER_PHOTO_MODAL;
  const dispatch = useAppDispatch();
  const avatarRef = createRef();

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid },
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  const buttonContent = isLoading ? <div className={'container dots-flow'} /> : 'Update';

  const onSubmitClose = () => {
    dispatch(closeModal());
  };

  const onSubmitHandler = async (data) => {
    const userInput = {
      item: {
        avatar: data.avatar[0],
      },
      id: user.id,
    };
    try {
      await updateProfile(userInput).unwrap();
      onSubmitClose();
    } catch (e) {
      console.log(e);
      toast.error('Something went wrong!');
    }
  };
  return (
    <Modal
      onRequestClose={() => {}}
      isOpen={isOpen}
      style={customStyles}
      ariaHideApp={false}
      overlayClassName="Overlay">
      <Header>
        <h2>Upload profile photo</h2>
        <HeaderActions>
          <Close onClick={onSubmitClose} />
        </HeaderActions>
      </Header>
      <Wrapper
        style={{ padding: '1.6rem', paddingLeft: 0 }}
        onSubmit={handleSubmit(onSubmitHandler)}
        id={'edit-profile-photo'}>
        <Container style={{ padding: '1.6rem' }}>
          <Label>Upload Your profile photo</Label>
          <input type="file" name={'avatar'} ref={avatarRef} {...register('avatar')} />
        </Container>
      </Wrapper>
      <BtnDiv style={{ padding: '1.6rem', paddingLeft: 0 }}>
        <Btn onClick={onSubmitClose} whileTap={{ scale: 0.97 }} whileHover={{ backgroundColor: '#f4ad3d' }}>
          Cancel
        </Btn>
        <Btn
          disabled={isLoading || !isValid || !isDirty}
          whileTap={{ scale: 0.97 }}
          whileHover={{ backgroundColor: '#014e35' }}
          className={'green'}
          type={'submit'}
          form={'edit-profile-photo'}>
          {buttonContent}
        </Btn>
      </BtnDiv>
      <ToastContainer />
    </Modal>
  );
};

export default EditUserPhotoModal;
