import styled from 'styled-components';
import { motion } from 'framer-motion';
import { MdKeyboardBackspace } from 'react-icons/md';

export const CheckoutWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

export const Asset = styled.div`
  background-color: var(--color-primary-light-1);
  padding: 1.6rem;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;

  h3 {
    font-weight: 500;
    font-size: 1.8rem;
    margin-top: 2rem;
    margin-left: 5rem;
  }
`;

export const BackButton = styled(MdKeyboardBackspace)`
  width: 3rem;
  height: auto;
  cursor: pointer;
`;

export const AssetHeader = styled.div`
  display: flex;
  align-items: center;
  column-gap: 2rem;
  margin-bottom: 2rem;
  cursor: pointer;

  img {
    width: 25rem;
    height: auto;
  }
`;

export const ContentWrapper = styled.div`
  border-radius: 10px;
  border: 1px solid #707070;
  border-top-color: var(--color-primary);
  padding: 1.6rem;
  margin: 1.6rem;

  .c-btn {
    display: flex;
    justify-content: center;
  }
`;

export const CheckoutHeader = styled.div`
  background-color: var(--color-primary);
  color: white;
  font-size: 2.3rem;
  letter-spacing: 0.5px;
  font-weight: bold;
  margin: 0 -1.7rem;
  margin-top: -1.6rem;
  display: flex;
  column-gap: 2rem;
  padding: 1rem 1.6rem;
  align-items: center;
  margin-bottom: 1.3rem;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;

  svg {
    background-color: #fff;
    border-radius: 50%;
    padding: 1.2rem;
    width: 2rem;
    height: 2rem;
    fill: black;
    box-sizing: content-box;
  }
`;

export const Title = styled.h4`
  font-weight: 500;
  font-size: 1.7rem;
  text-transform: capitalize;
`;

export const Label = styled.p`
  color: #707070;
  font-size: 1.6rem;

  span {
    color: var(--color-blue);
    text-decoration: underline;
  }
`;

export const Input = styled.input`
  background-color: transparent;
  border: 1px solid #707070;
  padding: 1.28rem;
  outline: none;
  width: 100%;
  border-radius: 5px;
`;

export const CardInput = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 1rem;
  border: 1px solid #707070;
  padding: 1.28rem;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;

  input {
    flex: 1;
    background-color: transparent;
    outline: none;
    border: none;
    width: 100%;
  }

  div {
    display: flex;
    align-items: center;
    column-gap: 0.4rem;
  }
`;

export const InputCard = styled.img`
  width: 5rem;
  height: auto;
`;

export const CardDetails = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid #707070;
  border-top: none;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  margin-bottom: 1.5rem;

  input {
    background-color: transparent;
    outline: none;
    border: none;
    width: 100%;
    padding: 0 1.28rem;
  }
`;

export const CVVWrap = styled.div`
  display: flex;
  align-items: center;
  padding: 1.28rem;
  justify-content: space-between;
  border-left: 1px solid #707070;

  svg {
    width: 4rem;
    height: auto;
  }
`;

export const HRule = styled.hr`
  height: 0;
  border: 1px dashed #707070;
  margin: 3rem 0;
`;

export const Button = styled(motion.button)`
  background-color: var(--color-primary);
  font-weight: bold;
  text-transform: uppercase;
  color: white;
  width: 100%;
  padding: 2rem;
  margin-bottom: 2rem;
`;

export const CardOption = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5rem;

  img {
    width: 9rem;
    height: 7rem;
    object-fit: contain;
    padding: 1rem;
    border: 1px solid #707070;
    border-radius: 5px;
    cursor: pointer;

    &.active {
      border-color: var(--color-primary);
    }
  }
`;

export const DFlex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  p {
    margin-bottom: 0.5rem;
  }
`;
