import styled from 'styled-components';

export const Wrapper = styled.div`
  background-color: #fff;
  border-radius: 10px;
  padding: 1.6rem;
`;

export const Cert = styled.div`
  display: grid;
  grid-template-columns: 6rem 1fr 3rem 3rem;
  column-gap: 2rem;
  padding: 2rem 1.6rem;
  border-radius: 10px;
  border: 1px solid #707070;
  margin-bottom: 2rem;
  align-items: center;
  letter-spacing: 0.4px;

  img {
    width: 6rem;
    height: auto;
  }

  h5 {
    font-weight: 500;
    font-size: 1.6rem;
    margin-bottom: 0.2rem;
  }

  p {
    font-size: 1.4rem;
    color: #707070;
  }
`;

export const View = styled.div`
  width: 3rem;
  background-color: var(--color-primary);
  display: grid;
  place-items: center;
  cursor: pointer;
  border-radius: 5px;
  padding: 0.5rem;

  svg {
    fill: white;
    width: 100%;
    height: auto;
  }
`;

export const Download = styled(View)`
  background-color: var(--color-accent);

  svg {
    fill: black;
  }
`;
