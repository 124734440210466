import React, { useState } from 'react';
import { useGetUserBusinessQuery, useGetUserQuery } from '../../../features/user/userApiSlice';
import { Wrapper } from './styles';
import CompanyDetails from './CompanyDetails';
import MeetupLoader from '../../Loaders/MeetupLoader';

const BusProfileSummary = ({ onSloganSet }) => {
  const tabs = ['Company Details'];
  const [selectedTab, setSelectedTab] = useState(tabs[0]);
  const { data: user } = useGetUserQuery();
  const { data: companyData, isLoading, isError, error, isSuccess } = useGetUserBusinessQuery(user.id);

  let content;
  const meetupLoader = <MeetupLoader />;
  if (selectedTab === tabs[0])
    if (isSuccess)
      content = (
        <CompanyDetails
          onSloganSet={onSloganSet}
          companyData={companyData}
          bussId={companyData.companies[0]?.id}
          userId={user.id}
        />
      );
  if (isError) console.log(error);

  return (
    <Wrapper>
      {/*  <Nav>
        <Ul style={{ marginBottom: '2rem', fontWeight: 'bold' }}>
          {tabs.map((tab) => (
            <Li
              style={{ textTransform: 'uppercase' }}
              key={tab}
              className={tab === selectedTab ? 'selected' : ''}
              onClick={() => setSelectedTab(tab)}>
              {tab}
              {tab === selectedTab ? <Underline layoutId="underline" /> : null}
            </Li>
          ))}
        </Ul>
        <AnimatePresence mode={'wait'}>
          <motion.div
            key={selectedTab}
            initial={{ y: 10, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: -10, opacity: 0 }}
            transition={{ duration: 0.2 }}>
            {isLoading ? meetupLoader : content}
          </motion.div>
        </AnimatePresence>
      </Nav>*/}
      {isLoading ? meetupLoader : content}
    </Wrapper>
  );
};

export default BusProfileSummary;
