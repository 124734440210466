import React, { useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import WelcomeLayout from './components/WelcomeLayout';
import SignIn from './pages/Auth/SignIn/SignIn';
import Register from './pages/Auth/Register/Register';
import pageRoutes from './constants/pageRoutes';
import AuthLayout from './pages/Auth/AuthLayout';
import Support from './pages/Dashboard/Support/Support';
import UserProfile from './pages/Dashboard/UserProfile/UserProfile';
import FundingLayout from './pages/Dashboard/Funding/FundingLayout';
import Konnect from './pages/Dashboard/Konnect/Konnect';
import Packages from './pages/RegistrationFlow/Packages';
import DashboardLayout from './pages/Dashboard/DashboardLayout';
import Inbox from './pages/Dashboard/Konnect/Inbox/Inbox';
import RequestList from './components/Konnect/Request/RequestList';
import BusinessProfileFill from './pages/RegistrationFlow/BusinessProfileFill';
import FundingApply from './pages/Dashboard/Funding/FundingApply';
import Post from './components/Konnect/Post/Post';
import Splasher from './pages/Dashboard/Splasher/Splasher';
import ClimateProfile from './pages/Dashboard/ClimateProfile/ClimateProfile';
import EventsPage from './pages/Dashboard/Konnect/EventsPage/EventsPage';
import NewTicket from './pages/NewTicket/NewTicket';
import ProfilePick from './pages/RegistrationFlow/ProfilePick';
import BusinessProfile from './pages/Dashboard/BusinessProfile/BusinessProfile';
import RiskProfile from './pages/Dashboard/RiskProfile/RiskProfile';
import Notifications from './components/Home/Notifications/Notifications';
import Experts from './pages/Dashboard/Experts/Experts';
import Marketplace from './pages/Dashboard/Marketplace/Marketplace';
import DataRoom from './pages/Dashboard/DataRoom/DataRoom';
import Academy from './pages/Dashboard/Academy/Academy';
import IncompleteClasses from './components/Academy/Incomplete/IncompleteClasses';
import Complete from './components/Academy/Complete/Complete';
import Enrolled from './components/Academy/Enrolled/Enrolled';
import MyCertifications from './components/Academy/Certifications/MyCertifications';
import Course from './pages/Dashboard/Academy/Course/Course';
import Lesson from './pages/Dashboard/Academy/Lesson/Lesson';
import ResetPassword from './pages/ResetPassword/ResetPassword';
import Modal from './modules/ModalWindow/Modal';
import NewPassword from './pages/Auth/NewPassword/NewPassword';
import ProfileSummary from './components/UserProfile/ProfileSummary/ProfileSummary';
import EditUser from './components/EditUser/EditUser';
import HelpfulDocs from './components/Academy/HelpfulDocs/HelpfulDocs';
import AssetRequests from './components/Funding/MyLoans/AssetRequests';
import AssetsFlow from './components/Funding/MyLoans/AssetsFlow';
import PaymentChoice from './components/Funding/MyLoans/PaymentChoice';
import MyKonnects from './components/Konnect/Request/MyKonnects/MyKonnects';
import MyRequests from './components/Konnect/Request/MyRequests/MyRequests';
import ExploreList from './components/Konnect/Request/ExploreList/ExploreList';
import { steps } from './survey/steps';
import Tour from 'reactour';
import ConfirmEmail from './pages/ConfirmEmail/ConfirmEmail';
import ClimateQuestion from './pages/ClimateQuiz/ClimateQuestion';

const App = () => {
  const [isOpen, setIsOpen] = useState(false);
  const completedTour = localStorage.getItem('completedTour');

  const closeTour = () => {
    setIsOpen(false);
    localStorage.setItem('completedTour', 'true');
  };

  const onOpenTour = () => {
    if (!JSON.parse(completedTour)) {
      setIsOpen(true);
    }
  };

  return (
    <div className="App" id={'app'}>
      <Modal />
      <Routes>
        <Route path={'/'} element={<WelcomeLayout />}>
          <Route index element={<Navigate to={pageRoutes.SIGN_IN} />} />
          <Route element={<AuthLayout />}>
            <Route path={pageRoutes.SIGN_IN} element={<SignIn />} />
            <Route path={pageRoutes.SING_UP} element={<Register />} />
            <Route path={pageRoutes.NEW_PASSWORD} element={<NewPassword />} />
          </Route>
          <Route path="*" element={<Navigate to="/" replace />} />
          <Route path={pageRoutes.PRE_PROFILE} element={<ProfilePick />} />
          <Route path={pageRoutes.PACKAGES} element={<Packages />} />
          <Route path={pageRoutes.FORGOT} element={<ResetPassword />} />
          <Route path={pageRoutes.PRE_BUSINESS} element={<BusinessProfileFill />} />
          <Route path={pageRoutes.CONFIRM_EMAIL} element={<ConfirmEmail />} />
          <Route path={pageRoutes.CLIMATE_QUIZ} element={<ClimateQuestion />} />
        </Route>
        <Route path={pageRoutes.DASHBOARD} element={<DashboardLayout openTour={onOpenTour} />}>
          <Route path={pageRoutes.HOME} element={<Splasher />}>
            <Route path={pageRoutes.NOTIFICATIONS} element={<Notifications />} />
          </Route>
          <Route path={pageRoutes.BUSINESS} element={<BusinessProfile />} />
          <Route path={pageRoutes.CREDIT} element={<RiskProfile />} />
          <Route path={pageRoutes.FUNDING} element={<FundingLayout />}>
            <Route index path={pageRoutes.APPLY} element={<FundingApply />} />
            <Route path={pageRoutes.MY_LOANS} element={<AssetsFlow />}>
              <Route index element={<PaymentChoice />} />
              <Route path={pageRoutes.PAY_CHOICE} element={<AssetRequests />} />
            </Route>
          </Route>
          <Route path={pageRoutes.KONNECT} element={<Konnect />}>
            <Route index path={pageRoutes.POSTS} element={<Post />} />
            <Route path={pageRoutes.REQUESTS} element={<RequestList />}>
              <Route index element={<MyKonnects />} />
              <Route path={pageRoutes.REQUESTS_LIST} element={<MyRequests />} />
              <Route path={pageRoutes.EXPLORE} element={<ExploreList />} />
            </Route>
            <Route path={pageRoutes.INBOX} element={<Inbox />} />
          </Route>
          <Route path={pageRoutes.EVENTS} element={<EventsPage />} />
          <Route path={pageRoutes.ACADEMY} element={<Academy />}>
            <Route index element={<HelpfulDocs />} />
            <Route path={pageRoutes.INPROGRESS} element={<IncompleteClasses />} />
            <Route path={pageRoutes.COMPLETE} element={<Complete />} />
            <Route path={pageRoutes.ENROLLED} element={<Enrolled />} />
            <Route path={pageRoutes.CERTIFICATIONS} element={<MyCertifications />} />
          </Route>
          <Route path={`${pageRoutes.ACADEMY}/${pageRoutes.COURSE}/:courseId`} element={<Course />} />
          <Route
            path={`${pageRoutes.ACADEMY}/${pageRoutes.COURSE}/:courseId/${pageRoutes.LESSON}/:lessonId`}
            element={<Lesson />}
          />
          <Route path={pageRoutes.SUPPORT}>
            <Route index element={<Support />} />
            <Route path={pageRoutes.NEW_TICKET} element={<NewTicket />} />
          </Route>
          <Route path={pageRoutes.EXPERTS} element={<Experts />} />
          <Route path={pageRoutes.IMPACT} element={<ClimateProfile />} />
          <Route path={pageRoutes.PROFILE} element={<UserProfile />}>
            <Route index element={<ProfileSummary />} />
            <Route path={pageRoutes.USER_EDIT} element={<EditUser />} />
          </Route>
          <Route path={pageRoutes.MARKET} element={<Marketplace />} />
          <Route path={pageRoutes.DATA_ROOM} element={<DataRoom />} />
        </Route>
      </Routes>
      <Tour steps={steps} isOpen={isOpen} onRequestClose={closeTour} />
    </div>
  );
};

export default App;
