import { apiSlice } from '../../app/api/apiSlice';
import { USER } from '../../types/api-responses';

export const userApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getUser: build.query<any, void>({
      query: () => '/user/profile',
      transformResponse(response: any) {
        return response?.response;
      },
      providesTags: [{ type: USER, id: 'USER' }],
    }),
    updateProfile: build.mutation({
      query: (payload) => {
        const data = new FormData();
        for (const itemKey in payload.item) {
          data.append(itemKey, payload.item[itemKey]);
        }
        return {
          url: `user/update/${payload.id}`,
          method: 'POST',
          body: data,
        };
      },
      invalidatesTags: [
        { type: USER, id: 'USER' },
        { type: USER, id: 'USER_DETAILS' },
      ],
    }),
    assignRole: build.mutation({
      query: (payload) => {
        const data = new FormData();
        data.append('attach_role', payload.key);
        return {
          url: `/assign/user/role/${payload.id}`,
          method: 'POST',
          body: data,
        };
      },
    }),
    logOut: build.query<void, void>({
      query: () => `/user/logout`,
    }),
    getUserBusiness: build.query<any, string>({
      query: (id) => `/user/${id}`,
      transformResponse: (rawResult: any) => {
        return rawResult?.response;
      },
    }),
    confirmEmail: build.mutation({
      query: ({ email }) => {
        const data = new FormData();
        data.append('email', email);
        return {
          url: `resend/verify/email`,
          method: 'POST',
          body: data,
        };
      },
    }),
    sendAppointment: build.mutation({
      query: (payload) => {
        const data = new FormData();
        for (const payloadKey in payload) {
          data.append(payloadKey, payload[payloadKey]);
        }

        return {
          url: `expert/appointment/create`,
          method: 'POST',
          body: data,
        };
      },
    }),
  }),
});

export const {
  useGetUserQuery,
  useUpdateProfileMutation,
  useGetUserBusinessQuery,
  useConfirmEmailMutation,
  useSendAppointmentMutation,
} = userApiSlice;
