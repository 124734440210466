import React from 'react';
import styles from './EventsPage.module.scss';
import Event from '../../../../components/Event/Event';
import SimilarEvents from '../../../../components/Event/SimilarEvents';

function EventsPage() {
  return (
    <main className={styles.container}>
      <section className={styles.main_content}>
        <Event />
      </section>
      <section className={styles.sidebar}>
        <SimilarEvents />
      </section>
    </main>
  );
}

export default EventsPage;
