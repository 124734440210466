import styled from 'styled-components';

export const Wrapper = styled.div`
  background-color: #fff;
  border-radius: 10px;
  padding: 1.6rem;
`;

export const Tip = styled.div`
  padding: 1.6rem;
  border-radius: 10px;
  background-color: var(--color-grey-light-1);
  margin-bottom: 1.6rem;
`;

export const Header = styled.div`
  border-radius: 10px 10px 0 0;
  background-color: var(--color-primary);
  padding: 2rem 1.6rem;
  font-size: 2rem;
  font-weight: 500;
  margin: 0 -1.6rem;
  margin-top: -1.6rem;
  margin-bottom: 1.6rem;
  color: white;
`;

export const Card = styled.div`
  margin-bottom: 1.6rem;
  background-color: #fff;
  border-radius: 10px;
  padding: 1.6rem;
  display: flex;
  align-items: center;
  column-gap: 1.6rem;
  color: #707070;
  font-size: 1.6rem;
  letter-spacing: 0.3px;

  img {
    width: 4rem;
    height: 4rem;
  }

  h5 {
    font-weight: 500;
    color: #000;
    font-size: 1.8rem;
    text-transform: capitalize;
  }
`;
