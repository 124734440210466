import {
  ApplyLoanModal,
  AppointModal,
  AssetPayment,
  CheckMail,
  CheckoutModal,
  ConfirmEmail,
  CongratsModal,
  EditBusLogoModal,
  EditCompanyModal,
  EditDocsModal,
  EditUserModal,
  EditUserPhotoModal,
  EnrollModal,
  ForgotPassword,
  JoinModal,
  LoanModal,
  PassModal,
  PostModal,
  ProductModal,
  ProgressModal,
  QuizModal,
  SorryModal,
  UploadModal,
} from './modals';

interface IModalTypes {
  POST_MODAL: string;
  UPLOAD_MODAL: string;
  CHECKOUT_MODAL: string;
  SORRY_MODAL: string;
  PRODUCT_MODAL: string;
  LOAN_MODAL: string;
  ENROLL_MODAL: string;
  PASS_MODAL: string;
  QUIZ_MODAL: string;
  CONGRATS_MODAL: string;
  EDIT_DOCS_MODAL: string;
  EDIT_COMPANY_MODAL: string;
  EDIT_USER_MODAL: string;
  EDIT_BUS_LOGO_MODAL: string;
  EDIT_USER_PHOTO_MODAL: string;
  CHECK_MAIL_MODAL: string;
  FORGOT_PASSWORD_MODAL: string;
  APPLY_LOAN: string;
  ASSET_PAYMENT: string;
  JOIN_MODAL: string;
  APPOINT_MODAL: string;
  CONFIRM_EMAIL: string;
  PROGRESS_MODAL: string;
}

export type TModalType = string;

export const MODAL_TYPES: IModalTypes = {
  POST_MODAL: 'POST_MODAL',
  UPLOAD_MODAL: 'UPLOAD_MODAL',
  CHECKOUT_MODAL: 'CHECKOUT_MODAL',
  SORRY_MODAL: 'SORRY_MODAL',
  PRODUCT_MODAL: 'PRODUCT_MODAL',
  LOAN_MODAL: 'LOAN_MODAL',
  ENROLL_MODAL: 'ENROLL_MODAL',
  PASS_MODAL: 'PASS_MODAL',
  QUIZ_MODAL: 'QUIZ_MODAL',
  CONGRATS_MODAL: 'CONGRATS_MODAL',
  CHECK_MAIL_MODAL: 'CHECK_MAIL_MODAL',
  FORGOT_PASSWORD_MODAL: 'FORGOT_PASSWORD_MODAL',
  EDIT_DOCS_MODAL: 'EDIT_DOCS_MODAL',
  EDIT_COMPANY_MODAL: 'EDIT_COMPANY_MODAL',
  EDIT_USER_MODAL: 'EDIT_USER_MODAL',
  EDIT_USER_PHOTO_MODAL: 'EDIT_PHOTO_USER_MODAL',
  EDIT_BUS_LOGO_MODAL: 'EDIT_BUS_LOGO_MODAL',
  APPLY_LOAN: 'APPLY_LOAN',
  ASSET_PAYMENT: 'ASSET_PAYMENT',
  JOIN_MODAL: 'JOIN_MODAL',
  APPOINT_MODAL: 'APPOINT_MODAL',
  CONFIRM_EMAIL: 'CONFIRM_EMAIL',
  PROGRESS_MODAL: 'PROGRESS_MODAL',
};

export const MODALS_MAPPING = {
  [MODAL_TYPES.POST_MODAL]: PostModal,
  [MODAL_TYPES.UPLOAD_MODAL]: UploadModal,
  [MODAL_TYPES.CHECKOUT_MODAL]: CheckoutModal,
  [MODAL_TYPES.SORRY_MODAL]: SorryModal,
  [MODAL_TYPES.PRODUCT_MODAL]: ProductModal,
  [MODAL_TYPES.LOAN_MODAL]: LoanModal,
  [MODAL_TYPES.ENROLL_MODAL]: EnrollModal,
  [MODAL_TYPES.PASS_MODAL]: PassModal,
  [MODAL_TYPES.QUIZ_MODAL]: QuizModal,
  [MODAL_TYPES.CONGRATS_MODAL]: CongratsModal,
  [MODAL_TYPES.EDIT_DOCS_MODAL]: EditDocsModal,
  [MODAL_TYPES.EDIT_COMPANY_MODAL]: EditCompanyModal,
  [MODAL_TYPES.EDIT_USER_MODAL]: EditUserModal,
  [MODAL_TYPES.EDIT_USER_PHOTO_MODAL]: EditUserPhotoModal,
  [MODAL_TYPES.EDIT_BUS_LOGO_MODAL]: EditBusLogoModal,
  [MODAL_TYPES.CHECK_MAIL_MODAL]: CheckMail,
  [MODAL_TYPES.FORGOT_PASSWORD_MODAL]: ForgotPassword,
  [MODAL_TYPES.APPLY_LOAN]: ApplyLoanModal,
  [MODAL_TYPES.ASSET_PAYMENT]: AssetPayment,
  [MODAL_TYPES.JOIN_MODAL]: JoinModal,
  [MODAL_TYPES.APPOINT_MODAL]: AppointModal,
  [MODAL_TYPES.CONFIRM_EMAIL]: ConfirmEmail,
  [MODAL_TYPES.PROGRESS_MODAL]: ProgressModal,
};
