import React from 'react';
import { Actions, Head, HeaderContainer, HeaderMain, Row, Table, Wrapper } from './styles';
import { FaRegEdit, FaRegEye } from 'react-icons/fa';
import { HiDownload } from 'react-icons/hi';
import { useGetUserQuery } from '../../../features/user/userApiSlice';
import { useGetBusinessByIdQuery } from '../../../features/business/businessApiSlice';
import { BACK_ID, FRONT_ID, PITCH_DOC, REG_DOC, TAX_DOC, VAT_DOC } from '../../../constants/constants';
import { covertBytesToMB, formatDate, getDocuments } from '../../../utils/object-mapper';
import { openModal } from '../../../features/modal/modalSlice';
import { MODAL_TYPES } from '../../../modules/ModalWindow/modalTypes';
import { useAppDispatch } from '../../../app/hooks';
import styles from '../RiskProfile/RiskProfile.module.scss';
import { Link } from 'react-router-dom';
import MeetupLoader from '../../../components/Loaders/MeetupLoader';
import pageRoutes from '../../../constants/pageRoutes';

const DataRoom = () => {
  const dispatch = useAppDispatch();
  const { data: user } = useGetUserQuery();
  const { data: companyData, isLoading, isSuccess, isError, error } = useGetBusinessByIdQuery(user.id);

  const docs = [REG_DOC, TAX_DOC, VAT_DOC, PITCH_DOC];
  let documents = [];
  const userDocs = [FRONT_ID, BACK_ID];
  const userDocuments = getDocuments(userDocs, user?.media);

  const busLink = `/${pageRoutes.DASHBOARD}/${pageRoutes.BUSINESS}`;
  const profLink = `/${pageRoutes.DASHBOARD}/${pageRoutes.PROFILE}`;
  const onOpenEdit = (doc) => {
    localStorage.setItem('doc', doc);
    dispatch(openModal({ modalType: MODAL_TYPES.EDIT_DOCS_MODAL }));
  };

  const handleDownload = (file) => {
    const link = document.createElement('a');
    link.href = file.original_url;
    link.download = file.name;
    document.body.appendChild(link);
    link.target = '_blank';
    link.click();
    document.body.removeChild(link);
  };

  let content;
  if (isSuccess) {
    if (companyData.companies.length > 0) {
      documents = getDocuments(docs, companyData.companies[0]?.media);
      if (documents.length > 0 || userDocuments.length > 0) {
        content = (
          <Table>
            <Head>
              <tr>
                <th>Name</th>
                <th>Date</th>
                <th>Size</th>
                <th>Actions</th>
              </tr>
            </Head>
            <tbody>
              {documents.map((doc, index) => (
                <Row key={index}>
                  <td className={'name'}>{doc.collection_name.replace('_', ' ')}</td>
                  <td>{formatDate(doc.updated_at)}</td>
                  <td>{covertBytesToMB(doc.size)}MB</td>
                  <td>
                    <Actions>
                      <FaRegEye onClick={() => handleDownload(doc)} />
                      <HiDownload onClick={() => handleDownload(doc)} />
                      <FaRegEdit onClick={() => onOpenEdit(doc.collection_name)} />
                    </Actions>
                  </td>
                </Row>
              ))}
              {userDocuments.map((doc, index) => (
                <Row key={index}>
                  <td className={'name'}>{doc.collection_name.replace('_', ' ')}</td>
                  <td>{formatDate(doc.updated_at)}</td>
                  <td>{covertBytesToMB(doc.size)}MB</td>
                  <td>
                    <Actions>
                      <FaRegEye onClick={() => handleDownload(doc)} />
                      <HiDownload onClick={() => handleDownload(doc)} />
                      <FaRegEdit onClick={() => onOpenEdit(doc.collection_name)} />
                    </Actions>
                  </td>
                </Row>
              ))}
            </tbody>
          </Table>
        );
      } else {
        content = (
          <p className={styles.pending}>
            You have not yet uploaded any documents, please check your <Link to={busLink}>business profile</Link> <br />
            and or your <Link to={profLink}>personal profile</Link> to upload your documents.
          </p>
        );
      }
    } else {
      content = (
        <p className={styles.pending}>
          To get started, please complete your <Link to={busLink}>business profile</Link>
        </p>
      );
    }
  } else if (isError) {
    console.log(error);
  } else if (isLoading) {
    content = <MeetupLoader />;
  }

  /*  const [currentItems, setCurrentItems] = useState(null);
    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);
    let itemsPerPage = 5;
    useEffect(() => {
      const endOffset = itemOffset + itemsPerPage;
      setCurrentItems(data.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(data.length / itemsPerPage));
    }, [itemOffset, itemsPerPage]);
  
    const handlePageClick = (event) => {
      const newOffset = (event.selected * itemsPerPage) % data.length;
      setItemOffset(newOffset);
    };*/

  return (
    <Wrapper>
      <HeaderContainer>
        <HeaderMain>
          <h3>Easily Track Your Asset Approval Process!</h3>
          <p>
            Locate, view or edit your asset application documents and see the comments made on their approval status
          </p>
        </HeaderMain>
        {/*        <div className="card-container">
          <HeaderCard>
            <img src={images.doc_approve} alt="Approved Documents" />
            <p>
              Approved
              <br />
              Documents
            </p>
            <h3>_ _</h3>
          </HeaderCard>
          <HeaderCard>
            <img src={images.doc_pending} alt="Pending Documents" />
            <p>
              Pending
              <br />
              Documents
            </p>
            <h3>_ _</h3>
          </HeaderCard>
          <HeaderCard>
            <img src={images.doc_reject} alt="Rejected Documents" />
            <p>
              Rejected
              <br />
              Documents
            </p>
            <h3>_ _</h3>
          </HeaderCard>
        </div>*/}
      </HeaderContainer>
      {content}
    </Wrapper>
  );
};

export default DataRoom;
