import React from 'react';
import { Card, Container, Head } from './styles';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import BusinessRadar from './BusinessRadar';
import CircularProgress from './CircularProgres/CircularProgres';
import CreditRange from './CreditRange';
import { BsArrowRight } from 'react-icons/bs';
import { selectBusAttrs, useGetBusAttrsQuery } from '../../features/recommendations/busAttrsApiSlice';
import { useAppSelector } from '../../app/hooks';
import { useGetBusScoresQuery } from '../../features/scores/busScoresApiSlice';
import { cleanUpData } from '../../utils/scores-utils';
import { useGetCreditScoresQuery } from '../../features/scores/creditScoresApiSlice';
import { selectCreditAttrs, useGetCreditAttrsQuery } from '../../features/recommendations/creditAttrsApiSlice';

const HomeAssets = ({ progress, userId }) => {
  const { profProgress, busProgress } = progress;
  const overallProgress = Math.floor((+profProgress + +busProgress) / 2);
  const {
    isLoading: attrsLoading,
    isSuccess: attrsSuccess,
    isError: attrsIsError,
    error: attrsError,
  } = useGetBusAttrsQuery();
  const attrs = useAppSelector(selectBusAttrs);
  let dataList = {
    scores: [],
    totalScore: 0,
  };
  const { isLoading, isSuccess, isError, error, data } = useGetBusScoresQuery(userId);
  const {
    isLoading: creditLoading,
    isSuccess: creditSuccess,
    isError: creditIsError,
    error: creditError,
    data: creditData,
  } = useGetCreditScoresQuery(userId);

  const {
    isLoading: creditAttrsLoading,
    isSuccess: creditAttrsSuccess,
    isError: creditAttrsIsError,
    error: creditAttrsError,
  } = useGetCreditAttrsQuery();

  const creditAttrs = useAppSelector(selectCreditAttrs);

  let creditDataList = {
    scores: [],
    totalScore: 0,
  };

  if (isLoading || attrsLoading) return <div>Loading...</div>;
  if (isError || attrsIsError) return <div>Something went wrong</div>;
  if (isSuccess && attrsSuccess) {
    dataList = cleanUpData(attrs, data);
    creditDataList = cleanUpData(creditAttrs, creditData);
  }

  return (
    <Container>
      <Card>
        <div className="content" style={{ height: '20rem' }}>
          <Head>
            <p>Overall Profile Completion</p>
            <div className="arrow">
              <BsArrowRight />
            </div>
          </Head>
          <CircularProgress progress={overallProgress} />
          <div className="text">
            <p>Entrepreneur Profile: {profProgress}%</p>
            <p>Business Profile: {busProgress}%</p>
          </div>
        </div>
        <div className="bottom">
          <div />
        </div>
      </Card>
      <Card>
        <div className="content">
          <Head>
            <p>Credit Score</p>
            <div className="arrow">
              <BsArrowRight />
            </div>
          </Head>
          <CreditRange score={creditDataList.totalScore} />
          <div className="text">
            <p>Your credit score is {creditDataList.totalScore}</p>
            <p>Out of 900</p>
          </div>
        </div>
        <div className="bottom">
          <div />
        </div>
      </Card>
      <Card>
        <div className="content">
          <Head>
            <p>Business Gap Analysis</p>
            <div className="arrow">
              <BsArrowRight />
            </div>
          </Head>
          <BusinessRadar data={dataList.scores} />
          <div className="context">
            {dataList.scores.map((item, index) => (
              <p key={index}>
                {index + 1}. {item.name}:&nbsp;
                <span style={{ float: 'right' }}>{item.score}|</span>
              </p>
            ))}
          </div>
        </div>
        <div className="bottom">
          <div />
        </div>
      </Card>
    </Container>
  );
};

export default HomeAssets;
