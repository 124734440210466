import React from 'react';
import Modal from 'react-modal';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { closeModal, openModal, selectCurrentModal } from '../../../../features/modal/modalSlice';
import { MODAL_TYPES } from '../../modalTypes';
import { Button, Close, Container } from './styles';
import images from '../../../../constants';
import pageRoutes from '../../../../constants/pageRoutes';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { schema } from './schema';
import { HiArrowNarrowRight } from 'react-icons/hi';
import { useVerifyOtpMutation } from '../../../../features/auth/resetApiSlice';
import { toast, ToastContainer } from 'react-toastify';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    zIndex: 100,
    border: 'none',
    width: '75%',
    borderRadius: '20px',
    maxWidth: '600px',
  },
};

const CheckMail = ({ onClose }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const modalType = useAppSelector(selectCurrentModal);
  const isOpen = modalType === MODAL_TYPES.CHECK_MAIL_MODAL && location.pathname === `/${pageRoutes.FORGOT}`;

  const [verifyOtp, { isLoading }] = useVerifyOtpMutation();

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid },
  } = useForm<{ otp: string }>({
    resolver: yupResolver(schema),
    mode: 'all',
  });

  const onSubmitHandler = async (data) => {
    try {
      const response = await verifyOtp(data.otp).unwrap();
      navigate(`/${pageRoutes.NEW_PASSWORD}`);
    } catch (error: any) {
      console.log(error);
      toast.error(error.data.message);
    }
  };

  const onBackPressed = () => {
    dispatch(closeModal());
    dispatch(openModal({ modalType: MODAL_TYPES.FORGOT_PASSWORD_MODAL }));
  };

  const buttonContent = isLoading ? (
    <div className={'container dots-flow'} />
  ) : (
    <>
      <span>Verify</span>
      <HiArrowNarrowRight />
    </>
  );

  return (
    <>
      <Modal
        onRequestClose={() => {}}
        isOpen={isOpen}
        style={customStyles}
        ariaHideApp={false}
        overlayClassName="Overlay-2">
        <Container>
          <Close onClick={() => dispatch(closeModal())} />
          <h3>Email Sent</h3>
          <img src={images.checkMail} alt="forgot password" />
          <p className="title">Verify OTP</p>
          <p>
            Check your mail for the OTP. If you don't see it, check your spam folder or{' '}
            <span className="link" onClick={onBackPressed}>
              resend OTP
            </span>
          </p>
          <form onSubmit={handleSubmit(onSubmitHandler)}>
            <input type="text" autoFocus placeholder={'OTP'} {...register('otp')} />
            <Button
              disabled={isLoading || !isDirty || !isValid}
              type="submit"
              whileHover={{ backgroundColor: 'var(--color-primary-dark)' }}
              whileTap={{ scale: 0.97 }}>
              {buttonContent}
            </Button>
          </form>
        </Container>
      </Modal>
      <ToastContainer />
    </>
  );
};

export default CheckMail;
