import styled from 'styled-components';
import { motion } from 'framer-motion';

export const StatementWrapper = styled.div`
  background-color: #fff;
  margin-top: 2rem;
  padding: 2rem 1.6rem;
`;

export const Header = styled.p`
  font-size: 1.6rem;
  font-weight: 500;
  margin-bottom: 1.5rem;
  color: #707070;
`;

export const Btn = styled(motion.button)`
  text-transform: uppercase;
  padding: 1.4rem 3rem;
  letter-spacing: 0.3px;
  font-size: 1.6rem;
  font-weight: 500;
  background-color: var(--color-accent) !important;
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
`;

export const Text = styled.p`
  font-size: 1.6rem;
  letter-spacing: 0.5px;
  color: #000 !important;
  margin-top: 1.6rem;
`;
export const Table = styled.table`
  width: 100%;
  border: none;
  border-collapse: collapse;
  text-align: center;
`;

export const Head = styled.thead`
  border-radius: 5px;

  tr {
    background-color: var(--color-primary-light-1);
  }

  th {
    font-size: 1.34rem;
    font-weight: bold;
    padding: 2.7rem 2rem;
    text-align: center;
  }
`;

export const Row = styled.tr`
  font-size: 1.34rem;
  color: #636363;
  border-radius: 5px;
  background-color: #fff;

  &:nth-child(even) {
    background-color: #f5f5f5;
  }

  td {
    padding: 2.7rem 2rem;
  }
`;
