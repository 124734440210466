import styled from 'styled-components';
import images from '../../../constants';
import { motion } from 'framer-motion';

export const HeaderWrapper = styled.div`
  padding: 1.6rem;
  border-radius: 10px;
  position: relative;
  height: 19.5rem;

  .bg {
    background-image: url(${images.topBackground});
    filter: blur(2px);
    -webkit-filter: blur(2px);
    margin: -1.6rem;
    border-radius: 10px;
    height: 100%;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
`;

export const Header = styled.div`
  display: flex;
  column-gap: 1.6rem;
  color: white;
  background-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  padding: 2rem;
  width: 100%;
  border-radius: 10px;
  align-items: flex-start;

  p {
    font-size: 1.6rem;
    margin-bottom: 0.8rem;
  }

  h3 {
    font-size: 2rem;
    margin-bottom: 0.5rem;
  }

  h5 {
    font-size: 1.8rem;
  }
`;

export const Avatar = styled.div`
  width: 12rem;
  height: 12rem;
  border-radius: 50%;
  position: relative;
  border: 3px solid white;
  z-index: 2;

  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
  }

  div {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 3rem;
    height: 3rem;
    background-color: var(--color-accent);
    border-radius: 50%;
    border: 1px solid white;
    display: grid;
    place-items: center;
    transition: all 0.2s ease-in-out;
    cursor: pointer;

    &:hover {
      background-color: var(--color-accent-dark);
      transform: scale(1.1);
    }

    &:active {
      transform: scale(1);
    }

    svg {
      display: block;
      width: 2rem;
      height: 2rem;
      fill: white;
    }
  }
`;

export const Button = styled(motion.button)`
  padding: 1.2rem 4rem;
  text-transform: capitalize;
  font-size: 1.6rem;
  border-radius: 5px;
  background-color: var(--color-primary);
  color: white;
  margin-top: 2rem;
  letter-spacing: 1px;
`;

export const Progress = styled(motion.div)`
  border-radius: 5px;
  background: rgb(255, 171, 0);
  background: linear-gradient(90deg, rgba(255, 171, 0, 1) 0%, rgba(255, 205, 102, 1) 18%, rgba(255, 221, 153, 1) 42%);
  height: 100%;
`;

export const ProgressBar = styled.div`
  width: 100%;
  height: 0.8rem;
  border-radius: 5px;
  margin: 0.8rem 0;
  background-color: #fff;
`;
