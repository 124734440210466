import React from 'react';
import './style.css';

function MeetupLoader() {
  return (
    <div className={'meetup-container'}>
      <div className="meetup">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
}

export default MeetupLoader;
