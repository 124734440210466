import React, { createRef } from 'react';
import { useGetUserQuery } from '../../../../features/user/userApiSlice';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { closeModal, selectCurrentModal } from '../../../../features/modal/modalSlice';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { schema } from './edit-bus-schema';
import { Close, Header, HeaderActions } from '../LoanModal/styles';
import Modal from 'react-modal';
import { Container, Label, Wrapper } from '../../../../components/LoanModal/PersonalDocuments/styles';
import { Btn, BtnDiv } from '../../../../components/BusinessProfile/EditProfile/styles';
import { MODAL_TYPES } from '../../modalTypes';
import { useGetBusinessByIdQuery, useUpdateBusinessMutation } from '../../../../features/business/businessApiSlice';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    zIndex: 100,
    border: 'none',
    padding: 0,
    width: '70%',
    borderRadius: '10px',
    height: 'auto',
  },
};
const EditBusLogoModal = () => {
  const [updateBusiness, { isLoading }] = useUpdateBusinessMutation();
  const { data: user } = useGetUserQuery();
  const { data: companyData } = useGetBusinessByIdQuery(user.id);
  const modalType = useAppSelector(selectCurrentModal);
  const isOpen = modalType === MODAL_TYPES.EDIT_BUS_LOGO_MODAL;
  const dispatch = useAppDispatch();
  const avatarRef = createRef();

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid },
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  const buttonContent = isLoading ? <div className={'container dots-flow'} /> : 'Update';

  const onSubmitClose = () => {
    dispatch(closeModal());
  };
  const onSubmitHandler = async (data) => {
    const userInput = {
      item: {
        logo: data.logo[0],
      },
      id: companyData.companies[0].id,
    };
    try {
      await updateBusiness(userInput).unwrap();
      onSubmitClose();
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <Modal
      onRequestClose={() => {}}
      isOpen={isOpen}
      style={customStyles}
      ariaHideApp={false}
      overlayClassName="Overlay">
      <Header>
        <h2>Upload your company logo</h2>
        <HeaderActions>
          <Close onClick={onSubmitClose} />
        </HeaderActions>
      </Header>
      <Wrapper
        style={{ padding: '1.6rem', paddingLeft: 0 }}
        onSubmit={handleSubmit(onSubmitHandler)}
        id={'edit-profile-photo'}>
        <Container style={{ padding: '1.6rem' }}>
          <Label>Upload your company logo</Label>
          <input type="file" name={'logo'} ref={avatarRef} {...register('logo')} />
        </Container>
      </Wrapper>
      <BtnDiv style={{ padding: '1.6rem', paddingLeft: 0 }}>
        <Btn onClick={onSubmitClose} whileTap={{ scale: 0.97 }} whileHover={{ backgroundColor: '#f4ad3d' }}>
          Cancel
        </Btn>
        <Btn
          disabled={isLoading || !isValid || !isDirty}
          whileTap={{ scale: 0.97 }}
          whileHover={{ backgroundColor: '#014e35' }}
          className={'green'}
          type={'submit'}
          form={'edit-profile-photo'}>
          {buttonContent}
        </Btn>
      </BtnDiv>
    </Modal>
  );
};

export default EditBusLogoModal;
