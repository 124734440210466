import React from 'react';
import Modal from 'react-modal';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { closeModal, selectCurrentModal } from '../../../../features/modal/modalSlice';
import { MODAL_TYPES } from '../../modalTypes';
import PostActions from '../../../../components/Konnect/Post/PostActions/PostActions';
import {
  Avatar,
  HRule,
  ModalContainer,
  Organization,
  PostContainer,
  PostHeader,
  PostImage,
  PostText,
  UserName,
} from './styles';
import { TbDots } from 'react-icons/tb';
import { useGetPostQuery } from '../../../../features/posts/postsApiSlice';
import MeetupLoader from '../../../../components/Loaders/MeetupLoader';
import images from '../../../../constants';
import TimeAgo from '../../../../components/Common/TimeAgo';
import PostComments from '../../../../components/PostComments/PostComments';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    zIndex: 100,
    border: 'none',
    width: '70%',
    height: '90%',
  },
};

const PostModal = () => {
  const postId = localStorage.getItem('postId');
  const { data: post, isLoading, isSuccess, isError, error } = useGetPostQuery(+postId);
  const dispatch = useAppDispatch();
  const modalType = useAppSelector(selectCurrentModal);
  const isOpen = modalType === MODAL_TYPES.POST_MODAL;

  let content;
  if (isLoading) {
    content = <MeetupLoader />;
  } else if (isSuccess) {
    content = (
      <PostContainer>
        <div>
          <PostHeader>
            <div>
              <Avatar src={images.logoIcon} />
              <section>
                <UserName>
                  Admin
                  <TimeAgo timestamp={post.created_at} />
                </UserName>
                <Organization>Melanin Kapital</Organization>
              </section>
            </div>
            <TbDots />
          </PostHeader>
          <HRule />
          <PostText>{post.content}</PostText>
          <PostImage src={post.poster} />
          <HRule />
        </div>
        {/*<PostActions post={post} />*/}
        <PostComments comments={post.comment} />
      </PostContainer>
    );
  } else if (isError) {
    console.log(error);
    dispatch(closeModal());
  }

  const onSubmitHandler = () => {
    dispatch(closeModal());
  };

  return (
    <Modal
      onRequestClose={onSubmitHandler}
      isOpen={isOpen}
      style={customStyles}
      ariaHideApp={false}
      overlayClassName="Overlay">
      <ModalContainer>{content}</ModalContainer>
    </Modal>
  );
};

export default PostModal;
