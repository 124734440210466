import styled from 'styled-components';

export const AnalysisWrapper = styled.section`
  padding: 4rem 1.6rem 1.6rem;
  display: grid;
  grid-gap: 1.6rem;
  grid-template-columns: repeat(auto-fill, 23rem);
  background-color: #fff;
  border-radius: 10px;
  margin-top: 2rem;

  & > :first-child {
    grid-column: 1 / 3;
  }
`;

export const CashFlowCard = styled.div`
  border: 1px solid var(--color-blue);
  background-color: #cdd5f7;
  padding: 1.6rem;

  div {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: baseline;
    column-gap: 1rem;
    width: 100%;
  }

  .header {
    font-size: 1.7rem;
    margin-bottom: 2rem;

    h4 {
      color: #00001a;
      font-weight: 500;
    }

    p {
      color: #707070;
    }
  }

  .content {
    font-size: 1.6rem;

    h5 {
      color: #060c26;
      font-weight: 500;
    }

    p {
      color: #707070;
    }
  }

  svg {
    width: 2rem;
    height: 2rem;
  }
`;

export const ExpensesCard = styled.div`
  background-color: var(--color-red-1);
  border: var(--color-red-2) solid 1px;
  padding: 1.6rem;

  h1 {
    font-weight: 500;
    font-size: 2rem;
  }

  h2 {
    font-weight: 500;
    font-size: 2rem;
    color: var(--color-red-2);
  }

  p {
    font-size: 1.6rem;
    color: #707070;
    margin: 0.5rem 0;
  }

  .info {
    width: 100%;
    display: flex;
    align-items: center;
    background-color: #f8c4c4;
    border-radius: 10px;
    padding: 0.5rem 1rem;
    font-size: 1.28rem;
    color: #707070;
    column-gap: 2rem;

    svg {
      width: 1.5rem;
      height: 1.5rem;
      fill: #707070;
    }
  }
`;

export const IncomeCard = styled.div`
  border: #018158 1px solid;
  background-color: var(--color-green-1);
  font-size: 1.92rem;
  color: #00001a;
  padding: 1.6rem;

  h6 {
    font-size: 1.92rem;
    font-weight: 500;
  }

  p {
    margin: 2rem 0;
  }

  .content {
    font-weight: 500;
    font-size: 1.9rem;
    display: flex;
    width: 100%;
    justify-content: space-between;

    .percent {
      color: var(--color-green);
      text-align: right;
    }
  }

  .bar {
    background-color: #e6e6e6;
    width: 100%;
    height: 1.6rem;

    div {
      background-color: var(--color-green);
      height: 100%;
    }
  }
`;

export const AnalysisCard = styled.div`
  padding: 1.6rem;
  border-radius: 3px;
  border: 1px solid #707070;
  position: relative;
`;

export const ExTitle = styled.h5`
  font-weight: 500;
  font-size: 1.92rem;
  color: #00001a;
`;

export const Text = styled.p`
  color: #707070;
  font-size: 1.6rem;
  letter-spacing: 0.5px;
  text-transform: capitalize;
`;

export const ContentIcon = styled.div<{ bgc: string; customColor: string }>`
  svg {
    border-radius: 50%;
    padding: 1.2rem;
    background-color: ${(props) => props.bgc};
    margin-bottom: 2rem;
    width: 3rem;
    height: 3rem;
    fill: ${(props) => props.customColor};
    box-sizing: content-box;
  }
`;

export const InfoIcon = styled.div`
  position: absolute;
  top: 1.6rem;
  right: 1.6rem;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  svg {
    fill: #707070;
    width: 2rem;
    height: 2rem;
  }

  &:hover {
    svg {
      fill: black;
    }
  }
`;
