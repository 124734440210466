import { apiSlice } from '../../app/api/apiSlice';
import { createEntityAdapter, createSelector } from '@reduxjs/toolkit';

const adapter = createEntityAdapter();
const initialState = adapter.getInitialState();

const creditAttrsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCreditAttrs: builder.query<any, void>({
      query: () => `/credit/attribute/list`,
      transformResponse: (rawResult: any) => adapter.setAll(initialState, rawResult?.response?.data),
    }),
  }),
});

export const { useGetCreditAttrsQuery } = creditAttrsApiSlice;
export const selectAttrsResult = creditAttrsApiSlice?.endpoints.getCreditAttrs.select();

const selectAttrsData = createSelector(selectAttrsResult, (attrsResult) => attrsResult?.data);
export const { selectAll: selectCreditAttrs } = adapter.getSelectors(
  (state: any) => selectAttrsData(state) ?? initialState,
);
