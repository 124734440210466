import React from 'react';
import 'survey-core/defaultV2.min.css';
import { Model, StylesManager } from 'survey-core';
import { Survey } from 'survey-react-ui';

StylesManager.applyTheme('defaultV2');

export interface ISurveyProps {
  json: any;
  data: any;
  onComplete: (survey: any) => void;
  completeText?: string;
  showCompletedPage?: boolean;
}

const SurveyComponent = ({
  onComplete,
  data,
  json,
  completeText = 'Submit',
  showCompletedPage = true,
}: ISurveyProps) => {
  let model = new Model(json);

  model.onComplete.add(function (survey, options) {
    console.log(survey.data);
  });
  return (
    <Survey
      completeText={completeText}
      logo={''}
      showTitle={false}
      model={model}
      data={data}
      onComplete={onComplete}
      showCompletedPage={showCompletedPage}
    />
  );
};

export default SurveyComponent;
