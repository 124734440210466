import { createEntityAdapter } from '@reduxjs/toolkit';
import { apiSlice } from '../../app/api/apiSlice';
import { ENROLL } from '../../types/api-responses';

const participantsAdapter = createEntityAdapter();

const initialState = participantsAdapter.getInitialState();

export const enrollApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    enroll: build.mutation({
      query: (enrollInfo) => {
        const data = new FormData();
        for (const enrollInfoKey in enrollInfo) {
          data.append(enrollInfoKey, enrollInfo[enrollInfoKey]);
        }
        return {
          url: '/lms/participant/create',
          method: 'POST',
          body: data,
        };
      },
      invalidatesTags: [
        { type: ENROLL, id: 'enroll' },
        { type: ENROLL, id: 'LIST' },
      ],
    }),
    getEnrolledCourses: build.query<any, string>({
      query: (id: string) => `lms/participants/enrolled/courses/${id}`,
      transformResponse: (rawResult: any) => rawResult?.response,
      providesTags: (result) => [{ type: ENROLL, id: 'LIST' }],
    }),
    getCertifications: build.query<any, string>({
      query: (id: string) => `/lms/participants/certifications/${id}`,
      transformResponse: (rawResult: any) => rawResult?.response,
    }),
    getOverview: build.query<any, string>({
      query: (id: string) => `/lms/participant/overview/${id}`,
      transformResponse: (rawResult: any) => rawResult?.response,
      providesTags: (result) => [{ type: ENROLL, id: 'enroll' }],
    }),
  }),
});

export const { useGetCertificationsQuery, useEnrollMutation, useGetEnrolledCoursesQuery, useGetOverviewQuery } =
  enrollApiSlice;
