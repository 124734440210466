import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

const localToken = localStorage.getItem('token');
const initialState: { token: string | null } = { token: localToken };
const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAccessToken: (state, action) => {
      const { access_token } = action.payload;
      state.token = access_token;
      localStorage.setItem('token', access_token);
    },
    logOut: (state) => {
      state.token = null;
      localStorage.removeItem('token');
      window.location.reload();
    },
  },
});

export const { setAccessToken, logOut } = authSlice.actions;
export default authSlice.reducer;
export const selectCurrentToken = (state: RootState) => state.auth.token;
