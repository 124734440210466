import React from 'react';
import DragNDrop from '../DragNDrop/DragNDrop';
import { Container, Label, Wrapper } from './styles';
import { Content } from '../../BusinessProfile/EditProfile/styles';

function PersonalDocsMd(props) {
  return (
    <Wrapper>
      <Content>
        <Container>
          <Label>
            1. Upload your tax document<sup>*</sup>
          </Label>
          <DragNDrop />
        </Container>
        <Container>
          <Label>
            2. Upload an image of the front of your ID<sup>*</sup>
          </Label>
          <DragNDrop />
        </Container>
        <Container>
          <Label>
            3. Upload an image of the back of your ID<sup>*</sup>
          </Label>
          <DragNDrop />
        </Container>
      </Content>
    </Wrapper>
  );
}

export default PersonalDocsMd;
