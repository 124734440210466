import styled from 'styled-components';
import { Nav } from '../../Common/Tabs/styles';

export const LoansWrapper = styled.div`
  position: relative;
  top: 7rem;
  padding-right: 1.2rem;
  padding-top: 1rem;
`;

export const LoansHeader = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  margin: 1rem 0;
  column-gap: 1.6rem;
`;

export const Title = styled.h4`
  font-weight: 500;
  font-size: 1.92rem;
  letter-spacing: 0.5px;
`;

export const Text = styled.p`
  font-size: 1.6rem;
  text-transform: capitalize;
`;

export const HeroTitle = styled.h2`
  margin-top: 0.5rem;
  font-weight: bold;
  font-size: 2.4rem;
  letter-spacing: 0.3px;
  display: flex;
  align-items: flex-start;

  span {
    text-transform: uppercase;
    font-size: 1.28rem;
    font-weight: lighter;
    position: relative;
    top: 0.5rem;
  }
`;

export const Card = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-radius: 10px;
  background-color: var(--color-accent-light);
  padding: 2rem 1.6rem;
  position: relative;

  img {
    width: 5rem;
    height: 5rem;
    object-fit: contain;
  }
`;

export const Arrow = styled.div`
  display: flex;
  position: absolute;
  bottom: -1.5rem;
  right: 1.6rem;
  background-color: var(--color-primary);
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  padding: 1rem;

  svg {
    width: 1.6rem;
    height: auto;
    fill: white;
  }
`;

export const Status = styled.p`
  padding: 1rem;
  border-radius: 30px;
  border: 1px solid ${(props) => props.color};
  color: ${(props) => props.color};
  max-width: 20rem;
  margin: 0 auto;
`;

export const HeaderTitle = styled.h3`
  font-size: 2rem;
  font-weight: 500;
  border-bottom: transparent 2px solid;
  margin-bottom: 2rem;
  width: max-content;
  cursor: pointer;

  &.active {
    border-bottom: var(--color-primary) 2px solid;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  column-gap: 2rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

export const MNav = styled(Nav)`
  width: 40rem;
  margin-top: 4rem;
`;

export const Table = styled.table`
  width: 100%;
  border: none;
  border-collapse: collapse;
  text-align: center;
`;

export const Head = styled.thead`
  border-radius: 5px;

  tr {
    background-color: var(--color-primary-light-1);
  }

  th {
    font-size: 1.34rem;
    font-weight: bold;
    padding: 2.7rem 2rem;
    text-align: center;
  }
`;

export const Row = styled.tr`
  font-size: 1.34rem;
  color: #636363;
  border-radius: 5px;
  cursor: pointer;
  background-color: #fff;

  &:nth-child(even) {
    background-color: #f5f5f5;
  }

  td {
    padding: 2.7rem 2rem;

    &.info {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      column-gap: 1rem;
      text-align: left;

      .image {
        display: grid;
        place-items: center;
        border-radius: 50%;
        padding: 0.5rem;
        background-color: black;

        img {
          width: 4rem;
          height: 4rem;
          object-fit: cover;
          border-radius: 50%;
        }
      }

      div {
        p:first-child {
          font-weight: bold;
        }

        p:last-child {
          color: #636363;
        }
      }
    }

    &.asset {
      img {
        width: 5rem;
        height: 5rem;
        object-fit: cover;
      }
    }
  }
`;
export const Chip = styled.div`
  display: grid;
  place-items: center;
  padding: 0.5rem 1rem;
  border-radius: 30px;
  background-color: var(--color-accent);
  color: white;
  font-size: 1.44rem;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: var(--color-accent-dark);
    transform: scale(1.05);
  }

  &:active {
    transform: scale(1);
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  letter-spacing: 0.5px;
  background-color: #fff;
  padding: 2rem 1.6rem;

  h3 {
    font-size: 2rem;
    font-weight: bold;

    &::after {
      content: '';
      display: block;
      width: 5rem;
      height: 0.3rem;
      background-color: var(--color-accent);
      margin-top: 0.5rem;
    }
  }
`;

export const Filter = styled.div`
  display: grid;
  grid-template-columns: 5rem 12rem 1fr 1fr;
  align-items: center;
  column-gap: 1rem;
  font-size: 1.6rem;
  color: #636363;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  padding: 0.5rem 1rem;

  .icon {
    width: 4rem;
    height: 4rem;
    fill: #636363;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &:hover {
      fill: var(--color-accent);
    }

    &.active {
      fill: var(--color-accent);
    }
  }

  .input {
    border: 1px solid #707070;
    border-radius: 5px;
    font-size: 1.4rem;
    text-transform: capitalize;
    padding: 0.5rem 0.8rem;
    height: 100%;
    width: 100%;

    &:focus {
      outline: none;
      border: 1px solid var(--color-accent);
    }
  }
`;

export const Specifications = styled.div`
  background-color: #fff;
  padding: 2rem 1.6rem;
  font-size: 1.6rem;
  letter-spacing: 0.5px;

  h3 {
    font-size: 2rem;
    font-weight: bold;
    color: #707070;
    margin-bottom: 1rem;
  }
`;

export const PayMode = styled.div`
  background-color: var(--color-primary-light-1);
  position: relative;
  padding: 2rem 1.6rem;
  margin-bottom: 2rem;

  .head {
    display: flex;
    align-items: center;
    column-gap: 2rem;
    margin-bottom: 2rem;

    h2 {
      font-size: 2rem;
      font-weight: bold;
      color: #707070;
      cursor: pointer;
      transition: all 0.2s ease-in-out;
      padding: 0.5rem 1rem;
      border-radius: 5px 5px 0 0;

      &::after {
        content: '';
        display: block;
        width: 5rem;
        height: 0.3rem;
        background-color: transparent;
        margin-top: 0.5rem;
      }

      &:hover {
        background-color: #40c096;
        color: #000;
      }

      &.active {
        color: #000;
        &::after {
          background-color: var(--color-accent);
        }
      }
    }
  }

  .title {
    font-size: 2.4rem;
    font-weight: bold;

    sup {
      font-size: 1.4rem;
      text-transform: uppercase;
      font-weight: normal;
    }
  }

  .pay_btn {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 1.5rem 2rem;
    background-color: var(--color-accent);
    color: #000;
    font-size: 1.8rem;
    font-weight: bold;
    border-radius: 10px 0 0 0;
    display: flex;
    align-items: center;
    transition: 0.2s ease-in-out;

    svg {
      margin-left: 1rem;
      width: 5rem;
      height: 3%;
      fill: #000;
      font-weight: normal;
    }

    &:hover {
      background-color: var(--color-accent-dark);
      transform: scale(1.05);
    }

    &:active {
      transform: scale(1);
    }
  }
`;

export const OptGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 2rem;
  align-items: flex-start;
  margin-bottom: 2rem;

  &.last {
    margin-bottom: 8rem;
  }
`;

export const PayOpt = styled.div`
  .slider {
    margin-top: 0.7rem;
  }

  #range-slider .range-slider__thumb[data-lower] {
    border: none;
    width: 0;
  }

  #range-slider .range-slider__range {
    border-radius: 6px;
    background-color: var(--color-accent);
  }

  #range-slider .range-slider__thumb[data-upper] {
    background-color: var(--color-accent);
    border: 0.5px solid var(--color-accent);
    width: 1.8rem;
    height: 1.8rem;
  }

  background-color: #fff;
  padding: 2.2rem 2rem;
  font-size: 1.6rem;
  letter-spacing: 0.5px;
  position: relative;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  border-radius: 5px;
  border: 1px solid #e5e5e5;

  &:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }

  &.other {
    border: none;
  }

  display: flex;
  align-items: center;
  column-gap: 1rem;

  .icon {
    width: 2rem;
    height: 2rem;
    fill: var(--color-accent) !important;
  }

  .content {
    flex: 1;
    display: flex;
    align-items: center;
    width: 100%;
    column-gap: 1.6rem;

    .main {
      flex: 1;
    }
  }

  .interval {
    color: #707070;
    font-size: 1.4rem;
    margin-top: -0.8rem;
  }

  .arrow {
    flex: 1;
    fill: black;
  }

  .price {
    font-size: 1.4rem;
    color: var(--color-primary);
  }

  .count {
    font-size: 1.6rem;
    font-weight: bold;
  }

  .footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .info {
    width: 2rem;
    height: 2rem;
    position: absolute;
    top: 0.5rem;
    right: 0.8rem;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    fill: black;
  }
`;

export const CheckBtn = styled.div`
  position: relative;
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-color: white;
  border: 2px solid var(--color-accent);
  border-radius: 50%;

  input[type='radio'] {
    position: absolute;
    opacity: 0;
    cursor: pointer;

    &:checked ~ .checkmark {
      background-color: var(--color-accent);
    }
  }

  .checkmark {
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    transform: translate(-50%, -50%);
    height: 1rem;
    width: 1rem;
    background-color: #fff;
    border: none;
    border-radius: 50%;
  }

  &:hover input ~ .checkmark {
    border-color: var(--color-accent);
  }
`;
