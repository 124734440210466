import styled from 'styled-components';

export const ProgressModalWrapper = styled.div`
  background-color: #fff;
`;

export const Header = styled.div`
  padding: 20px;
  background-color: var(--color-primary);
  color: #fff;
  font-size: 1.72rem;
  letter-spacing: 0.1rem;
  margin-bottom: 2rem;

  h4 {
    font-size: 1.92rem;
  }
`;

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 2rem;
  padding: 0 2rem;
  margin-bottom: 2rem;
`;

export const Item = styled.div<{ isNext: boolean }>`
  font-size: 1.6rem;
  display: grid;
  grid-template-columns: auto 1fr auto;
  column-gap: 1.6rem;
  align-items: center;
  margin-bottom: 0.6rem;
  padding: 1rem 1.6rem;
  border-radius: 0.4rem;
  background-color: ${({ isNext }) => (isNext ? 'var(--color-accent-light)' : '#fff')};

  .num {
    align-self: flex-start;
    justify-self: start;
    display: grid;
    place-items: center;
    width: 3.2rem;
    height: 3.2rem;
    font-weight: bold;
    color: ${({ isNext }) => (isNext ? '#fff' : '#000')};
    border-radius: 50%;
    background-color: ${({ isNext }) => (isNext ? 'var(--color-accent)' : '#c5c5c5')};
  }

  p {
    flex: 1;
  }

  img {
    width: 2rem;
    height: 2rem;
  }
`;

export const Content = styled.div`
  padding: 1.6rem;
  border: 1px solid #707070;
  border-radius: 0.4rem;

  .logo {
    width: 4rem;
    height: 4rem;
    background-color: #fff;
    box-shadow: 0 0 0.8rem rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    display: grid;
    place-items: center;

    img {
      width: 2.4rem;
      height: 2.4rem;
    }
  }

  .text {
    margin-top: 1rem;
    font-size: 1.4rem;
    color: #707070;
    display: flex;
    column-gap: 0.8rem;
    align-items: center;

    svg {
      width: 1rem;
      height: 1rem;
      fill: #707070;
    }
  }

  .title {
    font-size: 1.8rem;
    font-weight: bold;
    margin: 1rem 0;
  }
`;

export const Button = styled.button`
  background-color: var(--color-primary);
  text-align: center;
  color: #fff;
  font-size: 1.6rem;
  text-transform: uppercase;
  padding: 1.2rem 2.4rem;
  border-radius: 0.4rem;
  border: none;
  transition: background-color 0.2s ease-in-out, transform 0.2s ease-in-out;

  &:hover {
    background-color: var(--color-primary-dark);
  }

  &:active {
    transform: scale(0.9);
  }
`;
