import React from 'react';
import { Arrow, Card, CardContainer, Clip, Header, Input, Integrate, Wrapper } from './styles';
import images from '../../../constants';
import { BsArrowRight, BsSearch } from 'react-icons/bs';
import { useCheckBuiltInAppQuery } from '../../../features/apps/appsSlice';
import { openModal } from '../../../features/modal/modalSlice';
import { MODAL_TYPES } from '../../../modules/ModalWindow/modalTypes';
import { useAppDispatch } from '../../../app/hooks';
import { useGetUserBusinessQuery, useGetUserQuery } from '../../../features/user/userApiSlice';
import MeetupLoader from '../../../components/Loaders/MeetupLoader';
import styles from '../RiskProfile/RiskProfile.module.scss';
import { Link } from 'react-router-dom';
import pageRoutes from '../../../constants/pageRoutes';

const Marketplace = () => {
  const { data: user } = useGetUserQuery();
  const dispatch = useAppDispatch();
  const { data, isLoading, isSuccess, error, isError } = useCheckBuiltInAppQuery();
  const { data: companyData, isSuccess: success, isLoading: companyLoading } = useGetUserBusinessQuery(user.id);
  let company = {
    isCompany: false,
    credit_score: 0,
    id: 0,
  };
  let content;

  const onOpenLoginBuilt = () => {
    window.open('https://app.built.africa/auth/login', '_blank');
  };

  const onOpenJoinModal = () => {
    dispatch(openModal({ modalType: MODAL_TYPES.JOIN_MODAL }));
  };

  const busLink = `/${pageRoutes.DASHBOARD}/${pageRoutes.BUSINESS}`;
  const editLink = `/${pageRoutes.DASHBOARD}/${pageRoutes.PROFILE}/${pageRoutes.USER_EDIT}`;

  if (!user.phone) {
    return (
      <Wrapper>
        <Header>
          <img src={images.mk_header} alt="Banner Image" />
          <div>
            <h3>Discover Apps</h3>
            <Input>
              <input type="search" placeholder={'Search apps'} />
              <BsSearch />
            </Input>
            <p>Increase your resourcefulness with some of the apps we use</p>
          </div>
          <img src={images.mk_header} alt="Banner Image" />
        </Header>
        <p className={styles.pending}>
          To get started, please add your phone number to your <Link to={editLink}>personal profile</Link>
        </p>
      </Wrapper>
    );
  }

  if (success) {
    if (companyData.companies.length > 0) {
      company = {
        isCompany: true,
        credit_score: companyData.companies[0].credit_score,
        id: companyData.companies[0].id,
      };
    }
    if (!company.isCompany) {
      return (
        <Wrapper>
          <Header>
            <img src={images.mk_header} alt="Banner Image" />
            <div>
              <h3>Discover Apps</h3>
              <Input>
                <input type="search" placeholder={'Search apps'} />
                <BsSearch />
              </Input>
              <p>Increase your resourcefulness with some of the apps we use</p>
            </div>
            <img src={images.mk_header} alt="Banner Image" />
          </Header>
          <p className={styles.pending}>
            To get started, please complete your <Link to={busLink}>business profile</Link>
          </p>
        </Wrapper>
      );
    }
  } else if (companyLoading) {
    return (
      <Wrapper>
        <Header>
          <img src={images.mk_header} alt="Banner Image" />
          <div>
            <h3>Discover Apps</h3>
            <Input>
              <input type="search" placeholder={'Search apps'} />
              <BsSearch />
            </Input>
            <p>Increase your resourcefulness with some of the apps we use</p>
          </div>
          <img src={images.mk_header} alt="Banner Image" />
        </Header>
        <MeetupLoader />;
      </Wrapper>
    );
  }

  if (isLoading) {
    content = (
      <Integrate color={'#018158'}>
        <p>please wait...</p>
      </Integrate>
    );
  } else if (isSuccess) {
    if (data === '01') {
      content = (
        <Integrate onClick={onOpenJoinModal} color={'#018158'}>
          <p>Join</p>
          <Arrow color={'#018158'}>
            <BsArrowRight />
          </Arrow>
        </Integrate>
      );
    } else if (data === '00') {
      content = (
        <Integrate onClick={onOpenLoginBuilt} color={'#018158'}>
          <p>Login</p>
          <Arrow color={'#018158'}>
            <BsArrowRight />
          </Arrow>
        </Integrate>
      );
    }
  } else if (isError) {
    console.log(error);
  }

  return (
    <Wrapper>
      <Header>
        <img src={images.mk_header} alt="Banner Image" />
        <div>
          <h3>Discover Apps</h3>
          <Input>
            <input type="search" placeholder={'Search apps'} />
            <BsSearch />
          </Input>
          <p>Increase your resourcefulness with some of the apps we use</p>
        </div>
        <img src={images.mk_header} alt="Banner Image" />
      </Header>
      <CardContainer>
        <Card>
          <img
            src="https://firebasestorage.googleapis.com/v0/b/nectar-f5ee3.appspot.com/o/bs_tools%2Ffaidi.png?alt=media&token=b1f5e799-b04b-484d-bbf7-94b86b089a26"
            alt=""
          />
          <h3>Built Accounting</h3>
          <p>The all-in-one finance platform for unlimited invoicing, payment, payroll and more</p>
          {content}
          <Clip color={'#018158'} />
        </Card>
      </CardContainer>
    </Wrapper>
  );
};

export default Marketplace;
