import styled from 'styled-components';

export const Container = styled.div`
  background-color: #fff5e0;
  box-shadow: 0 7px 6px #00000029;
  border-radius: 5px;
  padding: 1rem;

  h3 {
    text-transform: uppercase;
    font-size: 1.8rem;
    font-weight: 500;
    color: #707070;
    margin-bottom: 1.2rem;
  }

  div {
    display: flex;
    align-items: center;
    font-size: 1.4rem;
    column-gap: 1.2rem;
    margin-bottom: 0.5rem;

    svg {
      width: 1.8rem;
      height: auto;
      fill: var(--color-accent);
    }

    p {
      flex: 1;
    }
  }
`;
