import React, { useEffect, useState } from 'react';
import { Container, Header, Main } from './styles';
import images from '../../constants';
import pageRoutes from '../../constants/pageRoutes';
import { useNavigate } from 'react-router-dom';
import { AiOutlineCar } from 'react-icons/ai';
import { FiPackage } from 'react-icons/fi';
import PagesWrapper from './PagesWrapper';

// RxLightningBolt
const ClimateQuestion = () => {
  const climateRoute = `/${pageRoutes.DASHBOARD}/${pageRoutes.IMPACT}`;
  const navigate = useNavigate();
  const [counter, setCounter] = useState(0);
  const quizList = ['Kenya Power', 'Solar Energy', 'Hydro Energy', 'Wind Energy'];
  const bolt = (
    <svg
      stroke="currentColor"
      fill="none"
      stroke-width="0"
      viewBox="0 0 15 15"
      height="1em"
      width="1em"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.69667 0.0403541C8.90859 0.131038 9.03106 0.354857 8.99316 0.582235L8.0902 6.00001H12.5C12.6893 6.00001 12.8625 6.10701 12.9472 6.27641C13.0319 6.4458 13.0136 6.6485 12.8999 6.80001L6.89997 14.8C6.76167 14.9844 6.51521 15.0503 6.30328 14.9597C6.09135 14.869 5.96888 14.6452 6.00678 14.4178L6.90974 9H2.49999C2.31061 9 2.13748 8.893 2.05278 8.72361C1.96809 8.55422 1.98636 8.35151 2.09999 8.2L8.09997 0.200038C8.23828 0.0156255 8.48474 -0.0503301 8.69667 0.0403541ZM3.49999 8.00001H7.49997C7.64695 8.00001 7.78648 8.06467 7.88148 8.17682C7.97648 8.28896 8.01733 8.43723 7.99317 8.5822L7.33027 12.5596L11.5 7.00001H7.49997C7.353 7.00001 7.21347 6.93534 7.11846 6.8232C7.02346 6.71105 6.98261 6.56279 7.00678 6.41781L7.66968 2.44042L3.49999 8.00001Z"
        fill="currentColor"></path>
    </svg>
  );
  const progress = 16;

  const [page, setPage] = useState(0);
  const pages = [
    {
      header: 'Energy Usage',
      subheader: 'Electricity',
      icon: bolt,
      subpages: [
        {
          question: 'Who is your electricity provider?',
          questionType: 'options',
          options: ['Kenya Power', 'Solar Energy', 'Hydro Energy', 'Wind Energy'],
        },
        {
          question: 'How much does your company spend on electricity monthly?',
          questionType: 'input',
        },
        {
          question: "Please upload your company's electricity bill",
          questionType: 'upload',
        },
        {
          question: 'Does your company receive heating?',
          questionType: 'options',
          options: ['Yes, Natural Gas', 'Yes, Electricity', 'No, Not Applicable', "I Don't Know"],
        },
        {
          question: 'How much does your company spend on heating monthly?',
          questionType: 'input',
        },
        {
          question: "Please upload your company's heating bill",
          questionType: 'upload',
        },
      ],
    },
    {
      header: 'Fuel Consumption',
      subheader: 'Company Vehicles',
      icon: <AiOutlineCar />,
      subpages: [
        {
          question: 'Does your company own or maintain long- term leases on vehicles?',
          questionType: 'options',
          options: ['Yes', 'No', "I Don't Know"],
        },
        {
          question: 'How many company vehicles run on:-',
          questionType: 'multiple',
          options: ['Petrol', 'Diesel', 'Electricity'],
        },
        {
          question: 'What is the average distance driven daily by each company vehicle?',
          questionType: 'multiple',
          options: ['Petroleum Vehicles', 'Diesel Vehicles', 'Electric Vehicles'],
        },
        {
          question: 'What is the average employee travel distance taken monthly by:-',
          questionType: 'multiple',
          options: ['Train', 'Plane', 'Car'],
        },
      ],
    },
    {
      header: 'Expenses',
      subheader: 'Packaging',
      icon: <FiPackage />,
      subpages: [
        {
          question: "Do your company's products use any packaging?",
          questionType: 'options',
          options: ['Yes Paper', 'Yes Plastic', 'Yes Metal', 'Yes Composite Materials', 'No, Not Applicable'],
        },
      ],
    },
  ];

  const onNextClicked = () => {
    if (page === pages.length - 1) {
      navigate(climateRoute);
    } else {
      setPage(page + 1);
    }
  };

  useEffect(() => {
    console.log('');
  }, [page]);

  return (
    <Container>
      <Main>
        <Header>
          {pages.map((item, index) => (
            <div key={index} onClick={() => {}} className={`header-item ${page === index ? 'active' : ''}`}>
              <p>
                {index + 1}.{item.header}
              </p>
            </div>
          ))}
        </Header>
        <div className="title">
          {pages[page].icon}
          <h1>{pages[page].subheader}</h1>
        </div>
        <div className="bg" />
        <img src={images.logoWhite} alt="Logo" className="logo" />
      </Main>
      <section className={'right'}>
        <PagesWrapper onNext={onNextClicked} questions={pages[page].subpages} page={page} />
      </section>
    </Container>
  );
};

export default ClimateQuestion;
