import React from 'react';
import { Button, Container, Wrapper } from './styles';
import { useGetUserQuery } from '../../features/user/userApiSlice';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../app/hooks';
import { openModal } from '../../features/modal/modalSlice';
import { MODAL_TYPES } from '../../modules/ModalWindow/modalTypes';
import { HiDownload } from 'react-icons/hi';

function SplasherHeader() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { data } = useGetUserQuery();
  let today = new Date();
  let curHr = today.getHours();
  let text;

  if (curHr < 12) {
    text = 'Good Morning';
  } else if (curHr < 18) {
    text = 'Good Afternoon';
  } else {
    text = 'Good Evening';
  }

  const onOpenSteps = () => {
    dispatch(openModal({ modalType: MODAL_TYPES.PROGRESS_MODAL }));
  };

  return (
    <Container>
      <Wrapper>
        <div className={'content'}>
          <div>
            <h1>
              {text}, {data.name.split(' ').slice(0, -1).join(' ')}
            </h1>
            <p>Welcome to Melanin Kapital Kijani App.</p>
          </div>
          <Button>
            <span>Download Report</span>
            <HiDownload />
          </Button>
        </div>
      </Wrapper>
    </Container>
  );
}

export default SplasherHeader;
