import styled from 'styled-components';

export const Wrapper = styled.main`
  position: relative;
  top: 9rem;
  margin-right: 1.6rem;
`;
export const Header = styled.section`
  background-color: var(--color-primary);
  height: 25rem;
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  column-gap: 2rem;
  align-items: center;
  color: white;
  border-radius: 10px;
  text-align: center;
  letter-spacing: 0.3px;
  justify-content: space-between;

  h3 {
    font-size: 2.5rem;
  }

  p {
    font-size: 1.6rem;
  }

  img {
    height: 25rem;
    object-fit: cover;

    &:last-child {
      transform: scaleX(-1);
      place-self: center end;
    }
  }
`;

export const Input = styled.div`
  background-color: #fff;
  border-radius: 10px;
  padding: 1.6rem;
  display: flex;
  align-items: center;
  margin: 2rem 0;

  input {
    flex: 1;
    outline: none;
    border: none;
    height: 100%;
    background-color: inherit;
    font-size: 1.45rem;
  }

  svg {
    width: 2rem;
    height: 2rem;
    cursor: pointer;
    fill: black;
  }
`;

export const Card = styled.div`
  border-radius: 10px;
  border: 1px solid var(--color-primary);
  padding: 2rem 1.6rem 1.6rem;
  position: relative;
  text-align: center;
  font-size: 1.6rem;
  background-color: #fff;
  display: flex;
  flex-flow: column;

  img {
    width: 7rem;
    height: 7rem;
    margin: 0 auto;
  }

  h3 {
    font-weight: 500;
    font-size: 1.8rem;
    color: var(--color-primary);
  }

  p {
    margin: 1.6rem 0;
  }
`;

export const Clip = styled.div<{ color: string }>`
  position: absolute;
  right: 0;
  top: 0;
  height: 30%;
  width: 30%;
  clip-path: circle(50% at 100% 1%);
  background-color: ${(props) => props.color};
  border-top-right-radius: 10px;
`;

export const Integrate = styled.div<{ color: string }>`
  text-transform: uppercase;
  font-size: 1.7rem;
  display: flex;
  height: 5rem;
  padding: 0.3rem 0.1rem 0.2rem;
  align-items: center;
  color: ${(props) => props.color};
  border: 1px solid ${(props) => props.color};
  cursor: pointer;
  align-self: flex-end;
  width: 100%;
  border-radius: 50px;

  p {
    flex: 1;
    margin: 0;
  }
`;

export const Arrow = styled.div<{ color: string }>`
  background-color: ${(props) => props.color};
  height: 100%;
  padding: 1.2rem;
  margin: 0.5rem 0.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;

  svg {
    width: 1.6rem;
    height: 1.6rem;
    fill: white;
  }
`;

export const CardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 3rem;
  margin-top: 2rem;
`;
