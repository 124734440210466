import styled from 'styled-components';

export const Wrapper = styled.section`
  letter-spacing: 0.4px;
`;

export const Container = styled.div`
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #707070;
  padding: 1rem 1.6rem;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  font-size: 1.6rem;
  color: var(--color-primary);
  margin-bottom: 1.6rem;

  &:first-child {
    margin-top: 1.6rem;
  }

  .chip {
    display: flex;
    column-gap: 2rem;
    align-items: center;
    font-size: 1.72rem;
    font-weight: 500;
    color: #707070;
  }
`;

export const Chip = styled.div`
  display: grid;
  place-items: center;
  padding: 1rem;
  border-radius: 50%;
  background-color: var(--color-accent);

  svg {
    fill: black;
    width: 1.8rem;
    height: 1.8rem;
  }
`;

export const Summary = styled.p`
  width: 100%;
  background-color: #fff;
  padding: 1.6rem;
  font-size: 1.6rem;
`;

export const Label = styled.p`
  font-size: 1.72rem;
  font-weight: 500;
  color: #707070;
`;

export const Socials = styled.div`
  display: grid;
  place-items: center;
  grid-template-columns: repeat(4, min-content);
  column-gap: 4rem;
  width: 100%;
  justify-content: center;

  img {
    width: auto;
    height: 4rem;
  }
`;
