import React, { createRef } from 'react';
import { Container, Label, Small, Wrapper } from '../PersonalDocuments/styles';
import { Btn, BtnDiv, Content, Note } from '../../BusinessProfile/EditProfile/styles';
import { toast, ToastContainer } from 'react-toastify';
import { useGetBusinessByIdQuery, useUpdateBusinessMutation } from '../../../features/business/businessApiSlice';
import { useGetUserQuery, useUpdateProfileMutation } from '../../../features/user/userApiSlice';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema } from './edit-docs-schema';
import { BACK_ID, FRONT_ID, MPESA_DOC } from '../../../constants/constants';
import { useUploadStatementMutation } from '../../../features/analysis/analysisSlice';
import { getDocumentItem } from '../../../utils/object-mapper';

const CompanyDocuments = ({ onClose }) => {
  const { data: user } = useGetUserQuery();
  const { data: companyData } = useGetBusinessByIdQuery(user.id);
  const [updateBusiness, { isLoading }] = useUpdateBusinessMutation();
  const [uploadStatement, { isLoading: loadingStatement }] = useUploadStatementMutation();
  const [updateProfile, { isLoading: profileLoading }] = useUpdateProfileMutation();
  const buttonContent =
    isLoading || loadingStatement || profileLoading ? <div className={'container dots-flow'} /> : 'Continue';
  const docRef = createRef();
  const doc = localStorage.getItem('doc');

  const {
    register,
    handleSubmit,
    formState: { isDirty, isValid, errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
  });

  const onSubmitHandler = async (data) => {
    try {
      if (doc === MPESA_DOC) {
        await uploadStatement(data.financial_statement[0]).unwrap();
        toast.success('Your M-PESA statement was uploaded successfully');
        onClose();
        return;
      }
      if (doc === FRONT_ID) {
        await updateProfile({ id: user.id, item: { front_ID: data.front_ID[0] } }).unwrap();
        toast.success('Your front ID was uploaded successfully');
        onClose();
        return;
      }
      if (doc === BACK_ID) {
        await updateProfile({ id: user.id, item: { back_ID: data.back_ID[0] } }).unwrap();
        toast.success('Your back ID was uploaded successfully');
        onClose();
        return;
      }

      const item = getDocumentItem(doc, data);
      const userInput = {
        item,
        id: companyData.companies[0].id,
      };
      await updateBusiness(userInput).unwrap();
      toast.success('Your business profile was updated successfully');
      onClose();
    } catch (e) {
      console.log(e);
      toast.error('Could not update \n Something went wrong!');
    }
  };

  return (
    <>
      <Content>
        <Wrapper onSubmit={handleSubmit(onSubmitHandler)} id={'edit-business-docs'}>
          <Container>
            <Label style={{ textTransform: 'capitalize' }}>
              1. Upload {doc.replace(/_/g, ' ')}.<span>(Max upload size 10mb)</span>
            </Label>
            <input type="file" name={'doc'} ref={docRef} {...register(doc)} accept=".doc, .docx, .pdf, .txt, image/*" />
            {errors[doc] && <Small>{errors[doc]?.message.toString()}</Small>}
          </Container>
        </Wrapper>
      </Content>
      <Note>
        Note: Your details will be subjected to verifications and integrity processes to validate you are a legitimate
        and legal business owner
      </Note>
      <BtnDiv>
        <Btn onClick={onClose} whileTap={{ scale: 0.97 }} whileHover={{ backgroundColor: '#f4ad3d' }}>
          Cancel
        </Btn>
        <Btn
          disabled={isLoading || !isValid || !isDirty || loadingStatement || profileLoading}
          whileTap={{ scale: 0.97 }}
          whileHover={{ backgroundColor: '#014e35' }}
          className={'green'}
          type={'submit'}
          form={'edit-business-docs'}>
          {buttonContent}
        </Btn>
      </BtnDiv>
      <ToastContainer autoClose={1000} />
    </>
  );
};

export default CompanyDocuments;
