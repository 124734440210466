// @ts-nocheck

import React, { useEffect } from 'react';
import { Close, Container, Header, HeaderActions } from '../LoanModal/styles';
import { IContainer, Input, Label, Wrapper } from '../../../../components/LoanModal/PersonalDetails/styles';
import Select from 'react-select';
import Modal from 'react-modal';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { closeModal, selectCurrentModal } from '../../../../features/modal/modalSlice';
import { MODAL_TYPES } from '../../modalTypes';
import { useGetUserQuery, useUpdateProfileMutation } from '../../../../features/user/userApiSlice';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Btn, BtnDiv } from '../../../../components/BusinessProfile/EditProfile/styles';
import { Controller, useForm } from 'react-hook-form';
import { User } from '../../../../types/user';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema } from './edit-user-schema';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactPhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { getCountries } from '../../../../utils/object-mapper';

const genders = [
  { value: 'female', label: 'Female' },
  { value: 'male', label: 'Male' },
  { value: 'n/a', label: 'N/A' },
];
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    border: 'none',
    padding: 0,
    width: '70%',
    borderRadius: '10px',
    height: 'auto',
    minHeight: '60vh',
  },
};
const EditUserModal = () => {
  const [updateProfile, { isLoading }] = useUpdateProfileMutation();
  const { data: user } = useGetUserQuery();
  let [firstname, lastname] = user.name.match(/(\w+)\s(\w+)/).slice(1);
  const preloadedData = {
    firstName: firstname,
    lastName: lastname,
    email: user.email,
    phone: user.phone,
    gender: user.gender,
  };

  const modalType = useAppSelector(selectCurrentModal);
  const dispatch = useAppDispatch();
  const isOpen = modalType === MODAL_TYPES.EDIT_USER_MODAL;

  const buttonContent = isLoading ? <div className={'container dots-flow'} /> : 'Continue';

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors, isDirty, isValid },
  } = useForm<User>({
    resolver: yupResolver(schema),
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      gender: '',
      linkedin: '',
      nationality: '',
    },
    mode: 'all',
  });

  useEffect(() => {
    reset(preloadedData);
  }, [user]);

  const onSubmitClose = () => {
    dispatch(closeModal());
  };
  const onSubmitHandler = async (data) => {
    const { firstName, lastName, date_of_birth, ...cleanData } = data;

    const userInput = {
      item: {
        name: `${firstName} ${lastName}`,
        date_of_birth: data.date_of_birth.toLocaleDateString(),
        ...cleanData,
      },
      id: user.id,
    };
    try {
      await updateProfile(userInput).unwrap();
      onSubmitClose();
    } catch (e) {
      console.log(e);
      toast.error('Could not update \n Something went wrong!');
    }
  };

  return (
    <Modal
      onRequestClose={() => {}}
      isOpen={isOpen}
      style={customStyles}
      ariaHideApp={false}
      overlayClassName="Overlay">
      <Header>
        <h2>Edit your personal details</h2>
        <HeaderActions>
          <Close onClick={onSubmitClose} />
        </HeaderActions>
      </Header>
      <Container>
        <div>
          <div>
            <Wrapper>
              <form onSubmit={handleSubmit(onSubmitHandler)}>
                <IContainer rows={4}>
                  <div className={'cont'}>
                    <Label>Firstname</Label>
                    <Input type={'text'} {...register('firstName')} />
                    {errors.firstName?.message && <small>{errors.firstName?.message}</small>}
                  </div>
                  <div className={'cont'}>
                    <Label>Lastname</Label>
                    <Input type={'text'} {...register('lastName')} />
                    {errors.lastName?.message && <small>{errors.lastName?.message}</small>}
                  </div>
                  <div className={'cont'}>
                    <Label>Email Address</Label>
                    <Input type={'email'} value={user.email} readOnly={true} />
                  </div>
                  <div className={'cont'}>
                    <Label>Date of birth</Label>
                    <Controller
                      name={'date_of_birth'}
                      control={control}
                      defaultValue={new Date(user.date_of_birth)}
                      render={({ field }) => (
                        <DatePicker
                          dateFormat={'yyyy/MM/dd'}
                          onChange={(date) => {
                            field.onChange(date);
                          }}
                          selected={field.value}
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                        />
                      )}
                    />
                  </div>
                  <div className={'cont'}>
                    <Label>Gender</Label>
                    <Controller
                      control={control}
                      render={({ field: { onChange, value, name, ref } }) => {
                        return (
                          <Select
                            value={genders.find((c: any) => c.value === value)}
                            name={name}
                            options={genders}
                            onChange={(selectedGender: any) => {
                              onChange(selectedGender.value);
                            }}
                            styles={{
                              container: (base) => ({
                                ...base,
                                fontSize: '1.6rem',
                              }),
                              control: (base) => ({
                                ...base,
                                fontSize: '1.6rem',
                                height: '5.4rem',
                              }),
                            }}
                            isSearchable
                            theme={(theme) => ({
                              ...theme,
                              colors: {
                                ...theme.colors,
                                primary25: '#cdd5f7',
                                primary: '#FFAB00',
                                neutral20: '#707070',
                              },
                            })}
                            placeholder={'Select Gender'}
                          />
                        );
                      }}
                      name={'gender'}
                      rules={{
                        required: true,
                      }}
                    />
                    {errors.gender?.message && <small>{errors.gender?.message}</small>}
                  </div>
                  <div className={'cont'}>
                    <Label>Phone No</Label>
                    <Controller
                      control={control}
                      name="phone"
                      rules={{ required: true }}
                      render={({ field: { ref, ...field } }) => (
                        <ReactPhoneInput
                          {...field}
                          inputProps={{
                            ref,
                            required: true,
                          }}
                          country={'ke'}
                          countryCodeEditable={false}
                          enableSearch={true}
                          regions={['america', 'europe', 'asia', 'oceania', 'africa']}
                        />
                      )}
                    />
                    {errors.phone?.message && <small>{errors.phone?.message}</small>}
                  </div>
                  <div className={'cont'}>
                    <Label>Country*</Label>
                    <Controller
                      control={control}
                      render={({ field: { onChange, value, name, ref } }) => {
                        return (
                          <Select
                            value={getCountries.find((c: any) => c.value === value)}
                            name={name}
                            options={getCountries}
                            onChange={(selectedOption: any) => {
                              onChange(selectedOption.value);
                            }}
                            styles={{
                              container: (base) => ({
                                ...base,
                                fontSize: '1.6rem',
                              }),
                              control: (base) => ({
                                ...base,
                                fontSize: '1.6rem',
                                height: '5.4rem',
                              }),
                            }}
                            isSearchable
                            theme={(theme) => ({
                              ...theme,
                              colors: {
                                ...theme.colors,
                                primary25: '#cdd5f7',
                                primary: '#FFAB00',
                                neutral20: '#707070',
                              },
                            })}
                            placeholder={'Please choose the country where you hold citizenship'}
                          />
                        );
                      }}
                      name={'nationality'}
                      rules={{
                        required: true,
                      }}
                    />
                    {errors.nationality?.message && <small>{errors.nationality?.message}</small>}
                  </div>
                </IContainer>
                <BtnDiv>
                  <Btn onClick={onSubmitClose} whileTap={{ scale: 0.97 }} whileHover={{ backgroundColor: '#f4ad3d' }}>
                    Cancel
                  </Btn>
                  <Btn
                    disabled={isLoading || !isDirty}
                    whileTap={{ scale: 0.97 }}
                    whileHover={{ backgroundColor: '#014e35' }}
                    className={'green'}
                    type={'submit'}>
                    {buttonContent}
                  </Btn>
                </BtnDiv>
              </form>
            </Wrapper>
          </div>
        </div>
      </Container>
      <ToastContainer />
    </Modal>
  );
};

export default EditUserModal;
