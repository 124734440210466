import React, { useEffect, useState } from 'react';
import { Chip, Filter, Head, Header, Row, Status, Table } from './styles';
import { BsFilter } from 'react-icons/bs';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';

const data2 = [
  {
    info: {
      vendor: 'Stima Boda',
      logo: 'https://firebasestorage.googleapis.com/v0/b/nectar-f5ee3.appspot.com/o/dashboard%2Fstima-boda.png?alt=media&token=877efbc3-781c-41e4-a745-4ade3a8d6860',
      model: 'SUZUKI GSX-R600',
    },
    asset:
      'https://firebasestorage.googleapis.com/v0/b/nectar-f5ee3.appspot.com/o/bike.png?alt=media&token=0be548e4-a7b1-447c-8194-85448b88fb79',
    price: 300000,
    quantity: 1,
    requested: '2023-03-01',
    approved: '2023-03-01',
    status: 'Approved',
  },
  {
    info: {
      vendor: 'Stima Boda',
      logo: 'https://firebasestorage.googleapis.com/v0/b/nectar-f5ee3.appspot.com/o/dashboard%2Fstima-boda.png?alt=media&token=877efbc3-781c-41e4-a745-4ade3a8d6860',
      model: 'SUZUKI GSX-R600',
    },
    asset:
      'https://firebasestorage.googleapis.com/v0/b/nectar-f5ee3.appspot.com/o/bike.png?alt=media&token=0be548e4-a7b1-447c-8194-85448b88fb79',
    price: 400000,
    quantity: 1,
    requested: '2023-05-01',
    approved: '---',
    status: 'Pending',
  },
];

const statuses = [
  { value: 'all', label: 'All' },
  { value: 'approved', label: 'Approved' },
  { value: 'pending', label: 'Pending' },
];
const AssetRequests = () => {
  const tabs = ['All Loans', 'Repayments'];
  const [selectedOption, setSelectedOption] = useState(null);

  const navigate = useNavigate();

  const handleChange = (option) => {
    setSelectedOption(option);
  };
  const [startDate, setStartDate] = useState(null);
  const [price, setPrice] = useState(null);
  const [selectedTab, setSelectedTab] = useState(tabs[0]);
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  let itemsPerPage = 6;

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(data2.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(data2.length / itemsPerPage));
  }, [itemOffset, itemsPerPage]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % data2.length;
    setItemOffset(newOffset);
  };

  const [isFilter, setIsFilter] = useState(false);
  const filterData = () => {
    let filteredData = data2;
    if (selectedOption) {
      if (selectedOption.value === 'approved') {
        filteredData = filteredData.filter((item) => item.status === 'Approved');
      } else if (selectedOption.value === 'pending') {
        filteredData = filteredData.filter((item) => item.status === 'Pending');
      }
    }
    if (startDate) {
      filteredData = filteredData.filter((item) => new Date(item.requested) >= startDate);
    }
    if (price) {
      filteredData = filteredData.filter((item) => item.price >= price);
    }
    return filteredData;
  };

  const handleFilter = () => {
    if (isFilter) {
      setSelectedOption(null);
      setStartDate(null);
      setPrice(null);
    } else {
      const filteredData = filterData();
      setCurrentItems(filteredData);
      setPageCount(Math.ceil(filteredData.length / itemsPerPage));
    }
    setIsFilter(!isFilter);
  };

  return (
    <>
      <Header>
        <h3>Asset Requests</h3>
        <Filter>
          <BsFilter className={`icon ${isFilter ? 'active' : ''}`} onClick={handleFilter} />
          <input className={'input'} type="number" placeholder={'Asset Price'} />
          <Select
            value={selectedOption}
            onChange={handleChange}
            options={statuses}
            styles={{
              container: (base) => ({
                ...base,
                fontSize: '1.4rem',
                height: '4.8rem',
                borderColor: '#707070',
              }),
              control: (base) => ({
                ...base,
                fontSize: '1.4rem',
              }),
            }}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: '#cdd5f7',
                primary: '#FFAB00',
                neutral20: '#707070',
              },
            })}
            placeholder={'Status'}
          />
          <DatePicker
            dateFormat={'yyyy/MM/dd'}
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            placeholderText="Date"
          />
        </Filter>
      </Header>
      <Table>
        <Head>
          <tr>
            <th>Asset Information</th>
            <th>Asset</th>
            <th>Price (KSH)</th>
            <th>Quantity</th>
            <th>Requested Date</th>
            <th>Approval Date</th>
            <th>Approval Status</th>
            <th>Actions</th>
          </tr>
        </Head>
        <tbody>
          {currentItems &&
            currentItems.map((data, index) => {
              let color;
              if (data.status === 'Approved') color = '#018158';
              return (
                <Row key={index}>
                  <td className={'info'}>
                    <div className="image">
                      <img src={data.info.logo} alt="Vendor logo" />
                    </div>
                    <div>
                      <h4>{data.info.vendor}</h4>
                      <p>{data.info.model}</p>
                    </div>
                  </td>
                  <td className={'asset'}>
                    <img src={data.asset} alt="Asset photo" />
                  </td>
                  <td>{data.price}</td>
                  <td>{data.quantity}</td>
                  <td>{data.requested}</td>
                  <td>{data.approved}</td>
                  <td>
                    <Status color={color}>{data.status}</Status>
                  </td>
                  <td>{data.status === 'Approved' ? <Chip>Pay</Chip> : '---'}</td>
                </Row>
              );
            })}
        </tbody>
      </Table>
    </>
  );
};

export default AssetRequests;
