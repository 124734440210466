import React from 'react';
import { Chip, Container, Label, Socials, Summary } from './styles';
import { SlLocationPin } from 'react-icons/sl';
import { RiContactsBook2Line } from 'react-icons/ri';
import { BsGlobe2, BsPeople, BsTelephone } from 'react-icons/bs';
import { MdOutlineMailOutline } from 'react-icons/md';
import { useGetBusinessDetailsQuery } from '../../../features/business/businessApiSlice';
import MeetupLoader from '../../Loaders/MeetupLoader';
import images from '../../../constants';
import { selectAllCategories, useGetCategoriesQuery } from '../../../features/categories/categories';
import { useSelector } from 'react-redux';
import { IoIosSettings } from 'react-icons/io';

const regex = /^(https?:\/\/)/i;
const checkIfLink = (link: any) => {
  if (link && link.length > 0) {
    return `//${link?.replace(regex, '')}`;
  } else {
    return null;
  }
};

const CompanyDetails = ({ companyData, bussId, userId, onSloganSet }) => {
  const { data: extraData, isLoading: loading, isSuccess } = useGetBusinessDetailsQuery(bussId);
  const { isLoading, isSuccess: categorySuccess } = useGetCategoriesQuery();
  const categories = useSelector(selectAllCategories);
  let details = [];
  if (loading || isLoading) {
    return <MeetupLoader />;
  } else if (isSuccess && categorySuccess) {
    const company = companyData.companies[0];
    const profileData = {
      userId: userId,
      businessId: bussId,
      companyName: company.name,
      category_id: company.category_id,
      type_of_company: extraData?.type_of_company,
      office_location: extraData?.office_location,
      phone: extraData?.office_phone,
      email: extraData?.office_email,
      description: company.description,
      linkedin: extraData?.linkedin,
      facebook: extraData?.facebook,
      twitter: extraData?.twitter,
      company_website: extraData?.company_website,
    };
    localStorage.setItem('profileData', JSON.stringify(profileData));
    onSloganSet(extraData?.type_of_company);
    const category = categories.find((category: any) => category.id === company.category_id) as any;
    details = [
      {
        label: 'Company name:',
        icon: <RiContactsBook2Line />,
        value: companyData.companies[0].name,
      },
      {
        label: 'Type of company:',
        icon: <BsPeople />,
        value: <span style={{ textTransform: 'capitalize' }}>{extraData?.type_of_company}</span>,
      },
      {
        label: 'Sector:',
        icon: <IoIosSettings />,
        value: category?.name,
      },
      {
        label: 'Office location:',
        icon: <SlLocationPin />,
        value: extraData?.office_location,
      },
      {
        label: 'Phone:',
        icon: <BsTelephone />,
        value: extraData?.office_phone,
      },
      {
        label: 'Email address:',
        icon: <MdOutlineMailOutline />,
        value: extraData?.office_email,
      },
      {
        label: 'Company Website',
        icon: <BsGlobe2 />,
        value:
          extraData?.company_website && extraData?.company_website.length > 0 ? (
            <a
              style={{ color: 'var(--color-primary)' }}
              href={checkIfLink(extraData?.company_website)}
              target={'_blank'}
              referrerPolicy={'no-referrer'}>
              Visit
            </a>
          ) : (
            ''
          ),
      },
    ];
  }

  return (
    <>
      <div>
        {details.map((item, index) => (
          <Container key={index}>
            <div className="chip">
              <Chip>{item.icon}</Chip>
              <p>{item.label}</p>
            </div>
            <p>{item.value}</p>
          </Container>
        ))}
        <Label>Business History</Label>
        <Summary>{companyData.companies[0]?.description}</Summary>
        <Label>Social media:</Label>
        <Socials>
          <a href={checkIfLink(extraData?.linkedin)} target="_blank" referrerPolicy={'no-referrer'}>
            <img
              src="https://firebasestorage.googleapis.com/v0/b/nectar-f5ee3.appspot.com/o/socials%2FLinkedIn_Logo.svg?alt=media&token=ad595068-b78d-42fa-9e5a-f616a0ad7481"
              alt="LinkedIn"
            />
          </a>
          <a href={checkIfLink(extraData?.twitter)} target="_blank" referrerPolicy={'no-referrer'}>
            <img
              src="https://firebasestorage.googleapis.com/v0/b/nectar-f5ee3.appspot.com/o/socials%2FTwitter-logo.svg?alt=media&token=3c0fd2af-6532-4307-852f-c92aa0076bf6"
              alt="Twitter"
            />
          </a>
          <a href={checkIfLink(extraData?.facebook)} target="_blank" referrerPolicy={'no-referrer'}>
            <img
              src="https://firebasestorage.googleapis.com/v0/b/nectar-f5ee3.appspot.com/o/socials%2F2021_Facebook_icon.svg?alt=media&token=3c41d5e1-3019-4239-9ccb-3dde1b0c4f9a"
              alt="Facebook"
            />
          </a>
          <a href={checkIfLink(extraData?.instagram)} target="_blank" referrerPolicy={'no-referrer'}>
            <img src={images.instagram} alt="Instagram" />
          </a>
        </Socials>
      </div>
    </>
  );
};

export default CompanyDetails;
