export interface Lesson {
  id: number;
  course_id: string;
  video_url: string;
  duration: string;
  created_by: number;
  status: null | string;
  created_at: Date;
  updated_at: Date;
}

export interface ICourse {
  id?: number;
  name?: string;
  description?: null;
  category?: null;
  createdBy?: number;
  updatedBy?: null;
  deletedBy?: null;
  status?: null;
  createdAt?: Date;
  updatedAt?: Date;
  deletedAt?: null;
}

export const LESSONS_TAG = 'Lessons' as const;
export const COURSES_TAG = 'courses' as const;
export const CATEGORIES = 'categories' as const;

export const LOANS_TAG = 'loans' as const;

export const BUSINESSES_TAG = 'businesses' as const;

export const POSTS = 'posts' as const;

export const LOAN_PRODUCTS = 'loan_products' as const;

export const LOAN_TRANSACTIONS = 'loan_transactions' as const;

export const TICKETS = 'tickets' as const;

export const USER = 'user' as const;
export const ANALYSIS = 'analysis' as const;
export const ENROLL = 'enroll' as const;

export const BUILT_ACC = 'built_acc' as const;
export const CONNECTS = 'connects' as const;

export const REQUESTS = 'requests' as const;

export const PENDING = 'pending' as const;

export const ACCEPTED = 'accepted' as const;
export const OPTIONS = 'options' as const;
