import styled from 'styled-components';

export const PostContainer = styled.div`
  background-color: #fff;
  height: 100%;
  display: flex;
  flex-direction: column;
  //justify-content: space-between;
  border-radius: 10px;
  position: relative;
`;

export const ModalContainer = styled.section`
  display: grid;
  //grid-template-columns: 35rem 1fr;
  column-gap: 1rem;
  height: 100%;
`;
export const PostImage = styled.img`
  float: left;
  border-radius: 10px;
  object-fit: contain;
  object-position: top center;
  width: auto;
  height: 30rem;
  margin-bottom: 1rem;
`;

export const PostHeader = styled.div`
  padding: 1rem 2rem;
  margin: 0 -2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: -3rem;
  background-color: #fff;
  z-index: 2;

  div {
    display: flex;
    align-items: center;
    column-gap: 1rem;
    margin-left: 1rem;
  }

  svg {
    height: 2.16rem;
    width: 2.16rem;
    cursor: pointer;
    transition: all 0.3s ease-in-out;

    &:hover {
      background-color: #f1efef;
      transform: scale(1.1);
      border-radius: 50%;
      padding: 0.8rem;
      box-sizing: content-box;
    }
  }
`;

export const Avatar = styled.img`
  width: 4rem;
  height: auto;
  border-radius: 50%;
`;

export const UserName = styled.p`
  span {
    color: #707070;
    margin-left: 1rem;
  }

  font-size: 1.36rem;
  letter-spacing: 0.41px;
`;

export const Organization = styled.p`
  font-size: 1.2rem;
  letter-spacing: 0.36px;
`;

export const HRule = styled.hr`
  margin: 1rem 0;
  border: none;
  width: 100%;
  height: 1px;
  background-color: #e4e4e4;
`;

export const PostText = styled.p`
  font-size: 1.6rem;
  letter-spacing: 0.48px;
`;
