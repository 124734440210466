import styled from 'styled-components';
import { AiOutlineClose, AiOutlineLeftSquare } from 'react-icons/ai';
import images from '../../../../constants';
import { motion } from 'framer-motion';

export const Wrapper = styled.section`
  border: 1px solid #707070;
  border-radius: 5px;
  padding: 1.6rem;
  margin-right: 6rem;
  margin-left: 3rem;
`;
export const Header = styled.section`
  background-color: var(--color-primary);
  border-radius: 10px 10px 0 0;
  padding: 1.6rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.6rem;
  letter-spacing: 0.3px;
  color: white;
`;
export const HeaderActions = styled.div`
  display: flex;
  column-gap: 3rem;
  justify-content: space-around;
  align-items: center;
`;
export const Icon = styled.div`
  padding: 0.8rem;
  background-color: var(--color-accent);
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: max-content;

  svg {
    width: 2rem;
    height: 2rem;
    fill: black;
  }
`;
export const Action = styled.div`
  display: flex;
  column-gap: 0.7rem;
  align-items: center;
  text-transform: uppercase;
  font-size: 1.72rem;
`;
export const Close = styled(AiOutlineClose)`
  width: 3rem;
  height: 3rem;
  cursor: pointer;
  fill: white;
`;
export const TopBar = styled.div`
  margin-left: -2rem;
  display: flex;
  column-gap: 2rem;
  margin-right: 6rem;
  align-items: flex-start;

  div {
    flex: 1;
  }

  h4 {
    font-weight: 500;
    font-size: 1.72rem;

    span {
      font-weight: lighter;
      color: #707070;
      font-size: 1.5rem;
      margin-left: 0.5rem;
    }
  }
`;
export const Back = styled(AiOutlineLeftSquare)`
  fill: var(--color-primary);
  cursor: pointer;
  width: 3rem;
  height: 3rem;
`;
export const Bar = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  column-gap: 0.4rem;
  margin-top: 1rem;
  margin-bottom: 2rem;

  hr {
    background-color: #d9d7d7;
    border-radius: 5px;
    border: none;
    height: 4px;

    &.active {
      background-color: var(--color-accent);
    }
  }
`;
export const Container = styled.div`
  position: relative;

  .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 2rem;
    margin-bottom: 1rem;
  }

  .scrollable {
    //height: 70vh;

    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 3px;
      height: 1px;
    }

    &::-webkit-scrollbar-track {
      background-color: #707070;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--color-accent);
      border-radius: 10px;
    }
  }

  & > :first-child {
    padding-left: 3rem;
    padding-top: 1.6rem;
    background-color: #fff;
  }
`;

export const Requirements = styled.div`
  background-image: url(${images.requirements});
  background-position: top center;
  background-size: cover;
  position: relative;
  height: 100%;
`;

export const ReqBtn = styled(motion.button)`
  position: absolute;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: white;
  font-size: 1.72rem;
  border: 1.6px solid white;
  background: transparent;
  border-radius: 10px 0 10px 0;
  padding: 1.6rem 3rem;
  bottom: 0;
  right: 0;

  svg {
    width: 1.75rem;
    height: 1.75rem;
    fill: white;
    margin-left: 3rem;
  }
`;

export const Continue = styled(motion.button)`
  padding: 2rem 6rem;
  background-color: var(--color-primary);
  border-radius: 10px;
  color: white;
  text-transform: uppercase;
`;
