import React from 'react';
import styles from '../Auth.module.scss';
import images from '../../../constants';
import { useNavigate } from 'react-router-dom';
import { schema } from './schema';
import { yupResolver } from '@hookform/resolvers/yup';
import { ISignUpData } from '../../../types/user';
import { Controller, useForm } from 'react-hook-form';
import { motion } from 'framer-motion';
import pageRoutes from '../../../constants/pageRoutes';
import { useRegisterUserMutation } from '../../../features/auth/authApiSlice';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { TERMS_AND_CONDITIONS } from '../../../constants/constants';
import Select from 'react-select';
import { programsList } from '../../../utils/object-mapper';

const Register = () => {
  const navigate = useNavigate();
  const [registerUser, { isLoading }] = useRegisterUserMutation();

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isDirty, isValid },
  } = useForm<ISignUpData>({
    resolver: yupResolver(schema),
    mode: 'all',
  });

  const onSubmitHandler = async (data: ISignUpData) => {
    const { firstName, lastName, confirmPassword, ...extraData } = data;
    const userInput = {
      name: `${firstName} ${lastName}`,
      role_type: 'ent',
      ...extraData,
    };
    try {
      await registerUser(userInput).unwrap();
      navigate(`/${pageRoutes.SIGN_IN}`);
    } catch (err: any) {
      if (err.data.response.errors.email.length > 0) {
        toast.error('You have already subscribed');
      } else {
        toast.error('Something went wrong!');
      }
    }
  };

  const buttonContent = isLoading ? <div className={'container dots-flow'} /> : 'Sign up';

  return (
    <motion.div className={styles.main} initial={{ opacity: 0, scale: 0.5 }} animate={{ opacity: 1, scale: 1 }}>
      <img className={styles.logo} src={images.logo} alt="M kapital logo" />
      <h2>
        Let's get started on your
        <br />
        Sign up process
      </h2>
      <p className={styles.welcome}>Welcome back! Kindly provide the following to sign up</p>
      <form onSubmit={handleSubmit(onSubmitHandler)}>
        <div className={styles.names}>
          <div className={styles.input_container}>
            <input
              autoFocus={true}
              className={styles.text_input}
              {...register('firstName')}
              placeholder={'First name'}
            />
            {errors.firstName?.message && <small>{errors.firstName?.message}</small>}
          </div>
          <div className={styles.input_container}>
            <input className={styles.text_input} {...register('lastName')} placeholder={'Last name'} />
            {errors.lastName?.message && <small>{errors.lastName?.message}</small>}
          </div>
        </div>
        <div className={styles.input_container}>
          <input className={styles.text_input} type={'email'} {...register('email')} placeholder={'Email'} />
          {errors.email?.message && <small>{errors.email?.message}</small>}
        </div>
        <div className={styles.input_container}>
          <input className={styles.text_input} type={'password'} {...register('password')} placeholder={'Password'} />
          {errors.password?.message && <small>{errors.password?.message}</small>}
        </div>
        <div className={styles.input_container}>
          <input
            className={styles.text_input}
            type={'password'}
            {...register('confirmPassword')}
            placeholder={'Confirm password'}
          />
          {errors.confirmPassword?.message && <small>{errors.confirmPassword?.message}</small>}
        </div>
        <div>
          <Controller
            control={control}
            render={({ field: { onChange, value, name } }) => {
              return (
                <Select
                  value={programsList.find((c: any) => c.value === value)}
                  name={name}
                  options={programsList}
                  onChange={(selectedOption: any) => {
                    onChange(selectedOption.value);
                  }}
                  styles={{
                    container: (base) => ({
                      ...base,
                      fontSize: '1.6rem',
                    }),
                    control: (base) => ({
                      ...base,
                      fontSize: '1.6rem',
                      height: '5.4rem',
                    }),
                  }}
                  isSearchable
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: '#cdd5f7',
                      primary: '#FFAB00',
                      neutral20: '#707070',
                    },
                  })}
                  placeholder={'Select the program'}
                />
              );
            }}
            name={'program'}
            rules={{
              required: true,
            }}
          />
          {errors.program?.message && <small>{errors.program?.message}</small>}
        </div>
        <p style={{ marginTop: '2rem' }} className={styles.policies}>
          By continuing you agree to the{' '}
          <a target={'_blank'} href={TERMS_AND_CONDITIONS}>
            Terms & Conditions{' '}
          </a>
          of Melanin Kapital
        </p>
        <motion.button
          type={'submit'}
          disabled={!isDirty || !isValid || isLoading}
          whileHover={{ scale: 1.02, backgroundColor: '#014e35' }}
          whileTap={{ scale: 0.9 }}
          className={styles.sign_in_btn}>
          {buttonContent}
        </motion.button>
      </form>
      <p className={styles.option}>
        Already have an account? <span onClick={() => navigate(`/${pageRoutes.SIGN_IN}`)}>Sign In</span>
      </p>
      <ToastContainer autoClose={1000} />
    </motion.div>
  );
};

export default Register;
