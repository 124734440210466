import styled from 'styled-components';

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  column-gap: 2rem;
  position: relative;
  top: 7rem;
  letter-spacing: 0.43px;
  line-height: 2rem;
  padding: 1.6rem;

  .title {
    font-weight: bold;
    font-size: 2rem;
    margin-bottom: 1.6rem;

    span {
      text-decoration: underline;
      color: var(--color-primary);
      margin-right: 0.5rem;
    }
  }

  .header {
    font-size: 1.8rem;
    margin: 1.6rem 0;
    font-weight: 500;
  }
`;

export const SideBar = styled.section`
  height: 100%;
  background-color: #fff;

  .main {
    position: sticky;
    margin-top: -6rem;
    top: 0;
    align-self: flex-start;
    padding: 1.6rem;
  }
`;

export const Quiz = styled.div`
  display: grid;
  grid-template-columns: 3rem 1fr;
  column-gap: 2.5rem;
  font-size: 1.6rem;
  margin-bottom: 2rem;
  padding-bottom: 1.6rem;
  color: #707070;
  border-bottom: 1px solid #707070;

  .chip {
    display: grid;
    place-items: center;
    border-radius: 50%;
    font-size: 2rem;
    font-weight: bold;
    width: 4rem;
    height: 4rem;
    color: #000;
    background-color: var(--color-accent);
  }
`;

export const Topic = styled.h3`
  font-weight: bold;
  font-size: 2rem;
  margin-bottom: 1.6rem;
  margin-top: 6rem;

  &::after {
    display: block;
    clear: both;
    content: '';
    position: relative;
    left: 0;
    bottom: 0;
    height: 1px;
    width: 65%;
    border-radius: 10px;
    border-bottom: 1px solid var(--color-primary);
    margin: 0;
  }
`;
