import { apiSlice } from '../../app/api/apiSlice';

const faqsApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getAllFaqs: build.query<any, void>({
      query: () => `/faqs/list`,
      transformResponse: (rawResult: any) => rawResult?.response?.data,
    }),
  }),
});

export const { useGetAllFaqsQuery } = faqsApiSlice;
