import styled from 'styled-components';
import { IContainer } from '../PersonalDetails/styles';

export const Wrapper = styled.div``;

export const TArea = styled.textarea`
  outline: none;
  border: 1px solid #707070;
  border-radius: 5px;
  width: 100%;
  max-height: 8rem;
  padding: 1.6rem;
  margin: 1.6rem 0;
`;

export const Socials = styled.div`
  display: flex;
  align-items: center;
  column-gap: 2rem;
  margin-bottom: 1.6rem;

  svg {
    width: 3rem;
    height: 3rem;
  }

  input {
    flex: 1;
    border-radius: 5px;
    padding: 1.6rem;
    background-color: transparent;
    outline: none;
    border: 1px solid #707070;
  }
`;
