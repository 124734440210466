import React, { useState } from 'react';
import styles from '../RequestList.module.scss';
import {
  selectAllPending,
  useAcceptRequestMutation,
  useGetPendingQuery,
} from '../../../../features/connect/pendingApiSlice';
import { useAppSelector } from '../../../../app/hooks';
import { getDocuments } from '../../../../utils/object-mapper';
import MeetupLoader from '../../../Loaders/MeetupLoader';
import { toast } from 'react-toastify';

const imageUrl =
  'https://thumbs.dreamstime.com/b/default-avatar-profile-icon-vector-social-media-user-portrait-176256935.jpg';
const MyRequests = () => {
  const { isLoading: loading, isSuccess, isError, error } = useGetPendingQuery();
  const allPending = useAppSelector(selectAllPending) as any;
  const [acceptRequest, { isLoading }] = useAcceptRequestMutation();
  const [selected, setSelected] = useState(0);

  if (isError) {
    console.log(error);
    return <div>Something went wrong</div>;
  } else if (loading) {
    return <MeetupLoader />;
  }

  if (allPending.length === 0) {
    return <div className={styles.no_requests}>No pending requests</div>;
  }

  const buttonContent = (id) => (isLoading && id === selected ? <div className={'container dots-flow'} /> : 'Accept');

  const onAccept = async (id) => {
    setSelected(id);
    try {
      await acceptRequest(id).unwrap();
      toast.success('Confirmed');
    } catch (e: any) {
      console.log(e);
      toast.error(e.data.message);
    }
  };

  return (
    <div className={styles.requests}>
      {allPending.map((user) => {
        const avatar = getDocuments(['avatar'], user?.follower?.media)[0]?.original_url;
        return (
          <article key={user.id} className={styles.requests_card}>
            <img
              className={styles.speaker}
              src={`${avatar === '' || avatar === undefined ? imageUrl : avatar}`}
              alt="Konnect user"
            />
            <div className={styles.requests_card_content}>
              <h4>{user?.follower.name}</h4>
            </div>
            <button onClick={() => onAccept(user.id)}>{buttonContent(user.id)}</button>
            <button className={styles.decline}>Decline</button>
          </article>
        );
      })}
    </div>
  );
};

export default MyRequests;
