import { createEntityAdapter, createSelector } from '@reduxjs/toolkit';
import { Lesson, LESSONS_TAG } from '../../types/api-responses';
import { apiSlice } from '../../app/api/apiSlice';

const lessonsAdapter = createEntityAdapter<Lesson>({
  selectId: (lesson) => lesson.id,
});
const initialState = lessonsAdapter.getInitialState();

const lessonApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getLessons: build.query<any, string>({
      query: (id) => `/lms/course/lessons/${id}`,

      transformResponse: (rawResult: any) => {
        return lessonsAdapter.setAll(initialState, rawResult?.response);
      },
      providesTags: (result) =>
        result
          ? [...result.ids.map((id) => ({ type: LESSONS_TAG, id })), { type: LESSONS_TAG, id: 'LIST' }]
          : [{ type: LESSONS_TAG, id: 'LIST' }],
    }),
  }),
});

export const { useGetLessonsQuery } = lessonApiSlice;

export const selectLessonsResult = (id) => lessonApiSlice.endpoints.getLessons.select(id);
const selectLessonsData = (id: string): any =>
  createSelector(selectLessonsResult(id), (lessonsResult) => lessonsResult?.data);

export const {
  selectAll: selectAllLessons,
  // @ts-ignore
} = lessonsAdapter.getSelectors((state) => selectLessonsData(state) ?? initialState);
