import React from 'react';
import { Container, DocAsset, DocumentsContainer, Header } from './styles';
import images from '../../../constants';
import { motion } from 'framer-motion';

const documents = [
  {
    name: 'Pitch deck template',
    url: 'https://slidebean.com/pitch-deck-template',
  },
  {
    name: 'Business plan template',
    url: 'https://nw.mercycorps.org/sites/default/files/2021-07/United-States-MCNW-Business_Plan_Template2016%20%282%29.pdf',
  },
  {
    name: 'Budget template',
    url: 'https://create.microsoft.com/en-us/templates/budgets',
  },
];
const HelpfulDocs = ({}) => {
  const onView = (url: string) => {
    window.open(url, '_blank');
  };

  return (
    <DocumentsContainer>
      <Header>Helpful Documents</Header>
      <div className="docs">
        {documents.map((doc, index) => (
          <Container key={index}>
            <div className="strip" />
            <DocAsset>
              <img src={images.fileTemplate} alt="document" />
            </DocAsset>
            <div className="content">
              <p className="title">{doc.name}</p>
              <div className="btns">
                <motion.button onClick={() => onView(doc.url)} className="view_btn">
                  View
                </motion.button>
                <motion.button onClick={() => onView(doc.url)} className="download_btn">
                  Download
                </motion.button>
              </div>
            </div>
          </Container>
        ))}
      </div>
    </DocumentsContainer>
  );
};

export default HelpfulDocs;
