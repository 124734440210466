import React from 'react';
import { StyledEmptyState } from './styles';
import { Header } from '../Documents/styles';

const EmptyState = ({ content }) => {
  const { header, title, subtitle, imageUrl } = content;

  return (
    <StyledEmptyState>
      <Header>{header}</Header>
      <img className={'upload'} src={imageUrl} alt={`Empty ${header} state`} />
      <p className="title">{title}</p>
      <p className="subtitle">{subtitle}</p>
    </StyledEmptyState>
  );
};

export default EmptyState;
