import React from 'react';
import { Container, Label, Wrapper } from '../PersonalDocuments/styles';
import DragNDrop from '../DragNDrop/DragNDrop';
import { Btn, BtnDiv, Content, Note } from '../../BusinessProfile/EditProfile/styles';
import { ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useCreateBusinessMutation } from '../../../features/business/businessApiSlice';

const CompanyDocumentsMd = ({}) => {
  const navigate = useNavigate();
  const [createBusiness, { isLoading }] = useCreateBusinessMutation();
  const buttonContent = isLoading ? <div className={'container dots-flow'} /> : 'Continue';
  return (
    <>
      <Content>
        <Wrapper>
          <Container>
            <Label>
              1. Upload Your Business Registration Document<sup>*</sup>
            </Label>
            <DragNDrop />
          </Container>
          <Container>
            <Label>
              2. Upload Your Business Tax Document<sup>*</sup>
            </Label>
            <DragNDrop />
          </Container>
          <Container>
            <Label>3. Upload Your Business VAT Document</Label>
            <DragNDrop />
          </Container>
          <Container>
            <Label>4. Upload Your Business Bank/M-Pesa Statement</Label>
            <DragNDrop />
          </Container>
        </Wrapper>
      </Content>
      <Note>
        Note: Your details will be subjected to verifications and integrity processes to validate you are a legitimate
        and legal business owner
      </Note>
      <ToastContainer autoClose={1000} />
    </>
  );
};

export default CompanyDocumentsMd;
