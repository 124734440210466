import styled from 'styled-components';

export const Wrapper = styled.main`
  display: grid;
  grid-template-columns: 2fr 1fr;
  column-gap: 2rem;
  position: relative;
  top: 7rem;

  .sidebar {
    margin-top: 4rem;
    margin-right: 1.6rem;
  }

  .masterclasses {
    margin-top: 2rem;
    display: grid;
    grid-template-columns: repeat(auto-fit, 30rem);
    grid-gap: 2rem;
    position: sticky;
    top: 9rem;

    .no_courses {
      width: 100%;
      height: 100%;
      display: grid;
      place-items: center;

      p {
        font-size: 2rem;
      }
    }
  }

  .tab {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 2rem;
    margin-bottom: 2rem;

    .heading {
      font: bold 1.92rem 'Poppins', sans-serif;

      &::after {
        content: '';
        display: block;
        width: 100%;
        height: 0.2rem;
        background-color: var(--color-accent);
      }
    }
  }
`;
