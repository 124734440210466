import { apiSlice } from '../../app/api/apiSlice';
import { createEntityAdapter } from '@reduxjs/toolkit';

const adapter = createEntityAdapter<any>();
const initialState = adapter.getInitialState();

const busRecoApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getBusRecos: builder.query<any, string>({
      query: (id) => `business/gap/recommendation/${id}`,
      transformResponse: (rawResult: any) => adapter.setAll(initialState, rawResult?.response),
    }),
  }),
});

export const { useGetBusRecosQuery } = busRecoApiSlice;
