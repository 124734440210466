import styled from 'styled-components';
import { motion } from 'framer-motion';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 18rem 1fr;
  border: 1px solid #707070;
  border-radius: 10px;
  background-color: #fff;
  font-size: 1.6rem;
  letter-spacing: 0.43px;
  color: #707070;
  margin-bottom: 2rem;

  h3 {
    font-weight: bold;
    font-size: 1.72rem;
    color: #000000;
  }

  small {
    font-size: 1.4rem;
  }

  svg {
    fill: #707070;
    width: 2rem;
    height: 2rem;
  }

  .flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .flex-1 {
    display: flex;
    justify-content: flex-start;
    column-gap: 0.8rem;
    align-items: center;
    margin-bottom: 0.8rem;
  }
`;

export const Count = styled.div`
  margin: -0.1rem 0 -0.1rem -0.1rem;
  background-color: var(--color-primary);
  display: grid;
  place-items: center;
  border-radius: 10px 0 0 10px;
  color: black;

  div {
    background-color: var(--color-accent);
    display: grid;
    place-items: center;
    border-radius: 50%;
    font-size: 1.8rem;
    font-weight: bold;
    width: 5rem;
    height: 5rem;
  }
`;

export const Content = styled.div`
  padding: 1.6rem;

  .summary {
    margin-right: 3.5rem;
    column-gap: 3rem;
  }
`;

export const Play = styled(motion.button)`
  border: none;
  display: grid;
  place-items: center;
  padding: 1.6rem;
  border-radius: 50%;
  background-color: var(--color-primary-light-1);
  cursor: pointer;
  transition: all 0.3s ease;

  svg {
    width: 2rem;
    height: auto;
    fill: black;
    transition: all 0.3s ease;
  }

  &:hover {
    svg {
      fill: white;
    }
  }
`;

export const Bar = styled.div`
  margin-top: 1rem;
  width: 100%;
  font-size: 1.5rem;
  color: #707070;
  display: flex;
  column-gap: 1rem;
  align-items: center;

  .container {
    height: 6px;
    background-color: transparent;
    border-radius: 5px;
    border: 1px solid #707070;
  }

  .bar {
    border-radius: 5px;
    background-color: var(--color-accent);
  }
`;
