import styled from 'styled-components';
import images from '../../constants';
import { motion } from 'framer-motion';

export const Container = styled.section`
  display: grid;
  grid-template-columns: 1fr max-content;
  column-gap: 2rem;
  margin-bottom: 2rem;
`;

export const Wrapper = styled.section`
  flex: 1;
  background-image: url(${images.nairobi});
  background-size: cover;
  background-position: bottom center;
  color: white;
  border-radius: 10px;

  .content {
    display: grid;
    grid-template-columns: 1fr auto;
    border-radius: 10px;
    padding: 2.2rem 2rem;
    backdrop-filter: blur(5px);

    h1 {
      font-size: 3.1rem;
      font-weight: 500;
      letter-spacing: 0.5px;
      margin-bottom: 1.6rem;
    }

    p {
      font-size: 2rem;
      letter-spacing: 0.5px;
    }
  }
`;

export const Wallet = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid var(--color-primary);
  padding: 1.6rem;
  font-weight: 500;
  font-size: 1.6rem;
  position: relative;

  .content {
    display: flex;
    column-gap: 1rem;
    margin: 1rem 0;
    align-items: center;
    z-index: 1;
  }

  .kes {
    font-weight: normal;
    font-size: 1.5rem;
  }

  .kijani {
    font-size: 1.8rem;
  }

  .big_circle {
    position: absolute;
    width: 54%;
    height: 54%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    background-color: var(--color-accent-light);
    clip-path: circle(50% at 50% 50%);
  }

  .small_circle {
    width: 20%;
    height: 20%;
    left: 1.9rem;
    top: 2.8rem;
    position: absolute;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    background-color: var(--color-accent-light);
    clip-path: circle(50% at 50% 50%);
  }

  .corner_circle {
    clip-path: circle(41.4% at 98% 2%);
    width: 60%;
    height: 60%;
    right: -5.9rem;
    top: 6rem;
    position: absolute;
    transform: translate(-50%, -50%);
    background-color: var(--color-accent-light);

    @media only screen and (min-width: 1515px) {
      top: 5.5rem;
      right: -6rem;
    }
  }
`;

export const Coin = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-primary);
  border-radius: 50%;
  width: 4rem;
  height: 4rem;
  padding: 0.6rem;
  color: white;
`;

export const Button = styled(motion.button)`
  background-color: var(--color-primary);
  color: white;
  text-transform: uppercase;
  font-size: 1.4rem;
  padding: 1.6rem;
  border-radius: 5px;
  z-index: 2;
  justify-self: center;
  align-self: center;
  margin-right: 3rem;
  display: grid;
  grid-template-columns: 1fr auto;
  column-gap: 1.6rem;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;

  svg {
    width: 2rem;
    height: 2rem;
    fill: white;
  }

  &:hover {
    background-color: var(--color-primary-dark);
  }

  &:active {
    transform: scale(0.98);
  }
`;
