import React, { useState } from 'react';
import styles from './Inbox.module.scss';
import Messages from '../../../../components/Konnect/Inbox/Messages';
import Chat from '../../../../components/Konnect/Inbox/Chat';
import { useGetAcceptedQuery } from '../../../../features/connect/acceptedApiSlice';
import MeetupLoader from '../../../../components/Loaders/MeetupLoader';
import images from '../../../../constants';

const Inbox = () => {
  let allKonnects = [];
  const { data, error, isLoading, isError, isSuccess } = useGetAcceptedQuery();
  const [selected, setSelected] = useState(null);

  if (isError) {
    console.log(error);
    return <div>Something went wrong</div>;
  } else if (isLoading) {
    return <MeetupLoader />;
  } else if (isSuccess) {
    allKonnects = data;
  }
  return (
    <main className={styles.container}>
      <section>
        {allKonnects.length === 0 ? (
          <p className={styles.pending}>You have not yet connected with anyone</p>
        ) : (
          <Messages allKonnects={allKonnects} onSetKonnect={setSelected} selected={selected} />
        )}
      </section>
      <section>
        {selected ? (
          <Chat konnectUser={selected} />
        ) : (
          <div className={styles.holder}>
            <div className={styles.c_white}>
              <img src={images.social} alt="Social Chat" />
            </div>
          </div>
        )}
      </section>
    </main>
  );
};

export default Inbox;
