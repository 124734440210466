import React from 'react';
import styles from './Event.module.scss';
import images from '../../constants';

function Event(props) {
  return (
    <div className={styles.container}>
      <div
        style={{
          backgroundImage:
            'url(https://firebasestorage.googleapis.com/v0/b/nectar-f5ee3.appspot.com/o/farming.jpg?alt=media&token=4258bce4-e35d-49e7-b4e7-940f7e1673a1)',
        }}
        className={styles.header}>
        <div>
          <h3>Agricultural Foundations of impact investments forum</h3>
          <button>Free</button>
        </div>
      </div>
      <p className={styles.time}>15th September 2022 || 5:30pm EAT</p>
      <div className={styles.actions}>
        <div>
          <button>Attend Event</button>
          <button className={styles.share}>Share Event</button>
        </div>
        <div className={styles.recommend}>
          <div className={styles.recommend_friends}>
            <img src={images.user1} alt="" />
            <img src={images.user3} alt="" />
            <img src={images.user2} alt="" />
          </div>
          <p className={styles.recommend_count}>Jane and 4 others will be attending</p>
        </div>
      </div>
      <p className={styles.join}>
        Join us on Thursday, September 29 at 12:15 EST as we connect with UX professionals from all over the world for
        monthly basic, coffee-house styled discussion.
      </p>
      <ul>
        <li>Doors will open at 12 noon EST for speed networking.</li>
        <li>The event will last for approximately 1.5 hours.</li>
        <li>Once you enter the room, pick a table to join a conversation.</li>
        <li>You can move between tables during the event.</li>
        <li>If a table looks full, you may still be able to join.</li>
        <li>Airmeet now supports mobile access through a browser and through the Airmeet mobile app.</li>
      </ul>
      <p className={styles.note}>
        {' '}
        NOTE: We may be moving to Fridays in the near future. Please let me know your thoughts on this potential shift.
      </p>
    </div>
  );
}

export default Event;
