import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TModalType } from '../../modules/ModalWindow/modalTypes';
import { RootState } from '../../app/store';

interface IModalInitialState {
  modalType: string;
}

const initialState: IModalInitialState = {
  modalType: '',
};

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    openModal: (state, action: PayloadAction<{ modalType: TModalType }>) => {
      const { modalType } = action.payload;
      state.modalType = modalType;
    },
    closeModal: () => initialState,
  },
});

export const { openModal, closeModal } = modalSlice.actions;

export default modalSlice.reducer;

export const selectCurrentModal = (state: RootState) => state.modal.modalType;
