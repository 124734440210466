import React from 'react';
import { Bar, Card, CompleteText, Wrapper } from '../Enrolled/styles';
import { Text, Title } from '../Overview/RecommendedClasses/styles';
import { BtnDiv, Button, HRule, TimeChip } from '../Overview/CurrentClass/styles';
import images from '../../../constants';
import EmptyState from '../../EmptyState/EmptyState';

const Complete = () => {
  const lessons = {
    total: 5,
    done: 5,
  };
  const setProgressbar = () => {
    let progress = [];
    for (let i = 0; i < lessons.total; i++) {
      if (i < lessons.done) progress.push(<hr className="complete" />);
      else progress.push(<hr />);
    }
    return progress;
  };

  const classes = 0;
  if (classes === 0) {
    const emptyContent = {
      title: 'You have not completed any masterclasses yet',
      subtitle: 'Complete a masterclass to view them here',
      imageUrl: images.emptyIncomplete,
      header: 'Completed Masterclasses',
    };
    return <EmptyState content={emptyContent} />;
  }

  return (
    <Wrapper>
      <Title>Completed masterclasses</Title>
      <Card>
        <img
          src="https://cmmonline.com/wp-content/uploads/Revenue_Growth_SKP1154691032_feat-1200x627.jpg"
          alt="Revenue growth"
        />
        <Title>Revenue growth</Title>
        <Text>
          Revenue growth is the increase (or decrease) in a company’s sales from one period to the next. Shown as a
          percentage, revenue growth illustrates the increases and decreases over time identifying trends in the
          business.
        </Text>
        <TimeChip>
          <p>5 Lessons&bull;60 min</p>
        </TimeChip>
        <CompleteText>
          {lessons.done}/{lessons.total} complete
        </CompleteText>
        <Bar total={lessons.total}>{setProgressbar()}</Bar>
        <HRule />
        <BtnDiv>
          <Button className={'accent'}>Review</Button>
          <Button>View Certificate</Button>
        </BtnDiv>
      </Card>
      <Card>
        <img
          src="https://vakilsearch.com/blog/wp-content/uploads/2022/05/NEW-AND-OLD-INCOME-TAX.jpg"
          alt="Tax compliance"
        />
        <Title>Tax compliance</Title>
        <Text>
          Tax compliance refers to the process of adhering to laws and regulations regarding the reporting and payment
          of taxes to the government. This involves accurately reporting income and assets, filing tax returns, and
          making timely payments of tax liabilities. Failure to comply with tax laws can result in penalties and fines.
        </Text>
        <TimeChip>
          <p>5 Lessons&bull;60 min</p>
        </TimeChip>
        <CompleteText>
          {lessons.done}/{lessons.total} complete
        </CompleteText>
        <Bar total={lessons.total}>{setProgressbar()}</Bar>
        <HRule />
        <BtnDiv>
          <Button className={'accent'}>Review</Button>
          <Button>View Certificate</Button>
        </BtnDiv>
      </Card>
    </Wrapper>
  );
};

export default Complete;
