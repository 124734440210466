import React from 'react';
import Modal from 'react-modal';
import { Button, Close, Container } from '../CheckMail/styles';
import images from '../../../../constants';
import { closeModal, selectCurrentModal } from '../../../../features/modal/modalSlice';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { MODAL_TYPES } from '../../modalTypes';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    zIndex: 100,
    border: 'none',
    width: '75%',
    borderRadius: '20px',
    maxWidth: '600px',
  },
};
const AssetPayment = ({ onClose }) => {
  const dispatch = useAppDispatch();

  const modalType = useAppSelector(selectCurrentModal);
  const isOpen = modalType === MODAL_TYPES.ASSET_PAYMENT;
  const onSubmitHandler = () => {
    dispatch(closeModal());
  };
  return (
    <Modal
      onRequestClose={onSubmitHandler}
      isOpen={isOpen}
      style={customStyles}
      ariaHideApp={false}
      overlayClassName="Overlay">
      <Container>
        <Close onClick={onSubmitHandler} />
        <h3>Asset Payment</h3>
        <img src={images.checkMail} alt="forgot password" />
        <p className="title">
          You'll pay a deposit of <span style={{ color: 'var(--color-accent' }}>KSH 70,000</span> today and{' '}
          <span style={{ color: 'var(--color-primary)' }}>KSH 2,333</span> daily until your payment is completed
        </p>
        <Button
          whileHover={{ backgroundColor: 'var(--color-primary-dark)' }}
          whileTap={{ scale: 0.97 }}
          onClick={onSubmitHandler}>
          Proceed to pay
        </Button>
      </Container>
    </Modal>
  );
};

export default AssetPayment;
