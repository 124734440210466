import { createEntityAdapter, createSelector } from '@reduxjs/toolkit';
import { apiSlice } from '../../app/api/apiSlice';

const adapter = createEntityAdapter();
const initialState = adapter.getInitialState();

export const quizApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getQuizes: build.query<any, string>({
      query: (id) => `/lms/lesson/question/list/${id}`,
      transformResponse: (rawResult: any) => {
        return adapter.setAll(initialState, rawResult?.response);
      },
    }),
  }),
});

export const { useGetQuizesQuery } = quizApiSlice;

/*
const selectQuizesResult = quizApiSlice?.endpoints?.getQuizes.select();
const selectQuizesData = createSelector(selectQuizesResult, (quizesResult) => quizesResult?.data);
export const { selectAll: selectAllQuizes } = adapter.getSelectors(
  (state: any) => selectQuizesData(state) ?? initialState,
);
*/
