import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { logOut, setAccessToken } from '../../features/auth/authSlice';
import {
  ACCEPTED,
  ANALYSIS,
  BUILT_ACC,
  BUSINESSES_TAG,
  CATEGORIES,
  CONNECTS,
  COURSES_TAG,
  ENROLL,
  LESSONS_TAG,
  LOAN_PRODUCTS,
  LOAN_TRANSACTIONS,
  LOANS_TAG,
  OPTIONS,
  PENDING,
  POSTS,
  TICKETS,
  USER,
} from '../../types/api-responses';
import { RootState } from '../store';
const baseUrl = process.env.REACT_APP_BASE_URL;

const baseQuery = fetchBaseQuery({
  baseUrl: baseUrl,
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).auth.token;
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWithReAuth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  if (result?.error?.status === 'PARSING_ERROR' && result?.error?.originalStatus === 403) {
    console.log('Sending refresh token');
    const refreshResult = await baseQuery('/refresh', api, extraOptions);
    console.log('RefreshResult:', refreshResult);
    if (refreshResult?.data) {
      api.dispatch(setAccessToken(refreshResult.data));
      result = await baseQuery(args, api, extraOptions);
    } else {
      api.dispatch(logOut());
    }
  }
  return result;
};

export const apiSlice = createApi({
  baseQuery: baseQueryWithReAuth,
  endpoints: () => ({}),
  tagTypes: [
    LESSONS_TAG,
    COURSES_TAG,
    CATEGORIES,
    LOANS_TAG,
    BUSINESSES_TAG,
    POSTS,
    LOAN_PRODUCTS,
    LOAN_TRANSACTIONS,
    TICKETS,
    USER,
    ANALYSIS,
    ENROLL,
    BUILT_ACC,
    CONNECTS,
    PENDING,
    ACCEPTED,
    OPTIONS,
  ],
});
