import React, { useState } from 'react';
import { RiSearchLine } from 'react-icons/ri';
import {
  Avatar,
  BlueBox,
  BookButton,
  Button,
  BWrapper,
  ExpertCard,
  ExpertContainer,
  ExpertsGrid,
  ExpertsWrapper,
  HeaderSection,
  HeaderWrapper,
  HText,
  InfoGrid,
  Name,
  OrangeBox,
  PageHeader,
  PText,
  Search,
  SideAvatar,
  Sidebar,
  SideHeader,
  Title,
} from './styles';
import { BsCalendarDate } from 'react-icons/bs';
import { IoIosEye } from 'react-icons/io';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { openModal } from '../../../features/modal/modalSlice';
import { MODAL_TYPES } from '../../../modules/ModalWindow/modalTypes';
import { selectAllExperts, useGetExpertsQuery } from '../../../features/experts/expertsApiSlice';
import MeetupLoader from '../../../components/Loaders/MeetupLoader';
import { refineExperts } from '../../../utils/object-mapper';
import { useGetUserQuery, useSendAppointmentMutation } from '../../../features/user/userApiSlice';

const Experts = () => {
  const { data: user } = useGetUserQuery();
  const dispatch = useAppDispatch();
  const [option, setOption] = useState(0);
  const tabs = ['All Experts', 'Financing', 'Agriculture', 'Technology', 'Education', 'NGOs'];
  const [selectedTab, setSelectedTab] = useState(tabs[0]);
  const [searchText, setSearchText] = useState('');
  const [id, setId] = useState(0);
  let experts = [];
  const { isLoading, isError, isSuccess, error } = useGetExpertsQuery();
  const expertsList = useAppSelector(selectAllExperts);
  const [sendAppointment, { isLoading: isAppointmentLoading }] = useSendAppointmentMutation();

  const handleSearch = (event) => {
    setSearchText(event.target.value);
  };

  let content;
  const meetupLoader = <MeetupLoader />;

  const buttonContent = (expert) => {
    if (isAppointmentLoading && id === +expert.id) {
      return 'Booking';
    } else {
      return 'Book';
    }
  };

  if (isLoading) {
    content = meetupLoader;
  } else if (isSuccess) {
    experts = refineExperts(expertsList);
  } else if (isError) {
    console.log(error);
  }

  const filteredData = experts.filter((item) => item.name.toLowerCase().includes(searchText.toLowerCase()));

  const openSorryModal = () => {
    // dispatch(openModal({ modalType: MODAL_TYPES.SORRY_MODAL }));
  };

  const openExpertModal = async (expert) => {
    setId(+expert.id);
    try {
      await sendAppointment({
        expert_id: expert.id,
        user_id: user.id,
        date: '2023-05-03 12:11:03',
        created_by: user.id,
      });
      dispatch(openModal({ modalType: MODAL_TYPES.APPOINT_MODAL }));
    } catch (error) {
      console.log(error);
    }
  };

  const [showSideBar, setShowSideBar] = useState(false);

  const handleViewClick = (expertId) => {
    if (showSideBar && expertId === option) setShowSideBar(false);
    else if (showSideBar) setOption(expertId);
    else {
      setOption(expertId);
      setShowSideBar(true);
    }
  };

  return (
    <ExpertsWrapper style={{ display: `${showSideBar ? 'flex' : 'block'}` }}>
      <div className={'experts'}>
        <HeaderWrapper>
          <PageHeader>
            <h3>Looking To Grow Your Business?</h3>
            <p>
              Schedule an appointment with one of our experts to gain more insights <br /> that will help your business
              stay ahead of the competition
            </p>
          </PageHeader>
        </HeaderWrapper>
        <div className="sticky">
          <HeaderSection>
            <h4 className={'heading'}>All Experts</h4>
            <Search>
              <input placeholder={`Search for an expert`} onChange={handleSearch} />
              <RiSearchLine />
            </Search>
          </HeaderSection>
          {isSuccess ? (
            <ExpertsGrid>
              {filteredData.map((el, index) => (
                <ExpertContainer key={index}>
                  <ExpertCard className={`${option === index && showSideBar ? 'active' : ''}`}>
                    <Avatar src={el.imageUrl} alt={`${el.name} ${el.title}`} />
                    <Name>{el.name}</Name>
                    <Title>{el.title}</Title>
                    <BWrapper>
                      <Button disabled={isAppointmentLoading} onClick={() => openExpertModal(el)}>
                        <BsCalendarDate />
                        <p>{buttonContent(el)}</p>
                      </Button>
                      <Button onClick={() => handleViewClick(index)}>
                        <IoIosEye />
                        View
                      </Button>
                    </BWrapper>
                  </ExpertCard>
                  <BlueBox />
                  <OrangeBox />
                </ExpertContainer>
              ))}
            </ExpertsGrid>
          ) : (
            content
          )}
        </div>
      </div>
      {isSuccess && showSideBar && (
        <Sidebar>
          <SideHeader>
            <h3>Expert Details</h3>
          </SideHeader>
          <SideAvatar src={experts[option].imageUrl} alt={`${experts[option].name} ${experts[option].title}`} />
          <InfoGrid>
            <HText>Name: </HText>
            <PText>{experts[option].name}</PText>
            <hr />
          </InfoGrid>
          <InfoGrid>
            <HText>Title: </HText>
            <PText>{experts[option].title}</PText>
            <hr />
          </InfoGrid>
          <InfoGrid>
            <HText>Company: </HText>
            <PText>{experts[option].company}</PText>
            <hr />
          </InfoGrid>
          <HText className={'bio'}>Bio:</HText>
          <PText>{experts[option].bio}</PText>
          <BookButton onClick={openExpertModal}>Book Appointment</BookButton>
        </Sidebar>
      )}
    </ExpertsWrapper>
  );
};

export default Experts;
