import React, { useState } from 'react';
import styles from './Notifications.module.scss';
import images from '../../../constants';
import { TbDots } from 'react-icons/tb';
import { AiOutlineClose } from 'react-icons/ai';

function Notifications() {
  const tabs = ['All Notifications', 'Unread Notifications', 'Read Notifications'];
  const [checked, setChecked] = useState(tabs[0]);
  const isChecked = (input) => input === checked;

  return (
    <section className={styles.container}>
      <section className="tabs ">
        {tabs.map((tab, index) => (
          <div className="tab-2" key={`${tab + index}`}>
            <label className={`${isChecked(tab) ? 'label__active' : ''}`} htmlFor={`${tab + index}`}>
              {tab}
            </label>
            <input
              onChange={() => setChecked(tab)}
              id={`${tab + index}`}
              name="tabs-two"
              type="radio"
              checked={isChecked(tab)}
            />
          </div>
        ))}
      </section>
      <h3>Today</h3>
      <div className={styles.top}>
        <div className={styles.comment}>
          <img className={styles.speaker} src={images.speaker} alt="Konnect user" />
          <div>
            <p className={styles.text}>
              Jane Doe just commented on your post “ there comes a time where investors have to lead”
            </p>
            <p className={styles.time}>5h ago</p>
            <img
              className={styles.post_img}
              src="https://firebasestorage.googleapis.com/v0/b/nectar-f5ee3.appspot.com/o/change.jpg?alt=media&token=587e3b3d-39f8-40e8-ad09-01fe3e19ce3a"
              alt="Change in Investment"
            />
          </div>
          <TbDots className={styles.dots} />
        </div>
        <hr />
        <div className={styles.konnect}>
          <img className={styles.speaker} src={images.speaker} alt="Konnect user" />
          <div>
            <p className={styles.text}>
              Jane Doe <span className={styles.time}>5h ago</span>
            </p>
            <p className={styles.text}>Wezacare Foundation</p>
          </div>
          <div className={styles.btns}>
            <button>Accept</button>
            <button className={styles.close}>
              <AiOutlineClose />
            </button>
          </div>
        </div>
        <hr />
      </div>
      {[1, 2, 3].map((ele) => (
        <div className={styles.bottom} key={ele}>
          <div className={styles.events}>
            <img className={styles.speaker} src={images.speaker} alt="Konnect user" />
            <div>
              <p className={styles.text}>
                Jane Doe is attending the Impact inesting social <br /> Event on the 22nd of September
              </p>
              <p className={styles.time}>5h ago</p>
            </div>
            <div className={styles.actions}>
              <TbDots className={styles.dots} />
              <button className={styles.view}>View Event</button>
            </div>
          </div>
          <hr />
        </div>
      ))}
    </section>
  );
}

export default Notifications;
