import React from 'react';
import { UserWrapper } from './styles';
import MyDocuments from '../../../components/UserProfile/MyDocuments/MyDocuments';
import { useGetUserQuery } from '../../../features/user/userApiSlice';
import MeetupLoader from '../../../components/Loaders/MeetupLoader';
import { Outlet } from 'react-router-dom';
import { BACK_ID, FRONT_ID } from '../../../constants/constants';
import { getDocuments, getPendingDocuments } from '../../../utils/object-mapper';

const UserProfile = () => {
  const { data: user, isLoading, isSuccess, error, isError } = useGetUserQuery();
  const docs = [FRONT_ID, BACK_ID];
  let documents = [];
  let pendingDocs = [];

  if (isLoading) return <MeetupLoader />;
  if (isSuccess) {
    documents = getDocuments(docs, user?.media);
    pendingDocs = getPendingDocuments(docs, documents);
  } else if (isError) {
    console.log(error);
  }
  return (
    <UserWrapper>
      <div className="main">
        <Outlet />
      </div>
      <div className={'side'}>
        <MyDocuments docs={pendingDocs} documents={documents} />
      </div>
    </UserWrapper>
  );
};

export default UserProfile;
