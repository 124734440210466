import styled from 'styled-components';
import { motion } from 'framer-motion';

export const NewTicketWrapper = styled.main`
  display: grid;
  grid-template-columns: 1fr minmax(max-content, auto);
  column-gap: 2rem;
  position: relative;
  top: 7rem;

  .main {
    padding: 1.28rem;
  }
`;

export const Title = styled.h3`
  font-size: 1.9rem;
  font-weight: 500;
  border-bottom: var(--color-primary) 2px solid;
  margin-bottom: 2rem;
  width: max-content;
`;

export const Labels = styled.h4`
  font-weight: bold;
  font-size: 1.7rem;
  color: #707070;
  margin-bottom: 0.8rem;
`;

export const SInput = styled.input`
  border-radius: 5px;
  width: 100%;
  padding: 1.28rem;
  outline: none;
  border: 1px solid #707070;
  margin-bottom: 2rem;
`;

export const TArea = styled.textarea`
  border-radius: 5px;
  width: 100%;
  outline: none;
  padding: 1.28rem;
  border: 1px solid #707070;
  margin-bottom: 2rem;
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .attach {
    margin-top: 3rem;
    display: flex;
    align-items: center;
    column-gap: 1rem;
    color: #cdc7c7;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 1.7rem;
    cursor: pointer;
    transition: all 0.3s ease-in-out;

    svg {
      width: 2rem;
      height: 2rem;
      fill: #cdc7c7;
    }

    &:hover {
      color: #000;

      svg {
        fill: black;
      }
    }
  }
`;

export const Button = styled(motion.button)`
  background-color: var(--color-primary);
  text-transform: uppercase;
  padding: 1.44rem;
  font-weight: 500;
  font-size: 1.7rem;
  letter-spacing: 0;
  color: white;
`;

export const Sidebar = styled.section`
  width: 40rem;
  padding: 1.28rem;
  background-color: #fff8eb;
  margin-top: 2rem;
`;

export const MyTicketsHeader = styled.h5`
  font-weight: bold;
  font-size: 1.7rem;
  width: max-content;
  border-bottom: 1px solid black;
  margin-bottom: 2rem;
`;
