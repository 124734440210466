import React, { useEffect } from 'react';
import { useUpdateBusinessMutation } from '../../../features/business/businessApiSlice';
import { selectAllCategories, useGetCategoriesQuery } from '../../../features/categories/categories';
import { useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { Profile } from '../../../types/user';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema } from '../../LoanModal/CompanyDetails/details-schema';
import { toast, ToastContainer } from 'react-toastify';
import { Input, Label, Wrapper } from '../../LoanModal/PersonalDetails/styles';
import { Btn, BtnDiv, Content, Form, Note } from '../EditProfile/styles';
import Select from 'react-select';
import { getCountries } from '../../../utils/object-mapper';
import ReactPhoneInput from 'react-phone-input-2';
import { Socials, TArea } from '../../LoanModal/CompanyDetails/styles';
import { ImFacebook2, ImLinkedin } from 'react-icons/im';
import { BsInstagram, BsTwitter } from 'react-icons/bs';

const companyTypes = [
  { value: 'sole proprietor', label: 'Sole Proprietor' },
  { value: 'partnership', label: 'Partnership' },
  { value: ' private limited company', label: 'Private Limited Company' },
  { value: 'company limited by guarantee', label: 'Company Limited by Guarantee' },
  { value: 'NGO', label: 'NGO' },
];
const EditBusiness = ({ onClose }) => {
  const [updateBusiness, { isLoading }] = useUpdateBusinessMutation();
  const { isLoading: loading } = useGetCategoriesQuery();
  const categories = useSelector(selectAllCategories);
  let retrievedProfile = localStorage.getItem('profileData');
  const profileState = JSON.parse(retrievedProfile);

  let options = [];
  categories.filter((c: any) => {
    if (c.type === 'sector') options.push({ label: c.name, value: c.id });
  });
  const onSubmitClose = () => {
    onClose(false);
  };

  const defaultValues = {
    name: profileState.companyName,
    category_id: profileState.category_id,
    type_of_company: profileState.type_of_company,
    office_location: profileState.office_location,
    office_phone: profileState.phone,
    office_email: profileState.email,
    description: profileState.description,
    linkedin: profileState.linkedin,
    facebook: profileState.facebook,
    company_website: profileState.company_website,
    twitter: profileState.twitter,
    instagram: profileState.instagram,
  };

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors, isDirty, isValid },
  } = useForm<Profile>({
    resolver: yupResolver(schema),
    mode: 'all',
  });

  useEffect(() => {
    reset(defaultValues);
  }, []);

  const buttonContent = isLoading ? <div className={'container dots-flow'} /> : 'Continue';
  const onSubmitHandler = async (data) => {
    const userInput = {
      item: {
        ...data,
      },
      id: profileState.businessId,
    };
    try {
      await updateBusiness(userInput).unwrap();
      toast.success('Your business profile was updated successfully');
      window.location.reload();
      onSubmitClose();
    } catch (e) {
      console.log(e);
      toast.error('Could not update \n Something went wrong!');
    }
  };

  return (
    <>
      <Wrapper>
        <Content>
          <Form id={'create-business'} onSubmit={handleSubmit(onSubmitHandler)}>
            <div className="flex">
              <div className={'child'}>
                <Label>Company name*</Label>
                <Input type={'text'} autoFocus {...register('name')} required />
                {errors.name?.message && <small>{errors.name?.message}</small>}
              </div>
              <div className={'child'}>
                <Label>Type of company*</Label>
                <Controller
                  control={control}
                  render={({ field: { onChange, value, name, ref } }) => {
                    return (
                      <Select
                        value={companyTypes.find((c: any) => c.value === value)}
                        name={name}
                        options={companyTypes}
                        onChange={(selectedOption: any) => {
                          onChange(selectedOption.value);
                        }}
                        styles={{
                          container: (base) => ({
                            ...base,
                            fontSize: '1.6rem',
                          }),
                          control: (base) => ({
                            ...base,
                            fontSize: '1.6rem',
                            height: '5.4rem',
                          }),
                        }}
                        isSearchable
                        theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary25: '#cdd5f7',
                            primary: '#FFAB00',
                            neutral20: '#707070',
                          },
                        })}
                        placeholder={'Select Type of Company'}
                      />
                    );
                  }}
                  name={'type_of_company'}
                  rules={{
                    required: true,
                  }}
                />
                {errors.type_of_company?.message && <small>{errors.type_of_company?.message}</small>}
              </div>
            </div>
            <div className="flex">
              <div className={'child'}>
                <Label>Business sector*</Label>
                <Controller
                  control={control}
                  render={({ field: { onChange, value, name } }) => {
                    return (
                      <Select
                        value={options.find((c: any) => c.value === value)}
                        name={name}
                        options={options}
                        onChange={(selectedOption: any) => {
                          onChange(selectedOption.value);
                        }}
                        styles={{
                          container: (base) => ({
                            ...base,
                            fontSize: '1.6rem',
                          }),
                          control: (base) => ({
                            ...base,
                            fontSize: '1.6rem',
                            height: '5.4rem',
                          }),
                        }}
                        isSearchable
                        theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary25: '#cdd5f7',
                            primary: '#FFAB00',
                            neutral20: '#707070',
                          },
                        })}
                      />
                    );
                  }}
                  name={'category_id'}
                  rules={{
                    required: true,
                  }}
                />
                {errors.category_id?.message && <small>{errors.category_id?.message}</small>}
              </div>
              <div className={'child'}>
                <Label>Country of incorporation*</Label>
                <Controller
                  control={control}
                  render={({ field: { onChange, value, name, ref } }) => {
                    return (
                      <Select
                        value={getCountries.find((c: any) => c.value === value)}
                        name={name}
                        options={getCountries}
                        onChange={(selectedOption: any) => {
                          onChange(selectedOption.value);
                        }}
                        styles={{
                          container: (base) => ({
                            ...base,
                            fontSize: '1.6rem',
                          }),
                          control: (base) => ({
                            ...base,
                            fontSize: '1.6rem',
                            height: '5.4rem',
                          }),
                        }}
                        isSearchable
                        theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary25: '#cdd5f7',
                            primary: '#FFAB00',
                            neutral20: '#707070',
                          },
                        })}
                        placeholder={'Select Country'}
                      />
                    );
                  }}
                  name={'office_location'}
                  rules={{
                    required: true,
                  }}
                />
                {errors.office_location?.message && <small>{errors.office_location?.message}</small>}
              </div>
            </div>
            <div className="flex">
              <div>
                <Label>Company Website</Label>
                <Input type={'text'} {...register('company_website')} />
              </div>
              <div className={'child'}>
                <Label>Registered phone number*</Label>
                <Controller
                  control={control}
                  name="office_phone"
                  rules={{ required: true }}
                  render={({ field: { ref, ...field } }) => (
                    <ReactPhoneInput
                      {...field}
                      inputProps={{
                        ref,
                        required: true,
                        autoFocus: false,
                      }}
                      country={'ke'}
                      countryCodeEditable={false}
                      enableSearch={true}
                      regions={['america', 'europe', 'asia', 'oceania', 'africa']}
                    />
                  )}
                />
                {errors.office_phone?.message && <small>{errors.office_phone?.message}</small>}
              </div>
            </div>
            <div className="cont">
              <Label>Registered email address*</Label>
              <Input type={'email'} {...register('office_email')} />
              {errors.office_email?.message && <small>{errors.office_email?.message}</small>}
            </div>
            <div className="cont t_area">
              <Label>Brief business history*</Label>
              <TArea rows={10} {...register('description')} />
              {errors.description?.message && <small>{errors.description?.message}</small>}
            </div>
            <Label>Social media handles</Label>
            <Socials>
              <ImLinkedin /> <input type={'text'} {...register('linkedin')} />
            </Socials>
            <Socials>
              <BsTwitter /> <input type={'text'} {...register('twitter')} />
            </Socials>
            <Socials>
              <ImFacebook2 /> <input type={'text'} {...register('facebook')} />
            </Socials>
            <Socials>
              <BsInstagram /> <input type={'text'} {...register('instagram')} />
            </Socials>
          </Form>
        </Content>
        <Note>
          Note: Your details will be subjected to verifications and integrity processes to validate you are a legitimate
          and legal business owner. <br /> <sup>*</sup>Required field
        </Note>
        <BtnDiv>
          <Btn onClick={onSubmitClose} whileTap={{ scale: 0.97 }} whileHover={{ backgroundColor: '#f4ad3d' }}>
            Cancel
          </Btn>
          <Btn
            disabled={isLoading || loading || !isDirty}
            whileTap={{ scale: 0.97 }}
            whileHover={{ backgroundColor: '#014e35' }}
            className={'green'}
            type={'submit'}
            form={'create-business'}>
            {buttonContent}
          </Btn>
        </BtnDiv>
        <ToastContainer autoClose={1000} />
      </Wrapper>
      <ToastContainer autoClose={1000} />
    </>
  );
};

export default EditBusiness;
