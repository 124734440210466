import styled from 'styled-components';
import { motion } from 'framer-motion';

export const Container = styled.div``;

export const Form = styled.form`
  height: 7rem;
  text-align: center;
  position: relative;

  input {
    display: none;
  }

  .text {
    font-size: 1.5rem;
    color: #707070;
  }
`;
export const FLabel = styled.label`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed black;
  background-color: transparent;

  &.drag-active {
    background-color: #76baff;
  }
`;

export const UBtn = styled(motion.button)`
  cursor: pointer;
  padding: 0.25rem;
  font-size: 1.7rem;
  border: none;
  background-color: transparent;
  font-weight: 500;
  color: var(--color-accent);
  margin-top: 0.6rem;

  &:hover {
    text-decoration-line: underline;
  }
`;

export const DragFile = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
`;
