import React, { useState } from 'react';
import Modal from 'react-modal';
import {
  Asset,
  AssetHeader,
  BackButton,
  Button,
  CardDetails,
  CardInput,
  CardOption,
  CheckoutHeader,
  CheckoutWrapper,
  ContentWrapper,
  CVVWrap,
  DFlex,
  HRule,
  Input,
  InputCard,
  Label,
  Title,
} from './styles';
import images from '../../../../constants';
import { FaCreditCard } from 'react-icons/fa';
import { closeModal, selectCurrentModal } from '../../../../features/modal/modalSlice';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { MODAL_TYPES } from '../../modalTypes';
import { BsWallet2 } from 'react-icons/bs';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    zIndex: 100,
    border: 'none',
    width: '70%',
    padding: 0,
  },
};

function CheckoutModal() {
  const dispatch = useAppDispatch();
  const [card, setCard] = useState(true);
  const modalType = useAppSelector(selectCurrentModal);
  const isOpen = modalType === MODAL_TYPES.CHECKOUT_MODAL;
  const onSubmitHandler = () => {
    dispatch(closeModal());
  };

  const mpesaContent = (
    <>
      <Label>Mpesa Phone Number</Label>
      <Input style={{ marginBottom: '2rem' }} type={'tel'} />
      <Label>Email Address</Label>
      <Input type={'text'} />
    </>
  );

  const cardContent = (
    <>
      <Label>Card information</Label>
      <CardInput>
        <input type={'text'} placeholder={'1234 1234 1234 1234'} />
        <div>
          <InputCard src={images.visa} />
          <InputCard src={images.mastercardLogo} />
        </div>
      </CardInput>
      <CardDetails>
        <input type={'text'} placeholder={'MM/YY'} />
        <CVVWrap>
          <input type={'text'} placeholder={'CVV'} />
          <FaCreditCard />
        </CVVWrap>
      </CardDetails>
      <Label>Name on card</Label>
      <Input type={'text'} placeholder={'John Doe'} />
    </>
  );
  return (
    <Modal isOpen={isOpen} style={customStyles} ariaHideApp={false} overlayClassName="Overlay">
      <CheckoutWrapper>
        <Asset style={{ backgroundImage: `url(${images.africaLight})` }}>
          <AssetHeader>
            <BackButton onClick={onSubmitHandler} />
            <img src={images.mkBlack} alt="Melanin Kapital Logo" />
          </AssetHeader>
          <h3>
            Please Complete your purchase by providing your <br /> payment details
          </h3>
        </Asset>
        <ContentWrapper>
          <CheckoutHeader>
            <BsWallet2 />
            <h5>Checkout</h5>
          </CheckoutHeader>
          <Title>Choose payment method</Title>
          <CardOption>
            <img
              onClick={() => setCard(false)}
              className={`${card ? '' : 'active'}`}
              src={images.mpesa}
              alt="Mpesa Logo"
            />
            <img
              onClick={() => setCard(true)}
              className={`${card ? 'active' : ''}`}
              src={images.visa}
              alt="Visa Logo"
            />
            <img src={images.mastercardLogo} alt="MasterCard Logo" />
          </CardOption>
          {card ? cardContent : mpesaContent}
          <HRule />
          <DFlex>
            <Label>Subtotal</Label>
            <Label>KES 4,200</Label>
          </DFlex>
          <DFlex>
            <Label>VAT(16%)</Label>
            <Label>KES 800</Label>
          </DFlex>
          <DFlex>
            <Title>Total Due</Title>
            <Title>KES 5,000</Title>
          </DFlex>
          <HRule />
          <div className={'c-btn'}>
            <Button>pay kes 5,500</Button>
          </div>
          <Label>
            By clicking the payment button, you agree with our <span>Terms and Conditions</span>. Thank you for trusting
            our service.
          </Label>
        </ContentWrapper>
      </CheckoutWrapper>
    </Modal>
  );
}

export default CheckoutModal;
