import styled from 'styled-components';

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr;
  column-gap: 2rem;
  position: relative;
  top: 9rem;
  padding-right: 2rem;
`;

export const Header = styled.div`
  background-image: url('https://firebasestorage.googleapis.com/v0/b/nectar-f5ee3.appspot.com/o/climate%2FGroup%2011657.png?alt=media&token=f2776ab5-bb69-4876-a575-3899bbeb651a');
  background-size: cover;
  background-position: bottom center;
  color: white;
  border-radius: 10px;
  margin-bottom: 2rem;

  div {
    border-radius: 10px;
    padding: 2.2rem 2rem;
    backdrop-filter: blur(5px);

    h1 {
      font-size: 3.1rem;
      font-weight: 500;
      letter-spacing: 0.5px;
      margin-bottom: 1.6rem;
      text-transform: capitalize;
    }

    p {
      font-size: 2rem;
      letter-spacing: 0.5px;
    }
  }
`;

export const MidSec = styled.div`
  background-color: #fff;
  padding: 1.6rem 1.6rem 3rem;
  border-radius: 10px 10px 0 0;

  h2 {
    font-size: 2rem;
    font-weight: 500;
    margin-bottom: 1rem;
  }
`;

export const Card = styled.div<{ color: string }>`
  border: 1px solid ${(props) => props.color};
  color: ${(props) => props.color};
  font-size: 1.8rem;
  padding: 1.6rem;
  font-weight: 500;
  border-radius: 10px;
  position: relative;

  p {
    font-weight: normal;
    font-size: 1.6rem;
    color: #000;
    letter-spacing: 0.3px;
  }

  img {
    width: 5rem;
    height: 7rem;
  }
`;

export const CardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 2rem;
`;

export const Arrow = styled.div<{ color: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  padding: 0.5rem;
  background-color: ${(props) => props.color};
  position: absolute;
  right: 3rem;
  bottom: -1.5rem;
  cursor: pointer;

  svg {
    height: 100%;
    width: 100%;
    fill: white;
  }
`;

export const SideWrapper = styled.div`
  border-radius: 10px;
  background-color: #fff;
  font-size: 1.6rem;
  padding: 1.6rem;
`;

export const SideHeader = styled.div`
  border-radius: 10px 10px 0 0;
  background-color: var(--color-primary);
  padding: 2rem 1.6rem;
  font-size: 2rem;
  font-weight: 500;
  margin: 0 -1.6rem;
  margin-top: -1.6rem;
  color: white;
`;

export const Point = styled.div`
  display: flex;
  align-items: flex-start;
  column-gap: 1.6rem;

  .head {
    color: var(--color-primary);
    margin-bottom: 0.4rem;
  }

  svg {
    width: 3rem;
    height: 3rem;
    fill: var(--color-primary);
  }
`;
