import React, { useState } from 'react';
import { openModal } from '../../../features/modal/modalSlice';
import { MODAL_TYPES } from '../../../modules/ModalWindow/modalTypes';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { AnimatePresence, motion } from 'framer-motion';
import { selectAllPosts, useGetPostsListQuery } from '../../../features/posts/postsApiSlice';
import PostItem from './PostActions/PostItem';
import { selectAllCategories, useGetCategoriesQuery } from '../../../features/categories/categories';
import { useSelector } from 'react-redux';
import styles from './Post.module.scss';
import MeetupLoader from '../../Loaders/MeetupLoader';
import { Li, Nav, Ul, Underline } from '../../Common/Tabs/styles';
import Request from '../Request/Request';

const Post = () => {
  const { isSuccess: isCategorySuccess } = useGetCategoriesQuery();
  const categories = useSelector(selectAllCategories);
  let tabs = [{ label: 'All Posts', value: 0 }];
  if (isCategorySuccess) {
    tabs = [
      ...tabs,
      ...categories
        .filter((category: any) => category.type === 'club')
        .map((category: any) => {
          return { label: category.name, value: category.id };
        }),
    ];
  }
  let filteredPosts = [];
  const [selectedTab, setSelectedTab] = useState(tabs[0]);
  const { isLoading, isSuccess, isError, error } = useGetPostsListQuery();
  const postList = useAppSelector(selectAllPosts);
  const dispatch = useAppDispatch();
  const onOpenPostModal = (postId) => {
    localStorage.setItem('postId', postId);
    dispatch(openModal({ modalType: MODAL_TYPES.POST_MODAL }));
  };

  if (isError) {
    console.log(error);
    return <div>Something went wrong</div>;
  } else if (isSuccess) {
    if (selectedTab.value !== 0) {
      filteredPosts = postList.filter((post: any) => post.category_id === selectedTab.value);
    } else {
      filteredPosts = postList;
    }
  }

  return (
    <main className={styles.main__container}>
      <section>
        <Nav className={'tab'}>
          <Ul>
            {tabs.map((tab) => (
              <Li
                key={tab.label}
                className={tab.value === selectedTab.value ? 'selected' : ''}
                onClick={() => setSelectedTab(tab)}>
                {tab.label}
                {tab.value === selectedTab.value ? <Underline layoutId="underline" /> : null}
              </Li>
            ))}
          </Ul>
        </Nav>
        <AnimatePresence mode={'wait'}>
          <motion.div
            key={selectedTab.label}
            initial={{ y: 10, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: -10, opacity: 0 }}
            transition={{ duration: 0.2 }}>
            {isLoading ? (
              <MeetupLoader />
            ) : filteredPosts.length > 0 ? (
              filteredPosts.map((post: any) => <PostItem key={post.id} post={post} onModal={onOpenPostModal} />)
            ) : (
              <p className={styles.pending}>We have no posts on {selectedTab.label} yet. </p>
            )}
          </motion.div>
        </AnimatePresence>
      </section>
      <section className={styles.sidebar}>
        <Request />
      </section>
    </main>
  );
};

export default Post;
