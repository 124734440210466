import { createEntityAdapter, createSelector } from '@reduxjs/toolkit';
import { COURSES_TAG, ICourse } from '../../types/api-responses';
import { apiSlice } from '../../app/api/apiSlice';

const coursesAdapter = createEntityAdapter<ICourse>();

const initialState = coursesAdapter.getInitialState();

const coursesApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getCourses: build.query<any, void>({
      query: () => '/lms/course/list?page=1&per_page=50',
      transformResponse: (rawResult: any) => {
        return coursesAdapter.setAll(initialState, rawResult?.response?.data);
      },
      providesTags: (result) =>
        result
          ? [...result.ids.map((id) => ({ type: COURSES_TAG, id })), { type: COURSES_TAG, id: 'LIST' }]
          : [{ type: COURSES_TAG, id: 'LIST' }],
    }),
    getCourseById: build.query<any, string>({
      query: (id: string) => `lms/course/${id}`,
      transformResponse: (rawResult: any) => rawResult?.response,
    }),
  }),
});

export const { useGetCoursesQuery, useGetCourseByIdQuery } = coursesApiSlice;

export const selectCoursesResult = coursesApiSlice?.endpoints.getCourses.select();
const selectCoursesData = createSelector(selectCoursesResult, (coursesResult) => coursesResult?.data);
const { selectAll: selectAllCourses } = coursesAdapter.getSelectors(
  (state: any) => selectCoursesData(state) ?? initialState,
);

export const selectAllCoursesReversed = createSelector(selectAllCourses, (courses) => courses.slice().reverse());
