import React, { useState } from 'react';
import { Actions, Button, Labels, MyTicketsHeader, NewTicketWrapper, Sidebar, SInput, TArea, Title } from './styles';
import { ImAttachment } from 'react-icons/im';
import MyTickets from '../../components/MyTickets/MyTickets';
import { useRaiseTicketMutation } from '../../features/tickets/tickets';
import { useGetUserQuery } from '../../features/user/userApiSlice';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const NewTicket = () => {
  const [raiseTicket, { isLoading }] = useRaiseTicketMutation();
  const { data: user } = useGetUserQuery();
  const [text, setText] = useState('');
  const [title, setTitle] = useState('');
  const buttonContent = isLoading ? <div className={'container dots-flow'} /> : 'Create Ticket';

  const isDirty = text.length === 0 || title.length === 0;
  const resetForm = () => {
    setText('');
    setTitle('');
  };
  const onSubmitTicket = async () => {
    try {
      await raiseTicket({ content: text, author_id: user.id, category_id: 1, title, priority_id: 1 });
      toast.success('Your ticket was submitted');
      resetForm();
    } catch (e) {
      console.log(e);
      toast.error('Something went wrong!');
    }
  };

  return (
    <NewTicketWrapper>
      <div className="main">
        <Title>New Ticket</Title>
        <Labels>Ticket Subject</Labels>
        <SInput type={'text'} placeholder={'Subject'} value={title} onChange={(e) => setTitle(e.target.value)} />
        <Labels>Ticket Details</Labels>
        <TArea rows={10} value={text} onChange={(e) => setText(e.target.value)} />
        <Actions>
          <div className="attach">
            <ImAttachment />
            <p>Add Attachment</p>
          </div>
          <Button
            onClick={onSubmitTicket}
            disabled={isLoading || isDirty}
            whileHover={{ scale: 1.02, backgroundColor: `#014e35` }}
            whileTap={{ scale: 0.97 }}>
            {buttonContent}
          </Button>
        </Actions>
      </div>
      <Sidebar>
        <MyTicketsHeader>My Tickets</MyTicketsHeader>
        {[1, 2, 3, 4].map((el) => (
          <MyTickets key={el} />
        ))}
      </Sidebar>
      <ToastContainer />
    </NewTicketWrapper>
  );
};

export default NewTicket;
