import styled from 'styled-components';
import { motion } from 'framer-motion';

export const Container = styled.div`
  text-align: center;
  position: relative;
  letter-spacing: 0.43px;
  font-size: 2.2rem;
  background-color: #fff;

  .hero_img {
    width: 25rem;
    height: 25rem;
    object-fit: cover;
  }

  h4 {
    font-weight: bold;
    font-size: 3.5rem;
    margin: 1.6rem 0;
  }
`;

export const Confetti = styled.img`
  width: 100%;
  height: 10rem;
  object-fit: cover;
`;

export const Close = styled.div`
  position: absolute;
  right: 1.6rem;
  top: 3rem;
  display: grid;
  place-items: center;
  width: 5rem;
  height: 5rem;
  cursor: pointer;
  background-color: var(--color-accent);
  border-radius: 50%;
  box-shadow: var(--shadow-light);

  svg {
    width: 2rem;
    height: 2rem;
    fill: black;
  }
`;

export const Button = styled(motion.button)`
  background-color: var(--color-primary);
  color: white;
  font-size: 1.8rem;
  text-transform: uppercase;
  font-weight: 500;
  width: max-content;
  padding: 2.1rem;
  margin: 2.7rem auto;
  letter-spacing: 0.6px;
`;
