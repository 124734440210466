import styled from 'styled-components';
import images from '../../constants';

export const HeadContainer = styled.div`
  display: flex;
  column-gap: 2rem;
  margin-bottom: 2rem;
  margin-top: 1.6rem;
`;

export const HeaderWrapper = styled.div`
  border-radius: 10px;
  position: relative;
  flex: 1;
  background-image: url(${images.nairobi});
  background-size: cover;
  background-position: bottom center;
  background-repeat: no-repeat;

  .bg {
    backdrop-filter: blur(5px);
    padding: 2rem;
    border-radius: 10px;
    height: 100%;
    width: 100%;
    color: white;
  }
`;

export const Title = styled.h3`
  font-size: 3.1rem;
  font-weight: 500;
  letter-spacing: 0.5px;
  margin-bottom: 1.6rem;
  text-transform: capitalize;
`;

export const Text = styled.p`
  font-size: 2rem;
  letter-spacing: 0.5px;
`;

export const Score = styled.div`
  height: 20rem;
  font-size: 1.92rem;
  font-weight: 500;
  padding: 1.6rem;
  display: flex;
  justify-content: center;
  flex-flow: column;
  //background-color: var(--color-primary-light-1);
  border-radius: 10px;
  //border: 1px solid #707070;
  text-align: center;
  width: max-content;

  h3 {
    font-size: 3rem;
    font-weight: bold;
    color: var(--color-green);
  }
`;
