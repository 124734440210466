import React from 'react';
import Modal from 'react-modal';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { closeModal, selectCurrentModal } from '../../../../features/modal/modalSlice';
import { MODAL_TYPES } from '../../modalTypes';
import { Button, Cancel, ContentWrapper, Register, SorryWrapper, Text, Title } from './styles';
import { VscChromeClose } from 'react-icons/vsc';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    zIndex: 100,
    border: 'none',
    width: '60rem',
    padding: 0,
  },
};

const SorryModal = () => {
  const dispatch = useAppDispatch();
  const modalType = useAppSelector(selectCurrentModal);
  const isOpen = modalType === MODAL_TYPES.SORRY_MODAL;
  const onSubmitHandler = () => {
    dispatch(closeModal());
  };

  return (
    <Modal
      onRequestClose={onSubmitHandler}
      isOpen={isOpen}
      style={customStyles}
      ariaHideApp={false}
      overlayClassName="Overlay">
      <ContentWrapper>
        <Cancel>
          <VscChromeClose />
        </Cancel>
        <SorryWrapper>
          <Title>Sorry!</Title>
          <Text>You have exceeded your free expert session limit. Continue at ease at a small fee!</Text>
          <Register>Ready to Set Started?</Register>
          <Button>Proceed to pay</Button>
        </SorryWrapper>
      </ContentWrapper>
    </Modal>
  );
};

export default SorryModal;
