import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Profile } from '../../../../types/user';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema } from '../../../../components/LoanModal/CompanyDetails/details-schema';
import { useUpdateBusinessMutation } from '../../../../features/business/businessApiSlice';
import { toast, ToastContainer } from 'react-toastify';
import { closeModal, selectCurrentModal } from '../../../../features/modal/modalSlice';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { Btn, BtnDiv, Content, Form, Note } from '../../../../components/BusinessProfile/EditProfile/styles';
import { Input, Label, Wrapper } from '../../../../components/LoanModal/PersonalDetails/styles';
import Select from 'react-select';
import { Socials, TArea } from '../../../../components/LoanModal/CompanyDetails/styles';
import { ImFacebook2, ImLinkedin } from 'react-icons/im';
import { BsInstagram, BsTwitter } from 'react-icons/bs';
import Modal from 'react-modal';
import { MODAL_TYPES } from '../../modalTypes';
import { selectAllCategories, useGetCategoriesQuery } from '../../../../features/categories/categories';
import { useSelector } from 'react-redux';
import { Close, Container, Header, HeaderActions } from '../LoanModal/styles';
import ReactPhoneInput from 'react-phone-input-2';
import { getCountries } from '../../../../utils/object-mapper';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    border: 'none',
    padding: 0,
    width: '70%',
    borderRadius: '10px',
    height: 'auto',
  },
};

const companyTypes = [
  { value: 'sole proprietor', label: 'Sole Proprietor' },
  { value: 'partnership', label: 'Partnership' },
  { value: ' private limited company', label: 'Private Limited Company' },
  { value: 'company limited by guarantee', label: 'Company Limited by Guarantee' },
  { value: 'NGO', label: 'NGO' },
];
const EditCompanyModal = () => {
  const modalType = useAppSelector(selectCurrentModal);
  const isOpen = modalType === MODAL_TYPES.EDIT_COMPANY_MODAL;
  const [updateBusiness, { isLoading }] = useUpdateBusinessMutation();
  const dispatch = useAppDispatch();
  const { isLoading: loading } = useGetCategoriesQuery();
  const categories = useSelector(selectAllCategories);
  let retrievedProfile = localStorage.getItem('profileData');
  const profileState = JSON.parse(retrievedProfile);

  let options = [];
  categories.filter((c: any) => {
    if (c.type === 'sector') options.push({ label: c.name, value: c.id });
  });
  const onSubmitClose = () => {
    dispatch(closeModal());
  };

  const defaultValues = {
    name: profileState.companyName,
    category_id: profileState.category_id,
    type_of_company: profileState.type_of_company,
    office_location: profileState.office_location,
    office_phone: profileState.phone,
    office_email: profileState.email,
    description: profileState.description,
    linkedin: profileState.linkedin,
    facebook: profileState.facebook,
    company_website: profileState.company_website,
    twitter: profileState.twitter,
    instagram: profileState.instagram,
  };

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors, isDirty },
  } = useForm<Profile>({
    resolver: yupResolver(schema),
    mode: 'all',
  });

  useEffect(() => {
    reset(defaultValues);
  }, []);

  const buttonContent = isLoading ? <div className={'container dots-flow'} /> : 'Continue';
  const onSubmitHandler = async (data) => {
    const userInput = {
      item: {
        ...data,
      },
      id: profileState.businessId,
    };
    try {
      await updateBusiness(userInput).unwrap();
      toast.success('Your business profile was updated successfully');
      onSubmitClose();
      window.location.reload();
    } catch (e) {
      console.log(e);
      toast.error('Could not update \n Something went wrong!');
    }
  };

  return (
    <Modal
      onRequestClose={() => {}}
      isOpen={isOpen}
      style={customStyles}
      ariaHideApp={false}
      overlayClassName="Overlay">
      <Header>
        <h2>Edit your business details</h2>
        <HeaderActions>
          <Close onClick={onSubmitClose} />
        </HeaderActions>
      </Header>
      <Container>
        <div>
          <div className="scrollable">
            <Wrapper>
              <Content>
                <Form id={'create-business'} onSubmit={handleSubmit(onSubmitHandler)}>
                  <div className="flex">
                    <div className={'child'}>
                      <Label>Company name*</Label>
                      <Input type={'text'} autoFocus={true} {...register('name')} required />
                      {errors.name?.message && <small>{errors.name?.message}</small>}
                    </div>
                    <div className={'child'}>
                      <Label>Type of company*</Label>
                      <Controller
                        control={control}
                        render={({ field: { onChange, value, name, ref } }) => {
                          return (
                            <Select
                              value={companyTypes.find((c: any) => c.value === value)}
                              name={name}
                              options={companyTypes}
                              onChange={(selectedOption: any) => {
                                onChange(selectedOption.value);
                              }}
                              styles={{
                                container: (base) => ({
                                  ...base,
                                  fontSize: '1.6rem',
                                }),
                                control: (base) => ({
                                  ...base,
                                  fontSize: '1.6rem',
                                  height: '5.4rem',
                                }),
                              }}
                              isSearchable
                              theme={(theme) => ({
                                ...theme,
                                colors: {
                                  ...theme.colors,
                                  primary25: '#cdd5f7',
                                  primary: '#FFAB00',
                                  neutral20: '#707070',
                                },
                              })}
                              placeholder={'Select Type of Company'}
                            />
                          );
                        }}
                        name={'type_of_company'}
                        rules={{
                          required: true,
                        }}
                      />
                      {errors.type_of_company?.message && <small>{errors.type_of_company?.message}</small>}
                    </div>
                  </div>
                  <div className="flex">
                    <div className={'child'}>
                      <Label>Business sector*</Label>
                      <Controller
                        control={control}
                        render={({ field: { onChange, value, name } }) => {
                          return (
                            <Select
                              value={options.find((c: any) => c.value === value)}
                              name={name}
                              options={options}
                              onChange={(selectedOption: any) => {
                                onChange(selectedOption.value);
                              }}
                              styles={{
                                container: (base) => ({
                                  ...base,
                                  fontSize: '1.6rem',
                                }),
                                control: (base) => ({
                                  ...base,
                                  fontSize: '1.6rem',
                                  height: '5.4rem',
                                }),
                              }}
                              isSearchable
                              theme={(theme) => ({
                                ...theme,
                                colors: {
                                  ...theme.colors,
                                  primary25: '#cdd5f7',
                                  primary: '#FFAB00',
                                  neutral20: '#707070',
                                },
                              })}
                            />
                          );
                        }}
                        name={'category_id'}
                        rules={{
                          required: true,
                        }}
                      />
                      {errors.category_id?.message && <small>{errors.category_id?.message}</small>}
                    </div>
                    <div className={'child'}>
                      <Label>Country of incorporation*</Label>
                      <Controller
                        control={control}
                        render={({ field: { onChange, value, name, ref } }) => {
                          return (
                            <Select
                              value={getCountries.find((c: any) => c.value === value)}
                              name={name}
                              options={getCountries}
                              onChange={(selectedOption: any) => {
                                onChange(selectedOption.value);
                              }}
                              styles={{
                                container: (base) => ({
                                  ...base,
                                  fontSize: '1.6rem',
                                }),
                                control: (base) => ({
                                  ...base,
                                  fontSize: '1.6rem',
                                  height: '5.4rem',
                                }),
                              }}
                              isSearchable
                              theme={(theme) => ({
                                ...theme,
                                colors: {
                                  ...theme.colors,
                                  primary25: '#cdd5f7',
                                  primary: '#FFAB00',
                                  neutral20: '#707070',
                                },
                              })}
                              placeholder={'Select Country'}
                            />
                          );
                        }}
                        name={'office_location'}
                        rules={{
                          required: true,
                        }}
                      />
                      {errors.office_location?.message && <small>{errors.office_location?.message}</small>}
                    </div>
                  </div>
                  <div className="flex">
                    <div>
                      <Label>Company Website</Label>
                      <Input type={'text'} {...register('company_website')} />
                    </div>
                    <div className={'child'}>
                      <Label>Registered phone number*</Label>
                      <Controller
                        control={control}
                        name="office_phone"
                        rules={{ required: true }}
                        render={({ field: { ref, ...field } }) => (
                          <ReactPhoneInput
                            {...field}
                            inputProps={{
                              ref,
                              required: true,
                              autoFocus: true,
                            }}
                            country={'ke'}
                            countryCodeEditable={false}
                            enableSearch={true}
                            regions={['america', 'europe', 'asia', 'oceania', 'africa']}
                          />
                        )}
                      />
                      {errors.office_phone?.message && <small>{errors.office_phone?.message}</small>}
                    </div>
                  </div>
                  <div className="cont">
                    <Label>Registered email address*</Label>
                    <Input type={'email'} {...register('office_email')} />
                    {errors.office_email?.message && <small>{errors.office_email?.message}</small>}
                  </div>
                  <div className="t_area">
                    <Label>Brief business history</Label>
                    <TArea rows={10} {...register('description')} />
                    {errors.description?.message && <small>{errors.description?.message}</small>}
                  </div>
                  <Label>Social media links</Label>
                  <Socials>
                    <ImLinkedin /> <input type={'text'} {...register('linkedin')} />
                  </Socials>
                  <Socials>
                    <BsTwitter /> <input type={'text'} {...register('twitter')} />
                  </Socials>
                  <Socials>
                    <ImFacebook2 /> <input type={'text'} {...register('facebook')} />
                  </Socials>
                  <Socials>
                    <BsInstagram /> <input type={'text'} {...register('instagram')} />
                  </Socials>
                </Form>
              </Content>
              <Note>
                Note: Your details will be subjected to verifications and integrity processes to validate you are a
                legitimate and legal business owner. <br /> <sup>*</sup>Required field
              </Note>
              <BtnDiv>
                <Btn onClick={onSubmitClose} whileTap={{ scale: 0.97 }} whileHover={{ backgroundColor: '#f4ad3d' }}>
                  Cancel
                </Btn>
                <Btn
                  disabled={isLoading || loading || !isDirty}
                  whileTap={{ scale: 0.97 }}
                  whileHover={{ backgroundColor: '#014e35' }}
                  className={'green'}
                  type={'submit'}
                  form={'create-business'}>
                  {buttonContent}
                </Btn>
              </BtnDiv>
            </Wrapper>
          </div>
        </div>
      </Container>
      <ToastContainer autoClose={1000} />
    </Modal>
  );
};

export default EditCompanyModal;
