import React from 'react';
import {
  Asset,
  AssetContainer,
  AssetImage,
  AssetsWrapper,
  Container,
  FundingHeader,
  Name,
  Steps,
  Title,
} from './styles';
import images from '../../../constants';
import { useAppDispatch } from '../../../app/hooks';
import { openModal } from '../../../features/modal/modalSlice';
import { MODAL_TYPES } from '../../../modules/ModalWindow/modalTypes';

function FundingAssets() {
  const dispatch = useAppDispatch();

  const openApplyLoanModal = () => {
    dispatch(openModal({ modalType: MODAL_TYPES.PRODUCT_MODAL }));
  };
  const e_mobility = [
    {
      name: 'Electric Boda',
      imageUrl:
        'https://firebasestorage.googleapis.com/v0/b/nectar-f5ee3.appspot.com/o/funding%2Felectric-motor.svg?alt=media&token=69ad6854-3423-4f63-970c-c046b135f99f',
    },
    {
      name: 'Electric TukTuk',
      imageUrl:
        'https://firebasestorage.googleapis.com/v0/b/nectar-f5ee3.appspot.com/o/funding%2Ftuktuk.svg?alt=media&token=215b4262-4e74-4e18-b02b-8cae10e6a1f4',
    },
    {
      name: 'Electric Matatu',
      imageUrl: images.matatu,
    },
  ];
  const solar_equipment = [
    {
      name: 'Solar Panels',
      imageUrl:
        'https://firebasestorage.googleapis.com/v0/b/nectar-f5ee3.appspot.com/o/funding%2Fsolar-panel.svg?alt=media&token=54fb80ba-d051-47d6-8f1a-27bf1caf5428',
    },
    {
      name: 'Solar Fridges',
      imageUrl:
        'https://firebasestorage.googleapis.com/v0/b/nectar-f5ee3.appspot.com/o/funding%2Fsolar-fridge.svg?alt=media&token=73951530-d898-4dbb-9c69-20801c3f4405',
    },
    {
      name: 'Solar Irrigation',
      imageUrl: images.irrigation,
    },
  ];

  const loans = [
    {
      name: 'Working Capital',
      imageUrl: images.loan2,
    },
    {
      name: 'Invoice Financing',
      imageUrl: images.loan1,
    },
    {
      name: 'Supply Chain Financing',
      imageUrl: images.loan4,
    },
  ];

  const financing = [
    {
      name: 'Merchant Cash Advance',
      imageUrl:
        'https://firebasestorage.googleapis.com/v0/b/nectar-f5ee3.appspot.com/o/funding%2Frevenue_icon.svg?alt=media&token=d1fee775-03bb-49e4-9a3f-e63464086903',
    },
    {
      name: 'Revenue Advance',
      imageUrl:
        'https://firebasestorage.googleapis.com/v0/b/nectar-f5ee3.appspot.com/o/funding%2Fmoney_icon.svg?alt=media&token=9822f1e9-199e-4a6f-819d-243cc56794fa',
    },
  ];
  return (
    <div>
      <FundingHeader>
        <h3>Select the type of financing you need</h3>
        <p>We help you acquire funds to purchase green equipments and your working capital based on your needs</p>
      </FundingHeader>
      <Steps>
        <img src={images.steps} alt="Steps to ge an asset finance" />
      </Steps>
      <AssetsWrapper>
        <Title>E-mobility Assets</Title>
        <AssetContainer>
          {e_mobility.map((asset, index) => (
            <Asset key={index}>
              <Container>
                <AssetImage src={asset.imageUrl} alt={asset.name} />
              </Container>
              <Name>{asset.name}</Name>
              <button onClick={openApplyLoanModal}>Apply For Asset</button>
            </Asset>
          ))}
        </AssetContainer>
        <Title>Solar Equipment Assets</Title>
        <AssetContainer>
          {solar_equipment.map((asset, index) => (
            <Asset key={index}>
              <Container>
                <AssetImage src={asset.imageUrl} alt={asset.name} />
              </Container>
              <Name>{asset.name}</Name>
              <button className={'noClick'}>Apply For Asset</button>
            </Asset>
          ))}
        </AssetContainer>
        <Title>Working Capital</Title>
        <AssetContainer>
          {loans.map((asset, index) => (
            <Asset key={index}>
              <Container>
                <AssetImage src={asset.imageUrl} alt={asset.name} />
              </Container>
              <Name>{asset.name}</Name>
              <button className={'noClick'}>Apply For Asset</button>
            </Asset>
          ))}
        </AssetContainer>
        {/*  <Title>Revenue Financing</Title>
        <AssetContainer>
          {financing.map((asset, index) => (
            <Asset key={index}>
              <Container><AssetImage src={asset.imageUrl} alt={asset.name} /></Container>
              <Name>{asset.name}</Name>
              <button>Apply Loan</button>
            </Asset>
          ))}
        </AssetContainer>*/}
      </AssetsWrapper>
    </div>
  );
}

export default FundingAssets;
