import React from 'react';
import Modal from 'react-modal';
import { Close, Container } from '../CheckMail/styles';
import { closeModal, selectCurrentModal } from '../../../../features/modal/modalSlice';
import images from '../../../../constants';
import { MODAL_TYPES } from '../../modalTypes';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { useGetUserQuery } from '../../../../features/user/userApiSlice';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    zIndex: 100,
    border: 'none',
    width: '75%',
    borderRadius: '20px',
    maxWidth: '600px',
  },
};

const AppointModal = ({ onClose }) => {
  const { data: user } = useGetUserQuery();
  const dispatch = useAppDispatch();
  const modalType = useAppSelector(selectCurrentModal);
  const isOpen = modalType === MODAL_TYPES.APPOINT_MODAL;
  return (
    <Modal
      onRequestClose={() => dispatch(closeModal())}
      isOpen={isOpen}
      style={customStyles}
      ariaHideApp={false}
      overlayClassName="Overlay">
      <Container>
        <Close onClick={() => dispatch(closeModal())} />
        <h3>Appointment Sent</h3>
        <img src={images.checkMail} alt="forgot password" />
        <p>We will communicate your appointment details via your email: {user.email}</p>
      </Container>
    </Modal>
  );
};

export default AppointModal;
