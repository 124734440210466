import React from 'react';
import styles from './ProfilePick.module.scss';
import images from '../../constants';
import { BsArrowRight } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';

function ProfilePick() {
  const options = [
    {
      image: images.entrepreneur_profile,
      title: 'Entrepreneur',
      text: 'For people who own SMEs',
    },
    {
      image: images.lender_profile,
      title: 'Lender',
      text: 'For people who want to lend to SMEs',
    },
    {
      image: images.investor_profile,
      title: 'Investor',
      text: 'For people who want to invest in SMEs',
    },
  ];
  const navigate = useNavigate();

  return (
    <main className={styles.container}>
      <section style={{ backgroundImage: `url(${images.africaLight})` }} className={styles.welcome}>
        <img src={images.logoWhite} alt="Melanin Kapital Logo" />
        <p>Thank you for signing up!</p>
        <h2>Tell us how you would like Us to customize your Account</h2>
        <p className={styles.text}>
          We will try and create a suitable account Based on your needs and goals. Either as An entrepreneur or investor
          we got you Covered
        </p>
      </section>
      <section className={styles.content}>
        <h1>
          Karibu sana Tafari! Let us know <br />
          What best describes you
        </h1>
        <h6>Kindly select one and proceed to the next step</h6>
        <div className={styles.profiles}>
          {options.map((profile) => (
            <div className={styles.profiles_card} key={profile.title}>
              <img src={profile.image} alt={profile.title} />
              <h3>{profile.title}</h3>
              <p>{profile.text}</p>
              <button onClick={() => navigate('/packages')}>
                <span>Select</span> <BsArrowRight />
              </button>
            </div>
          ))}
        </div>
      </section>
    </main>
  );
}

export default ProfilePick;
