import styled from 'styled-components';

export const Wrapper = styled.main`
  background-color: var(--color-accent-light);
  min-height: 100vh;
  position: relative;

  .left {
    clip-path: polygon(0 0, 65% 0, 35% 100%, 0% 100%);
    background-color: var(--color-primary);
    min-height: 100vh;
  }
`;

export const Logo = styled.img`
  width: auto;
  height: 4rem;
  object-fit: contain;
  cursor: pointer;
  position: absolute;
  left: 2rem;
  top: 2rem;
  z-index: 2;
`;
