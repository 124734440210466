import styled from 'styled-components';
import { motion } from 'framer-motion';

export const Nav = styled.nav`
  background: #fdfdfd;
  padding: 0.5rem 0.5rem 0;
  border-radius: 10px 10px 0 0;
  border-bottom: 1px solid #eeeeee;
  height: 5rem;
`;

export const Ul = styled.ul`
  display: flex;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
`;

export const Li = styled.li`
  list-style: none;
  border-radius: 5px 5px 0 0;
  width: 100%;
  padding: 1rem 1.5rem;
  position: relative;
  background: white;
  cursor: pointer;
  height: 100%;
  flex: 1;
  min-width: 0;
  user-select: none;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-flow: column;
  font-size: 1.6rem;

  &.selected {
    font-weight: 500;
    background: #eee;
    color: var(--color-primary);
  }
`;

export const Underline = styled(motion.div)`
  position: absolute;
  bottom: -1px;
  left: 0;
  right: 0;
  height: 1px;
  background: var(--color-primary);
`;
