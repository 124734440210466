import React from 'react';
import { Action, ActionCard, HandIcon, Wrapper } from './styles';
import { BsArrowRight } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import pageRoutes from '../../constants/pageRoutes';

const academy = `/${pageRoutes.DASHBOARD}/${pageRoutes.ACADEMY}`;
const experts = `/${pageRoutes.DASHBOARD}/${pageRoutes.EXPERTS}`;
const connect = `/${pageRoutes.DASHBOARD}/${pageRoutes.KONNECT}/${pageRoutes.REQUESTS}/${pageRoutes.EXPLORE}`;

const SplasherActions = () => {
  const navigate = useNavigate();

  const onConnect = () => {
    navigate(connect);
  };

  const onExperts = () => {
    navigate(experts);
  };

  const onAcademy = () => {
    navigate(academy);
  };

  return (
    <Wrapper>
      <h2>How can we help your business today?</h2>
      <div className="cards">
        <ActionCard>
          <h4>Yearning to Expand Your Network?</h4>
          <p>Connect with investors and other entrepreneurs and increase your company's reach!</p>
          <Action onClick={onConnect} whileHover={{ scale: 1.03 }}>
            Connect Now <BsArrowRight />
          </Action>
          <HandIcon>
            <img
              src={
                'https://firebasestorage.googleapis.com/v0/b/nectar-f5ee3.appspot.com/o/d4%2Fhandcoin.svg?alt=media&token=eda70d3e-2ff6-4173-8551-8772ead48b47'
              }
              alt="credit icon"
            />
          </HandIcon>
        </ActionCard>
        <ActionCard className={'second'}>
          <h4>Seeking Professional Consultation?</h4>
          <p>Schedule an appointment and learn from the best in the market today!</p>
          <Action onClick={onExperts} className={'second'} whileHover={{ scale: 1.03 }}>
            FIND AN EXPERT <BsArrowRight />
          </Action>
          <HandIcon className={'second'}>
            <img
              src={
                'https://firebasestorage.googleapis.com/v0/b/nectar-f5ee3.appspot.com/o/d4%2Fqualify.svg?alt=media&token=c8d3074e-2ce9-4ce9-8616-03d8dd5c38c1'
              }
              alt="credit icon"
            />
          </HandIcon>
        </ActionCard>
        <ActionCard>
          <h4>Looking to Grow Your Business?</h4>
          <p>Get more tips, insights and certifications from our masterclasses today!</p>
          <Action whileHover={{ scale: 1.03 }} onClick={onAcademy}>
            START LEARNING
            <BsArrowRight />
          </Action>
          <HandIcon>
            <img
              src={
                'https://firebasestorage.googleapis.com/v0/b/nectar-f5ee3.appspot.com/o/d4%2Ftokens.svg?alt=media&token=5275f160-c6f6-4ce1-b091-1659436a0fc5'
              }
              alt="credit icon"
            />
          </HandIcon>
        </ActionCard>
      </div>
    </Wrapper>
  );
};

export default SplasherActions;
