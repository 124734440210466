import styled from 'styled-components';
import { motion } from 'framer-motion';

export const Wrapper = styled.main`
  padding: 4rem 4rem 3rem;
  border-radius: 10px;
`;

export const Header = styled.div`
  background-color: var(--color-primary);
  border-radius: 10px 10px 0 0;
  padding: 2rem;
  font-weight: 500;
  color: white;
  font-size: 2.2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: -4rem -4rem 2rem;

  svg {
    cursor: pointer;
    width: 3rem;
    height: 3rem;
    fill: white !important;
  }
`;

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 2rem;
`;

export const Asset = styled.div`
  background-color: var(--color-primary-light-1);
  border-radius: 10px;
  border: 1px solid var(--color-primary);
  padding: 1.6rem 3.2rem 1.6rem 1.6rem;
  display: flex;
  align-items: flex-start;
  column-gap: 1rem;
  margin-bottom: 1rem;

  .image {
    border-radius: 50%;
    background-color: black;
    padding: 0.3rem;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 4rem;
      height: 4rem;
    }
  }
`;

export const Title = styled.p`
  font-size: 1.92rem;
  font-weight: 500;
  display: flex;
  align-items: baseline;
  column-gap: 0.4rem;

  span {
    font-weight: lighter;
    font-size: 1.6rem;
    color: #707070;
  }
`;

export const Desc = styled.p`
  color: #707070;
  font-size: 1.6rem;
`;

export const Text = styled(Title)`
  font-size: 1.72rem;
`;

export const Extra = styled(Title)`
  color: #707070;
  font-weight: lighter;
  font-size: 1.72rem;
`;

export const Card = styled.div`
  position: relative;
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid var(--color-primary);
  padding: 1.6rem;
  margin-bottom: 0.7rem;

  img {
    height: 18rem;
    width: 30rem;
    object-fit: contain;
  }
`;

export const Arrow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  background-color: var(--color-accent);
  cursor: pointer;
  border-radius: 50%;
  position: absolute;
  top: 40%;
  height: max-content;

  svg {
    width: 2rem;
    height: 2rem;
    fill: black;
  }

  &.left {
    left: -2rem;
  }

  &.right {
    right: -2rem;
  }

  &.inactive {
    cursor: not-allowed;
    background-color: #707070;
    pointer-events: none;
  }
`;

export const BtnDiv = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(3, 1fr);
  column-gap: 1.6rem;
  row-gap: 2rem;
  margin-top: 3.5rem;
`;
export const Btn = styled(motion.button)`
  color: white;
  text-transform: uppercase;
  padding: 2rem;
  font-size: 1.6rem;
  font-weight: 500;
  border-radius: 5px;
  background-color: var(--color-primary);

  &.green {
    background-color: var(--color-green);
  }

  &.apply {
    background-color: var(--color-accent);
    color: black;
  }
`;

export const SpecsContainer = styled.div`
  border-radius: 10px;
  border: 1px solid var(--color-primary);
  padding: 1.6rem;
  position: relative;
`;

export const Specs = styled(Title)`
  margin-bottom: 2rem;
  margin-top: 0.5rem;
`;
export const Color = styled.div<{ color: string }>`
  position: absolute;
  right: 1.6rem;
  bottom: 1.6rem;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  background-color: ${(props) => props.color};
`;
