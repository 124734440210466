import React from 'react';
import { Card, Header, Tip, Wrapper } from './styles';

function RiskProfileOptions() {
  return (
    <Wrapper>
      <Tip>
        <Header>Tips To Improve Your Credit Score</Header>
        <Card>
          <img
            src="https://firebasestorage.googleapis.com/v0/b/nectar-f5ee3.appspot.com/o/cedit%2Fon-time-icon.svg?alt=media&token=c5fd1621-e06e-4a7d-a639-d6bab67fbfe5"
            alt="One time payments image"
          />
          <div>
            <h5>One-time payments</h5>
            <p>Paying your loans on time shows your capability of handling credit responsibly</p>
          </div>
        </Card>
        <Card>
          <img
            src="https://firebasestorage.googleapis.com/v0/b/nectar-f5ee3.appspot.com/o/cedit%2FEconomic_sustainability.svg?alt=media&token=f89cf77a-1b8e-4cb4-8d9e-44fcc7c059e8"
            alt="Economic Sustainability"
          />
          <div>
            <h5>Lower Credit Utilization</h5>
            <p>Keep your total outstanding balance at 30% or less of your total credit limit.</p>
          </div>
        </Card>
        <Card>
          <img
            src="https://firebasestorage.googleapis.com/v0/b/nectar-f5ee3.appspot.com/o/cedit%2Fhandcoin.svg?alt=media&token=b6ef4a13-8005-4741-91b1-50dc1574b340"
            alt="Credit requests image"
          />
          <div>
            <h5>Fewer Credit Requests</h5>
            <p>Limit your requests on credit to avoid seeming like you are facing financial difficulties</p>
          </div>
        </Card>
        <Card>
          <img
            src="https://firebasestorage.googleapis.com/v0/b/nectar-f5ee3.appspot.com/o/cedit%2Freports_icon.svg?alt=media&token=fc304a4c-168a-431d-bb08-9e35b61d1f37"
            alt="Credit reports image"
          />
          <div>
            <h5>Review your credit reports</h5>
            <p>Regularly check your credit history to know what's helping or hurting your score.</p>
          </div>
        </Card>
      </Tip>
      <Tip>
        <Header>Benefits of Improving your Credit Score</Header>
        <Card>
          <img
            src="https://firebasestorage.googleapis.com/v0/b/nectar-f5ee3.appspot.com/o/cedit%2Floan-approval.svg?alt=media&token=6f6eb106-bd82-4973-81e7-c049fa73367f"
            alt="Loan Approval Image"
          />
          <div>
            <h5>Faster Loan Approval</h5>
            <p>A higher credit score helps you stand a better chance of being approved for a loan</p>
          </div>
        </Card>
        <Card>
          <img
            src="https://firebasestorage.googleapis.com/v0/b/nectar-f5ee3.appspot.com/o/cedit%2Flow_interest_rate.svg?alt=media&token=a4cbd0b8-f9b6-4b4a-a060-5df75948f696"
            alt="Low Interest Rate Image"
          />
          <div>
            <h5>Lower Interest Rates</h5>
            <p>A good credit score helps you access cheaper borrowing through better loan interest rates.</p>
          </div>
        </Card>
        <Card>
          <img
            src="https://firebasestorage.googleapis.com/v0/b/nectar-f5ee3.appspot.com/o/cedit%2Fcredit_limit.svg?alt=media&token=1defef37-814c-4094-bfe8-d6bda11ee2d8"
            alt="Credit limit image"
          />
          <div>
            <h5>Higher Credit Limits</h5>
            <p>Improving your credit score helps you get a better chance of borrowing larger amounts.</p>
          </div>
        </Card>
      </Tip>
    </Wrapper>
  );
}

export default RiskProfileOptions;
