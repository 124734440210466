import React, { useState } from 'react';
import { IContainer, Input, Label } from '../PersonalDetails/styles';
import Select from 'react-select';
import { Socials, TArea } from './styles';
import { ImFacebook2, ImLinkedin } from 'react-icons/im';
import { BsTwitter } from 'react-icons/bs';
import { Btn, BtnDiv, Content, Form, Note } from '../../BusinessProfile/EditProfile/styles';
import { schema } from './details-schema';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { toast, ToastContainer } from 'react-toastify';
import { selectAllCategories, useGetCategoriesQuery } from '../../../features/categories/categories';
import { useSelector } from 'react-redux';
import { useCreateBusinessMutation } from '../../../features/business/businessApiSlice';
import { useGetUserQuery } from '../../../features/user/userApiSlice';
import { useNavigate } from 'react-router-dom';

const options = [
  { value: 'uganda', label: 'Uganda' },
  { value: 'kenya', label: 'Kenya' },
  { value: 'tanzania', label: 'Tanzania' },
  { value: 'rwanda', label: 'Rwanda' },
  { value: 'burundi', label: 'Burundi' },
];

const CompanyDetailsMd = () => {
  const navigate = useNavigate();

  const [createBusiness, { isLoading }] = useCreateBusinessMutation();
  const { data: user } = useGetUserQuery();
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    control,
    formState: { errors, isDirty, isValid },
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  const { isLoading: loading } = useGetCategoriesQuery();
  const categories = useSelector(selectAllCategories);

  let options = [];
  categories.map((c: any) => options.push({ label: c.name, value: c.id }));

  const onSubmitHandler = async (data) => {
    const userInput = {
      ...data,
      created_by: user.id,
    };
    try {
      await createBusiness(userInput).unwrap();
      toast.success('Your business profile was updated successfully');
      navigate(-1);
    } catch (e) {
      console.log(e);
      toast.error('Could not update \n Something went wrong!');
    }
  };

  const buttonContent = isLoading ? <div className={'container dots-flow'} /> : 'Continue';

  return (
    <>
      <Content>
        <Form id={'create-business'} onSubmit={handleSubmit(onSubmitHandler)}>
          <IContainer rows={3}>
            <div>
              <Label>Company name</Label>
              <Input type={'text'} autoFocus {...register('name')} />
            </div>
            <div>
              <Label>Type of company</Label>
              <Input {...register('type_of_company')} />
            </div>
            <div>
              <Label>Business sector</Label>
              <Controller
                control={control}
                render={({ field: { onChange, value, name, ref } }) => {
                  return (
                    <Select
                      value={options.find((c: any) => c.value === value)}
                      name={name}
                      options={options}
                      onChange={(selectedOption: any) => {
                        onChange(selectedOption.value);
                      }}
                      styles={{
                        container: (base) => ({
                          ...base,
                          fontSize: '1.6rem',
                        }),
                        control: (base) => ({
                          ...base,
                          fontSize: '1.6rem',
                          height: '5.4rem',
                        }),
                      }}
                      isSearchable
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary25: '#cdd5f7',
                          primary: '#FFAB00',
                          neutral20: '#707070',
                        },
                      })}
                    />
                  );
                }}
                name={'category_id'}
                rules={{
                  required: true,
                }}
              />
            </div>
            <div>
              <Label>Office location </Label>
              <Input type={'text'} {...register('office_location')} />
            </div>
            <div>
              <Label>Company Website</Label>
              <Input type={'text'} {...register('company_website')} />
            </div>
            <div>
              <Label>Registered phone number</Label>
              <Input type={'tel'} {...register('office_phone')} />
            </div>
          </IContainer>
          <Label>Registered email address</Label>
          <Input type={'email'} {...register('office_email')} />
          <Label>Brief business history</Label>
          <TArea maxLength={250} rows={10} {...register('business_history')} />
          <Label>Social media handles</Label>
          <Socials>
            <ImLinkedin /> <input type={'text'} {...register('instagram')} />
          </Socials>
          <Socials>
            <BsTwitter /> <input type={'text'} {...register('twitter')} />
          </Socials>
          <Socials>
            <ImFacebook2 /> <input type={'text'} {...register('facebook')} />
          </Socials>
        </Form>
      </Content>
      <Note>
        Note: Your details will be subjected to verifications and integrity processes to validate you are a legitimate
        and legal business owner
      </Note>
      <ToastContainer autoClose={1000} />
    </>
  );
};

export default CompanyDetailsMd;
