import React from 'react';
import {
  ClassesContainer,
  ClassesContent,
  ClassSummary,
  Desc,
  Header,
  HeadersWrapper,
  HRule,
  ImpactWrapper,
  PieSection,
  Score,
  What,
} from './styles';
import { BsInfoCircle } from 'react-icons/bs';
import Chart from 'react-apexcharts';
import pageRoutes from '../../../constants/pageRoutes';
import { useNavigate } from 'react-router-dom';

const ImpactProfile = () => {
  const climateRoute = `/${pageRoutes.CLIMATE_QUIZ}`;
  const navigate = useNavigate();
  let chartOptions = {
    options: {
      plotOptions: {
        radialBar: {
          startAngle: 0,
          endAngle: 270,
          hollow: {
            margin: 5,
            size: '30%',
            background: 'transparent',
            image: undefined,
          },
          dataLabels: {
            name: {
              show: false,
            },
            value: {
              show: false,
            },
          },
        },
      },
      colors: ['#676767', '#4d4d4d', '#343434'],
      labels: ['Class 1', 'Class 2', 'Class 3'],
      legend: {
        show: false,
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              show: false,
            },
          },
        },
      ],
    },
    series: [16, 0.38, 83.62],
  };

  return (
    <ImpactWrapper>
      <Header>My Carbon Emissions Calculations</Header>
      <div className="content">
        <div>
          <HeadersWrapper>
            <p className="est">Estimated Total Emissions</p>
            <div>
              <p>
                tonnes CO<sub>2</sub>
              </p>
              <BsInfoCircle />
            </div>
          </HeadersWrapper>
          <Score>97.6</Score>
          <Desc>
            Your emissions estimate is only based on the expenses and activities you provide. Missing expenses or
            activities would mean your full carbon footprint is not covered. Check out our <a href="">methodology</a> to
            better understand how we estimate your emissions from your expense data
          </Desc>
        </div>
        <div>
          <HeadersWrapper>
            <p className="est">Your Class Overview</p>
            <div>
              <p>
                tonnes CO<sub>2</sub>
              </p>
              <BsInfoCircle />
            </div>
          </HeadersWrapper>
          <PieSection>
            <Chart
              options={chartOptions.options}
              series={chartOptions.series}
              type="radialBar"
              width={'100%'}
              height={'auto'}
            />
            <div>
              <ClassSummary>
                <p>
                  <span style={{ backgroundColor: '#676767' }} /> Class-1 Direct emissions
                </p>
                <h5>15.7</h5>
              </ClassSummary>
              <HRule />
              <ClassSummary>
                <p>
                  <span style={{ backgroundColor: '#4d4d4d' }} /> Class-2 Indirect emissions
                </p>
                <h5>0.37</h5>
              </ClassSummary>
              <HRule />
              <ClassSummary>
                <p>
                  <span style={{ backgroundColor: '#343434' }} /> Class-3 Supply Chain emissions
                </p>
                <h5>81</h5>
              </ClassSummary>
            </div>
          </PieSection>
        </div>
      </div>
      <What>What are classes?</What>
      <ClassesContainer>
        <ClassesContent>
          <p className="head">Class 1</p>
          <p>
            Direct emissions from the combustion of fuel in assets that a company operates. Such as fuel emissions from
            company owned cars, diesel generators, etc
          </p>
        </ClassesContent>
        <ClassesContent>
          <p className="head">Class 2</p>
          <p>Indirect emissions from energy purchased from a utility provider such as heating, etc</p>
        </ClassesContent>

        <ClassesContent>
          <p className="head">Class 3</p>
          <p>
            All indirect green house gas emissions that do not fall under Class 1 & Class 2. This includes upstream
            emissions from a company's suppliers.
          </p>
        </ClassesContent>
      </ClassesContainer>
    </ImpactWrapper>
  );
};

export default ImpactProfile;
