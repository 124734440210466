import React from 'react';
import { Quiz, SideBar, Topic, Wrapper } from './styles';
import ReactPlayer from 'react-player';
import { Video } from '../Course/styles';
import { getSurveyJSON } from '../../../../survey/entrepreneur-survey';
import SurveyComponent from '../../../../components/survey/SurveyComponent';
import { useParams } from 'react-router-dom';
import { useGetLessonsQuery } from '../../../../features/lessons/lessonsApiSlice';
import MeetupLoader from '../../../../components/Loaders/MeetupLoader';
import { useGetQuizesQuery } from '../../../../features/lesson/questionsApiSlice';

const Lesson = () => {
  const { courseId, lessonId } = useParams();
  const { isLoading, isSuccess, isError, error, data } = useGetLessonsQuery(courseId);

  const {
    isLoading: quizLoading,
    isSuccess: quizSuccess,
    isError: quizIsError,
    error: quizError,
    data: quizList,
  } = useGetQuizesQuery(lessonId);
  let lesson;
  let lessons = [];
  let questions = [];
  let surveyJSON = {};
  if (isLoading) {
    return <MeetupLoader />;
  } else if (isSuccess && quizSuccess) {
    lessons = data.ids.map((id) => {
      return data.entities[id];
    });
    lesson = lessons.find((obj) => obj.id === +lessonId);
    questions = quizList.ids.map((id) => {
      return quizList.entities[id];
    });
    surveyJSON = getSurveyJSON(questions);
  } else if (isError) {
    console.log(error);
  }
  const handleCompleteForm = () => {};

  return (
    <Wrapper>
      <div>
        <div className="title">
          <span>LESSON:</span>
          {lesson?.title}
        </div>
        <Video>
          <ReactPlayer url={lesson?.video_url} controls={true} width="100%" height={'350px'} light={true} />
        </Video>
        <div className="header">Answer the following questions to increase your chances of getting a certification</div>
        <SurveyComponent
          json={surveyJSON}
          data={{}}
          onComplete={handleCompleteForm}
          // showCompletedPage={false}
        />
      </div>
      <SideBar>
        <div className="main">
          <Topic>Lesson Questions</Topic>
          {questions.map((quiz, index) => (
            <Quiz key={index}>
              <div className="chip">
                <p>{index + 1}</p>
              </div>
              <p>{quiz.question}</p>
            </Quiz>
          ))}
        </div>
      </SideBar>
    </Wrapper>
  );
};

export default Lesson;
