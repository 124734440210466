import { createEntityAdapter, createSelector } from '@reduxjs/toolkit';
import { apiSlice } from '../../app/api/apiSlice';
import { CONNECTS } from '../../types/api-responses';

const connectAdapter = createEntityAdapter();
const initialState = connectAdapter.getInitialState();

const connectApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getAllUsers: build.query<any, void>({
      query: () => '/all/entrepreneur',
      transformResponse: (rawResult: any) => connectAdapter.setAll(initialState, rawResult?.response),
      providesTags: () => [{ type: CONNECTS, id: 'USER_LIST' }],
    }),
  }),
});

export const { useGetAllUsersQuery } = connectApiSlice;
const selectUsersResult = connectApiSlice?.endpoints?.getAllUsers.select();
const selectUsersData = createSelector(selectUsersResult, (usersResult) => usersResult?.data);

export const { selectAll: selectAllUsers } = connectAdapter.getSelectors(
  (state: any) => selectUsersData(state) ?? initialState,
);
