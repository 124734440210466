import { motion } from 'framer-motion';
import React from 'react';
import Lottie from 'react-lottie-player';
import lottieJson from '../../assets/call.json';
import styles from './Contact.module.scss';

const Contact = () => {
  const onOpenWhatsapp = () => {
    window.open('https://wa.me/+254703680991', '_blank');
  };

  return (
    <section className={styles.container}>
      <section>
        <h3>Do you still need our help?</h3>
        <p>Call us today</p>
        <motion.button
          whileHover={{ scale: 1.02, backgroundColor: '#014e35' }}
          whileTap={{ scale: 1 }}
          onClick={onOpenWhatsapp}>
          contact us
        </motion.button>
      </section>
      <Lottie loop animationData={lottieJson} play />
    </section>
  );
};

export default Contact;
