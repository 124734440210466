import React from 'react';
import { Arrow, Card, CardContainer, Header, MidSec, Point, SideHeader, SideWrapper, Wrapper } from './styles';
import { BsArrowUpRight, BsDiamondFill } from 'react-icons/bs';
import ImpactProfile from '../ImpactProfile/ImpactProfile';
import pageRoutes from '../../../constants/pageRoutes';
import { useNavigate } from 'react-router-dom';

function ClimateProfile() {
  const climateRoute = `/${pageRoutes.CLIMATE_QUIZ}`;
  const navigate = useNavigate();

  const handleCancel = () => {
    navigate(climateRoute);
  };

  return (
    <>
      <Wrapper>
        <section>
          <Header>
            <div>
              <h1>Starting your Journey to net Zero?</h1>
              <p>
                We help you understand your business's emissions and guide your business towards a more <br />
                sustainable future
              </p>
            </div>
          </Header>
          <MidSec>
            <h2>What kind of climate service would you like to get for your business?</h2>
            <CardContainer>
              <Card color={'#2445D4'}>
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/nectar-f5ee3.appspot.com/o/climate%2Fcalculator.svg?alt=media&token=d981584e-96ec-4eb7-887b-3a29f9235134"
                  alt="Calculator image"
                />
                <h3>Measure</h3>
                <p>
                  Add your business data to our free Business Carbon Calculator, powered by Planelty, and get clear,
                  actionable insights into our emissions.
                </p>
                <Arrow color={'#2445D4'} onClick={handleCancel}>
                  <BsArrowUpRight />
                </Arrow>
              </Card>
              <Card color={'#FFAB00'}>
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/nectar-f5ee3.appspot.com/o/climate%2FCO2-reduce.svg?alt=media&token=ecfe1cda-b946-4ff8-bd16-123d3147cd9c"
                  alt="Carbon reduction image"
                />
                <h3>Reduce</h3>
                <p>
                  Get tips and advice to help improve your business's sustainability by reducing your carbon and
                  environmental impact.
                </p>
                <Arrow color={'#FFAB00'}>
                  <BsArrowUpRight />
                </Arrow>
              </Card>
              <Card color={'#018158'}>
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/nectar-f5ee3.appspot.com/o/climate%2Fsell.svg?alt=media&token=53688d10-c5e0-421f-beb9-2eb69bb20cec"
                  alt="Trade Image"
                />
                <h3>Trade</h3>
                <p>
                  Make money while saving the planet by selling the carbon credits your business earned from reducing
                  its carbon footprint.
                </p>
                <Arrow color={'#018158'}>
                  <BsArrowUpRight />
                </Arrow>
              </Card>
            </CardContainer>
          </MidSec>
        </section>
        <section>
          <SideWrapper>
            <SideHeader>Climate Terminologies</SideHeader>
            <p>Know how your business relates with the climate with the following terms :-</p>
            <Point>
              <BsDiamondFill />
              <div>
                <p className="head">Net Zero</p>
                <p>
                  It means achieving a balance between the greenhouse emissions produced and those taken out of the
                  atmosphere in order to reduce global warming
                </p>
              </div>
            </Point>
            <Point>
              <BsDiamondFill />
              <div>
                <p className="head">Greenhouse Gases</p>
                <p>
                  Refers to the gases in the atmosphere that have an influence on the earth's energy balance such as
                  carbon dioxide, methane, nitrous oxide and water vapor.
                </p>
              </div>
            </Point>
            <Point>
              <BsDiamondFill />
              <div>
                <p className="head">Global Warming</p>
                <p>
                  It is the gradual increase in the earth's temperature caused by the release of greenhouse gases into
                  the atmosphere
                </p>
              </div>
            </Point>
          </SideWrapper>
        </section>
      </Wrapper>
      <ImpactProfile />
    </>
  );
}

export default ClimateProfile;
//BsArrowUpRight
