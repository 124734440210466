import React from 'react';
import { Card, Container, Title } from './styles';
import { useLocation, useNavigate } from 'react-router-dom';
import pageRoutes from '../../../constants/pageRoutes';
import { useGetOverviewQuery } from '../../../features/enroll/enrollApiSlice';
import { useGetUserQuery } from '../../../features/user/userApiSlice';

const AcademyHeader = () => {
  const options = [
    {
      imageUrl:
        'https://firebasestorage.googleapis.com/v0/b/nectar-f5ee3.appspot.com/o/academy%2Fgreen%2Fbook-morph.svg?alt=media&token=2b94cf1d-53d6-4100-996a-c2b33f21af4e',
      title: 'Enrolled Classes',
      value: '0',
      link: pageRoutes.ENROLLED,
    },
    {
      imageUrl:
        'https://firebasestorage.googleapis.com/v0/b/nectar-f5ee3.appspot.com/o/academy%2Fgreen%2FCertificate.svg?alt=media&token=7ef32009-bf68-4e23-b5db-2fe22103683f',
      title: 'My Certifications',
      value: '0',
      link: pageRoutes.CERTIFICATIONS,
    },
    {
      imageUrl:
        'https://firebasestorage.googleapis.com/v0/b/nectar-f5ee3.appspot.com/o/academy%2Fgreen%2Fvictory-success.svg?alt=media&token=49bc3b3d-a1bd-458c-ba49-23806e12cffc',
      title: 'Completed Classes',
      value: '0',
      link: pageRoutes.COMPLETE,
    },
    {
      imageUrl:
        'https://firebasestorage.googleapis.com/v0/b/nectar-f5ee3.appspot.com/o/academy%2Fgreen%2FLayer_1.svg?alt=media&token=640835d4-0d86-44bf-8f84-eef9e8468818',
      title: 'Incomplete Classes',
      value: '0',
      link: pageRoutes.INPROGRESS,
    },
  ];
  const { data: user } = useGetUserQuery();
  const { data: enrolled, isLoading, isSuccess, isError, error } = useGetOverviewQuery(user.id);
  /*  const {
      data: certifications,
      isLoading: loading,
      isSuccess: success,
      isError: isErr,
      error: err,
    } = useGetCertificationsQuery(user.id);*/

  const navigate = useNavigate();
  const location = useLocation();

  if (isLoading) {
    options[0].value = '---';
    options[1].value = '---';
    options[2].value = '---';
    options[3].value = '---';
  } else if (isSuccess) {
    options[0].value = enrolled.classes;
    options[1].value = enrolled.certificates;
    options[2].value = enrolled.completedClasses;
    options[3].value = enrolled.incompleteClasses;
  } else if (isError) {
    console.log(error);
    options[0].value = '---';
    options[1].value = '---';
    options[2].value = '---';
    options[3].value = '---';
  }

  return (
    <div>
      <Title>Overview</Title>
      <Container>
        {options.map((opt) => {
          const isActive = location.pathname.includes(opt.link);
          return (
            <Card key={opt.title} className={`${isActive ? 'active' : ''}`}>
              <div className="flex">
                <img src={opt.imageUrl} alt={opt.title} />
                <h3>{opt.value}</h3>
              </div>
              <p>{opt.title}</p>
              {/* <Arrow className={`${isActive ? 'active' : ''}`} onClick={() => {}}>
                <BsArrowUpRight />
              </Arrow>*/}
            </Card>
          );
        })}
      </Container>
    </div>
  );
};

export default AcademyHeader;
