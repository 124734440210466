import React from 'react';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const CircularProgress = ({ progress }) => {
  return (
    <CircularProgressbar
      value={progress}
      text={`${progress}%`}
      styles={buildStyles({
        pathColor: '#FFAB00',
        textColor: 'black',
      })}
      strokeWidth={12}
      className={'circular-progress'}
    />
  );
};

export default CircularProgress;
