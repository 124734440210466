import React, { useEffect, useRef } from 'react';
import styles from './Chat.module.scss';
import Pusher from 'pusher-js';
import { useGetUserQuery } from '../../../features/user/userApiSlice';
import { useGetMessagesQuery } from '../../../features/connect/messagesApiSlice';
import CoffeeLoader from '../../Loaders/CoffeeLoader';
import ChatActions from './ChatActions';
import { addMessage } from '../../../features/connect/messagesSlice';
import { useAppDispatch } from '../../../app/hooks';
import ChatMessageContainer from '../../ChatMessage/ChatMessageContainer';
import { getSortedMessages } from '../../../utils/sortMessages';
import { addUserMessage, addUserMessages, selectUserMessages } from '../../../features/connect/chatMessagesSlice';
import { useSelector } from 'react-redux';
import images from '../../../constants';

const now = new Date();
const today = new Date(now.getFullYear(), now.getMonth(), now.getDate()).toLocaleDateString('en-US', {
  month: 'long',
  day: 'numeric',
  year: 'numeric',
});
const yesterday = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1).toLocaleDateString('en-US', {
  month: 'long',
  day: 'numeric',
  year: 'numeric',
});

const Chat = ({ konnectUser }) => {
  const timestampRef = useRef(Date.now()).current;
  const { data: userData } = useGetUserQuery();
  const {
    data: chatList,
    isLoading: chatLoading,
    isSuccess,
    isError,
    error,
  } = useGetMessagesQuery({ id: konnectUser['0'].id, time: timestampRef });
  const dispatch = useAppDispatch();
  const allMessages = useSelector((state) => selectUserMessages(state, konnectUser['0'].id));
  const containerRef = useRef(null);

  const conId = konnectUser?.conversation_id;

  useEffect(() => {
    if (isSuccess) {
      dispatch(addUserMessages({ userId: konnectUser['0'].id, messages: chatList }));
    }
  }, [chatList, isSuccess]);

  useEffect(() => {
    const container = containerRef.current;
    container.scrollTop = container.scrollHeight;
  }, [allMessages]);

  useEffect(() => {
    const pusher = new Pusher('319d14857e5fadec954d', {
      cluster: 'eu',
    });

    const channel = pusher.subscribe(`chat_${conId}`);
    channel.bind('message', (data) => {
      dispatch(
        addUserMessage({
          userId: konnectUser['0'].id,
          message: {
            user_id: data.userId,
            body: data.message,
            conversation_id: data.conversation_id,
            created_at: new Date().toISOString(),
          },
        }),
      );
    });
  }, [addMessage]);

  if (konnectUser === null || konnectUser === undefined) {
    return (
      <div className={styles.holder}>
        <div className={styles.c_white}>
          <img src={images.social} alt="Social Chat" />
        </div>
      </div>
    );
  }
  let content;
  if (chatLoading) {
    content = <CoffeeLoader />;
  } else if (isSuccess) {
    content = getSortedMessages([...allMessages]).map((dateGroup) => {
      let dateValue = dateGroup.date;
      if (dateValue === today) {
        dateValue = 'Today';
      } else if (dateValue === yesterday) {
        dateValue = 'Yesterday';
      }
      return (
        <>
          <h3 key={dateGroup.date} className={styles.d_label}>
            {dateValue}
          </h3>
          {dateGroup.messages.map((message, index) => (
            <ChatMessageContainer key={index} chat={message} user={userData} conn={konnectUser['0']} />
          ))}
        </>
      );
    });
  } else if (isError) {
    console.log(error);
  }

  return (
    <div className={styles.container}>
      <div className={styles.container__header}>
        <h3>{konnectUser['0'].name}</h3>
      </div>
      <div ref={containerRef} className={`${styles.chat__box} ${chatLoading ? styles.not_loaded : ''}`}>
        {content}
      </div>
      <ChatActions conId={conId} userId={userData.id} />
    </div>
  );
};

export default Chat;
