import React from 'react';
import styles from '../RequestList.module.scss';
import { useGetAcceptedQuery } from '../../../../features/connect/acceptedApiSlice';
import MeetupLoader from '../../../Loaders/MeetupLoader';
import { getDocuments } from '../../../../utils/object-mapper';
import pageRoutes from '../../../../constants/pageRoutes';
import { useNavigate } from 'react-router-dom';

const imageUrl =
  'https://thumbs.dreamstime.com/b/default-avatar-profile-icon-vector-social-media-user-portrait-176256935.jpg';
const MyKonnects = () => {
  const navigate = useNavigate();
  const { data, error, isLoading, isError, isSuccess } = useGetAcceptedQuery();
  let allKonnects = [];

  if (isError) {
    console.log(error);
    return <div>Something went wrong</div>;
  } else if (isLoading) {
    return <MeetupLoader />;
  } else if (isSuccess) {
    allKonnects = data;
  }

  const openInbox = () => {
    navigate(`/${pageRoutes.DASHBOARD}/${pageRoutes.INBOX}`);
  };

  if (allKonnects.length === 0) {
    return <div className={styles.no_requests}>You have no connects</div>;
  }

  return (
    <div className={styles.requests}>
      {allKonnects.map((user) => {
        const avatar = getDocuments(['avatar'], user['0'].media)[0]?.original_url;
        return (
          <article key={user['0'].id} className={styles.requests_card}>
            <img
              className={styles.speaker}
              src={`${avatar === '' || avatar === undefined ? imageUrl : avatar}`}
              alt="Connect user"
            />
            <div className={styles.requests_card_content}>
              <h4>{user['0'].name}</h4>
            </div>
            <button onClick={openInbox}>Chat</button>
            <button className={styles.block}>Block</button>
          </article>
        );
      })}
    </div>
  );
};

export default MyKonnects;
