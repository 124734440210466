import styled from 'styled-components';

export const Asset = styled.div<{ imageUrl: string }>`
  background-image: url(${(props) => props.imageUrl});
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: flex-end;
  border-radius: 10px !important;
  height: 20rem;

  .blur {
    width: 35%;
    padding: 1.6rem;
    border-radius: 0 10px 10px 0;
    text-align: center;
    font-size: 1.6rem;
    font-weight: 500;
    -webkit-backdrop-filter: blur(8px);
    backdrop-filter: blur(8px);
    background-color: rgba(255, 255, 255, 0.3);
    display: grid;
    place-items: center;
  }

  .empty {
    width: 65%;
  }
`;

export const Image = styled.div`
  height: auto;
  width: 80%;
  border-radius: 50%;
  display: grid;
  place-items: center;
  padding: 1.3rem;
  background-color: white;
  margin-bottom: 1rem;

  img {
    width: 100%;
    height: 100%;
  }
`;

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 2rem;
`;

export const Card = styled.div`
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;

  .content {
    flex: 1;
    padding: 1.6rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .circle {
    height: 10rem;
  }

  .radar {
    margin-bottom: -4rem;
  }

  .bottom {
    margin-top: auto;
    width: 100%;
    background-color: var(--color-primary-light-1);
    border-radius: 0 0 10px 10px;
    height: 1rem;

    div {
      width: 45%;
      height: 100%;
      background-color: var(--color-primary);
      border-radius: 0 10px 10px 10px;
    }
  }

  .text {
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-size: 1.4rem;
    text-align: center;
  }

  .context {
    margin-top: -2rem;
    display: flex;
    justify-content: center;
    column-gap: 1rem;
    flex-wrap: wrap;
    text-align: center;
    font-size: 1.4rem;
  }
`;

export const Head = styled.div`
  font-size: 1.65rem;
  font-weight: bold;
  display: grid;
  grid-template-columns: 1fr auto;
  justify-content: space-between;
  align-items: start;
  column-gap: 2rem;

  .arrow {
    display: grid;
    place-items: center;
    background-color: var(--color-primary);
    border-radius: 50%;
    height: 3rem;
    width: 3rem;
    justify-self: flex-end;

    svg {
      width: 1.5rem;
      height: 1.5rem;
      transform: rotate(-45deg);
      fill: white;
    }
  }
`;
