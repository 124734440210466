export const getSortedMessages = (messages) => {
  if (!messages) return [];
  if (messages.length === 0) return [];

  let sortedMessages = [...messages];

  // Initialize date groups
  const dateGroups = [];

  // Loop through messages and group by date
  sortedMessages.forEach((message) => {
    // Get the date of the message
    const date = new Date(message.created_at).toLocaleDateString('en-US', {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    });

    // Get the last date group
    const lastGroup = dateGroups[dateGroups.length - 1];

    // If this is the first message, create a new group
    if (!lastGroup) {
      dateGroups.push({
        date,
        messages: [message],
      });
    } else {
      // If the date is the same as the last group, add the message to the existing group
      if (lastGroup.date === date) {
        lastGroup.messages.push(message);
      } else {
        // If the date is different, create a new group
        dateGroups.push({
          date,
          messages: [message],
        });
      }
    }
  });

  // @ts-ignore
  dateGroups.sort((a, b) => new Date(a.messages[0].created_at) - new Date(b.messages[0].created_at));
  /*// Add special groups for yesterday and today
  const now = new Date();
  const today = new Date(now.getFullYear(), now.getMonth(), now.getDate()).toLocaleDateString();
  const yesterday = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1).toLocaleDateString();

  if (dateGroups.length > 0 && dateGroups[0].date === today) {
    dateGroups.push({
      date: 'Today',
      messages: dateGroups[0].messages,
    });
    dateGroups.shift();
  } else if (dateGroups.length > 0 && dateGroups[0].date === yesterday) {
    dateGroups.unshift({
      date: 'Yesterday',
      messages: dateGroups[0].messages,
    });
    dateGroups.shift();
  }*/

  return dateGroups;
};
