import React, { useState } from 'react';
import styles from './Support.module.scss';
import Faqs from '../../../components/Support/Faqs';
import Contact from '../../../components/Support/Contact';
import { useNavigate } from 'react-router-dom';

function Support() {
  const navigate = useNavigate();
  const tabs = ['All Tickets', 'Pending Tickets', 'Closed Tickets'];
  const [selectedTab, setSelectedTab] = useState(tabs[0]);
  /*const { data: user } = useGetUserQuery();
    const { data: tickets, isLoading, isSuccess, isError, error } = useGetUserTicketsQuery(user.id);
  
    let content;
  
    if (isLoading) content = <p>Loading...</p>;
    else if (isSuccess) {
      content = <p>{tickets.toString()}</p>;
    } else if (isError) {
      content = <p>Could not fetch tickets</p>;
      console.log(error);
    }*/

  return (
    <main className={styles.container}>
      <section className={styles.main_content}>
        {/*<Nav>
          <Ul>
            {tabs.map((tab) => (
              <Li key={tab} className={tab === selectedTab ? 'selected' : ''} onClick={() => setSelectedTab(tab)}>
                {tab}
                {tab === selectedTab ? <Underline layoutId="underline" /> : null}
              </Li>
            ))}
          </Ul>
        </Nav>
        <div className={styles.new_ticket} onClick={() => navigate(pageRoutes.NEW_TICKET)}>
          <img src={images.newTicket} alt="new Ticket" />
          <p>New Ticket</p>
        </div>
        <AnimatePresence mode={'wait'}>
          <motion.div
            key={selectedTab}
            initial={{ y: 10, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: -10, opacity: 0 }}
            transition={{ duration: 0.2 }}>
            <Ticket />
            <Ticket />
          </motion.div>
        </AnimatePresence>*/}
        <Faqs />
      </section>
      <section className={styles.sidebar}>
        <Contact />
      </section>
    </main>
  );
}

export default Support;
