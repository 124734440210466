export const getSurveyJSON = (quizList: any) => {
  const elements = quizList.map((quiz: any) => {
    let element: any;
    if (quiz.expected_response_type === 'statement') {
      element = {
        type: 'text',
        name: `${quiz.id}`,
        title: quiz.question,
        isRequired: true,
      };
    } else if (quiz.expected_response_type === 'range') {
      const rateMax = parseInt(quiz.expected_range.split('-')[1]);
      element = {
        type: 'rating',
        name: `${quiz.id}`,
        title: quiz.question,
        isRequired: true,
        rateMax,
      };
    } else if (quiz.expected_response_type === 'option') {
      element = {
        type: 'radiogroup',
        name: `${quiz.id}`,
        title: quiz.question,
        isRequired: true,
        choices: Object.values(quiz.options),
      };
    }
    return element;
  });

  return {
    logoPosition: 'right',
    completedHtml: '<h3>Thank you for completing the questions, please wait for grading :)</h3>',
    pages: [
      {
        name: 'page1',
        elements,
      },
    ],
  };
};
