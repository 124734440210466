import styled from 'styled-components';
import { AiOutlineClose } from 'react-icons/ai';
import { motion } from 'framer-motion';

export const Container = styled.div`
  position: relative;
  padding: 2rem;
  text-align: center;
  letter-spacing: 0.4px;
  font-size: 1.8rem;
  background-color: #fff;

  h3 {
    font-size: 2.4rem;
    font-weight: bold;
  }

  img {
    width: auto;
    height: 25rem;
    object-fit: contain;
    margin: 1rem auto;
  }

  .title {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }

  form {
    width: 100%;
  }

  input {
    width: 100%;
    outline: none;
    background-color: transparent;
    border-radius: 5px;
    padding: 1.6rem;
    border: 1px solid #cccccc;
    margin: 1rem auto;

    &:focus {
      border-color: var(--color-accent);
    }
  }

  span {
    font-weight: bold;

    &.link {
      color: var(--color-primary);
      cursor: pointer;
      font-weight: normal;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .errors {
    color: red !important;
    font-size: 1.4rem;
    margin: -1rem 0 0;
    text-align: left !important;
  }
`;

export const Close = styled(AiOutlineClose)`
  cursor: pointer;
  width: 3rem;
  height: 3rem;
  fill: black;
  position: absolute;
  right: 1rem;
  top: 1rem;
  transition: 0.2s;
  padding: 0.8rem;
  border-radius: 50%;
  background-color: transparent;
  box-sizing: content-box;

  &:hover {
    fill: var(--color-primary);
    background-color: #cccccc;
    -o-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
`;

export const Button = styled(motion.button)`
  background-color: var(--color-primary);
  height: 6rem;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 1.2rem;
  width: 100%;
  margin: 2rem auto 1rem;
  text-transform: uppercase;

  svg {
    float: right;
    width: 2rem;
    height: auto;
    fill: white;
  }
`;
