import styled from 'styled-components';
import { motion } from 'framer-motion';
import { TopBar } from '../../../modules/ModalWindow/modals/LoanModal/styles';

export const Wrapper = styled.section`
  margin-top: 2rem;
  background-color: #fff;
`;

export const Note = styled.p`
  background-color: var(--color-primary-light-1);
  padding: 1.6rem;
  font-size: 1.6rem;
  letter-spacing: 0.3px;
  margin-top: 1.6rem;
  margin-right: 2rem;
`;

export const BtnDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  column-gap: 3.5rem;
  margin-top: 1.6rem;
  padding-right: 2rem;
`;

export const Content = styled.div`
  padding-right: 1rem;
  height: 45vh;
  overflow-y: auto;

  .flex {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1.6rem;
    align-items: flex-start;
    margin-bottom: 1.6rem;

    .child {
      position: relative;

      small {
        position: absolute;
        color: red;
        bottom: -1.4rem;
        font-size: 1.4rem;
        left: 0;
      }
    }
  }

  .t_area {
    position: relative;

    small {
      position: absolute;
      color: red;
      bottom: 0.3rem;
      font-size: 1.4rem;
      left: 0;
    }
  }

  &::-webkit-scrollbar {
    width: 3px;
    height: 1px;
  }

  &::-webkit-scrollbar-track {
    background-color: var(--color-primary-light-1);
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--color-accent);
    border-radius: 10px;
  }
`;

export const Btn = styled(motion.button)`
  min-width: 8rem;
  background-color: var(--color-accent);
  padding: 1.6rem;
  border-radius: 5px;
  font-size: 1.6rem;
  color: #000;

  &.green {
    background-color: var(--color-primary);
    color: white;

    &:disabled {
      pointer-events: none;
      cursor: not-allowed;
      background-color: #707070;
    }
  }
`;

export const TpBar = styled(TopBar)`
  margin: 0;
`;

export const Header = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 1fr max-content 1fr;
  column-gap: 2rem;
  text-transform: uppercase;
  font-size: 1.72rem;
  font-weight: 500;
  letter-spacing: 0.7px;

  hr {
    border: 1px solid #707070;
    height: 0;
  }
`;

export const Form = styled.form`
  width: 100%;
`;
