import { createEntityAdapter } from '@reduxjs/toolkit';
import { apiSlice } from '../../app/api/apiSlice';
import { TICKETS } from '../../types/api-responses';

const ticketsAdapter = createEntityAdapter();

const initialState = ticketsAdapter.getInitialState();

const ticketApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getUserTickets: build.query<any, string>({
      query: (id) => `user/tickets/${id}`,
      transformResponse: (rawResult: any) => {
        return ticketsAdapter.setAll(initialState, rawResult?.response);
      },
      providesTags: (result) =>
        result
          ? [...result.ids.map((id) => ({ type: TICKETS, id })), { type: TICKETS, id: 'LIST' }]
          : [{ type: TICKETS, id: 'LIST' }],
    }),
    raiseTicket: build.mutation({
      query: (ticket) => {
        const ticketData = new FormData();
        for (const ticketKey in ticket) {
          ticketData.append(ticketKey, ticket[ticketKey]);
        }
        return {
          url: '/ticket/create',
          method: 'POST',
          body: ticketData,
        };
      },
      invalidatesTags: [{ type: TICKETS, id: 'LIST' }],
    }),
  }),
});

export const { useRaiseTicketMutation, useGetUserTicketsQuery } = ticketApiSlice;
