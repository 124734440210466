import styled from 'styled-components';

export const SummaryWrapper = styled.div`
  padding: 0 1.6rem 1.6rem;
  .scroll {
    overflow-y: auto;
    height: 42vh;
    padding-right: 4px;

    &::-webkit-scrollbar {
      width: 3px;
      height: 1px;
      margin-left: 4px !important;
    }

    &::-webkit-scrollbar-track {
      background-color: #707070;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--color-primary);
      border-radius: 10px;
    }
  }
`;

export const Title = styled.h2`
  font-size: 2.2rem;
  letter-spacing: 0.5px;
  font-weight: 500;
  margin-bottom: 1.6rem;
`;

export const Icon = styled.div`
  background-color: var(--color-accent);
  border-radius: 50%;
  padding: 1rem;
  display: flex;
  justify-content: center;

  svg {
    width: 2rem;
    height: 2rem;
  }
`;

export const Label = styled.p`
  font-size: 1.7rem;
  font-weight: 500;
  color: #707070;
`;

export const Text = styled.p`
  font-size: 1.6rem;
  color: var(--color-green);
  margin-left: auto;
  //text-decoration: underline;

  &.link {
    a {
      cursor: pointer;
      color: var(--color-primary);
      text-decoration: underline;
    }
  }
`;

export const Input = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  column-gap: 2rem;
  padding: 1.2rem 2rem;
  border-radius: 5px;
  border: 1px solid #707070;
  margin-bottom: 2rem;
`;

export const InputWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template: 1fr 1fr / 1fr 1fr;
  column-gap: 1.6rem;
`;
