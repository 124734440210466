import React from 'react';
import { Header, HeaderContainer, StatementWrapper, Text } from './styles';
import { useGetStatusQuery } from '../../../features/analysis/analysisSlice';
import MeetupLoader from '../../Loaders/MeetupLoader';

const Statements = ({ company, documents, onAnalysisSet }) => {
  const { data, isLoading, isError, error, isSuccess } = useGetStatusQuery(company.id);
  let content;
  if (isLoading) {
    content = <MeetupLoader />;
  } else if (isError) {
    console.log(error);
    // @ts-ignore
    if (error.message.includes('Undefined array key')) {
      content = <Text>Analysis is not ready</Text>;
    }
  } else if (isSuccess) {
    content = <Text>{data}</Text>;
    if (data === 'Analysis is ready' || data === 'Data was already analyzed') {
      onAnalysisSet(true);
    }
  }

  return (
    <>
      <StatementWrapper>
        <HeaderContainer>
          <Header>Statement Analysis Summary</Header>
        </HeaderContainer>
        {content}
      </StatementWrapper>
    </>
  );
};

export default Statements;
