import React, { useState } from 'react';
import styles from './BusinessProfile.module.scss';
import Documents from '../../../components/Documents/Documents';
import { useGetUserQuery } from '../../../features/user/userApiSlice';
import MeetupLoader from '../../../components/Loaders/MeetupLoader';
import { useGetBusinessByIdQuery } from '../../../features/business/businessApiSlice';
import { getDocuments, getPendingDocuments } from '../../../utils/object-mapper';
import BusProfileSummary from '../../../components/BusinessProfile/BusProfileSummary/BusProfileSummary';
import CreateBusiness from '../../../components/BusinessProfile/CreateBusiness/CreateBusiness';
import { PITCH_DOC, REG_DOC, TAX_DOC, VAT_DOC } from '../../../constants/constants';
import EditBusiness from '../../../components/BusinessProfile/EditBusiness/EditBusiness';
import BusinessTopHeader from '../../../components/BusinessProfile/BusinessHeader/BusinessTopHeader';

const BusinessProfile = () => {
  const [isEdit, setIsEdit] = useState(false);
  const [slogan, setSlogan] = useState('');
  const { data: user } = useGetUserQuery();
  const { data: companyData, isLoading, isSuccess, isError, error } = useGetBusinessByIdQuery(user.id);
  let company = {
    isCompany: false,
    logo: null,
    name: '',
  };
  const docs = [REG_DOC, TAX_DOC, VAT_DOC, PITCH_DOC];
  let documents = [];
  let pendingDocs = [];

  if (isLoading) {
    return <MeetupLoader />;
  } else if (isSuccess) {
    if (companyData.companies.length > 0) {
      company = { isCompany: true, logo: companyData.companies[0].logo, name: companyData.companies[0].name };
      documents = getDocuments(docs, companyData.companies[0]?.media);
      pendingDocs = getPendingDocuments(docs, documents);
    }
  } else if (isError) {
    console.log(error);
  }

  return (
    <main className={styles.container}>
      <section className={styles.content}>
        <BusinessTopHeader isEdit={isEdit} business={company} onEdit={setIsEdit} slogan={slogan} />
        {company.isCompany ? (
          isEdit ? (
            <EditBusiness onClose={setIsEdit} />
          ) : (
            <BusProfileSummary onSloganSet={setSlogan} />
          )
        ) : (
          <>
            <CreateBusiness />
          </>
        )}
      </section>
      <section className={styles.sidebar}>
        <Documents documents={documents} isCompany={company.isCompany} docs={pendingDocs} />
      </section>
    </main>
  );
};

export default BusinessProfile;
