import styled from 'styled-components';

export const StyledEmptyState = styled.div`
  border-radius: 10px;
  background-color: white;
  padding: 1.6rem;
  letter-spacing: 0.4px;
  margin-top: 2rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  .title {
    font-size: 1.72rem;
    font-weight: bold;
    margin-bottom: 1rem;
    text-align: center;
  }

  .subtitle {
    font-size: 1.6rem;
    font-weight: 400;
    text-align: center;
    color: #707070;
    margin-bottom: 4rem;
  }

  .upload {
    display: block;
    margin: 0 auto;
    width: auto;
    height: 27rem;
    object-fit: cover;
  }
`;

export const Header = styled.div`
  background-color: var(--color-primary);
  color: white;
  padding: 1.6rem;
  border-radius: 10px 10px 0 0;
  margin: -1.6rem -1.6rem 1.6rem;
  font-size: 2rem;
  font-weight: bold;
  letter-spacing: 0.6px;
`;
