import React, { useState } from 'react';
import Sidebar from '../../components/Sidebar/Sidebar';
import Topbar from '../../components/Sidebar/Topbar';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAppSelector } from '../../app/hooks';
import { selectCurrentToken } from '../../features/auth/authSlice';
import { useGetUserQuery } from '../../features/user/userApiSlice';
import { motion } from 'framer-motion';

const DashboardLayout = ({ openTour }) => {
  const [showSidebar, setShowSidebar] = useState(false);
  const token = useAppSelector(selectCurrentToken);
  const { data, error, isLoading, isSuccess, isError } = useGetUserQuery();
  const location = useLocation();

  let content;
  if (isLoading) {
    content = <div className={'whirl_container whirl'} />;
  } else if (isError) {
    console.log(error);
  } else if (isSuccess) {
    content = (
      <>
        <Topbar user={data} toggle={() => setShowSidebar(true)} showSidebar={showSidebar} />
        <Outlet />
      </>
    );
    openTour();
  }

  return token ? (
    <div className={'dashboard'}>
      <Sidebar setToggle={() => setShowSidebar(false)} showSidebar={showSidebar} />
      <motion.div initial={{ opacity: 0, scale: 0.5 }} animate={{ opacity: 1, scale: 1 }} className={'main-content'}>
        {content}
      </motion.div>
    </div>
  ) : (
    <Navigate to="/sign_in" state={{ from: location }} replace />
  );
};

export default DashboardLayout;
