import { createEntityAdapter, createSelector } from '@reduxjs/toolkit';
import { apiSlice } from '../../app/api/apiSlice';

const adapter = createEntityAdapter();
const initialState = adapter.getInitialState();

const busAttrsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getBusAttrs: builder.query<any, void>({
      query: () => `/business/gap/list`,
      transformResponse: (rawResult: any) => adapter.setAll(initialState, rawResult?.response?.data),
    }),
  }),
});

export const { useGetBusAttrsQuery } = busAttrsApiSlice;
export const selectAttrsResult = busAttrsApiSlice?.endpoints.getBusAttrs.select();

const selectAttrsData = createSelector(selectAttrsResult, (attrsResult) => attrsResult?.data);
export const { selectAll: selectBusAttrs } = adapter.getSelectors(
  (state: any) => selectAttrsData(state) ?? initialState,
);
