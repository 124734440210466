import React from 'react';
import styles from './Chat.module.scss';
import { useSendMessageMutation } from '../../../features/connect/acceptedApiSlice';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { schema } from '../Post/PostActions/comment-schema';
import { AiOutlineSend } from 'react-icons/ai';

const ChatActions = ({ conId, userId }) => {
  const [sendMessage, { isLoading }] = useSendMessageMutation();

  const {
    register,
    handleSubmit,
    reset,
    formState: { isValid, isDirty },
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
  });
  const onSubmit = async (data) => {
    try {
      await sendMessage({
        body: data.content,
        conversation_id: conId,
        user_id: userId,
      }).unwrap();
      reset();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className={styles.container__actions}>
      <form onSubmit={handleSubmit(onSubmit)} id={'chat'}>
        <input autoFocus type="text" placeholder={'Write something'} {...register('content')} />
      </form>
      <button type={'submit'} form={'chat'} disabled={isLoading || !isDirty || !isValid} className={styles.send_btn}>
        {isLoading ? (
          <div className={'container--chat'}>
            <div className={'bloom'} />
          </div>
        ) : (
          <AiOutlineSend />
        )}
      </button>
    </div>
  );
};

export default ChatActions;
