import styled from 'styled-components';
import { motion } from 'framer-motion';

export const Wrapper = styled.div`
  background-color: #fff;
  border-radius: 10px;
  padding: 1.6rem;
  font-size: 1.6rem;
  letter-spacing: 0.5px;
  margin-bottom: 2rem;
  position: relative;

  img {
    width: 100%;
    height: 15rem;
    object-fit: cover;
    object-position: bottom center;
    margin: 1.6rem 0 1rem;
  }
`;

export const Button = styled(motion.button)`
  width: 100%;
  padding: 1.5rem;
  background-color: var(--color-primary);
  color: #ffffff;
  text-transform: uppercase;
  border-radius: 5px;
  margin-top: 1rem;
`;

export const Text = styled.p`
  color: #707070;
  font-size: 1.5rem;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin: 1rem 0;
  height: 7rem;
`;

export const Title = styled.h5`
  height: 6rem;
  font-size: 1.72rem;
  font-weight: 500;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;
