import React from 'react';
import { ChatMessage, ChatMessageContent, ChatMessageHeader, ChatMessageSender, ChatMessageTimestamp } from './styles';

const ChatMessageContainer = ({ conn, user, chat }) => {
  const isSelf = +chat.user_id != conn.id;
  const sender = isSelf ? 'You' : conn.name;
  const timestamp = new Date(chat.created_at).toLocaleTimeString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: false,
  });
  return (
    <ChatMessage isSelf={isSelf}>
      <ChatMessageContent isSelf={isSelf}>
        <ChatMessageHeader>
          <ChatMessageSender>{sender}</ChatMessageSender>
          <ChatMessageTimestamp>{timestamp}</ChatMessageTimestamp>
        </ChatMessageHeader>
        <div>{chat.body}</div>
      </ChatMessageContent>
    </ChatMessage>
  );
};

export default ChatMessageContainer;
