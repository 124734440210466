import React, { useState } from 'react';
import styles from './RiskProfile.module.scss';
import AnalysisExpenses from '../../../components/Analysis/AnalysisExpenses';
import CashFlow from '../../../components/Analysis/CashFlow';
import AnalysisIncome from '../../../components/Analysis/AnalysisIncome';
import Statements from '../../../components/RiskProfile/Statements/Statements';
import { Li, Nav, Ul, Underline } from '../../../components/Common/Tabs/styles';
import { AnimatePresence, motion } from 'framer-motion';
import { HeadContainer, HeaderWrapper, Score, Text, Title } from '../../../components/RiskProfile/styles';
import RiskProfileOptions from '../../../components/RiskProfile/CreditTips/RiskProfileOptions';
import { useGetUserBusinessQuery, useGetUserQuery } from '../../../features/user/userApiSlice';
import MeetupLoader from '../../../components/Loaders/MeetupLoader';
import { MPESA_DOC } from '../../../constants/constants';
import { getDocuments } from '../../../utils/object-mapper';
import { Btn, Header, HeaderContainer, StatementWrapper } from '../../../components/RiskProfile/Statements/styles';
import { openModal } from '../../../features/modal/modalSlice';
import { MODAL_TYPES } from '../../../modules/ModalWindow/modalTypes';
import { useAppDispatch } from '../../../app/hooks';
import pageRoutes from '../../../constants/pageRoutes';
import { Link } from 'react-router-dom';

const RiskProfile = () => {
  const dispatch = useAppDispatch();
  const [tabs, setTabs] = useState(['Statements', 'Cash Flow', 'Income', 'Expenses']);
  const [isAnalysis, setIsAnalysis] = useState(false);
  const [selectedTab, setSelectedTab] = useState(tabs[0]);
  const { data: user } = useGetUserQuery();
  const { data: companyData, isLoading, isError, error, isSuccess } = useGetUserBusinessQuery(user.id);
  let company = {
    isCompany: false,
    credit_score: 0,
    id: 0,
  };

  const docs = [MPESA_DOC];
  let documents = [];

  const busLink = `/${pageRoutes.DASHBOARD}/${pageRoutes.BUSINESS}`;
  const onUpload = () => {
    localStorage.setItem('doc', MPESA_DOC);
    dispatch(openModal({ modalType: MODAL_TYPES.EDIT_DOCS_MODAL }));
  };

  let info = (
    <p className={styles.pending}>
      To get started, please complete your <Link to={busLink}>business profile</Link>
    </p>
  );
  if (isLoading) return <MeetupLoader />;
  if (isError) console.log(error);
  if (isSuccess) {
    if (companyData.companies.length > 0) {
      company = {
        isCompany: true,
        credit_score: companyData.companies[0].credit_score,
        id: companyData.companies[0].id,
      };
      documents = getDocuments(docs, companyData?.media);
      if (documents.length === 0) {
        return (
          <main className={styles.container}>
            <section>
              <HeadContainer>
                <HeaderWrapper>
                  <div className="bg">
                    <Title>Credit Ready?</Title>
                    <Text>
                      We help you increase you credit score by preparing a strong profile
                      <br /> that inspires confidence among our financial partners
                    </Text>
                  </div>
                </HeaderWrapper>
                <Score>
                  <p>Your Credit Score</p>
                  <h3>{company.credit_score}</h3>
                </Score>
              </HeadContainer>
              <AnimatePresence mode={'wait'}>
                <motion.div
                  key={selectedTab}
                  initial={{ y: 10, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  exit={{ y: -10, opacity: 0 }}
                  transition={{ duration: 0.2 }}>
                  <StatementWrapper>
                    <HeaderContainer>
                      <Header>Statement Analysis Summary</Header>
                      <Btn
                        onClick={onUpload}
                        whileHover={{ scale: 1.01, backgroundColor: '#F4AD3D' }}
                        whileTap={{ scale: 0.95 }}>
                        Upload Statement
                      </Btn>
                    </HeaderContainer>
                    <Text>
                      You have not uploaded your M-PESA statement. Please upload your statement to get a detailed
                      analysis
                    </Text>
                  </StatementWrapper>
                </motion.div>
              </AnimatePresence>
            </section>
            <section>
              <RiskProfileOptions />
            </section>
          </main>
        );
      }
    }
  }

  let content;
  if (selectedTab === tabs[1]) {
    content = <CashFlow company={company} documents={documents} isAnalysis={isAnalysis} />;
  } else if (selectedTab === tabs[2]) {
    content = <AnalysisIncome company={company} documents={documents} isAnalysis={isAnalysis} />;
  } else if (selectedTab === tabs[0]) {
    content = <Statements company={company} documents={documents} onAnalysisSet={setIsAnalysis} />;
  } else {
    content = <AnalysisExpenses company={company} documents={documents} isAnalysis={isAnalysis} />;
  }

  return (
    <main className={styles.container}>
      <section>
        <HeadContainer>
          <HeaderWrapper>
            <div className="bg">
              <Title>Credit Ready?</Title>
              <Text>
                We help you increase you credit score by preparing a strong profile
                <br /> that inspires confidence among our financial partners
              </Text>
            </div>
          </HeaderWrapper>
          <Score>
            <p>Your Credit Score</p>
            <h3>{company.credit_score}</h3>
          </Score>
        </HeadContainer>
        {company.isCompany ? (
          <>
            <Nav>
              <Ul>
                {tabs.map((tab) => (
                  <Li key={tab} className={tab === selectedTab ? 'selected' : ''} onClick={() => setSelectedTab(tab)}>
                    {tab}
                    {tab === selectedTab ? <Underline layoutId="underline" /> : null}
                  </Li>
                ))}
              </Ul>
            </Nav>
            <AnimatePresence mode={'wait'}>
              <motion.div
                key={selectedTab}
                initial={{ y: 10, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: -10, opacity: 0 }}
                transition={{ duration: 0.2 }}>
                {content}
              </motion.div>
            </AnimatePresence>
          </>
        ) : (
          info
        )}
      </section>
      <section>
        <RiskProfileOptions />
      </section>
    </main>
  );
};
export default RiskProfile;
