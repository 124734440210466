import React from 'react';
import Modal from 'react-modal';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { closeModal, selectCurrentModal } from '../../../../features/modal/modalSlice';
import { MODAL_TYPES } from '../../modalTypes';
import { Button, Close, Confetti, Container } from './styles';
import { AiOutlineClose } from 'react-icons/ai';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    zIndex: 100,
    border: 'none',
    padding: 0,
    width: '80%',
    maxWidth: '650px',
    borderRadius: '10px',
    backgroundColor: 'transparent',
  },
};

const CongratsModal = () => {
  const dispatch = useAppDispatch();
  const modalType = useAppSelector(selectCurrentModal);
  const isOpen = modalType === MODAL_TYPES.CONGRATS_MODAL;
  const onSubmitHandler = () => {
    dispatch(closeModal());
  };
  return (
    <Modal
      onRequestClose={onSubmitHandler}
      isOpen={isOpen}
      style={customStyles}
      ariaHideApp={false}
      overlayClassName="Overlay">
      <Container>
        <Close onClick={onSubmitHandler}>
          <AiOutlineClose />
        </Close>
        <Confetti
          src={
            'https://firebasestorage.googleapis.com/v0/b/nectar-f5ee3.appspot.com/o/academy%2Fcongratulations-img.svg?alt=media&token=c84f0b68-49d6-4bab-aa31-4ddd87c3ac99'
          }
          alt={'Confetti'}
        />
        <img
          className="hero_img"
          src={
            'https://firebasestorage.googleapis.com/v0/b/nectar-f5ee3.appspot.com/o/academy%2Fconfetti-outline.svg?alt=media&token=980d5ed5-0e6c-462a-8fc7-8e1358c98c8b'
          }
          alt={'Congratulations'}
        />
        <h4>Congratulations!</h4>
        <p>
          You have successfully completed the masterclass lesson quiz. <br />
          Keep up the great work!
        </p>
        <Button>GO TO NEXT LESSON</Button>
      </Container>
    </Modal>
  );
};

export default CongratsModal;
