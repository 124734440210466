import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { closeModal, selectCurrentModal } from '../../../../features/modal/modalSlice';
import { MODAL_TYPES } from '../../modalTypes';
import Modal from 'react-modal';
import {
  Action,
  Back,
  Bar,
  Close,
  Container,
  Continue,
  Header,
  HeaderActions,
  Icon,
  ReqBtn,
  Requirements,
  TopBar,
  Wrapper,
} from './styles';
import PersonaDetailsMd from '../../../../components/md/PersonalDetails/PersonaDetailsMd';
import { IoIosArrowUp } from 'react-icons/io';
import RequestSummaryMd from '../../../../components/md/RequestSummary/RequestSummaryMd';
import CompanyDetailsMd from '../../../../components/md/CompanyDetails/CompanyDetailsMd';
import CompanyDocumentsMd from '../../../../components/md/CompanyDocuments/CompanyDocumentsMd';
import PersonalDocsMd from '../../../../components/md/PersonalDocuments/PersonalDocsMd';
import { BsChatDots } from 'react-icons/bs';
import { BiPhoneCall } from 'react-icons/bi';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    zIndex: 100,
    border: 'none',
    padding: 0,
    width: '70%',
    borderRadius: '10px',
    height: 'auto',
  },
};

function LoanModal() {
  const dispatch = useAppDispatch();
  const modalType = useAppSelector(selectCurrentModal);
  const isOpen = modalType === MODAL_TYPES.LOAN_MODAL;
  const onSubmitHandler = () => {
    dispatch(closeModal());
  };
  const [current, setCurrent] = useState(1);

  const handleBackClick = () => {
    if (current > 1) {
      setCurrent(current - 1);
    }
  };

  const handleForwardClick = () => {
    if (current < 5) {
      setCurrent(current + 1);
    }
  };

  let content;
  let header;

  switch (current) {
    case 1:
      content = <PersonaDetailsMd />;
      header = 'Personal details';
      break;
    case 2:
      content = <PersonalDocsMd />;
      header = 'Personal Documents';
      break;
    case 3:
      content = (
        <Wrapper>
          <CompanyDetailsMd />
        </Wrapper>
      );
      header = 'Company Details';
      break;
    case 4:
      content = <CompanyDocumentsMd />;
      header = 'Company Documents';
      break;
    default:
      content = <RequestSummaryMd />;
      header = 'Asset request summary';
  }

  return (
    <Modal onRequestClose={false} isOpen={isOpen} style={customStyles} ariaHideApp={false} overlayClassName="Overlay">
      <Header>
        <h2>Asset Application</h2>
        <HeaderActions>
          <p>Need any help?</p>
          <Action>
            <Icon>
              <BiPhoneCall />
            </Icon>
            Call us
          </Action>
          <Action>
            <Icon>
              <BsChatDots />
            </Icon>
            Raise Ticket
          </Action>
          <Close onClick={onSubmitHandler} />
        </HeaderActions>
      </Header>
      <Container>
        <div>
          <TopBar>
            <Back onClick={handleBackClick} />
            <div>
              <h4>
                {header} <span>{current} of 5</span>
              </h4>
              <Bar>
                {[1, 2, 3, 4, 5].map((el) => (
                  <hr key={el} className={`${el <= current ? 'active' : ''}`} />
                ))}
              </Bar>
            </div>
          </TopBar>
          <div className="scrollable">{content}</div>
          <div className="btn">
            <Continue onClick={handleForwardClick}>Continue</Continue>
          </div>
        </div>
        <Requirements>
          <ReqBtn>
            <p>Requirements</p> <IoIosArrowUp />
          </ReqBtn>
        </Requirements>
      </Container>
    </Modal>
  );
}

export default LoanModal;
