import React, { useState } from 'react';
import { Chip } from './styles';

const Options = ({ options }) => {
  const [counter, setCounter] = useState(0);
  return (
    <div>
      {options.map((item, index) => (
        <Chip onClick={() => setCounter(index)} active={counter === index} key={index}>
          {item}
        </Chip>
      ))}
    </div>
  );
};

export default Options;
