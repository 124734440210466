import React from 'react';
import Modal from 'react-modal';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { closeModal, selectCurrentModal } from '../../../../features/modal/modalSlice';
import { MODAL_TYPES } from '../../modalTypes';
import { Button, Container, Content, Header, Item } from './styles';
import images from '../../../../constants';
import { AiFillStar } from 'react-icons/ai';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    zIndex: 100,
    border: 'none',
    width: '60%',
    padding: 0,
    borderRadius: '10px',
  },
};

const steps = [
  { title: 'Complete your personal profile', status: 'done' },
  {
    title: 'Complete your business profile',
    status: 'active',
  },
  {
    title: 'Complete your credit profile',
    status: 'inactive',
  },
  {
    title: 'Complete your climate profile',
    status: 'inactive',
  },
];

function ProgressModal() {
  const dispatch = useAppDispatch();
  const modalType = useAppSelector(selectCurrentModal);
  const isOpen = modalType === MODAL_TYPES.PROGRESS_MODAL;
  return (
    <Modal
      onRequestClose={() => dispatch(closeModal())}
      isOpen={isOpen}
      style={customStyles}
      ariaHideApp={false}
      overlayClassName="Overlay">
      <Header>
        <h4>To Kick Things Off...</h4>
        <p>Complete these steps to access all the app's features</p>
      </Header>
      <Container>
        <div>
          {steps.map((step, index) => (
            <Item isNext={step.status === 'active'} key={index}>
              <p className="num">{index + 1}</p>
              <p>{step.title}</p>
              {step.status === 'done' ? <img src={images.check} alt="" /> : <img src={images.errorIcon} alt="" />}
            </Item>
          ))}
        </div>
        <Content>
          <div className="logo">
            <img src={images.goal} alt="Graph" />
          </div>
          <div className="text">
            <AiFillStar />
            <p>Your business profile is at 50%</p>
          </div>
          <div className="title">Take Required Actions to Complete your Profile</div>
          <Button>Complete Business Profile</Button>
        </Content>
      </Container>
    </Modal>
  );
}

export default ProgressModal;
