import styled from 'styled-components';
import images from '../../../constants';
import { motion } from 'framer-motion';

export const Container = styled.div`
  position: sticky;
  top: 6rem;
  background-color: transparent;
  z-index: 2;
  border-radius: 10px 10px 0 0;

  .space {
    height: 2rem;
    backdrop-filter: blur(100px);
  }

  .content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 2rem;
    height: 16rem;
    border-radius: 1rem;
    margin-top: 0.5rem;
    position: relative;
  }

  .bg_image {
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    transform: translate(-50%, -50%);
    z-index: 1;
    filter: blur(1px);
    -webkit-filter: blur(1px);
    border-radius: 1rem;
    height: 100%;
    width: 100%;
    background-image: url(${images.topBackground});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
`;

export const Logo = styled.div`
  width: 12rem;
  height: 12rem;
  border-radius: 50%;
  position: relative;
  border: 3px solid white;
  z-index: 2;
  margin-left: 2rem;

  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
  }

  div {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 3rem;
    height: 3rem;
    background-color: var(--color-accent);
    border-radius: 50%;
    border: 1px solid white;
    display: grid;
    place-items: center;
    transition: all 0.2s ease-in-out;
    cursor: pointer;

    &:hover {
      background-color: var(--color-accent-dark);
      transform: scale(1.1);
    }

    &:active {
      transform: scale(1);
    }

    svg {
      display: block;
      width: 2rem;
      height: 2rem;
      fill: white;
    }
  }
`;

export const Info = styled.div`
  flex: 1;
  color: white;
  letter-spacing: 0.4px;
  z-index: 2;
  margin-right: 2rem;

  h3 {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }

  p {
    font-size: 1.6rem;
    font-weight: 400;
  }
`;

export const EditButton = styled(motion.button)`
  background-color: var(--color-primary);
  margin-top: 2rem;
  padding: 1rem 2rem;
  border-radius: 5px;
  color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
`;
