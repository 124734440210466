import React from 'react';
import { Check, Container } from './styles';
import { AiOutlineCheck } from 'react-icons/ai';

const CourseProgress = ({ lesson }) => (
  <Container>
    <Check completed={false}>
      <AiOutlineCheck />
    </Check>
    <p>{lesson.title}</p>
  </Container>
);

export default CourseProgress;
