import styled from 'styled-components';

export const Container = styled.section`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 2rem;
  margin-bottom: 3rem;
`;

export const Title = styled.h2`
  font-weight: bold;
  font-size: 2rem;
  letter-spacing: 0.5px;
  margin: 1.6rem 0 1rem;
`;

export const Card = styled.div`
  font-size: 1.72rem;
  letter-spacing: 0.5px;
  text-transform: capitalize;
  position: relative;
  padding: 1.6rem 1.6rem 4rem;
  border-radius: 10px;
  border: 2px solid transparent;
  background-color: var(--color-accent-light);

  &.active {
    border-color: var(--color-accent);
  }

  .flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
  }

  img {
    height: 5rem;
    width: auto;
  }

  h3 {
    font-weight: bold;
    font-size: 3.2rem;
  }
`;

export const Arrow = styled.div`
  display: grid;
  place-items: center;
  padding: 1.2rem;
  border-radius: 50%;
  cursor: pointer;
  position: absolute;
  bottom: -2rem;
  right: 1.6rem;
  background-color: var(--color-accent);
  transition: 0.3s ease all;

  svg {
    width: 2rem;
    height: 2rem;
    fill: black;
    transition: 0.3s ease all;
  }

  /*&:hover,
  &.active {
    background-color: var(--color-blue-1);

    svg {
      fill: white;
      transform: rotate(45deg);
    }
  }*/
`;
