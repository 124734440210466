import React from 'react';
import styles from './Funding.module.scss';
import FundingAssets from '../../../components/Funding/FundingAssets/FundingAssets';
import LoanCalculator from '../../../components/Funding/LoanCalculator/LoanCalculator';

function FundingApply() {
  return (
    <section className={styles.funding}>
      <div>
        <FundingAssets />
      </div>
      <div className={styles.sidebar}>
        <LoanCalculator />
      </div>
    </section>
  );
}

export default FundingApply;
