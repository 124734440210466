import React from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { closeModal, selectCurrentModal } from '../../features/modal/modalSlice';
import { MODALS_MAPPING } from './modalTypes';

const Modal = () => {
  const dispatch = useAppDispatch();
  const modalType = useAppSelector(selectCurrentModal);
  const ModalComponent = MODALS_MAPPING[modalType];
  const onCloseModal = () => dispatch(closeModal());
  return <>{modalType && <ModalComponent onClose={onCloseModal} />}</>;
};

export default Modal;
