import styled from 'styled-components';
import { motion } from 'framer-motion';

export const DocumentsContainer = styled.div`
  border-radius: 10px;
  background-color: white;
  padding: 1.6rem;
  letter-spacing: 0.4px;
  margin-top: 2rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  .title {
    font-size: 1.72rem;
    font-weight: bold;
    margin-bottom: 1rem;
    margin-top: -7rem;
    text-align: center;
  }

  .subtitle {
    font-size: 1.6rem;
    font-weight: 400;
    text-align: center;
    color: #707070;
    margin-bottom: 4rem;
  }

  .upload {
    display: block;
    margin: 0 auto;
    width: 80%;
    height: auto;
    object-fit: cover;
  }
`;

export const Title = styled.div`
  font-size: 1.92rem;
  letter-spacing: 0.58px;
`;

export const Header = styled.div`
  background-color: var(--color-primary);
  color: white;
  padding: 1.6rem;
  border-radius: 10px 10px 0 0;
  margin: -1.6rem -1.6rem 1.6rem;
  font-size: 2rem;
  font-weight: bold;
  letter-spacing: 0.6px;
`;

export const Doc = styled.div`
  border-bottom: 1px solid #e4e4e4;
  padding: 0.5rem 0;
  display: flex;
  column-gap: 1rem;
  align-items: center;
  justify-content: space-between;
  font-size: 1.6rem;
  letter-spacing: 0.48px;
`;

export const DocImage = styled.img`
  width: 48px;
  height: 48px;
`;

export const Text = styled.p`
  flex: 1;
  max-width: 15rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
`;

export const DocType = styled.p`
  text-transform: uppercase;
  color: var(--color-primary);
  font-weight: bold;
  padding: 0.4rem;
  border: 1px solid #707070;
  border-radius: 10px;
`;

export const DocSize = styled.div`
  color: #707070;
`;

export const Button = styled(motion.button)`
  background-color: var(--color-green) !important;
  color: #ffffff;
  text-transform: capitalize;
  padding: 1.92rem 1.6rem;
  text-align: center;
  letter-spacing: 0.6px;
  margin: 2rem auto 0;
`;

export const Pending = styled(Doc)`
  display: grid;
  align-items: center !important;
  grid-template-columns: 3rem 1fr max-content;
  column-gap: 2rem;
  padding: 1.6rem 0;

  .error {
    width: 2rem;
    height: auto;
  }

  .middle {
    display: flex;
    column-gap: 1.6rem;
    align-items: center;
  }

  .icon {
    width: 4.8rem;
    height: auto;
  }

  svg {
    width: 100%;
    height: auto;
    fill: var(--color-primary);
  }

  .actions {
    display: flex;
    column-gap: 1.6rem;
    align-items: center;

    img {
      width: 3rem;
    }
  }
`;

export const UploadBtn = styled(motion.button)`
  background-color: transparent;
  color: var(--color-accent);
  text-transform: capitalize;
  padding: 1rem 1.6rem;
  text-align: center;
  letter-spacing: 0.6px;
  border: 1px solid #707070;
  width: 100%;
  font-size: 1.6rem;
  border-radius: 5px;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: var(--color-accent);
    color: white;
    border: 1px solid var(--color-accent);
  }

  &:active {
    background-color: var(--color-accent-dark);
    transform: scale(0.98);
  }
`;
