import styled from 'styled-components';
import { AiOutlineClose } from 'react-icons/ai';

export const LoanWrapper = styled.div`
  border-radius: 10px;
  padding: 3rem 2rem;
  max-width: 100rem;
  margin: 0 auto;
`;

export const Header = styled.section`
  background-color: var(--color-primary);
  border-radius: 10px 10px 0 0;
  padding: 1.6rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.6rem;
  letter-spacing: 0.3px;
  color: white;
`;
export const HeaderActions = styled.div`
  display: flex;
  column-gap: 3rem;
  justify-content: space-around;
  align-items: center;
`;
export const Icon = styled.div`
  padding: 0.8rem;
  background-color: var(--color-accent);
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: max-content;

  svg {
    width: 2rem;
    height: 2rem;
    fill: black;
  }
`;
export const Action = styled.div`
  display: flex;
  column-gap: 0.7rem;
  align-items: center;
  text-transform: uppercase;
  font-size: 1.72rem;
`;
export const Close = styled(AiOutlineClose)`
  width: 3rem;
  height: 3rem;
  cursor: pointer;
  fill: white;
`;

export const Button = styled.button`
  background-color: var(--color-primary);
  padding: 2.4rem 5rem;
  width: 100%;
  font-size: 1.6rem;
  font-weight: 500;
  margin-bottom: 1rem;
  text-transform: uppercase;
  margin-top: 3rem;
  color: white;
`;

export const Container = styled.div`
  padding: 1.6rem;
  border-radius: 5px;
  border: 1px solid #707070;

  label {
    font-size: 1.7rem;
    font-weight: 500;
    margin-bottom: 0.5rem;
    color: #707070;
  }

  input {
    width: 100%;
    padding: 1.6rem;
    border: 1px solid var(--color-accent);
    border-radius: 5px;
    font-size: 1.6rem;
    margin-bottom: 1.6rem;
  }
`;
