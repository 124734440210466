import { apiSlice } from '../../app/api/apiSlice';

const resetApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    resetPassword: build.mutation({
      query: (input) => {
        const data = new FormData();
        for (const inputKey in input) {
          data.append(inputKey, input[inputKey]);
        }
        return {
          url: 'auth/password/reset',
          method: 'POST',
          body: data,
        };
      },
    }),
    verifyOtp: build.mutation({
      query: (input) => {
        const data = new FormData();
        data.append('otp', input);
        return {
          url: 'auth/password/verify/otp',
          method: 'POST',
          body: data,
        };
      },
    }),
    forgotPassword: build.mutation({
      query: (email: string) => {
        const data = new FormData();
        data.append('login', email);
        return {
          url: 'auth/password/forgot',
          method: 'POST',
          body: data,
        };
      },
    }),
  }),
});

export const { useResetPasswordMutation, useVerifyOtpMutation, useForgotPasswordMutation } = resetApiSlice;
