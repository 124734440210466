import React, { useEffect } from 'react';
import { Logo, Wrapper } from './styles';
import images from '../../constants';
import { useAppDispatch } from '../../app/hooks';
import { openModal } from '../../features/modal/modalSlice';
import { MODAL_TYPES } from '../../modules/ModalWindow/modalTypes';

const ResetPassword = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(openModal({ modalType: MODAL_TYPES.FORGOT_PASSWORD_MODAL }));
  }, []);
  return (
    <Wrapper>
      <Logo src={images.logoWhite} alt={'Melanin Kapital Logo'} />
      <div className="left" />
    </Wrapper>
  );
};

export default ResetPassword;
