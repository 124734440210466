import React, { useState } from 'react';
import { CheckBtn, Head, Header, OptGrid, PayMode, PayOpt, Row, Specifications, Status, Table } from './styles';
import { AnimatePresence, motion } from 'framer-motion';
import { HiOutlineArrowLongRight } from 'react-icons/hi2';
import { BiInfoCircle } from 'react-icons/bi';
import { MinDiv } from '../LoanCalculator/styles';
import RangeSlider from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';
import { useAppDispatch } from '../../../app/hooks';
import { openModal } from '../../../features/modal/modalSlice';
import { MODAL_TYPES } from '../../../modules/ModalWindow/modalTypes';

const asset = {
  info: {
    vendor: 'Stima Boda',
    logo: 'https://firebasestorage.googleapis.com/v0/b/nectar-f5ee3.appspot.com/o/dashboard%2Fstima-boda.png?alt=media&token=877efbc3-781c-41e4-a745-4ade3a8d6860',
    model: 'SUZUKI GSX-R600',
  },
  asset:
    'https://firebasestorage.googleapis.com/v0/b/nectar-f5ee3.appspot.com/o/bike.png?alt=media&token=0be548e4-a7b1-447c-8194-85448b88fb79',
  price: 500_000,
  quantity: 1,
  paid: 0,
  balance: 500_000,
  status: 'Approved',
};
const PaymentChoice = () => {
  const dispatch = useAppDispatch();

  const onPay = () => {
    dispatch(openModal({ modalType: MODAL_TYPES.ASSET_PAYMENT }));
  };

  const [selected, setSelected] = useState(0);
  const [options, setOptions] = useState(1);
  const min = 50_000;
  const max = 500_000;
  const [myLoan, setLoan] = useState([min, min]);

  const oneTime = (
    <h3 className={'title'}>
      500,000 <sup>ksh</sup>
    </h3>
  );

  const installment = (
    <>
      <OptGrid>
        <PayOpt onClick={() => setOptions(1)}>
          <CheckBtn>
            <input type="radio" name="radio-btn" checked={options === 1} />
            <span className="checkmark"></span>
          </CheckBtn>
          <div className="content">
            <div>
              <h3 className={'title'}>
                167,000 <sup>ksh</sup>
              </h3>
              <p className="interval">per month</p>
            </div>
            <HiOutlineArrowLongRight className="arrow" />
            <div>
              <p className="count">Over 3 Months</p>
              <p className="price">KSH 5,600 daily</p>
            </div>
          </div>
          <BiInfoCircle className="info" />
        </PayOpt>
        <PayOpt onClick={() => setOptions(3)}>
          <CheckBtn>
            <input type="radio" name="radio-btn" checked={options === 3} />
            <span className="checkmark"></span>
          </CheckBtn>
          <div className="content">
            <div>
              <h3 className={'title'}>
                42,000 <sup>ksh</sup>
              </h3>
              <p className="interval">per month</p>
            </div>
            <HiOutlineArrowLongRight className="arrow" />
            <div>
              <p className="count">Over 12 Months</p>
              <p className="price">KSH 1,400 daily</p>
            </div>
          </div>
          <BiInfoCircle className="info" />
        </PayOpt>
      </OptGrid>
      <OptGrid className={'last'}>
        <PayOpt onClick={() => setOptions(2)}>
          <CheckBtn>
            <input type="radio" name="radio-btn" checked={options === 2} />
            <span className="checkmark"></span>
          </CheckBtn>
          <div className="content">
            <div>
              <h3 className={'title'}>
                84,000 <sup>ksh</sup>
              </h3>
              <p className="interval">per month</p>
            </div>
            <HiOutlineArrowLongRight className="arrow" />
            <div>
              <p className="count">Over 6 Months</p>
              <p className="price">KSH 2,800 daily</p>
            </div>
          </div>
          <BiInfoCircle className="info" />
        </PayOpt>
        <PayOpt onClick={() => setOptions(4)}>
          <CheckBtn>
            <input type="radio" name="radio-btn" checked={options === 4} />
            <span className="checkmark"></span>
          </CheckBtn>
          <div className="content">
            <div className={'main'}>
              <h3 className={'title'}>Other</h3>
              <p className="interval">pick an amount</p>
              <div className="slider">
                <RangeSlider
                  id={'range-slider'}
                  rangeSlideDisabled={true}
                  min={min}
                  max={max}
                  step={100}
                  value={myLoan}
                  onInput={setLoan}
                  thumbsDisabled={[true, false]}
                />
                <MinDiv>
                  <p>Ksh {min.toLocaleString()}</p>
                  <p>Ksh {max.toLocaleString()}</p>
                </MinDiv>
              </div>
              <div className="footer">
                <p />
                <div className={''}>
                  <h3 className={'title'}>
                    {myLoan[1].toLocaleString()} <sup>ksh</sup>
                  </h3>
                  <p className="interval">per month</p>
                </div>
                <p className="price">KSH {(myLoan[1] / 30).toFixed(2).toLocaleString()} daily</p>
              </div>
            </div>
          </div>
          <BiInfoCircle className="info" />
        </PayOpt>
      </OptGrid>
    </>
  );

  let color;
  if (asset.status === 'Approved') color = '#018158';
  return (
    <>
      <Header>
        <h3>Asset Request #001</h3>
      </Header>
      <Table>
        <Head>
          <tr>
            <th>Asset Information</th>
            <th>Asset</th>
            <th>Price (KSH)</th>
            <th>Quantity</th>
            <th>Paid</th>
            <th>Balance</th>
            <th>Approval Status</th>
          </tr>
        </Head>
        <tbody>
          <Row>
            <td className={'info'}>
              <div className="image">
                <img src={asset.info.logo} alt="Vendor logo" />
              </div>
              <div>
                <h4>{asset.info.vendor}</h4>
                <p>{asset.info.model}</p>
              </div>
            </td>
            <td className={'asset'}>
              <img src={asset.asset} alt="Asset photo" />
            </td>
            <td>{asset.price}</td>
            <td>{asset.quantity}</td>
            <td>{asset.paid}</td>
            <td>{asset.balance}</td>
            <td>
              <Status color={color}>{asset.status}</Status>
            </td>
          </Row>
        </tbody>
      </Table>
      <Specifications>
        <h3>Specifications</h3>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi accumsan aliquet felis non congue. Mauris a
          purus massa. Praesent suscipit congue leo, ut condimentum turpis pretium sit amet. Vestibulum nibh mi,
          placerat nec sodales quis, fermentum quis nunc. Aenean ligula turpis, elementum ut varius non, aliquet non
          nibh. Nunc consequat rhoncus quam vel condimentum. Duis ut sollicitudin diam. Nullam lobortis pretium quam
          eget bibendum. Integer eu leo sit amet ante ultricies vestibulum.
        </p>
      </Specifications>
      <PayMode>
        <div className="head">
          <h2 onClick={() => setSelected(0)} className={`${selected === 0 ? 'active' : ''}`}>
            One Time Purchase
          </h2>
          <h2 onClick={() => setSelected(1)} className={`${selected === 0 ? '' : 'active'}`}>
            Installment Purchase
          </h2>
        </div>
        <AnimatePresence mode={'wait'}>
          <motion.div
            key={selected}
            initial={{ y: 10, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: -10, opacity: 0 }}
            transition={{ duration: 0.2 }}>
            {selected === 0 ? oneTime : installment}
          </motion.div>
        </AnimatePresence>
        <button className="pay_btn" onClick={onPay}>
          Pay Now <HiOutlineArrowLongRight />
        </button>
      </PayMode>
    </>
  );
};

export default PaymentChoice;
