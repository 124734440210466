import { createEntityAdapter, createSelector } from '@reduxjs/toolkit';
import { apiSlice } from '../../app/api/apiSlice';
import { ACCEPTED } from '../../types/api-responses';

const adapter = createEntityAdapter();
const initialState = adapter.getInitialState();

const acceptedApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getAccepted: build.query<any, void>({
      query: () => '/conversation/all',
      transformResponse: (rawResult: any) => {
        return rawResult?.response;
      },
      providesTags: () => [{ type: ACCEPTED, id: 'LIST' }],
    }),
    sendMessage: build.mutation({
      query: (data) => {
        const fData = new FormData();
        for (const dataKey in data) {
          fData.append(dataKey, data[dataKey]);
        }
        return {
          url: 'message/create',
          method: 'POST',
          body: fData,
        };
      },
    }),
  }),
});

export const { useGetAcceptedQuery, useSendMessageMutation } = acceptedApiSlice;
/*
const selectAcceptedResult = acceptedApiSlice?.endpoints?.getAccepted.select();
const selectAcceptedData = createSelector(selectAcceptedResult, (acceptedResult) => acceptedResult?.data);

export const { selectAll: selectAllAccepted } = adapter.getSelectors(
  (state: any) => selectAcceptedData(state) ?? initialState,
);
*/
