import React from 'react';
import styles from './BusinessProfileFill.module.scss';
import images from '../../constants';
import { AiOutlineCheck } from 'react-icons/ai';
import { BsArrowRight } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';

function BusinessProfileFill() {
  const steps = ['Create Account', 'Contact', 'Company', 'Operations', 'Shareholding', 'Board Members', 'Team Members'];
  const inputs = [
    'Company name',
    'Incorporation date',
    'Business registration date',
    'Upload Certificate of registration',
    'Registered address',
    'Office location( if different)',
    'City',
  ];
  const isActive = 'Company';

  const navigate = useNavigate();

  return (
    <main className={styles.container}>
      <section style={{ backgroundImage: `url(${images.africaLight})` }} className={styles.welcome}>
        <img className={styles.logo} src={images.logoWhite} alt="Melanin Kapital Logo" />
        <p className={styles.b_prof}>Business Profile</p>
        <div>
          <div style={{ width: '20%' }} />
        </div>
        <p className={styles.status}>20% Complete</p>
        <p className={styles.thanks}>Thank you for signing up!</p>
        <h3>Great job Tafari! We would be happy If you completed your business profile</h3>
        <h6>
          We will try and create a suitable account Based on your needs and goals. Either as An entrepreneur or investor
          we got you Covered
        </h6>
      </section>
      <section className={styles.main_content}>
        <div className={styles.options}>
          {steps.map((elem, index) => (
            <div className={`${styles.card} ${isActive === elem ? styles.card_active : ''}`} key={elem}>
              <div className={styles.check}>{isActive === elem ? index + 1 : <AiOutlineCheck />}</div>
              <p>{elem}</p>
            </div>
          ))}
        </div>
        <div className={styles.content}>
          <h1>Great job Tafari! We would be happy If you completed your business profile</h1>
          <div className={styles.inputs}>
            {inputs.map((choice) => (
              <input key={choice} type="text" placeholder={choice} />
            ))}
          </div>
          <button onClick={() => navigate('/dashboard/home/main')}>
            <span>Continue</span>
            <BsArrowRight />
          </button>
          <p onClick={() => navigate('/dashboard/home/main')}>Skip for now</p>
        </div>
      </section>
    </main>
  );
}

export default BusinessProfileFill;
