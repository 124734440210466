import React from 'react';
import { Comment, CommentDetails, CommentSection, CommentText } from './styles';
import images from '../../constants';
import TimeAgo from '../Common/TimeAgo';

const PostComments = ({ comments }) => (
  <CommentSection>
    {comments.map((comment) => (
      <div className={'content'}>
        <Comment key={comment.id}>
          <img src={images.placeholder} alt="User Avatar" />
          <CommentDetails>
            <span>Anonymous</span>
            <p>
              <TimeAgo timestamp={comment.created_at} />
            </p>
          </CommentDetails>
        </Comment>
        <CommentText>{comment.comment}</CommentText>
      </div>
    ))}
  </CommentSection>
);

export default PostComments;
