import styled from 'styled-components';

export const ImpactWrapper = styled.section`
  background-color: #fff;
  position: relative;
  margin-top: 2rem;
  border-radius: 10px;
  margin-right: 2rem;
  padding: 0 2rem 2rem;
  top: 9rem;

  .content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2rem;
  }
`;

export const Header = styled.div`
  background-color: var(--color-primary);
  border-radius: 10px 10px 0 0;
  padding: 2rem 1.6rem;
  font-size: 2rem;
  font-weight: 500;
  margin: 0 -2rem;
  margin-bottom: 1rem;
  color: white;
`;
export const HeadersWrapper = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  border-bottom: 1px solid #707070;
  padding-bottom: 0.5rem;
  margin-bottom: 1rem;

  .est {
    font-size: 1.7rem;
    font-weight: 500;
  }

  p {
    font-size: 1.6rem;
  }

  div {
    display: flex;
    column-gap: 1rem;
    align-items: baseline;

    svg {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
`;

export const Score = styled.h2`
  font-size: 8.4rem;
  font-weight: 500;
`;

export const Desc = styled.p`
  margin-top: 2rem;
  padding: 1rem;
  background-color: var(--color-green-1);
  font-size: 1.6rem;
`;

export const PieSection = styled.div`
  display: flex;
  column-gap: 1.5rem;
`;

export const ClassSummary = styled.div`
  span {
    height: 1.5rem;
    width: 1.5rem;
  }

  p {
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
  }

  h5 {
    font-size: 1.9rem;
    font-weight: 500;
  }
`;

export const HRule = styled.hr`
  height: 1px;
  border: none;
  background-color: #707070;
  margin: 1rem 0;
`;

export const What = styled.h4`
  font-size: 2rem;
  font-weight: 500;
  margin: 2rem 0;
`;

export const ClassesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 2rem;
`;

export const ClassesContent = styled.div`
  p {
    font-size: 1.6rem;
  }

  .head {
    font-weight: 500;
    margin-bottom: 0.8rem;
  }
`;
