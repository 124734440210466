import * as yup from 'yup';

export const schema = yup.object().shape({
  firstName: yup.string().min(3, 'Name should be more than 3 character long').required('Firstname is required'),
  lastName: yup.string().min(3, 'Name should be more than 3 character long').required('Lastname is required'),
  email: yup.string().email('Email is invalid, example: username@gmail.com').required('Email is required'),
  password: yup.string().min(6, 'Password must be at least 6 characters').required('Password is required'),
  confirmPassword: yup.string().oneOf([yup.ref('password'), null], 'Passwords must match'),
  program: yup.string().required('program is required'),
});
