import React from 'react';
import { HRule, Wrapper } from './styles';
import { Button, Text, Title } from '../Overview/RecommendedClasses/styles';
import { useNavigate } from 'react-router-dom';
import pageRoutes from '../../../constants/pageRoutes';
import { useEnrollMutation } from '../../../features/enroll/enrollApiSlice';
import { useGetUserQuery } from '../../../features/user/userApiSlice';

const MasterClassCard = ({ course }) => {
  const { data: user } = useGetUserQuery();
  const navigate = useNavigate();
  const link = `${pageRoutes.COURSE}/${course.id}`;

  const [enroll, { isLoading }] = useEnrollMutation();
  const buttonContent = isLoading ? <div className={'container dots-flow'} /> : 'Enroll';

  const handleEnroll = async () => {
    const enrollInfo = {
      course_id: course.id,
      user_id: user.id,
      start_date: new Date().toISOString().substring(0, 10),
      created_by: user.id,
    };
    try {
      await enroll(enrollInfo).unwrap();
      navigate(link);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Wrapper>
      <img src={course.poster || 'https://via.placeholder.com/300x200'} alt={course.name} />
      <div className="content">
        <Title>{course.name}</Title>
        <Text>{course.description}</Text>
        <HRule />
        <Button onClick={handleEnroll} whileHover={{ backgroundColor: '#014e35' }} whileTap={{ scale: 0.96 }}>
          {buttonContent}
        </Button>
      </div>
    </Wrapper>
  );
};

export default MasterClassCard;
