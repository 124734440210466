import styled from 'styled-components';
import images from '../../constants';

export const Container = styled.main`
  display: grid;
  grid-template-columns: 1fr 1fr;
  min-height: 100vh;

  .progress-text {
    font-size: 1.8rem;
    font-weight: bold;
  }

  .btns {
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
    align-items: center;
    margin-top: auto;
  }

  .flex {
    display: flex;
    align-items: flex-end;
    column-gap: 2rem;

    .large {
      flex: 1;
    }
  }

  .right {
    display: flex;
    flex-direction: column;
    padding: 4rem;
  }

  .chips {
    flex: 1rem;
  }
`;

export const Main = styled.section`
  background-color: var(--color-primary);
  padding: 4rem;
  display: flex;
  flex-direction: column;
  height: 100%;

  .title {
    font-size: 4rem;
    color: #ffffff;
    font-weight: bold;
    margin-bottom: 2rem;
    display: flex;
    align-items: center;
    column-gap: 2rem;
    margin-top: 3rem;

    svg {
      fill: #ffffff;
      width: 8rem;
      height: 8rem;
    }
  }

  .bg {
    background-image: url(${images.africaDark});
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    flex: 1;
  }

  .logo {
    height: 5rem;
    margin-right: auto;
  }
`;
export const Header = styled.div`
  border-bottom: 3px solid #ffffff;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 3rem;
  color: #ffffff;
  font-size: 2rem;
  border-radius: 3px;

  .header-item {
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &:hover {
      color: var(--color-accent);
      transform: scale(1.1);
    }

    &.active {
      color: var(--color-accent);

      &::after {
        content: '';
        display: block;
        width: 100%;
        height: 4px;
        background-color: var(--color-accent);
        border-radius: 3px;
        position: relative;
        top: 0.4rem;
      }
    }
  }
`;

export const ProgressBar = styled.div`
  width: 100%;
  height: 1rem;
  background-color: #707070;
  border-radius: 10px;
  margin-top: 0.8rem;

  div {
    height: 100%;
    background-color: var(--color-accent);
    border-radius: 10px;
    transition: all 0.2s ease-in-out;
  }
`;

export const Button = styled.button`
  background-color: var(--color-primary);
  display: flex;
  align-items: center;
  column-gap: 2rem;
  padding: 2rem 3rem;
  border: 2px solid var(--color-primary);
  border-radius: 10px;
  font-weight: bold;
  color: #ffffff;
  transition: all 0.2s ease-in-out;

  svg {
    fill: #ffffff;
    width: 2rem;
    height: 2rem;
  }

  &.cancel {
    background-color: #fff;
    color: var(--color-accent);
    border: 2px solid var(--color-accent);

    &:hover {
      background-color: var(--color-accent);
      color: #000000;
    }
  }

  &:hover {
    background-color: var(--color-primary-dark);
  }
`;

export const Title = styled.h1`
  font-size: 3rem;
  margin-bottom: 4rem;
  margin-top: 3rem;
`;

export const Chip = styled.div<{ active: boolean }>`
  background-color: ${({ active }) => (active ? 'var(--color-primary)' : 'var(--color-accent-light)')};
  color: ${({ active }) => (active ? '#ffffff' : '#000000')};
  padding: 2rem 2rem;
  width: 100%;
  border-radius: 100px;
  font-size: 2rem;
  text-align: center;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:not(:last-child) {
    margin-bottom: 2rem;
  }

  &:hover {
    background-color: ${({ active }) => (active ? 'var(--color-primary-dark)' : 'var(--color-accent-light2)')};
  }
`;

export const InputDiv = styled.div`
  display: flex;
  column-gap: 2rem;
  margin-top: 2rem;
  width: 100%;

  input {
    background-color: var(--color-accent-light);
    border: none;
    padding: 2rem 2rem;
    font-size: 2rem;
    outline: none;
    width: 100%;
    flex: 1;
    border-radius: 10px;

    &::placeholder {
      color: #707070;
    }
  }

  select {
    background-color: var(--color-accent-light);
    border: none;
    padding: 2rem 2rem;
    font-size: 2rem;
    outline: none;
    border-radius: 10px;
  }
`;

export const DONT = styled(Chip)`
  border-radius: 10px;
  margin-top: 2rem;
  margin-bottom: 2rem;
`;

export const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 2rem;

  svg {
    fill: black;
    width: 3rem;
    height: 3rem;
  }
`;

export const Input = styled.input`
  background-color: var(--color-accent-light);
  border: none;
  padding: 2rem 2rem;
  font-size: 2rem;
  outline: none;
  width: 100%;
  flex: 1;
  margin-bottom: 2rem;
  border-radius: 10px;

  &::placeholder {
    color: #707070;
  }
`;

export const Back = styled.div`
  margin-bottom: 1rem;
  display: grid;
  place-items: center;
  width: 4rem;
  height: 4rem;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  background-color: #cccccc;
  border-radius: 50%;

  &:hover {
    background-color: var(--color-accent-light);

    svg {
      fill: white;
    }
  }

  svg {
    fill: black;
    width: 3rem;
    height: 3rem;
    transition: all 0.2s ease-in-out;
  }
`;
