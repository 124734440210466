import React, { useState } from 'react';
import { Action, Button, Close, Container, Header, HeaderActions, Icon, LoanWrapper } from './styles';
import Modal from 'react-modal';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { closeModal, selectCurrentModal } from '../../../../features/modal/modalSlice';
import { MODAL_TYPES } from '../../modalTypes';
import { selectCurrentLoan, setApplicationLoan } from '../../../../features/loans/loanApplicationSlice';
import { useApplyLoanMutation } from '../../../../features/loans/loansApiSlice';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useGetUserQuery } from '../../../../features/user/userApiSlice';
import { BsChatDots } from 'react-icons/bs';
import { BiPhoneCall } from 'react-icons/bi';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    zIndex: 100,
    border: 'none',
    width: '60%',
    padding: 0,
    borderRadius: '10px',
  },
};

const ApplyLoanModal = () => {
  const dispatch = useAppDispatch();
  const { data: user } = useGetUserQuery();
  const modalType = useAppSelector(selectCurrentModal);
  const isOpen = modalType === MODAL_TYPES.APPLY_LOAN;
  const [purpose, setPurpose] = useState('');
  const onSubmitHandler = () => {
    dispatch(closeModal());
  };

  const currentLoan = useAppSelector(selectCurrentLoan);
  const isDirty = purpose.length === 0 || currentLoan.amount <= 0 || currentLoan.months <= 0;

  const [applyLoan, { isLoading }] = useApplyLoanMutation();

  const submitLoan = async () => {
    let date = new Date();
    const myDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    const inputData = {
      updated_by: user.id,
      created_by: user.id,
      start_date: myDate,
      amount: currentLoan.amount,
      business_id: 1,
      product_id: 1,
      description: purpose,
      int_rate: 35,
      installments_in_month: currentLoan.amount / currentLoan.months,
    };
    try {
      // await applyLoan({ ...inputData }).unwrap();
      toast('Loan application was sent successfully');
      dispatch(setApplicationLoan({ amount: 0, months: 0 }));
    } catch (e) {
      toast.error('Something went wrong!');
      console.log(e);
    }
  };

  const buttonContent = isLoading ? <div className={'container dots-flow'} /> : 'Apply Loan';

  return (
    <Modal isOpen={isOpen} style={customStyles} ariaHideApp={false} overlayClassName="Overlay">
      <Header>
        <h2>Asset Application</h2>
        <HeaderActions>
          <p>Need any help?</p>
          <Action>
            <Icon>
              <BiPhoneCall />
            </Icon>
            Call us
          </Action>
          <Action>
            <Icon>
              <BsChatDots />
            </Icon>
            Raise Ticket
          </Action>
          <Close onClick={onSubmitHandler} />
        </HeaderActions>
      </Header>
      <LoanWrapper>
        <Container>
          <label>1.Asset Type</label>
          <input type="text" value={'Electric Bike'} />
          <label>2.Asset Vendor</label>
          <input type="text" value={'Stima Boda'} />
          <label>3.Total Asset price</label>
          <input type="number" value={'300000'} />
          <label>4.Number of Assets</label>
          <input type="number" value={'2'} />
          <label>5.Asset Purpose</label>
          <input type="text" value={''} />
        </Container>
        <Button>Submit Application</Button>
      </LoanWrapper>
      <ToastContainer />
    </Modal>
  );
};

export default ApplyLoanModal;
