import React from 'react';
import './coffee-loader.css';

const CoffeeLoader = () => (
  <div className={'coffee-container'}>
    <div className="coffee">
      <div />
      <div />
      <div />
    </div>
  </div>
);

export default CoffeeLoader;
