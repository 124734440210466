import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';

const BusinessRadar = ({ data }) => {
  const [state, setState] = useState({
    series: [
      {
        name: 'Series 1',
        data: [...data.map((item) => item.score)],
      },
    ],
    options: {
      chart: {
        height: 160,
        type: 'radar',
        toolbar: {
          show: false,
        },
      },
      fill: {
        opacity: 0.5,
        colors: ['#FFAB00DB'],
      },
      stroke: {
        show: true,
        width: 1,
        colors: ['#DC9400'],
        dashArray: 0,
      },
      markers: {
        size: 0,
        hover: {
          size: 1,
        },
      },
      xaxis: {
        categories: [1, 2, 3, 4],
      },
      yaxis: {
        show: false,
      },
    },
  });
  // @ts-ignore
  return <ReactApexChart options={state.options} series={state.series} type="radar" height={160} />;
};

export default BusinessRadar;
