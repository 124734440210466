import { createEntityAdapter, createSelector } from '@reduxjs/toolkit';
import { apiSlice } from '../../app/api/apiSlice';
import { CATEGORIES } from '../../types/api-responses';

const categoriesAdapter = createEntityAdapter();

const initialState = categoriesAdapter.getInitialState();

const categoriesApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getCategories: build.query<any, void>({
      query: () => '/category/list/all',
      transformResponse: (rawResult: any) => {
        return categoriesAdapter.setAll(initialState, rawResult);
      },
      providesTags: (result) =>
        result
          ? [...result.ids.map((id) => ({ type: CATEGORIES, id })), { type: CATEGORIES, id: 'LIST' }]
          : [{ type: CATEGORIES, id: 'LIST' }],
    }),
    getCategoryById: build.query<any, string>({
      query: (id: string) => `/category/${id}`,
      transformResponse: (rawResult: any) => rawResult?.response,
    }),
    addNewCategory: build.mutation({
      query: (category) => {
        const formData = new FormData();
        for (const categoryKey of category) {
          formData.append(categoryKey, category[categoryKey]);
        }
        return {
          url: '/lms/category/create',
          method: 'POST',
          body: formData,
        };
      },
      invalidatesTags: [{ type: CATEGORIES, id: 'LIST' }],
    }),
  }),
});

export const { useGetCategoriesQuery } = categoriesApiSlice;

export const selectCategoriesResult = categoriesApiSlice?.endpoints.getCategories.select();

const selectCategoriesData = createSelector(selectCategoriesResult, (categoriesResult) => categoriesResult?.data);

export const { selectAll: selectAllCategories } = categoriesAdapter.getSelectors(
  (state: any) => selectCategoriesData(state) ?? initialState,
);
