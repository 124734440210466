import { MPESA_DOC, PITCH_DOC, REG_DOC, TAX_DOC, VAT_DOC } from '../constants/constants';

export const refineExperts = (experts: any) =>
  experts.map((expert) => {
    return {
      id: expert.id,
      name: expert.name,
      imageUrl: expert.media[0].original_url,
      book: expert.other_info.booking_url,
      title: expert.other_info.title,
      company: expert.other_info.company,
      bio: expert.other_info.bio,
    };
  });

export const getDocuments = (collections, documents) => {
  return documents.filter((doc) => collections.includes(doc.collection_name));
};

export const extractName = (fullName) => {
  if (!fullName) {
    return null;
  }

  const words = fullName.trim().split(/\s+/);

  if (words.length === 1) {
    return {
      firstName: words[0],
      lastName: null,
    };
  }

  if (words.length === 2) {
    return {
      firstName: words[0],
      lastName: words[1],
    };
  }

  const lastName = words.pop();
  const firstName = words.join(' ');

  return {
    firstName,
    lastName,
  };
};

export const getPendingDocuments = (names, documents) => {
  const namesSet = new Set(documents.map((obj) => obj.collection_name));
  return names.filter((name) => !namesSet.has(name));
};

export function getDateFromString(dateString) {
  if (dateString === '' || null) {
    return new Date(); // Return current date if string is empty
  } else {
    return new Date(dateString); // Convert string to date object
  }
}

const countryList = [
  {
    country: 'Afghanistan',
  },
  {
    country: 'Albania',
  },
  {
    country: 'Algeria',
  },
  {
    country: 'American Samoa',
  },
  {
    country: 'Andorra',
  },
  {
    country: 'Angola',
  },
  {
    country: 'Anguilla',
  },
  {
    country: 'Antarctica',
  },
  {
    country: 'Antigua and Barbuda',
  },
  {
    country: 'Argentina',
  },
  {
    country: 'Armenia',
  },
  {
    country: 'Aruba',
  },
  {
    country: 'Australia',
  },
  {
    country: 'Austria',
  },
  {
    country: 'Azerbaijan',
  },
  {
    country: 'Bahamas',
  },
  {
    country: 'Bahrain',
  },
  {
    country: 'Bangladesh',
  },
  {
    country: 'Barbados',
  },
  {
    country: 'Belarus',
  },
  {
    country: 'Belgium',
  },
  {
    country: 'Belize',
  },
  {
    country: 'Benin',
  },
  {
    country: 'Bermuda',
  },
  {
    country: 'Bhutan',
  },
  {
    country: 'Bolivia',
  },
  {
    country: 'Bosnia and Herzegovina',
  },
  {
    country: 'Botswana',
  },
  {
    country: 'Bouvet Island',
  },
  {
    country: 'Brazil',
  },
  {
    country: 'British Indian Ocean Territory',
  },
  {
    country: 'Brunei',
  },
  {
    country: 'Bulgaria',
  },
  {
    country: 'Burkina Faso',
  },
  {
    country: 'Burundi',
  },
  {
    country: 'Cambodia',
  },
  {
    country: 'Cameroon',
  },
  {
    country: 'Canada',
  },
  {
    country: 'Cape Verde',
  },
  {
    country: 'Cayman Islands',
  },
  {
    country: 'Central African Republic',
  },
  {
    country: 'Chad',
  },
  {
    country: 'Chile',
  },
  {
    country: 'China',
  },
  {
    country: 'Christmas Island',
  },
  {
    country: 'Cocos (Keeling) Islands',
  },
  {
    country: 'Colombia',
  },
  {
    country: 'Comoros',
  },
  {
    country: 'Congo',
  },
  {
    country: 'The Democratic Republic of Congo',
  },
  {
    country: 'Cook Islands',
  },
  {
    country: 'Costa Rica',
  },
  {
    country: 'Ivory Coast',
  },
  {
    country: 'Croatia',
  },
  {
    country: 'Cuba',
  },
  {
    country: 'Cyprus',
  },
  {
    country: 'Czech Republic',
  },
  {
    country: 'Denmark',
  },
  {
    country: 'Djibouti',
  },
  {
    country: 'Dominica',
  },
  {
    country: 'Dominican Republic',
  },
  {
    country: 'East Timor',
  },
  {
    country: 'Ecuador',
  },
  {
    country: 'Egypt',
  },
  {
    country: 'England',
  },
  {
    country: 'El Salvador',
  },
  {
    country: 'Equatorial Guinea',
  },
  {
    country: 'Eritrea',
  },
  {
    country: 'Estonia',
  },
  {
    country: 'Ethiopia',
  },
  {
    country: 'Falkland Islands',
  },
  {
    country: 'Faroe Islands',
  },
  {
    country: 'Fiji Islands',
  },
  {
    country: 'Finland',
  },
  {
    country: 'France',
  },
  {
    country: 'French Guiana',
  },
  {
    country: 'French Polynesia',
  },
  {
    country: 'French Southern territories',
  },
  {
    country: 'Gabon',
  },
  {
    country: 'Gambia',
  },
  {
    country: 'Georgia',
  },
  {
    country: 'Germany',
  },
  {
    country: 'Ghana',
  },
  {
    country: 'Gibraltar',
  },
  {
    country: 'Greece',
  },
  {
    country: 'Greenland',
  },
  {
    country: 'Grenada',
  },
  {
    country: 'Guadeloupe',
  },
  {
    country: 'Guam',
  },
  {
    country: 'Guatemala',
  },
  {
    country: 'Guernsey',
  },
  {
    country: 'Guinea',
  },
  {
    country: 'Guinea-Bissau',
  },
  {
    country: 'Guyana',
  },
  {
    country: 'Haiti',
  },
  {
    country: 'Heard Island and McDonald Islands',
  },
  {
    country: 'Holy See (Vatican City State)',
  },
  {
    country: 'Honduras',
  },
  {
    country: 'Hong Kong',
  },
  {
    country: 'Hungary',
  },
  {
    country: 'Iceland',
  },
  {
    country: 'India',
  },
  {
    country: 'Indonesia',
  },
  {
    country: 'Iran',
  },
  {
    country: 'Iraq',
  },
  {
    country: 'Ireland',
  },
  {
    country: 'Israel',
  },
  {
    country: 'Isle of Man',
  },
  {
    country: 'Italy',
  },
  {
    country: 'Jamaica',
  },
  {
    country: 'Japan',
  },
  {
    country: 'Jersey',
  },
  {
    country: 'Jordan',
  },
  {
    country: 'Kazakhstan',
  },
  {
    country: 'Kenya',
  },
  {
    country: 'Kiribati',
  },
  {
    country: 'Kuwait',
  },
  {
    country: 'Kyrgyzstan',
  },
  {
    country: 'Laos',
  },
  {
    country: 'Latvia',
  },
  {
    country: 'Lebanon',
  },
  {
    country: 'Lesotho',
  },
  {
    country: 'Liberia',
  },
  {
    country: 'Libyan Arab Jamahiriya',
  },
  {
    country: 'Liechtenstein',
  },
  {
    country: 'Lithuania',
  },
  {
    country: 'Luxembourg',
  },
  {
    country: 'Macao',
  },
  {
    country: 'North Macedonia',
  },
  {
    country: 'Madagascar',
  },
  {
    country: 'Malawi',
  },
  {
    country: 'Malaysia',
  },
  {
    country: 'Maldives',
  },
  {
    country: 'Mali',
  },
  {
    country: 'Malta',
  },
  {
    country: 'Marshall Islands',
  },
  {
    country: 'Martinique',
  },
  {
    country: 'Mauritania',
  },
  {
    country: 'Mauritius',
  },
  {
    country: 'Mayotte',
  },
  {
    country: 'Mexico',
  },
  {
    country: 'Micronesia, Federated States of',
  },
  {
    country: 'Moldova',
  },
  {
    country: 'Monaco',
  },
  {
    country: 'Mongolia',
  },
  {
    country: 'Montserrat',
  },
  {
    country: 'Montenegro',
  },
  {
    country: 'Morocco',
  },
  {
    country: 'Mozambique',
  },
  {
    country: 'Myanmar',
  },
  {
    country: 'Namibia',
  },
  {
    country: 'Nauru',
  },
  {
    country: 'Nepal',
  },
  {
    country: 'Netherlands',
  },
  {
    country: 'Netherlands Antilles',
  },
  {
    country: 'New Caledonia',
  },
  {
    country: 'New Zealand',
  },
  {
    country: 'Nicaragua',
  },
  {
    country: 'Niger',
  },
  {
    country: 'Nigeria',
  },
  {
    country: 'Niue',
  },
  {
    country: 'Norfolk Island',
  },
  {
    country: 'North Korea',
  },
  {
    country: 'Northern Ireland',
  },
  {
    country: 'Northern Mariana Islands',
  },
  {
    country: 'Norway',
  },
  {
    country: 'Oman',
  },
  {
    country: 'Pakistan',
  },
  {
    country: 'Palau',
  },
  {
    country: 'Palestine',
  },
  {
    country: 'Panama',
  },
  {
    country: 'Papua New Guinea',
  },
  {
    country: 'Paraguay',
  },
  {
    country: 'Peru',
  },
  {
    country: 'Philippines',
  },
  {
    country: 'Pitcairn',
  },
  {
    country: 'Poland',
  },
  {
    country: 'Portugal',
  },
  {
    country: 'Puerto Rico',
  },
  {
    country: 'Qatar',
  },
  {
    country: 'Reunion',
  },
  {
    country: 'Romania',
  },
  {
    country: 'Russian Federation',
  },
  {
    country: 'Rwanda',
  },
  {
    country: 'Saint Helena',
  },
  {
    country: 'Saint Kitts and Nevis',
  },
  {
    country: 'Saint Lucia',
  },
  {
    country: 'Saint Pierre and Miquelon',
  },
  {
    country: 'Saint Vincent and the Grenadines',
  },
  {
    country: 'Samoa',
  },
  {
    country: 'San Marino',
  },
  {
    country: 'Sao Tome and Principe',
  },
  {
    country: 'Saudi Arabia',
  },
  {
    country: 'Scotland',
  },
  {
    country: 'Senegal',
  },
  {
    country: 'Serbia',
  },
  {
    country: 'Seychelles',
  },
  {
    country: 'Sierra Leone',
  },
  {
    country: 'Singapore',
  },
  {
    country: 'Slovakia',
  },
  {
    country: 'Slovenia',
  },
  {
    country: 'Solomon Islands',
  },
  {
    country: 'Somalia',
  },
  {
    country: 'South Africa',
  },
  {
    country: 'South Georgia and the South Sandwich Islands',
  },
  {
    country: 'South Korea',
  },
  {
    country: 'South Sudan',
  },
  {
    country: 'Spain',
  },
  {
    country: 'Sri Lanka',
  },
  {
    country: 'Sudan',
  },
  {
    country: 'Suriname',
  },
  {
    country: 'Svalbard and Jan Mayen',
  },
  {
    country: 'Swaziland',
  },
  {
    country: 'Sweden',
  },
  {
    country: 'Switzerland',
  },
  {
    country: 'Syria',
  },
  {
    country: 'Tajikistan',
  },
  {
    country: 'Tanzania',
  },
  {
    country: 'Thailand',
  },
  {
    country: 'Timor-Leste',
  },
  {
    country: 'Togo',
  },
  {
    country: 'Tokelau',
  },
  {
    country: 'Tonga',
  },
  {
    country: 'Trinidad and Tobago',
  },
  {
    country: 'Tunisia',
  },
  {
    country: 'Turkey',
  },
  {
    country: 'Turkmenistan',
  },
  {
    country: 'Turks and Caicos Islands',
  },
  {
    country: 'Tuvalu',
  },
  {
    country: 'Uganda',
  },
  {
    country: 'Ukraine',
  },
  {
    country: 'United Arab Emirates',
  },
  {
    country: 'United Kingdom',
  },
  {
    country: 'United States',
  },
  {
    country: 'United States Minor Outlying Islands',
  },
  {
    country: 'Uruguay',
  },
  {
    country: 'Uzbekistan',
  },
  {
    country: 'Vanuatu',
  },
  {
    country: 'Venezuela',
  },
  {
    country: 'Vietnam',
  },
  {
    country: 'Virgin Islands, British',
  },
  {
    country: 'Virgin Islands, U.S.',
  },
  {
    country: 'Wales',
  },
  {
    country: 'Wallis and Futuna',
  },
  {
    country: 'Western Sahara',
  },
  {
    country: 'Yemen',
  },
  {
    country: 'Zambia',
  },
  {
    country: 'Zimbabwe',
  },
];

export const getCountries = countryList.map((country) => ({ label: country.country, value: country.country }));

export const programsList = [
  { value: 1, label: 'Tuungane2XnaAbsa' },
  { value: 2, label: 'UNIDO - OYA Program' },
  { value: 3, label: 'GIZ-SAIS' },
  { value: 4, label: 'GIZ-CGIAR' },
  { value: 5, label: 'Falling Walls Lab Nairobi' },
  { value: 0, label: 'None' },
];

export const capitalizeWords = (str) => {
  const words = str.split(' ');
  const capitalizedWords = words.map((word) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  });

  return capitalizedWords.join(' ');
};

export const formatDuration = (durationInMinutes) => {
  if (durationInMinutes >= 60) {
    const hours = Math.floor(durationInMinutes / 60);
    const remainingMinutes = durationInMinutes % 60;
    return `${hours} hr${hours > 1 ? 's' : ''} ${remainingMinutes} min${remainingMinutes > 1 ? 's' : ''}`;
  } else {
    return `${durationInMinutes} min${durationInMinutes > 1 ? 's' : ''}`;
  }
};

export const covertBytesToMB = (sizeInKB) => {
  return (sizeInKB / 1024 / 1024).toFixed(2);
};

export const formatDate = (date) => {
  const dateArray = date.split('T')[0].split('-');
  return `${dateArray[2]}/${dateArray[1]}/${dateArray[0]}`;
};

export const getDocumentItem = (doc, data) => {
  switch (doc) {
    case REG_DOC:
      return {
        registration_document: data.registration_document[0],
      };
    case VAT_DOC:
      return {
        vat_document: data.vat_document[0],
      };
    case TAX_DOC:
      return {
        tax_document: data.tax_document[0],
      };
    case MPESA_DOC:
      return {
        financial_statement: data.financial_statement[0],
      };
    case PITCH_DOC:
      return {
        pitch_deck: data.pitch_deck[0],
      };
    default:
      return {};
  }
};
