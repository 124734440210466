import React from 'react';
import Modal from 'react-modal';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { closeModal, selectCurrentModal } from '../../../../features/modal/modalSlice';
import { MODAL_TYPES } from '../../modalTypes';
import { Button, Close, Container } from '../EnrollModal/styles';
import { AiOutlineClose } from 'react-icons/ai';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    zIndex: 100,
    border: 'none',
    padding: 0,
    width: '80%',
    maxWidth: '650px',
    borderRadius: '10px',
    backgroundColor: 'transparent',
  },
};

const QuizModal = () => {
  const dispatch = useAppDispatch();
  const modalType = useAppSelector(selectCurrentModal);
  const isOpen = modalType === MODAL_TYPES.QUIZ_MODAL;
  const onSubmitHandler = () => {
    dispatch(closeModal());
  };
  return (
    <Modal
      onRequestClose={onSubmitHandler}
      isOpen={isOpen}
      style={customStyles}
      ariaHideApp={false}
      overlayClassName="Overlay">
      <Container>
        <div className="content">
          <Close>
            <AiOutlineClose />
          </Close>
          <h4>Sorry!</h4>
          <p>You need to complete the previous lesson to get access to this lesson and its quizzes</p>
          <Button>GO BACK TO PREVIOUS LESSON</Button>
        </div>
      </Container>
    </Modal>
  );
};

export default QuizModal;
