import * as yup from 'yup';

export const schema = yup.object().shape({
  registration_document: yup
    .mixed()
    .test('fileSize', 'The file should be 10mb or less', (value) => !value || value[0]?.size <= 10 * 1024 * 1024),
  vat_document: yup
    .mixed()
    .test('fileSize', 'The file should be 10mb or less', (value) => !value || value[0]?.size <= 10 * 1024 * 1024),
  tax_document: yup
    .mixed()
    .test('fileSize', 'The file should be 10mb or less', (value) => !value || value[0]?.size <= 10 * 1024 * 1024),
  financial_statement: yup
    .mixed()
    .test('fileSize', 'The file should be 10mb or less', (value) => !value || value[0]?.size <= 10 * 1024 * 1024),
  pitch_deck: yup
    .mixed()
    .test('fileSize', 'The file should be 10mb or less', (value) => !value || value[0]?.size <= 10 * 1024 * 1024),
  front_ID: yup
    .mixed()
    .test('fileSize', 'The file should be 10mb or less', (value) => !value || value[0]?.size <= 10 * 1024 * 1024),
  back_ID: yup
    .mixed()
    .test('fileSize', 'The file should be 10mb or less', (value) => !value || value[0]?.size <= 10 * 1024 * 1024),
});
