import styled from 'styled-components';

export const DocumentsContainer = styled.div`
  border-radius: 10px;
  background-color: white;
  padding: 1.6rem;
  letter-spacing: 0.4px;

  .docs {
  }
`;

export const Container = styled.div`
  display: grid;
  grid-template-columns: 7rem 1fr;
  column-gap: 1.6rem;
  align-items: center;
  position: relative;
  border-radius: 5px;
  padding: 2rem 1.6rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem;

  .strip {
    position: absolute;
    height: 100%;
    background-color: var(--color-primary);
    width: 1rem;
    right: 0;
    top: 0;
    border-radius: 0 5px 5px 0;
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    .title {
      font-size: 1.6rem;
      font-weight: bold;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 100%;
      margin-bottom: 1.5rem;
    }
  }

  .btns {
    display: flex;
    column-gap: 1.6rem;
    width: 100%;
  }

  .view_btn {
    background-color: transparent;
    border: 1px solid var(--color-primary);
    color: var(--color-primary);
    padding: 0.8rem 1.6rem;
    border-radius: 5px;
    transition: all 0.3s ease;

    &:hover {
      background-color: var(--color-primary);
      color: white;
    }

    &:active {
      background-color: var(--color-primary-dark);
      color: white;
      transform: scale(0.95);
    }
  }

  .download_btn {
    background-color: var(--color-accent);
    color: black;
    padding: 0.8rem 1.6rem;
    border-radius: 5px;
    transition: all 0.3s ease;

    &:hover {
      background-color: var(--color-accent-dark);
      color: white;
    }

    &:active {
      transform: scale(0.95);
    }
  }
`;

export const DocAsset = styled.div`
  padding: 0.6rem 0.6rem 1.2rem;
  border-radius: 5px;
  display: grid;
  place-items: center;
  background-color: var(--color-accent-light);

  img {
    width: 100%;
    height: 100%;
  }
`;

export const Header = styled.div`
  color: white;
  background-color: var(--color-primary);
  padding: 1.6rem;
  margin: -1.6rem -1.6rem 1.6rem -1.6rem;
  border-radius: 10px 10px 0 0;
  font-size: 2rem;
  font-weight: bold;
  letter-spacing: 0.6px;
  position: sticky;
  top: 7rem;
  z-index: 2;
`;
