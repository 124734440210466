import styled from 'styled-components';
import { motion } from 'framer-motion';

export const MinDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0.4rem;

  p {
    font-size: 1.4rem;
    color: #707070;
  }
`;

export const CalculatorWrapper = styled.section`
  padding: 1.6rem;

  .slider {
    margin-top: 1.6rem;
  }

  #range-slider .range-slider__thumb[data-lower] {
    border: none;
    width: 0;
  }

  #range-slider .range-slider__range {
    border-radius: 6px;
    background-color: var(--color-accent);
  }

  #range-slider .range-slider__thumb[data-upper] {
    background-color: var(--color-accent);
    border: 0.5px solid var(--color-accent);
    width: 1.8rem;
    height: 1.8rem;
  }
`;

export const Header = styled.h3`
  font-weight: 500;
  font-size: 2.5rem;
  margin: -1.6rem -1.6rem 2.4rem;
  border-radius: 10px 10px 0 0;
  padding: 2rem;
  background-color: var(--color-primary-light-1);
`;

export const CalculatorContainer = styled.div`
  padding: 1.6rem;
  border-radius: 10px;
  border: 1px solid #707070;
`;

export const Label = styled.p`
  font-size: 1.6rem;
  font-weight: 500;
  color: #707070;
`;

export const Title = styled.p`
  font-size: 2.2rem;
  font-weight: 500;
`;

export const PrimaryText = styled.div`
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: var(--color-primary);
  text-align: center;
  padding: 1.6rem 1rem;

  p {
    color: white !important;
  }
`;

export const SecText = styled.div`
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  text-align: center;
  padding: 1.6rem 1rem;
  background-color: var(--color-primary-light-1);

  p {
    color: #000 !important;
  }
`;

export const HRule = styled.hr`
  border: none;
  margin: 1.6rem -1.6rem;
  background-color: #707070;
  height: 1px;
`;

export const BtnDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 2rem 0;
`;

export const Button = styled(motion.button)`
  background-color: var(--color-green);
  text-transform: uppercase;
  color: white;
  padding: 1.28rem 3rem;
  font-size: 1.3rem;
  border-radius: 5px;
  letter-spacing: 0.5px;
`;
