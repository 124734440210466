import React from 'react';
import { Card, Loading, Wrapper } from './styles';
import { Button, Text, Title } from '../Overview/RecommendedClasses/styles';
import { HRule } from '../Overview/CurrentClass/styles';
import images from '../../../constants';
import EmptyState from '../../EmptyState/EmptyState';
import { useGetUserQuery } from '../../../features/user/userApiSlice';
import { useGetEnrolledCoursesQuery } from '../../../features/enroll/enrollApiSlice';

const Enrolled = () => {
  const { data: user } = useGetUserQuery();
  const { data: enrolledClasses, isLoading, isSuccess, isError, error } = useGetEnrolledCoursesQuery(user?.id);
  let classes = [];

  if (isLoading) {
    return <Loading>loading</Loading>;
  } else if (isSuccess) {
    classes = enrolledClasses;
  } else if (isError) {
    console.log(error);
  }

  const lessons = {
    total: 5,
    done: 2,
  };
  const setProgressbar = () => {
    let progress = [];
    for (let i = 0; i < lessons.total; i++) {
      if (i < lessons.done) progress.push(<hr className="complete" />);
      else progress.push(<hr />);
    }
    return progress;
  };

  if (classes.length === 0) {
    const emptyContent = {
      title: 'You have not enrolled to any masterclasses yet',
      subtitle: 'Enroll today to easily view your progress',
      imageUrl: images.emptyClasses,
      header: 'Enrolled masterclasses',
    };
    return <EmptyState content={emptyContent} />;
  }

  return (
    <Wrapper>
      <Title>Enrolled masterclasses</Title>
      {classes.map((item) => (
        <Card>
          <img src={item.poster} alt={item.name} />
          <Title>{item.name}</Title>
          <Text>{item.description}</Text>
          <HRule />
          <Button>Continue</Button>
        </Card>
      ))}
    </Wrapper>
  );
};

export default Enrolled;
