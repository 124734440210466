import React from 'react';
import { useOutlet } from 'react-router-dom';

function WelcomeLayout() {
  const outlet = useOutlet();

  return <div>{outlet}</div>;
}

export default WelcomeLayout;
