import { createEntityAdapter, createSelector } from '@reduxjs/toolkit';
import { apiSlice } from '../../app/api/apiSlice';
import { BUILT_ACC } from '../../types/api-responses';

const appsAdapter = createEntityAdapter();
const initialState = appsAdapter.getInitialState();

export const appsApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getApps: build.query<any, void>({
      query: () => '/business/app/list',
      transformResponse(rawResult: any) {
        return appsAdapter.setAll(initialState, rawResult?.response?.data);
      },
    }),
    checkBuiltInApp: build.query<any, void>({
      query: () => `/builtaccounting/account/check`,
      transformResponse(rawResult: any) {
        return rawResult?.response;
      },
      providesTags: () => [{ type: BUILT_ACC, id: 'LIST' }],
    }),
    joinBuiltInApp: build.mutation({
      query: (input) => {
        const data = new FormData();
        for (const inputKey in input) {
          data.append(inputKey, input[inputKey]);
        }
        return {
          url: '/builtaccounting/signup',
          method: 'POST',
          body: data,
        };
      },
      invalidatesTags: () => [{ type: BUILT_ACC, id: 'LIST' }],
    }),
  }),
});

export const { useGetAppsQuery, useCheckBuiltInAppQuery, useJoinBuiltInAppMutation } = appsApiSlice;

/*const selectAppsResult = appsApiSlice?.endpoints.getApps.select();
const selectAppsData = createSelector(selectAppsResult, (result) => result?.data);
export const { selectAll: selectAllApps } = appsAdapter.getSelectors(
  (state: any) => selectAppsData(state) ?? initialState,
);*/
